const languageCode = {
  'en-US': 'en',
  km: 'kh',
  'ms-MY': 'ms',
  'th-TH': 'th',
  'vi-VN': 'vn',
  'zh-CN': 'cn',
  'zh-HK': 'zh',
  'ja-JP': 'jp',
  'id-ID': 'id',
}

export default languageCode
