import { Layout1_icon } from './layout1_icon'
import { Layout2_icon } from './layout2_icon'
import { Layout3_icon } from './layout3_icon'
import { Layout4_icon } from './layout4_icon'
import { Layout5_icon } from './layout5_icon'

export const getSVGIconsSet = (layout = 4) => {
  switch (layout) {
    case 1: {
      return Layout1_icon
    }
    case 2: {
      return Layout2_icon
    }
    case 3: {
      return Layout3_icon
    }
    case 4: {
      return Layout4_icon
    }
    case 5: {
      return Layout5_icon
    }
    default:
      break
  }
}

export const getSVGIcon = (iconsSet, icon) => {
  if (iconsSet[icon]) {
    return iconsSet[icon]
  } else {
    return iconsSet['placeholderIcon']
  }
}

// let svgIconsSet = getSVGIconsSet(1);
// getSVGIcon(svgIconsSet, 'taskIcon', 'red', 'blue');
