import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { TopBar3 } from 'shared-crm/components/LayoutComponents/TopBar'
import MenuBottom from 'shared-crm/components/LayoutComponents/Menu/MenuBottom'
import { Footer3 } from 'shared-crm/components/LayoutComponents/Footer'
import Breadcrumbs from 'shared-crm/components/LayoutComponents/Breadcrumbs'
import Settings from 'shared-crm/components/LayoutComponents/Settings'

const mapStateToProps = ({ settings, user }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isMobileView: settings.isMobileView,
  isLightTheme: settings.isLightTheme,
  brandSettings_layout: user.brandSettings_layout,
})

@withRouter
@connect(mapStateToProps)
@connect(({ settings }) => ({ settings }))
class MainLayout_3 extends React.PureComponent {
  componentDidMount() {
    const { dispatch, user_id } = this.props

    dispatch({
      type: 'user/SIDEBAR_MODULE_SETTINGS',
    })
    if (user_id) {
      dispatch({
        type: 'user/SIDEBAR_MODULE_USER_SETTINGS',
        payload: {
          user_id,
        },
      })
    }
  }
  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      isLightTheme,
      isMobileView,
      brandSettings_layout,
    } = this.props

    const pathName = this.props.location.pathname

    let isPathCommunity

    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false
    let hideBreadcrumbPathNameList = ['/dashboard/summary', '/markets']

    return (
      <Layout
        id="main_layout"
        className={`${classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
          lightTheme: isLightTheme,
          darkTheme: !isLightTheme,
        })}
        main_layout_${brandSettings_layout}`}
      >
        <Layout.Header style={{ position: 'fixed', width: '100%', top: 0, zIndex: 99 }}>
          <TopBar3 />
        </Layout.Header>
        <Layout
          className={`${
            pathName === '/dashboard/summary' ? 'layoutHomeBackground' : 'layoutContentBackground'
          }`}
        >
          <BackTop
            visible={pathName !== '/markets'}
            style={{
              bottom: isMobileView === true && isPathCommunity === true && '8rem',
              right: isMobileView === true && isPathCommunity === true && '2rem',
            }}
          />
          {isMobileView && <MenuBottom />}

          <Settings />
          {/* <Menu onMouseHover={onmouseover} /> */}
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div
              className={`utils__content h-100 ${
                hideBreadcrumbPathNameList.includes(pathName) ? 'p-0' : ''
              } ${pathName === '/markets' && 'maxWidth_unset'}`}
            >
              {!hideBreadcrumbPathNameList.includes(pathName) &&
                !pathName.includes('/community') && <Breadcrumbs />}
              {children}
            </div>
          </Layout.Content>
        </Layout>
        <Layout.Footer style={{ position: 'fixed', width: '100%', bottom: 0, zIndex: 99 }}>
          <Footer3 />
        </Layout.Footer>
      </Layout>
    )
  }
}

export default MainLayout_3
