import React from 'react'
import { Menu, Dropdown } from 'antd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ApiConfig from 'api/config'
import languageCode from '../../../../data/languageCode'

@injectIntl
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
class LanguageSelector1 extends React.Component {
  changeLang = ({ key }) => {
    const { isLoggedIn, dispatch, user } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    if (isLoggedIn) {
      dispatch({
        type: 'user/UPDATE_LANGUAGE',
        payload: {
          user_id: user.id,
          lg: languageCode[key],
        },
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props
    if (nextProps.user.user !== this.props.user.user) {
      if (this.props.isLoggedIn && nextProps.user.user.lg) {
        let lgCodeCRM = ''
        let lg = nextProps.user.user.lg
        if (nextProps.user.user.lg === 'my') {
          lg = 'ms'
        }
        lgCodeCRM = Object.keys(languageCode).find((key) => languageCode[key] === lg)
        if (!lgCodeCRM) {
          lgCodeCRM = 'en-US'
        }
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'locale',
            value: lgCodeCRM,
          },
        })
      }
    }
  }

  render() {
    const {
      isLoggedIn,
      // user: { brandSettings_layout },
      settings: { locale, isMobileView, isTabletView },
      intl: { formatMessage },
    } = this.props

    const nextradesURL = ApiConfig.nextradesURL

    const langMenu = (
      <Menu className="menu langDropdown" selectedKeys={[locale]} onClick={this.changeLang}>
        <Menu.Item key="en-US">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/us.svg`} />
          English
        </Menu.Item>
        <Menu.Item key="zh-CN">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/cn.svg`} />
          简体中文
        </Menu.Item>
        <Menu.Item key="zh-HK">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/hk.svg`} />
          繁體中文
        </Menu.Item>
        <Menu.Item key="ja-JP">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/jp.svg`} />
          日本語
        </Menu.Item>
        <Menu.Item key="vi-VN">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/vn.svg`} />
          Tiếng Việt
        </Menu.Item>
        <Menu.Item key="ms-MY">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/my.svg`} />
          Malay
        </Menu.Item>
        <Menu.Item key="th-TH">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/th.svg`} />
          ภาษาไทย
        </Menu.Item>
        <Menu.Item key="km">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/kh.svg`} />
          ខ្មែរ
        </Menu.Item>
        <Menu.Item key="id-ID">
          <img alt="flag" className="flagImg" src={`${nextradesURL}public/flags/4x3/id.svg`} />
          Indonesia
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown
        overlay={langMenu}
        trigger={['click']}
        getPopupContainer={() =>
          isLoggedIn
            ? document.getElementById('main_layout')
            : document.getElementById('login_layout')
        }
        className="langSelector"
        overlayClassName="langSelector"
      >
        <div className="dropdown">
          <img
            alt="flag"
            className="flagImg"
            src={`${nextradesURL}public/flags/4x3/${formatMessage({
              id: 'language_flag_name',
            })}.svg`}
          />
          {!isMobileView && !isTabletView && (
            <span className="langDisplayed">{formatMessage({ id: 'language_name' })}</span>
          )}
        </div>
      </Dropdown>
    )
  }
}

export default LanguageSelector1
