import React from 'react'
import { Modal, Button, Form, Divider, Icon, Menu, Tabs } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ProfileMenu3 } from './ProfileMenu'
import { LanguageSelector1 } from './LanguageSelector'
import { Link } from 'react-router-dom'
import QRCode from '../../GfxComponents/QRCode'
import ApiConfig from 'api/config'
import { connect } from 'react-redux'
// import data from './data.json'
import { SettingsModal3 } from '../../LayoutComponents/SettingsModal'
import { EditNavigation3 } from '../../LayoutComponents/EditNavigation'
import styles from './style.module.scss'
import modules from 'shared-crm/constants/modules'
import external from 'shared-crm/constants/external'
import images from 'shared-crm/constants/images'
// import { copyFileSync } from 'fs'

const { SubMenu } = Menu
const { TabPane } = Tabs

@Form.create()
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
@injectIntl
class TopBar3 extends React.Component {
  state = {
    ModalText: 'Content of the modal',
    mt4ModalVisible: false,
    QRModalVisible: false,
    goLiveModalVisible: false,
    confirmLoading: false,
    selectedKeys: '',
    screenWidth: '',
    editNavigationModalVisible: false,
    platformDownloadList: [],
  }
  topbarRef = React.createRef()

  componentDidMount() {
    const { dispatch, user_id, locale } = this.props

    dispatch({
      type: 'user/SIDEBAR_MODULE_SETTINGS',
    })
    if (user_id) {
      dispatch({
        type: 'user/SIDEBAR_MODULE_USER_SETTINGS',
        payload: {
          user_id,
        },
      })
    }

    this.getPlatformDownload()
  }
  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props
    if (prevProps.user.id !== this.props.user.id) {
      dispatch({
        type: 'user/SIDEBAR_MODULE_USER_SETTINGS',
        payload: {
          user_id: this.props.user.id,
        },
      })
    }
  }
  topbarRef = React.createRef()
  showMT4Modal = () => {
    this.setState({
      mt4ModalVisible: true,
    })
  }

  showQRModal = () => {
    this.setState({
      QRModalVisible: true,
    })
  }

  showGoLiveModal = () => {
    this.setState({
      goLiveModalVisible: true,
    })
  }

  QRhandleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
      QRModalVisible: false,
    })
  }
  showEditNavigationModal = () => {
    this.setState({
      editNavigationModalVisible: true,
    })
  }

  toggleModalVisible = (event) => {
    // event.preventDefault()
    this.setState({
      editNavigationModalVisible: false,
    })
  }

  mt4handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        mt4ModalVisible: false,
        confirmLoading: false,
      })
    }, 0)
  }

  goLivehandleOk = (event) => {
    event.preventDefault()
    const { form, dispatch, user } = this.props
    form.validateFields((error, fieldsValue) => {
      if (!error) {
        const values = {
          ...fieldsValue,
          dob: fieldsValue['dob'].format('YYYY-MM-DD'),
          user_id: user['id'],
        }
        // console.log(values)
        dispatch({
          type: 'user/GO_LIVE',
          payload: values,
        })
        this.setState({
          goLiveModalVisible: false,
        })
      }
    })
    // form.resetFields()
  }

  mt4handleCancel = () => {
    this.setState({
      mt4ModalVisible: false,
    })
  }

  qrCancel = () => {
    this.setState({
      QRModalVisible: false,
    })
  }

  showSettingsModal = () => {
    this.setState({
      settingsModalVisible: true,
    })
  }

  handleSettingsOk = (event) => {
    event.preventDefault()
    this.setState({
      settingsModalVisible: false,
    })
  }

  handleSettingsCancel = () => {
    this.setState({
      settingsModalVisible: false,
    })
  }

  goLiveCancel = () => {
    this.setState({
      goLiveModalVisible: false,
    })
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }

  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  generateMenuItems = (sideBarModulesUserSettings) => {
    const {
      user: { brandSettings },
      intl: { formatMessage },
      settings: { isLightTheme },
    } = this.props

    const MAX_MENU_ITEM = 5
    let res = sideBarModulesUserSettings.filter(
      (item) => item.web_default && item.web_default <= MAX_MENU_ITEM,
    )

    let tempArray = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )
    if (tempArray.length !== 0 && tempArray !== undefined && tempArray !== null) {
      tempArray.unshift({ name: 'Home', sub_modules: [], enabled: true })
      tempArray.push({ name: 'More', sub_modules: [], enabled: true })
    }

    //moreDropdown
    let otherModules = sideBarModulesUserSettings.filter(
      (item) => !item.web_default || item.web_default > MAX_MENU_ITEM,
    )

    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]

    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }

    const generateItem = (item) => {
      const { name, enabled } = item
      if (name === 'More') {
        return (
          <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
            <a className="moreDropdownLink" onClick={this.showMoreDropdown}>
              <span>
                {formatMessage({
                  id: 'module.' + this.convertTextToLowercase(name),
                })}
              </span>
              <div id="moreDropDown">
                <div className="row moreDropDownContent">
                  {groupTypes.map((group, index) => {
                    return (
                      <div key={index} className="col-lg-4 groupContent">
                        <div className="row header">
                          <div className="col">
                            <span>
                              {formatMessage({
                                id: 'module.group_type.' + this.convertTextToLowercase(group),
                              })}
                            </span>
                          </div>
                        </div>
                        <Divider type="horizontal" className="" />
                        <div className="row">
                          {otherModules
                            .filter(
                              (item) =>
                                item.group_type === group &&
                                item.enabled === 1 &&
                                item.sub_modules.length === 0,
                            )
                            .map((module, index) => {
                              return external[this.convertTextToLowercase(module.name)] ? (
                                <div key={index} className="col-6">
                                  <a
                                    href={
                                      brandSettings.company_website +
                                      external[this.convertTextToLowercase(module.name)]
                                    }
                                    onClick={() => this.setState({ moreModalVisible: false })}
                                    className="row moreItem"
                                    style={{ margin: 0 }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {formatMessage({
                                      id: 'module.' + this.convertTextToLowercase(module.name),
                                    })}
                                  </a>
                                </div>
                              ) : (
                                <div key={index} className="col-6">
                                  <Link
                                    onClick={() => {
                                      this.setState({ moreModalVisible: false })
                                      // if (isMobileView) {
                                      //   this.props.toggleOpen()
                                      // }
                                    }}
                                    className="row moreItem"
                                    style={{ margin: 0 }}
                                    to={
                                      this.getPathName(module.name)
                                        ? this.getPathName(module.name)
                                        : '/'
                                    }
                                  >
                                    {formatMessage({
                                      id: 'module.' + this.convertTextToLowercase(module.name),
                                    })}
                                  </Link>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Divider type="horizontal" className="footerDivider" />
                <div className="moreDropdownFooter">
                  <a
                    onClick={() => {
                      this.setState({ editNavigationModalVisible: true })
                    }}
                  >
                    <span>
                      {formatMessage({
                        id: 'menuleft.editnavigation.edit_navigation',
                      })}
                    </span>
                  </a>
                </div>
              </div>
            </a>
          </Menu.Item>
        )
      }
      return external[this.convertTextToLowercase(name)] !== undefined ? (
        <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
          <a
            href={brandSettings.company_website + external[this.convertTextToLowercase(name)]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(name),
              })}
            </span>
          </a>
        </Menu.Item>
      ) : (
        <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
          <Link to={this.getPathName(name) ? this.getPathName(name) : '/'}>
            <span>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(name),
              })}
            </span>
          </Link>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        return generateItem(menuItem)
      })

    // return menuData.map((menuItem) => {
    return tempArray.map((menuItem) => {
      if (menuItem.sub_modules.length !== 0) {
        const subMenuTitle = (
          <span key={this.convertTextToLowercase(menuItem.name)}>
            <span>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(menuItem.name),
              })}
            </span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            popupClassName={isLightTheme ? 'topBarMenuLight3' : 'topBarMenuDark3'}
          >
            {generateSubmenu(menuItem.sub_modules)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  changeTheme = (checked) => {
    const { dispatch } = this.props
    let isLightTheme, themeSelected
    if (checked) {
      isLightTheme = true
      themeSelected = 'light'
    } else {
      isLightTheme = false
      themeSelected = 'dark'
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: isLightTheme,
      },
    })
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: {
        user_id: this.props.user.id,
        theme: themeSelected,
      },
    })
  }

  getPlatformDownload = () => {
    const {
      user: { brandSettings },
    } = this.props

    const platform = ['mt4_download', 'mt5_download', 'ctrader_download', 'app_download']
    let platformList = []
    platform.map((platform) => {
      let platformLink = {}
      Object.keys(brandSettings[platform]).map((item) => {
        if (brandSettings[platform][item] !== '') {
          platformLink[item] = brandSettings[platform][item]
        }
      })
      if (Object.keys(platformLink).length > 0) {
        switch (platform) {
          case 'mt4_download':
            platformLink.name = 'MT4 Download'
            break
          case 'mt5_download':
            platformLink.name = 'MT5 Download'
            break
          case 'ctrader_download':
            platformLink.name = 'cTrader Download'
            break
          case 'app_download':
            platformLink.name = 'App Download'
            break
        }
        platformList.push(platformLink)
      }
    })
    if (platformList.length > 0) {
      this.setState({ platformDownloadList: platformList })
    }
  }

  render() {
    const {
      selectedKeys,
      QRModalVisible,
      mt4ModalVisible,
      goLiveModalVisible,
      confirmLoading,
      settingsModalVisible,
      screenWidth,
      editNavigationModalVisible,
      platformDownloadList,
    } = this.state
    const {
      form,
      settings: { isLightTheme },
      user: { user, brandSettings, brandSettings_layout, loading, sideBarModulesUserSettings },
      intl: { formatMessage },
      settings: { locale, isMobileView },
    } = this.props

    const menu = this.generateMenuItems(sideBarModulesUserSettings)

    let crmURL = ApiConfig.siteURL

    let crmLang = locale
    if (crmLang.length > 2) {
      crmLang = `${locale.split('-')[0]}-${locale.split('-')[1].toLowerCase()}`
    }

    let websiteURL = brandSettings.company_website ? brandSettings.company_website : ''
    let websiteLang = 'en'

    if (websiteURL[websiteURL.length - 1] === '/') {
      websiteURL = websiteURL.slice(0, -1)
    }

    // if (data.brandLanguage[websiteURL] && data.brandLanguage[websiteURL][locale]) {
    //   websiteLang = data.brandLanguage[websiteURL][locale]
    // }
    switch (locale) {
      case 'ms-MY':
        websiteLang = 'ms'
        break
      case 'th-TH':
        websiteLang = 'th'
        break
      case 'vi-VN':
        websiteLang = 'vn'
        break
      case 'zh-CN':
        websiteLang = 'cn'
        break
      case 'zh-HK':
        websiteLang = 'zh'
        break
      case 'ja-JP':
        websiteLang = 'jp'
        break
      default:
        websiteLang = 'en'
        break
    }

    // if (websiteURL.includes('antospnc')) {
    //   if (locale === 'zh-CN') {
    //     websiteURL = `${websiteURL}/zh/%e4%b8%bb%e9%a1%b5/`
    //   }
    //   if (locale === 'ms-MY') {
    //     websiteURL = `${websiteURL}/bm/halaman-utama/`
    //   }
    // } else {
    //   websiteURL = `${websiteURL}/lang/${websiteLang}`
    // }
    // websiteURL = `${websiteURL}/lang/${websiteLang}`
    websiteURL = `${websiteURL}/home`

    let isReferrerLinkMoreThan2 =
      [
        brandSettings.home_referral_link,
        brandSettings.live_referral_link,
        brandSettings.demo_referral_link,
      ].filter((x) => x === true).length >= 2

    let isReferrerLinkAllFalse =
      !brandSettings.home_referral_link &&
      !brandSettings.live_referral_link &&
      !brandSettings.demo_referral_link

    return (
      <div id="topbar" className="row" ref={this.topbarRef}>
        {/* <Button onClick={this.changeTheme}>Change Theme</Button> */}

        <Modal
          getContainer="#topbar"
          title={formatMessage({
            id: 'menuleft.editnavigation.edit_navigation',
          })}
          visible={editNavigationModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal edit_navigation_modal`}
        >
          <div className={`row ${styles.ant_modal}`}>
            <EditNavigation3 handleCancel={this.toggleModalVisible} />
          </div>
        </Modal>
        <div
          className={`${isMobileView ? 'col-3 d-flex align-items-center' : 'col-1 h-100 d-flex'}`}
        >
          <img
            className="logo pr-2"
            src={
              brandSettings
                ? isMobileView
                  ? brandSettings.company_logo_small
                  : brandSettings_layout === 3
                  ? isLightTheme
                    ? brandSettings.company_logo
                    : brandSettings.company_logo_inverse
                  : brandSettings.company_logo_inverse
                : ''
            }
            alt={
              brandSettings && brandSettings.company_name_short
                ? brandSettings.company_name_short
                : ''
            }
          />
        </div>
        {!isMobileView && (
          <div className="col-lg-7 col-sm-5">
            <Menu
              theme={isLightTheme ? 'light' : 'dark'}
              // theme={isLightTheme ? 'light' : 'dark'}
              //   onClick={this.handleClick}
              selectedKeys={selectedKeys}
              //   openKeys={openedKeys}
              //   onOpenChange={this.onOpenChange}
              mode="horizontal"
              //   className={styles.navigation}
            >
              {menu}
            </Menu>
          </div>
        )}

        <div
          className={`${isMobileView ? 'col-9' : 'col-lg-4 col-sm-6'}
         h-100 d-flex align-items-center justify-content-end p-0`}
        >
          {/* {user.type === 'demo' && (
            <Button className={`golivebtn`} onClick={this.showGoLiveModal} shape="round">
              <FormattedMessage id="nav.go-live" />
            </Button>
          )} */}

          {user.type === 'demo' && <Divider type="vertical" className="mx-3" />}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.mt4-download' })}
            visible={mt4ModalVisible}
            onOk={this.mt4handleOk}
            confirmLoading={confirmLoading}
            onCancel={this.mt4handleCancel}
            className={`ant_modal mt4_download_modal`}
          >
            {platformDownloadList.length > 0 && (
              <>
                <Tabs defaultActiveKey="0">
                  {platformDownloadList.map((item, index) => {
                    return (
                      <TabPane tab={item.name} key={index}>
                        <div className={`row ant_modal pl-3 pr-3`} style={{ padding: 'unset' }}>
                          {Object.keys(item).map((platform) => {
                            if (platform !== 'name') {
                              let platformName = ''
                              let platformFormatID = ''
                              if (platform.toLowerCase().includes('desktop')) {
                                platformName = 'window'
                                platformFormatID = 'common.window'
                              } else if (platform.toLowerCase().includes('mac')) {
                                platformName = 'mac_os_x'
                                platformFormatID = 'common.mac-os-x'
                              } else if (platform.toLowerCase().includes('ios')) {
                                platformName = 'ios'
                                platformFormatID = 'common.ios'
                              } else if (platform.toLowerCase().includes('android_apk')) {
                                platformName = 'android'
                                platformFormatID = 'common.android_apk'
                              } else if (platform.toLowerCase().includes('android_download')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('android_app')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('huawei')) {
                                platformName = 'huawei'
                                platformFormatID = 'common.huawei'
                              }

                              return (
                                <>
                                  <div className="col-lg-4" key={index}>
                                    <a
                                      href={item[platform]}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <div className={`card card--withShadow paymentCard mb-4`}>
                                        <img
                                          className="platform"
                                          src={images[platformName]}
                                          alt={platformName}
                                        />
                                        <div className="col-lg-12 mt-4">
                                          <span className="name text-nowrap">
                                            <FormattedMessage id={platformFormatID} />
                                          </span>
                                        </div>
                                        <div />
                                        {/*<div className="col-lg-12">*/}
                                        {/*<Button type="primary" className="uploadBtn">*/}
                                        {/*  <FormattedMessage id="common.download" />*/}
                                        {/*</Button>*/}
                                        {/*</div>*/}
                                      </div>
                                    </a>
                                  </div>
                                </>
                              )
                            }
                          })}
                        </div>
                      </TabPane>
                    )
                  })}
                </Tabs>
              </>
            )}

            {/* <div className={`row ant_modal`}>
              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.window} alt="window" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.window" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.desktop_exe ? brandSettings.desktop_exe : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.mac_os_x} alt="mac-os-x" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.mac-os-x" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={brandSettings && brandSettings.mac_os ? brandSettings.mac_os : ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.ios} alt="ios" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.ios" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.ios_download_link
                          ? brandSettings.ios_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.android} alt="android" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.android_apk" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_apk ? brandSettings.android_apk : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.google_play_store} alt="google play" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.google-play" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_download_link
                          ? brandSettings.android_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.huawei} alt="huawei" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.huawei" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.huawei_download_link
                          ? brandSettings.huawei_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </Modal>

          {/* <Divider type="vertical" className="mx-0 mx-lg-4" /> */}

          {/* <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'nav.go-live' })}
            visible={goLiveModalVisible}
            onOk={this.goLivehandleOk}
            // confirmLoading={confirmLoading}
            onCancel={this.goLiveCancel}
            className="ant_modal"
          >
            <div className={`row ant_modal`}>
              <div className="col-lg-12">
                <Form layout="vertical">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.ic' }).replace('*', '')}>
                              {form.getFieldDecorator('ic', {
                                initialValue: user['ic'] ? user['ic'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.ic_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.birthday' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('dob', {
                                rules: [
                                  {
                                    type: 'object',
                                    required: true,
                                    message: formatMessage({ id: 'ur.birthday_require' }),
                                  },
                                ],
                              })(<DatePicker />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-12 my-3">
                          <h4 className="text-uppercase">
                            <strong>
                              <FormattedMessage id="ur.residence_info" />
                            </strong>
                          </h4>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.address_1' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('address_1', {
                                initialValue: user['address_1'] ? user['address_1'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.address_1_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                              {form.getFieldDecorator('address_2', {
                                initialValue: user['address_2'] ? user['address_2'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.postcode' })}>
                              {form.getFieldDecorator('postcode', {
                                initialValue: user['postcode'] ? user['postcode'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6" />
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.state' })}>
                              {form.getFieldDecorator('state', {
                                initialValue: user['state'] ? user['state'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.city' }).replace('*', '')}>
                              {form.getFieldDecorator('city', {
                                initialValue: user['city'] ? user['city'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.city_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Modal> */}

          {!isReferrerLinkAllFalse && (
            // <i className={`qr icmn-qrcode mr-4`} onClick={this.showQRModal} />
            <Icon className={isMobileView ? `mr-4` : ``} type="scan" onClick={this.showQRModal} />
          )}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.member_qr' })}
            visible={QRModalVisible}
            onOk={this.QRhandleOk}
            onCancel={this.qrCancel}
            className={`text-left ${
              isReferrerLinkMoreThan2 ? 'ant_modal_ref_qr' : 'ant_modal_ref_qr_sm'
            }`}
          >
            <div className={`row`}>
              <div className="col-12 text-center">
                <h5>
                  <FormattedMessage id="topBar.modal.member_qr.desc" />
                </h5>
              </div>
              <div className="col-12 d-flex flex-column flex-lg-row justify-content-around">
                {brandSettings.home_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.HOMEPAGE" />
                    </h3>
                    <a
                      href={`${websiteURL}?affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${websiteURL}?affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode value={`${websiteURL}?affiliate=${user['code']}`} />
                    </div>
                  </div>
                )}
                {brandSettings.live_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.LIVE" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
                {brandSettings.demo_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.DEMO" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>

          {!isMobileView && <Divider type="vertical" className="mx-3" />}

          <ProfileMenu3 />

          <Divider type="vertical" className="mx-0 mx-lg-3" />

          {user.type !== 'demo' && (
            <Link
              to="/dashboard/walletManagement"
              style={{ display: isMobileView ? 'none' : 'block' }}
            >
              <div className={`ml-2 h-100 d-flex align-items-center`}>
                {!isMobileView && (
                  <span className="walletBalance">
                    ${user['ewallet_balance'] ? user['ewallet_balance'] : '0.00'}
                  </span>
                )}
              </div>
            </Link>
          )}

          {user.type !== 'demo' && !isMobileView && <Divider type="vertical" className="mx-3" />}

          {!isMobileView && (
            <div className="mr-3 lightDarkSwitch">
              {isLightTheme ? (
                <i
                  className="fa fa-sun-o"
                  style={{ cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => (loading ? null : this.changeTheme(false))}
                ></i>
              ) : (
                <i
                  className="fa fa-moon-o"
                  style={{ cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => (loading ? null : this.changeTheme(true))}
                ></i>
              )}
            </div>
          )}

          <div className={isMobileView ? `ml-4` : ``}>
            <LanguageSelector1 isLoggedIn={true} />
          </div>

          <Icon
            className={!isMobileView ? `mr-3 ml-2` : `ml-4`}
            type="setting"
            onClick={() => this.showSettingsModal()}
          />

          <Modal
            getContainer="#topbar"
            title={formatMessage({
              id: 'ct.form.settings',
            })}
            visible={settingsModalVisible}
            onOk={this.handleSettingsOk}
            onCancel={this.handleSettingsCancel}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal settings_modal`}
          >
            <div className={`row ${styles.ant_modal}`}>
              <SettingsModal3 handleCancel={this.handleCancel} />
            </div>
          </Modal>

          {isMobileView ? (
            <i className={`downloadbtnMobile icmn-download mx-4`} onClick={this.showMT4Modal} />
          ) : (
            <Button
              className="downloadbtn mr-4"
              onClick={this.showMT4Modal}
              shape="round"
              icon="download"
            >
              <FormattedMessage id="topBar.platform_download" />
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default TopBar3
