import antdData from 'antd/lib/locale-provider/th_TH'
import localeData from 'react-intl/locale-data/th'
import messages from './lang/th-TH.json'

export default {
  locale: 'th-TH',
  antdData,
  localeData,
  messages: messages["th-TH"],
}
