import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout, Modal, Icon, Form } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _, { findLastKey } from 'lodash'
import { arrayMoveImmutable } from 'array-move'
import { FormattedMessage, injectIntl } from 'react-intl'
import { EditNavigation3 } from '../../EditNavigation'
import modules from 'shared-crm/constants/modules'
import external from 'shared-crm/constants/external'
import { element } from 'prop-types'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})

@Form.create()
@withRouter
@connect(mapStateToProps)
@injectIntl
class MenuBottom extends React.Component {
  state = {
    moreModalVisible: false,
    menuModalVisible: false,
    editNavigationModalVisible: false,
    y: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => this.handleScroll(e))
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', (e) => this.handleScroll(e))
  }

  handleScroll = (e) => {
    const { y } = this.state
    const window = e.currentTarget
    var menuBottom = document.getElementById('menuBottom')
    if (menuBottom) {
      if (y > window.scrollY) {
        menuBottom.classList.add('menuBottomShow')
        menuBottom.classList.remove('menuBottomHide')
      } else if (y < window.scrollY) {
        menuBottom.classList.add('menuBottomHide')
        menuBottom.classList.remove('menuBottomShow')
      }
    }
    this.setState({ y: window.scrollY })
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }

  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  generateMenuItems = (sideBarModulesUserSettings) => {
    const {
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props

    const MAX_MENU_ITEM = 5
    let res = sideBarModulesUserSettings.filter(
      (item) => item.web_default && item.web_default <= MAX_MENU_ITEM,
    )

    let modules = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )

    let tempArray = []
    tempArray.push({ name: 'Home', sub_modules: [], enabled: true })
    tempArray.push(...modules)
    tempArray.push({ name: 'More', sub_modules: [], enabled: true })

    // if (tempArray.length !== 0 && tempArray !== undefined && tempArray !== null) {
    // }
    return (
      <div id="menuModal" className="col">
        <div className="row content">
          <div className="col">
            <Link
              onClick={() => {
                this.toggleModalVisible()
                // if (isMobileView) {
                //   this.props.toggleOpen()
                // }
              }}
              className="row menuItem"
              to={'/dashboard/summary'}
            >
              <span>
                {formatMessage({
                  id: 'module.home',
                })}
              </span>
            </Link>
            {tempArray
              .filter((item) => item.enabled === 1 && item.sub_modules.length === 0)
              .map((module, index) => {
                return external[this.convertTextToLowercase(module.name)] ? (
                  <a
                    key={index}
                    href={
                      brandSettings.company_website +
                      external[this.convertTextToLowercase(module.name)]
                    }
                    onClick={() => this.toggleModalVisible()}
                    className="row menuItem"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      {formatMessage({
                        id: 'module.' + this.convertTextToLowercase(module.name),
                      })}
                    </span>
                  </a>
                ) : (
                  <Link
                    key={index}
                    onClick={() => {
                      this.toggleModalVisible()
                      // if (isMobileView) {
                      //   this.props.toggleOpen()
                      // }
                    }}
                    className="row menuItem"
                    to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                  >
                    <span>
                      {formatMessage({
                        id: 'module.' + this.convertTextToLowercase(module.name),
                      })}
                    </span>
                  </Link>
                )
              })}
            <a
              onClick={this.showMoreModal}
              className="row menuItem"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                {formatMessage({
                  id: 'module.more',
                })}
              </span>
            </a>
          </div>
        </div>
        <div className="ant-dropdown-menu-item-divider divider"></div>
        <div className="row footer">
          <a onClick={this.showEditNavigationModal} className="editNavigationButton">
            <span>
              {formatMessage({
                id: 'menuleft.editnavigation.edit_navigation',
              })}
            </span>
          </a>
        </div>
      </div>
    )
  }

  generateMoreItem = (sideBarModulesUserSettings) => {
    const {
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props
    const MAX_MENU_ITEM = 5
    let otherModules = sideBarModulesUserSettings.filter(
      (item) => !item.web_default || item.web_default > MAX_MENU_ITEM,
    )

    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]

    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }
    return (
      <div id="moreModal" className="col">
        <div className="row content">
          <div className="col">
            {groupTypes.map((group, index) => {
              return (
                <div key={index}>
                  <div className="row moreHeaderItem">
                    <span>
                      {formatMessage({
                        id: 'module.group_type.' + this.convertTextToLowercase(group),
                      })}
                    </span>
                  </div>
                  {otherModules
                    .filter(
                      (item) =>
                        item.group_type === group &&
                        item.enabled === 1 &&
                        item.sub_modules.length === 0,
                    )
                    .map((module, index) => {
                      return external[this.convertTextToLowercase(module.name)] ? (
                        <a
                          key={index}
                          href={
                            brandSettings.company_website +
                            external[this.convertTextToLowercase(module.name)]
                          }
                          onClick={() => this.setState({ moreModalVisible: false })}
                          className="row moreItem"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </a>
                      ) : (
                        <Link
                          key={index}
                          onClick={() => {
                            this.setState({ moreModalVisible: false })
                            // if (isMobileView) {
                            //   this.props.toggleOpen()
                            // }
                          }}
                          className="row moreItem"
                          to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </Link>
                      )
                    })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="ant-dropdown-menu-item-divider divider"></div>
        <div className="row footer">
          <a
            onClick={() => {
              this.setState({ moreModalVisible: false, editNavigationModalVisible: true })
            }}
            className="editNavigationButton"
          >
            <span>
              {formatMessage({
                id: 'menuleft.editnavigation.edit_navigation',
              })}
            </span>
          </a>
        </div>
      </div>
    )
  }

  showMenuModal = () => {
    this.setState({
      menuModalVisible: true,
      moreModalVisible: false,
      editNavigationModalVisible: false,
    })
  }

  showMoreModal = () => {
    this.setState({
      menuModalVisible: false,
      moreModalVisible: true,
      editNavigationModalVisible: false,
    })
  }

  showEditNavigationModal = () => {
    this.setState({
      menuModalVisible: false,
      moreModalVisible: false,
      editNavigationModalVisible: true,
    })
  }

  toggleModalVisible = () => {
    // event.preventDefault()
    this.setState({
      selectedKeys: [],
      menuModalVisible: false,
      moreModalVisible: false,
      editNavigationModalVisible: false,
    })
  }

  render() {
    const {
      user: { user, brandSettings, brandSettings_layout, loading, sideBarModulesUserSettings },
      intl: { formatMessage },
    } = this.props

    const { moreModalVisible, menuModalVisible, editNavigationModalVisible } = this.state

    const menu = this.generateMenuItems(sideBarModulesUserSettings)
    const moreModal = this.generateMoreItem(sideBarModulesUserSettings)
    return (
      <div id="menuBottom">
        <Modal
          getContainer="#menuBottom"
          title={formatMessage({
            id: 'module.more',
          })}
          visible={moreModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal moreModal`}
        >
          {moreModal}
        </Modal>

        <Modal
          getContainer="#menuBottom"
          title={formatMessage({ id: 'module.menu' })}
          visible={menuModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
        >
          {menu}
        </Modal>

        <Modal
          getContainer={brandSettings_layout === 5 ? '#topbarcontainer' : '#topbar'}
          title={formatMessage({
            id: 'menuleft.editnavigation.edit_navigation',
          })}
          visible={editNavigationModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal edit_navigation_modal`}
        >
          <div className={`row ant_modal`}>
            <EditNavigation3 handleCancel={this.toggleModalVisible} />
          </div>
        </Modal>

        <Icon className="menuBottomIcon" onClick={this.showMenuModal} type="bars" />
      </div>
    )
  }
}

export default MenuBottom
