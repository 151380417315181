import React from 'react'
import { connect } from 'react-redux'
import { Icon, Rad, Button } from 'antd'
import languageCode from '../../../data/languageCode'
import { FormattedMessage, injectIntl } from 'react-intl'
import images from 'shared-crm/constants/images'
import {LanguageSelector1} from '../TopBar/LanguageSelector'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})

@connect(mapStateToProps)
@injectIntl
class SettingsModal1 extends React.Component {
  state = {
    theme: 'light',
  }

  changeLang = ({ key }) => {
    const { isLoggedIn, dispatch, user } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    if (isLoggedIn) {
      dispatch({
        type: 'user/UPDATE_LANGUAGE',
        payload: {
          user_id: user.id,
          lg: languageCode[key],
        },
      })
    }
  }

  componentDidMount() {
    const {
      user: { user },
    } = this.props
    this.setState({ theme: user.theme })
  }

  changeTheme = (selectedTheme) => {
    this.setState({ theme: selectedTheme })
    const { dispatch } = this.props
    let value
    switch (selectedTheme) {
      case 'light':
        value = true
        break
      case 'dark':
        value = false
        break
      case 'device':
        value = window.matchMedia('(prefers-color-scheme: light)').matches ? true : false
        break
      default:
        value = true
        break
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: value,
      },
    })
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: {
        user_id: this.props.user.id,
        theme: selectedTheme,
      },
    })
  }

  render() {
    const {
      intl: { formatMessage },
      user: { user },
    } = this.props
    const { theme } = this.state
    return (
      <div id="settingsModal">
        <div className="settingsContent col">
          <div className="settingsItem row">
            <div className="col">
              <div className="settingsTitle">
                <p>
                  {formatMessage({
                    id: 'menuleft.settingsmodal.theme',
                  })}
                </p>
              </div>
              <div className="settingsValueContainer row">
                <div
                  id="lightSettings"
                  onClick={() => this.changeTheme('light')}
                  className={`col-lg-4 ${
                    theme === 'light'
                      ? 'themeActive themeSelectionFirst'
                      : 'themeSelection themeSelectionFirst'
                  } `}
                >
                  <p className="themeSettingsText">
                    {formatMessage({
                      id: 'menuleft.settingsmodal.light',
                    })}
                  </p>
                  <img className="themeSettingsImage" src={images['light_mode']} alt="Light Mode" />
                </div>
                <div
                  id="darkSettings"
                  onClick={() => this.changeTheme('dark')}
                  className={`col-lg-4 ${theme === 'dark' ? 'themeActive ' : 'themeSelection'} `}
                >
                  <p className="themeSettingsText">
                    {formatMessage({
                      id: 'menuleft.settingsmodal.dark',
                    })}
                  </p>
                  <img className="themeSettingsImage" src={images['dark_mode']} alt="Dark Mode" />
                </div>
                <div
                  id="deviceSettings"
                  onClick={() => this.changeTheme('device')}
                  className={`col-lg-4 ${
                    theme === 'device'
                      ? 'themeActive themeSelectionThird'
                      : 'themeSelection themeSelectionThird'
                  } `}
                >
                  <p className="themeSettingsText">
                    {formatMessage({
                      id: 'menuleft.settingsmodal.device',
                    })}
                  </p>
                  <img
                    className="themeSettingsImage"
                    src={images['device_setting']}
                    alt="Use Device Setting"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="settingsItem row">
            <div className="col">
              <div className="settingsTitle">
                <p>
                  {formatMessage({
                    id: 'menuleft.settingsmodal.language',
                  })}
                </p>
              </div>
              <div className="settingsValueContainer greyContainer languageContainer row">
                <p className="languageSettingsText">
                  {formatMessage({
                    id: 'menuleft.settingsmodal.languagetext',
                  })}
                </p>
                <div className="languageSelector">
                  <LanguageSelector1 isLoggedIn={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsModal1
