import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Layout, Tabs, Modal, Form, Icon, Menu, Button, Input, DatePicker } from 'antd'
import store from 'store'
import { FormattedMessage, injectIntl } from 'react-intl'
import Avatar from 'shared-crm/components/CleanUIComponents/Avatar'
import QRCode from '../../../GfxComponents/QRCode'
import styles from './style.module.scss'
import images from 'shared-crm/constants/images'
import ApiConfig from 'api/config'
import modules from 'shared-crm/constants/modules'
import SVG, { Props as SVGProps } from 'react-inlinesvg'
import external from 'shared-crm/constants/external'
// import { Popover } from 'antd'
import { EditNavigation4 } from '../../EditNavigation'
import { SettingsModal1 } from '../../SettingsModal'
import { LanguageSelector4 } from '../../TopBar/LanguageSelector'
// import { fromPairs } from 'lodash'

import { getSVGIconsSet, getSVGIcon } from '../../../../assets/icons'

const { Sider } = Layout
const { SubMenu } = Menu
const { TabPane } = Tabs

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  locale: settings.locale,
  user: user,
})

@Form.create()
@withRouter
@connect(mapStateToProps)
@injectIntl
class MenuLeft4 extends React.Component {
  state = {
    showEditNavigationModal: false,
    collapsed: false,
    brandSettings: {},
    currentActive: null,
    popOverDrawerVisible: false,
    moreModalVisible: false,
    QRModalVisible: false,
    goLiveModalVisible: false,
    mt4ModalVisible: false,
    confirmLoading: false,
    platformDownloadList: [],
    settingsModalVisible: false,
    editNavigationModalVisible: false,
    moduleSelected: null,
    isMenuLeftShow: true,
    morePopOverVisible: false,
    parentModulePopOverVisible: {
      moduleName: null,
      visible: false,
    },
  }

  componentDidMount() {
    const { isLoggedIn, dispatch, user } = this.props
    this.setState({ currentActive: this.getModuleName(this.props.location.pathname) })
    this.getPlatformDownload()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.sideBarModulesUserSettings !== this.props.user.sideBarModulesUserSettings) {
      this.setState({ currentActive: this.getModuleName(this.props.location.pathname) + 'Current' })
      this.setMenuItemInactive(this.getModuleName(prevProps.location.pathname))
      this.setMenuItemActive(this.getModuleName(this.props.location.pathname))
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ currentActive: this.getModuleName(this.props.location.pathname) + 'Current' })
      this.setMenuItemInactive(this.getModuleName(prevProps.location.pathname))
      this.setMenuItemActive(this.getModuleName(this.props.location.pathname))
    }
  }

  showGoLiveModal = () => {
    this.setState({
      goLiveModalVisible: true,
    })
  }

  goLiveCancel = () => {
    this.setState({
      goLiveModalVisible: false,
    })
  }

  goLivehandleOk = (event) => {
    event.preventDefault()
    const { form, dispatch, user } = this.props
    form.validateFields((error, fieldsValue) => {
      if (!error) {
        const values = {
          ...fieldsValue,
          dob: fieldsValue['dob'].format('YYYY-MM-DD'),
          user_id: user['id'],
        }
        // console.log(values)
        dispatch({
          type: 'user/GO_LIVE',
          payload: values,
        })
        this.setState({
          goLiveModalVisible: false,
        })
      }
    })
    // form.resetFields()
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  changeTheme = (checked) => {
    const { dispatch, isLightTheme } = this.props
    let newTheme = !isLightTheme
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: {
        user_id: this.props.user.id,
        theme: newTheme ? 'light' : 'dark',
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: newTheme,
      },
    })
  }

  getPlatformDownload = () => {
    const {
      user: { brandSettings },
    } = this.props

    const platform = ['mt4_download', 'mt5_download', 'ctrader_download', 'app_download']
    let platformList = []
    platform.map((platform) => {
      let platformLink = {}
      brandSettings[platform] &&
        Object.keys(brandSettings[platform]).map((item) => {
          if (brandSettings[platform][item] !== '') {
            platformLink[item] = brandSettings[platform][item]
          }
        })
      if (Object.keys(platformLink).length > 0) {
        switch (platform) {
          case 'mt4_download':
            platformLink.name = 'MT4 Download'
            break
          case 'mt5_download':
            platformLink.name = 'MT5 Download'
            break
          case 'ctrader_download':
            platformLink.name = 'cTrader Download'
            break
          case 'app_download':
            platformLink.name = 'App Download'
            break
          default:
            platformLink.name = ''
            break
        }
        platformList.push(platformLink)
      }
    })
    if (platformList.length > 0) {
      this.setState({ platformDownloadList: platformList })
    }
  }

  handleClick = (e) => {
    const { dispatch, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])

    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
  }

  redirectToVerification = () => {
    this.props.history.push('/dashboard/Verification')
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed: collapsed })
  }

  handleOk = (event) => {
    event.preventDefault()
    this.setState({
      showEditNavigationModal: false,
    })
  }

  showQRModal = () => {
    this.setState({
      QRModalVisible: true,
    })
  }

  QRhandleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
      QRModalVisible: false,
    })
  }

  qrCancel = () => {
    this.setState({
      QRModalVisible: false,
    })
  }

  showEditNavigationModal = () => {
    this.setState({
      moreModalVisible: false,
      editNavigationModalVisible: true,
    })
  }

  showMoreModal = () => {
    this.setState({
      moreModalVisible: true,
      editNavigationModalVisible: false,
    })
  }

  handleCancel = () => {
    this.setState({
      showEditNavigationModal: false,
    })
  }
  popOverDrawerVisibleToggle = (itemName, visible) => {
    if (itemName === 'all') {
      this.setState({ morePopOverVisible: visible })
    } else {
      this.setState({
        parentModulePopOverVisible: {
          moduleName: itemName,
          visible: visible,
        },
      })
    }
    if (visible) {
      this.setMenuItemActive(itemName)
    } else {
      this.setMenuItemInactive(itemName)
    }
  }

  removeItemActiveState = () => {
    this.setMenuItemInactive(this.state.currentActive)
  }

  handleOnMouseOver = (name, component) => {
    if (component === 'more') {
      this.setPopoverItemActive(name)
    } else if (component === 'drawer') {
      this.setDrawerItemActive(name)
    } else {
      if (this.state.currentActive !== name + 'Current') {
        this.setMenuItemActive(name)
      }
    }
  }
  handleOnMouseOut = (name, component) => {
    if (component === 'more') {
      this.setPopoverItemInactive(name)
    } else if (component === 'drawer') {
      this.setDrawerItemInactive(name)
    } else {
      if (this.state.currentActive !== name + 'Current') {
        this.setMenuItemInactive(name)
      }
    }
  }
  setDrawerItemActive = (name) => {
    var image = document.getElementById(name + 'DrawerImage')
    if (image) {
      image.src = images[`nav_${name}_active`]
    }

    var label = document.getElementById(name + 'DrawerLabel')
    if (label) {
      label.classList.add('subModuleTextActive')
      label.classList.remove('subModuleText')
    }
  }
  setDrawerItemInactive = (name) => {
    var image = document.getElementById(name + 'DrawerImage')
    if (image) {
      image.src = images[`nav_${name}`]
    }
    var label = document.getElementById(name + 'DrawerLabel')
    if (label) {
      label.classList.add('subModuleText')
      label.classList.remove('subModuleTextActive')
    }
  }

  setPopoverItemActive = (name) => {
    var image = document.getElementById(name + 'MoreImage')
    if (image) {
      image.src = images[`nav_${name}_active`]
    }
  }

  setPopoverItemInactive = (name) => {
    var image = document.getElementById(name + 'MoreImage')
    if (image) {
      image.src = images[`nav_${name}`]
    }
  }

  setMenuItemActive = (name) => {}
  setMenuItemInactive = (name) => {
    //Image
    var image = document.getElementById(name + 'Image')
    if (image) {
      image.src = images[`nav_${name}`]
    }

    //Label
    var label = document.getElementById(name + 'Label')
    if (label) {
      label.classList.add('menuLeftLabel')
      label.classList.remove('menuLabelActive')
    }
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  generateMenuItems = (data) => {
    // Getting the sequence of the menu item from api
    const MAX_MENU_ITEM = 5
    let res = data.filter((item) => item.web_default && item.web_default <= MAX_MENU_ITEM)

    let tempArray = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )

    return tempArray
  }

  generateMoreItem = (sideBarModulesUserSettings) => {
    const {
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props
    const MAX_MENU_ITEM = 5
    let otherModules = sideBarModulesUserSettings.filter(
      (item) => !item.web_default || item.web_default > MAX_MENU_ITEM,
    )

    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]

    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }

    return (
      <div id="moreModal" className="col">
        <div className="row content">
          <div className="col">
            {groupTypes.map((group, index) => {
              return (
                <div key={index}>
                  <div className="row moreHeaderItem">
                    <span>
                      {formatMessage({
                        id: 'module.group_type.' + this.convertTextToLowercase(group),
                      })}
                    </span>
                  </div>
                  {otherModules
                    .filter(
                      (item) =>
                        item.group_type === group &&
                        item.enabled === 1 &&
                        item.sub_modules.length === 0,
                    )
                    .map((module, index) => {
                      return external[this.convertTextToLowercase(module.name)] ? (
                        <a
                          href={
                            ApiConfig.websiteURL +
                            external[this.convertTextToLowercase(module.name)]
                          }
                          onClick={() => this.setState({ moreModalVisible: false })}
                          key={index}
                          className="row moreItem"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </a>
                      ) : (
                        <Link
                          onClick={() => {
                            this.setState({ moreModalVisible: false })
                            // if (isMobileView) {
                            //   this.props.toggleOpen()
                            // }
                          }}
                          key={index}
                          className="row moreItem"
                          to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </Link>
                      )
                    })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="ant-dropdown-menu-item-divider divider"></div>
        <div className="row footer">
          <a
            onClick={() => {
              this.setState({ moreModalVisible: false, editNavigationModalVisible: true })
            }}
            className="editNavigationButton"
          >
            <span>
              {formatMessage({
                id: 'menuleft.editnavigation.edit_navigation',
              })}
            </span>
          </a>
        </div>
      </div>
    )
  }

  toggleMenuLeft = () => {
    const { dispatch } = this.props
    const menuLeft = document.getElementById('menuLeft')
    if (this.state.isMenuLeftShow) {
      menuLeft.style.marginLeft = '-80px'
      this.setState({ isMenuLeftShow: false })
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    } else {
      menuLeft.style.marginLeft = '0px'
      this.setState({ isMenuLeftShow: true })
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: false,
        },
      })
    }
  }

  arrangeMenuItem = (menuItem) => {
    let tempArray = []
    let home = { module_id: 999, name: 'Home', sub_modules: [], enabled: true }
    let more = { module_id: 9999, name: 'More', sub_modules: [], enabled: false }

    tempArray.push(home)
    tempArray.push(...menuItem)
    tempArray.push(more)

    return tempArray
  }

  showDrawer = (module) => {
    const drawer = document.getElementById('menuLeftPopOverDrawerMenu')
    drawer.style.left = '0'
    this.setState({ popOverDrawerVisible: true, moduleSelected: module })
  }

  closeDrawer = () => {
    const drawer = document.getElementById('menuLeftPopOverDrawerMenu')
    drawer.style.left = '433px'
    this.setState({ popOverDrawerVisible: false })
  }

  showSettingModal = () => {
    this.setState({ settingsModalVisible: true })
  }

  showMT4Modal = () => {
    this.setState({
      mt4ModalVisible: true,
    })
  }

  mt4handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        mt4ModalVisible: false,
        confirmLoading: false,
      })
    }, 0)
  }

  mt4handleCancel = () => {
    this.setState({
      mt4ModalVisible: false,
    })
  }

  handleSettingsOk = (event) => {
    event.preventDefault()
    this.setState({
      settingsModalVisible: false,
    })
  }

  handleSettingsCancel = () => {
    this.setState({
      settingsModalVisible: false,
    })
  }

  toggleModalVisible = (event) => {
    this.setState({
      selectedKeys: [],
      moreModalVisible: false,
      editNavigationModalVisible: false,
      settingsModalVisible: false,
    })
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }
  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  render() {
    const {
      collapsed,
      settingsModalVisible,
      moreModalVisible,
      mt4ModalVisible,
      QRModalVisible,
      confirmLoading,
      platformDownloadList,
      editNavigationModalVisible,
      goLiveModalVisible,
    } = this.state
    const {
      form,
      isMobileView,
      // locale,
      isLightTheme,
      locale,
      user: { sideBarModulesUserSettings, user, loading, brandSettings, brandSettings_layout },
      intl: { formatMessage },
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 60,
          collapsible: true,
          collapsed: true,
          onCollapse: this.onCollapse,
        }
      : {
          width: 240,
          collapsible: true,
          collapsed: this.state.collapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }
    const menuItemsList = this.generateMenuItems(sideBarModulesUserSettings)
    const moreModal = this.generateMoreItem(sideBarModulesUserSettings)
    const sideBarData = this.arrangeMenuItem(menuItemsList)

    let crmURL = ApiConfig.siteURL

    let crmLang = locale
    if (crmLang.length > 2) {
      crmLang = `${locale.split('-')[0]}-${locale.split('-')[1].toLowerCase()}`
    }

    let websiteURL = brandSettings.company_website ? brandSettings.company_website : ''
    let websiteLang = 'en'

    if (websiteURL[websiteURL.length - 1] === '/') {
      websiteURL = websiteURL.slice(0, -1)
    }

    switch (locale) {
      case 'ms-MY':
        websiteLang = 'ms'
        break
      case 'th-TH':
        websiteLang = 'th'
        break
      case 'vi-VN':
        websiteLang = 'vn'
        break
      case 'zh-CN':
        websiteLang = 'cn'
        break
      case 'zh-HK':
        websiteLang = 'zh'
        break
      case 'ja-JP':
        websiteLang = 'jp'
        break
      default:
        websiteLang = 'en'
        break
    }
    // websiteURL = `${websiteURL}/lang/${websiteLang}`
    websiteURL = `${websiteURL}/home`

    let isReferrerLinkMoreThan2 =
      [
        brandSettings.home_referral_link,
        brandSettings.live_referral_link,
        brandSettings.demo_referral_link,
      ].filter((x) => x === true).length >= 2

    let isReferrerLinkAllFalse =
      !brandSettings.home_referral_link &&
      !brandSettings.live_referral_link &&
      !brandSettings.demo_referral_link

    const iconSet = getSVGIconsSet(4)

    return (
      <Sider
        {...menuSettings}
        trigger={null}
        id="menuLeft"
        theme={`${isLightTheme ? 'light' : 'dark'}`}
        className={styles.menu}
      >
        {/* <Scrollbars className={isMobileView ? 'scrollbarMobile' : 'scrollbarDesktop'} autoHide > */}
        <Modal
          getContainer="#menuLeft"
          title={formatMessage({
            id: 'module.more',
          })}
          visible={moreModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal moreModal`}
        >
          {moreModal}
        </Modal>
        <Modal
          getContainer="#menuLeft"
          title={formatMessage({
            id: 'menuleft.editnavigation.edit_navigation',
          })}
          visible={editNavigationModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal edit_navigation_modal`}
        >
          <div className={`row ant_modal`}>
            <EditNavigation4 handleCancel={this.toggleModalVisible} />
          </div>
        </Modal>
        <Modal
          getContainer="#menuLeft"
          title={formatMessage({
            id: 'ct.form.settings',
          })}
          visible={settingsModalVisible}
          onOk={this.handleSettingsOk}
          onCancel={this.handleSettingsCancel}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal settings_modal`}
        >
          <div className={`row ant_modal`}>
            <SettingsModal1 handleCancel={this.toggleModalVisible} />
          </div>
        </Modal>
        <Modal
          getContainer="#menuLeft"
          title={formatMessage({ id: 'topBar.modal.mt4-download' })}
          visible={mt4ModalVisible}
          onOk={this.mt4handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.mt4handleCancel}
          className={`ant_modal mt4_download_modal`}
        >
          {platformDownloadList.length > 0 && (
            <>
              <Tabs defaultActiveKey="0">
                {platformDownloadList.map((item, index) => {
                  return (
                    <TabPane tab={item.name} key={index}>
                      <div className={`row ant_modal pl-3 pr-3`} style={{ padding: 'unset' }}>
                        {Object.keys(item).map((platform) => {
                          if (platform !== 'name') {
                            let platformName = ''
                            let platformFormatID = ''
                            if (platform.toLowerCase().includes('desktop')) {
                              platformName = 'window'
                              platformFormatID = 'common.window'
                            } else if (platform.toLowerCase().includes('mac')) {
                              platformName = 'mac_os_x'
                              platformFormatID = 'common.mac-os-x'
                            } else if (platform.toLowerCase().includes('ios')) {
                              platformName = 'ios'
                              platformFormatID = 'common.ios'
                            } else if (platform.toLowerCase().includes('android_apk')) {
                              platformName = 'android'
                              platformFormatID = 'common.android_apk'
                            } else if (platform.toLowerCase().includes('android_download')) {
                              platformName = 'google_play_store'
                              platformFormatID = 'common.google-play'
                            } else if (platform.toLowerCase().includes('android_app')) {
                              platformName = 'google_play_store'
                              platformFormatID = 'common.google-play'
                            } else if (platform.toLowerCase().includes('huawei')) {
                              platformName = 'huawei'
                              platformFormatID = 'common.huawei'
                            }

                            return (
                              <>
                                <div className="col-lg-4">
                                  <a
                                    href={item[platform]}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <div className={`card card--withShadow paymentCard mb-4`}>
                                      <img
                                        className="platform"
                                        src={images[platformName]}
                                        alt={platformName}
                                      />
                                      <div className="col-lg-12 mt-4">
                                        <span className="name text-nowrap">
                                          <FormattedMessage id={platformFormatID} />
                                        </span>
                                      </div>
                                      <div />
                                      {/*<div className="col-lg-12">*/}
                                      {/*<Button type="primary" className="uploadBtn">*/}
                                      {/*  <FormattedMessage id="common.download" />*/}
                                      {/*</Button>*/}
                                      {/*</div>*/}
                                    </div>
                                  </a>
                                </div>
                              </>
                            )
                          }
                        })}
                      </div>
                    </TabPane>
                  )
                })}
              </Tabs>
            </>
          )}

          {/* <div className={`row ant_modal`}>
              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.window} alt="window" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.window" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.desktop_exe ? brandSettings.desktop_exe : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.mac_os_x} alt="mac-os-x" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.mac-os-x" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={brandSettings && brandSettings.mac_os ? brandSettings.mac_os : ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.ios} alt="ios" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.ios" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.ios_download_link
                          ? brandSettings.ios_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.android} alt="android" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.android_apk" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_apk ? brandSettings.android_apk : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.google_play_store} alt="google play" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.google-play" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_download_link
                          ? brandSettings.android_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.huawei} alt="huawei" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.huawei" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.huawei_download_link
                          ? brandSettings.huawei_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
        </Modal>

        <Modal
          getContainer="#menuLeft"
          title={formatMessage({ id: 'topBar.modal.member_qr' })}
          visible={QRModalVisible}
          onOk={this.QRhandleOk}
          onCancel={this.qrCancel}
          className={`text-left ${
            isReferrerLinkMoreThan2 ? 'ant_modal_ref_qr' : 'ant_modal_ref_qr_sm'
          }`}
        >
          <div className={`row`}>
            <div className="col-12 text-center">
              <h5>
                <FormattedMessage id="topBar.modal.member_qr.desc" />
              </h5>
            </div>
            <div className="col-12 d-flex flex-column flex-lg-row justify-content-around">
              {brandSettings.home_referral_link && (
                <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                  <h3 className="font-weight-bold">
                    <FormattedMessage id="topBar.modal.member_qr.HOMEPAGE" />
                  </h3>
                  <a
                    href={`${websiteURL}?affiliate=${user['code']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${websiteURL}?affiliate=${user['code']}`}
                  </a>
                  <div className="mt-3">
                    <QRCode value={`${websiteURL}?affiliate=${user['code']}`} />
                  </div>
                </div>
              )}
              {brandSettings.live_referral_link && (
                <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                  <h3 className="font-weight-bold">
                    <FormattedMessage id="topBar.modal.member_qr.LIVE" />
                  </h3>
                  <a
                    href={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                  </a>
                  <div className="mt-3">
                    <QRCode
                      value={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                    />
                  </div>
                </div>
              )}
              {brandSettings.demo_referral_link && (
                <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                  <h3 className="font-weight-bold">
                    <FormattedMessage id="topBar.modal.member_qr.DEMO" />
                  </h3>
                  <a
                    href={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                  </a>
                  <div className="mt-3">
                    <QRCode
                      value={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        <Modal
          getContainer="#menuLeft"
          title={formatMessage({ id: 'nav.go-live' })}
          visible={goLiveModalVisible}
          onOk={this.goLivehandleOk}
          // confirmLoading={confirmLoading}
          onCancel={this.goLiveCancel}
          className="ant_modal"
        >
          <div className={`row ant_modal`}>
            <div className="col-lg-12">
              <Form layout="vertical">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.ic' }).replace('*', '')}>
                            {form.getFieldDecorator('ic', {
                              initialValue: user['ic'] ? user['ic'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.ic_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.birthday' }).replace('*', '')}>
                            {form.getFieldDecorator('dob', {
                              rules: [
                                {
                                  type: 'object',
                                  required: true,
                                  message: formatMessage({ id: 'ur.birthday_require' }),
                                },
                              ],
                            })(<DatePicker getCalendarContainer={(trigger) => trigger.parentNode} />)}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-12 my-3">
                        <h4 className="text-uppercase">
                          <strong>
                            <FormattedMessage id="ur.residence_info" />
                          </strong>
                        </h4>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.address_1' }).replace('*', '')}>
                            {form.getFieldDecorator('address_1', {
                              initialValue: user['address_1'] ? user['address_1'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.address_1_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                            {form.getFieldDecorator('address_2', {
                              initialValue: user['address_2'] ? user['address_2'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.postcode' })}>
                            {form.getFieldDecorator('postcode', {
                              initialValue: user['postcode'] ? user['postcode'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6" />
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.state' })}>
                            {form.getFieldDecorator('state', {
                              initialValue: user['state'] ? user['state'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.city' }).replace('*', '')}>
                            {form.getFieldDecorator('city', {
                              initialValue: user['city'] ? user['city'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.city_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
        <div style={{ padding: '30px 30px 30px 25px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: collapsed ? 'center' : 'space-between',
              alignItem: 'center',
            }}
          >
            {!collapsed && (
              <div>
                <img
                  className="brand-logo pr-2"
                  src={
                    brandSettings
                      ? isMobileView
                        ? brandSettings.company_logo_small
                        : isLightTheme
                        ? brandSettings.company_logo
                        : brandSettings.company_logo_inverse
                      : ''
                  }
                  alt={
                    brandSettings && brandSettings.company_name_short
                      ? brandSettings.company_name_short
                      : ''
                  }
                />
              </div>
            )}
            {!isMobileView && (
              <>
                {collapsed ? (
                  <div style={{ alignItems: 'center' }} onClick={() => this.onCollapse(!collapsed)}>
                    <Icon
                      className="collapsedIcon"
                      type="arrow-right"
                      style={{ fontSize: '1.6em' }}
                    />
                  </div>
                ) : (
                  <div style={{ alignItems: 'center' }} onClick={() => this.onCollapse(!collapsed)}>
                    <Icon
                      className="collapsedIcon"
                      type="arrow-left"
                      style={{ fontSize: '1.6em' }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className={`${collapsed || isMobileView ? 'collapsed text-center' : 'notcollapsed mb-4'}`}
          style={{ padding: '0 12px' }}
        >
          {collapsed || isMobileView ? (
            <Link to="/dashboard/Settings">
              <Avatar size={48} borderColor="white" />
            </Link>
          ) : (
            // <Link to="/dashboard/Settings">
            <div className="user_container d-flex flex-row w-100">
              <Avatar size={48} borderColor="white" />
              <div className="d-flex flex-column ml-2" style={{ width: '79%' }}>
                <div className="justify-content-between d-flex">
                  <div
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    <Link to={this.getPathName('Profile')}>
                      <span className="user_username">{user.username}</span>
                    </Link>
                  </div>

                  <div
                    style={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }}
                    onClick={this.showQRModal}
                  >
                    <SVG height={'16px'} width={'16px'} src={`${getSVGIcon(iconSet, 'qrIcon')}`} />
                  </div>
                </div>
                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  <Link to={this.getPathName('Profile')}>
                    <span className="user_email">{user.email}</span>
                  </Link>
                </div>
              </div>
            </div>
            // </Link>
          )}
        </div>
        {!isMobileView && !collapsed && (
          <div className="goliveButtonContainer">
            {
              // loading === true ? (
              //   <p className={`${styles.white_font} mt-3`}> {'---'} </p>
              // ) :
              user['type'] === 'demo' && (
                <Button
                  className={`goliveButton mb-4`}
                  onClick={this.showGoLiveModal}
                  shape="round"
                >
                  <FormattedMessage id="nav.go-live" />
                </Button>
              )
            }
          </div>
        )}

        {isMobileView && !collapsed && user['type'] === 'demo' && (
          <Button
            size="small"
            className={`goliveButton mt-2`}
            onClick={this.showGoLiveModal}
            shape="round"
          >
            <FormattedMessage id="nav.go-live" />
          </Button>
        )}

        <Menu
          theme={isLightTheme ? 'light' : 'dark'}
          defaultSelectedKeys={['home']}
          selectedKeys={[this.getModuleName(this.props.location.pathname)]}
          mode="inline"
          style={{ paddingBottom: '135px' }}
        >
          {sideBarData.map((module) => {
            if (module.name === 'More') {
              return (
                <Menu.Item
                  key={this.convertTextToLowercase(module.name)}
                  title={formatMessage({
                    id: 'module.' + this.convertTextToLowercase(module.name),
                  })}
                >
                  <a onClick={this.showMoreModal}>
                    <div className="menuleft_module_item">
                      <SVG
                        height={'20px'}
                        width={'20px'}
                        src={`${getSVGIcon(iconSet, this.convertTextToLowercase(module.name))}`}
                      />

                      <span className="menuleft_module_label">
                        {formatMessage({
                          id: 'module.' + this.convertTextToLowercase(module.name),
                        })}
                      </span>
                    </div>
                  </a>
                </Menu.Item>
              )
            }
            if (module.sub_modules.length === 0) {
              return external[this.convertTextToLowercase(module.name)] !== undefined ? (
                <Menu.Item
                  key={this.convertTextToLowercase(module.name)}
                  title={formatMessage({
                    id: 'module.' + this.convertTextToLowercase(module.name),
                  })}
                >
                  <a
                    href={ApiConfig.websiteURL + external[this.convertTextToLowercase(module.name)]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menuleft_module_item">
                      <SVG
                        height={'20px'}
                        width={'20px'}
                        src={`${getSVGIcon(iconSet, this.convertTextToLowercase(module.name))}`}
                      />

                      <span className="menuleft_module_label">
                        {formatMessage({
                          id: 'module.' + this.convertTextToLowercase(module.name),
                        })}
                      </span>
                    </div>
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item
                  key={this.convertTextToLowercase(module.name)}
                  title={formatMessage({
                    id: 'module.' + this.convertTextToLowercase(module.name),
                  })}
                >
                  <Link to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}>
                    <div className="menuleft_module_item">
                      <SVG
                        height={'20px'}
                        width={'20px'}
                        src={`${getSVGIcon(iconSet, this.convertTextToLowercase(module.name))}`}
                      />

                      <span className="menuleft_module_label">
                        {formatMessage({
                          id: 'module.' + this.convertTextToLowercase(module.name),
                        })}
                      </span>
                    </div>
                  </Link>
                </Menu.Item>
              )
            } else {
              return (
                <SubMenu
                  key={module.key}
                  title={
                    <div className="menuleft_module_item">
                      <SVG
                        height={'20px'}
                        width={'20px'}
                        src={`${getSVGIcon(iconSet, this.convertTextToLowercase(module.name))}`}
                      />

                      <span className="menuleft_module_label">
                        {formatMessage({
                          id: 'module.' + this.convertTextToLowercase(module.name),
                        })}
                      </span>
                    </div>
                  }
                >
                  {module.sub_modules.map((submodule) => {
                    return external[this.convertTextToLowercase(submodule.name)] !== undefined ? (
                      <Menu.Item
                        key={this.convertTextToLowercase(submodule.name)}
                        title={formatMessage({
                          id: 'module.' + this.convertTextToLowercase(submodule.name),
                        })}
                      >
                        <a
                          href={
                            ApiConfig.websiteURL +
                            external[this.convertTextToLowercase(submodule.name)]
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="menuleft_module_item">
                            {/* <SVG
                              height={'20px'}
                              width={'20px'}
                              src={`${getSVGIcon(
                                iconSet,
                                this.convertTextToLowercase(submodule.name),
                              )}`}
                            /> */}

                            <span className="menuleft_module_label">
                              {formatMessage({
                                id: 'module.' + this.convertTextToLowercase(submodule.name),
                              })}
                            </span>
                          </div>
                        </a>
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        key={this.convertTextToLowercase(submodule.name)}
                        title={formatMessage({
                          id: 'module.' + this.convertTextToLowercase(submodule.name),
                        })}
                      >
                        <Link
                          to={
                            this.getPathName(submodule.name)
                              ? this.getPathName(submodule.name)
                              : '/'
                          }
                        >
                          <div className="menuleft_module_item">
                            {/* <SVG
                              height={'20px'}
                              width={'20px'}
                              src={`${getSVGIcon(
                                iconSet,
                                this.convertTextToLowercase(submodule.name),
                              )}`}
                            /> */}

                            <span className="menuleft_module_label">
                              {formatMessage({
                                id: 'module.' + this.convertTextToLowercase(submodule.name),
                              })}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              )
            }
          })}
        </Menu>

        <div
          className={`row sider-footer fixed-bottom m-0 `}
          style={{
            width: isMobileView ? '80px' : collapsed ? '80px' : '240px',
            left: isMobileView ? '8px' : '0px',
            bottom: isMobileView ? '0px' : '35px',
            paddingBottom: isMobileView ? '0' : '15px',
          }}
        >
          <div className="col w-100 p-0">
            {/* <Button className="w-100">test</Button> */}
            <div
              className={`w-100 ${
                isMobileView || collapsed ? `sidebar-toolbox-collapsed` : `sidebar-toolbox`
              }`}
              style={{ paddingBottom: isMobileView ? '0' : '15px' }}
            >
              <>
                <LanguageSelector4 />
                {isLightTheme ? (
                  <i
                    className={`fa fa-sun-o lightDarkIcon sider-footer-icon ${
                      isMobileView || collapsed ? '' : ''
                    }`}
                    aria-hidden="true"
                    // onClick={() => (loading ? null : this.changeTheme(false))}
                    onClick={this.changeTheme}
                  ></i>
                ) : (
                  <i
                    className={`fa fa-moon-o lightDarkIcon sider-footer-icon ${
                      isMobileView || collapsed ? '' : ''
                    }`}
                    aria-hidden="true"
                    // onClick={() => (loading ? null : this.changeTheme(true))}
                    onClick={this.changeTheme}
                  ></i>
                )}
              </>

              <Icon
                className={`lightDarkIcon sider-footer-icon ${isMobileView || collapsed ? '' : ''}`}
                type="setting"
                onClick={() => this.showSettingModal()}
              />

              <i
                className={`sider-footer-icon icmn-exit`}
                onClick={() => {
                  this.logout()
                }}
              />
            </div>
            {isMobileView || collapsed ? (
              <></>
            ) : (
              <div className="w-100">
                <Button
                  className={`ant-btn-primary downloadbtn w-100`}
                  onClick={this.showMT4Modal}
                  icon="download"
                >
                  <FormattedMessage id="topBar.platform_download" />
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* </Scrollbars> */}
      </Sider>
    )
  }
}

export default MenuLeft4
