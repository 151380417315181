import React from 'react'
import { Modal, Button, Tabs } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ProfileMenu1 } from './ProfileMenu'
import {LanguageSelector1} from './LanguageSelector'
import { Link } from 'react-router-dom'
import QRCode from '../../GfxComponents/QRCode'
import ApiConfig from 'api/config'
import { connect } from 'react-redux'
import data from './data.json'
import images from 'shared-crm/constants/images'

const { TabPane } = Tabs

@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
@injectIntl
class TopBar1 extends React.Component {
  state = {
    ModalText: 'Content of the modal',
    visible: false,
    OSXvisible: false,
    QRvisible: false,
    confirmLoading: false,
    platformDownloadList: [],
  }

  componentDidMount() {
    this.getPlatformDownload()
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  showOSXModal = () => {
    this.setState({
      OSXvisible: true,
    })
  }

  showQRModal = () => {
    this.setState({
      QRvisible: true,
    })
  }

  OSXhandleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        OSXvisible: false,
        confirmLoading: false,
      })
    }, 0)
  }

  QRhandleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
      QRvisible: false,
    })
  }

  handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      })
    }, 0)
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  osxCancel = () => {
    this.setState({
      OSXvisible: false,
    })
  }

  qrCancel = () => {
    this.setState({
      QRvisible: false,
    })
  }

  changeTheme = () => {
    const {
      dispatch,
      settings: { isLightTheme },
    } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: !isLightTheme,
      },
    })
  }

  getPlatformDownload = () => {
    const {
      user: { brandSettings },
    } = this.props
    const platform = ['mt4_download', 'mt5_download', 'ctrader_download', 'app_download']
    let platformList = []
    platform.map((platform) => {
      let platformLink = {}
      if (Object.keys(brandSettings).length > 0) {
        brandSettings[platform] && Object.keys(brandSettings[platform]).map((item) => {
          if (brandSettings[platform][item] !== '') {
            platformLink[item] = brandSettings[platform][item]
          }
        })
        if (Object.keys(platformLink).length > 0) {
          switch (platform) {
            case 'mt4_download':
              platformLink.name = 'MT4 Download'
              break
            case 'mt5_download':
              platformLink.name = 'MT5 Download'
              break
            case 'ctrader_download':
              platformLink.name = 'cTrader Download'
              break
            case 'app_download':
              platformLink.name = 'App Download'
              break
          }
          platformList.push(platformLink)
        }
      }
    })
    if (platformList.length > 0) {
      this.setState({ platformDownloadList: platformList })
    }
  }

  render() {
    const { QRvisible, OSXvisible, visible, confirmLoading, ModalText, platformDownloadList } =
      this.state
    const {
      user: { user, brandSettings },
      intl: { formatMessage },
      settings: { locale },
    } = this.props

    let crmURL = ApiConfig.siteURL

    let crmLang = locale
    if (crmLang.length > 2) {
      crmLang = `${locale.split('-')[0]}-${locale.split('-')[1].toLowerCase()}`
    }

    let websiteURL = brandSettings.company_website ? brandSettings.company_website : ''
    let websiteLang = 'en'

    if (websiteURL[websiteURL.length - 1] === '/') {
      websiteURL = websiteURL.slice(0, -1)
    }

    // if (data.brandLanguage[websiteURL] && data.brandLanguage[websiteURL][locale]) {
    //   websiteLang = data.brandLanguage[websiteURL][locale]
    // }
    switch (locale) {
      case 'ms-MY':
        websiteLang = 'ms'
        break
      case 'th-TH':
        websiteLang = 'th'
        break
      case 'vi-VN':
        websiteLang = 'vn'
        break
      case 'zh-CN':
        websiteLang = 'cn'
        break
      case 'zh-HK':
        websiteLang = 'zh'
        break
      case 'ja-JP':
        websiteLang = 'jp'
        break
      default:
        websiteLang = 'en'
        break
    }

    // if (websiteURL.includes('antospnc')) {
    //   if (locale === 'zh-CN') {
    //     websiteURL = `${websiteURL}/zh/%e4%b8%bb%e9%a1%b5/`
    //   }
    //   if (locale === 'ms-MY') {
    //     websiteURL = `${websiteURL}/bm/halaman-utama/`
    //   }
    // } else {
    //   websiteURL = `${websiteURL}/lang/${websiteLang}`
    // }
    // websiteURL = `${websiteURL}/lang/${websiteLang}`
    websiteURL = `${websiteURL}/home`

    let isReferrerLinkMoreThan2 =
      [
        brandSettings.home_referral_link,
        brandSettings.live_referral_link,
        brandSettings.demo_referral_link,
      ].filter((x) => x === true).length >= 2

    let isReferrerLinkAllFalse =
      !brandSettings.home_referral_link &&
      !brandSettings.live_referral_link &&
      !brandSettings.demo_referral_link

    return (
      <div id="topbar">
        {user['type'] !== 'demo' && (
          <>
            <div className="dropdown mr-3 mr-lg-4">
              <Link to="/deposit">
                <i className="icon icmn-coin-dollar" />
                <span className="d-none d-xl-inline">
                  <strong>
                    <FormattedMessage id="topBar.deposit" />
                  </strong>
                </span>
              </Link>
            </div>
            <div className="dropdown mr-3 mr-lg-4">
              <Link to="/withdrawal">
                <i className="icon icmn-credit-card" />
                <span className="d-none d-xl-inline">
                  <strong>
                    <FormattedMessage id="topBar.withdrawal" />
                  </strong>
                </span>
              </Link>
            </div>
          </>
        )}
        <div className="dropdown mr-auto">
          <Link to="/support">
            <i className="icon icmn-bubbles" />
            <span className="d-none d-xl-inline">
              <strong>
                <FormattedMessage id="topBar.support" />
              </strong>
            </span>
          </Link>
        </div>

        <div className="mr-3 mr-lg-4">
          <Button className="downloadbtn" onClick={this.showModal} shape="round" icon="download">
            <FormattedMessage id="topBar.platform_download" />
          </Button>
          <i className={`downloadbtnMobile icmn-download`} onClick={this.showModal} />

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.mt4-guide-mac' })}
            visible={OSXvisible}
            onOk={this.OSXhandleOk}
            confirmLoading={confirmLoading}
            onCancel={this.osxCancel}
            className="text-left"
          >
            <div className={`row`}>
              <div className="col-lg-12">
                <p>
                  1. Visit to http://winebottler.kronenberg.org and download the winebottler app.
                </p>
                <p>
                  2. Double click on the installation file and drag both "Wine" and "WineBottler"
                  into Applications folder.
                </p>
                <p>3. Launch the WineBottler app in the Application folder</p>
                <p>
                  4. Download{' '}
                  {brandSettings && brandSettings.company_name_short
                    ? brandSettings.company_name_short
                    : ''}{' '}
                  MetaTrader Terminal from{' '}
                  {brandSettings && brandSettings.company_website
                    ? brandSettings.company_website
                    : ''}
                  /trading_platform.html
                </p>
                <p>
                  5. Click on the Advanced tab on the WineBottler, click on the select File to
                  browse the goldfxinvestmentco4setup.exe file which just downloaded.
                </p>
                <p>
                  6. Click on the Install button at the bottom right of the WineBottler. When
                  prompted, type the following info and save it.
                </p>
                <p>
                  7. Save As:{' '}
                  {brandSettings && brandSettings.company_name_short
                    ? brandSettings.company_name_short
                    : ''}{' '}
                  MetaTrader Terminal | Tag: Leave empty | Where: Choose Applications | Go through
                  the installation process.
                </p>
                <p>
                  8. Type "
                  {brandSettings && brandSettings.company_name ? brandSettings.company_name : ''}"
                  and click Scan. Make sure{' '}
                  {brandSettings && brandSettings.live_server_name
                    ? brandSettings.live_server_name
                    : ''}{' '}
                  and
                  {brandSettings && brandSettings.demo_server_name
                    ? brandSettings.demo_server_name
                    : ''}{' '}
                  is shown after the search.
                </p>
                <p>
                  9. Close the terminal to complete the installation process. When prompted, select
                  the one with terminal.exe at the back.
                </p>
                <p>
                  10. To launch the terminal again, go to the WineBottler -&gt On My Mac and click
                  on the{' '}
                  {brandSettings && brandSettings.company_name_short
                    ? brandSettings.company_name_short
                    : ''}{' '}
                  MetaTrader Terminal.
                </p>
              </div>
            </div>
          </Modal>

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.mt4-download' })}
            visible={visible}
            onOk={this.handleOk}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="submit" type="primary" onClick={this.handleOk}>
                OK
              </Button>,
            ]}
            className={`ant_modal mt4_download_modal`}
          >
            {platformDownloadList.length > 0 && (
              <>
                <Tabs defaultActiveKey="0">
                  {platformDownloadList.map((item, index) => {
                    return (
                      <TabPane tab={item.name} key={index}>
                        <div className={`row ant_modal`}>
                          {Object.keys(item).map((platform) => {
                            if (platform !== 'name') {
                              let platformName = ''
                              let platformFormatID = ''
                              if (platform.toLowerCase().includes('desktop')) {
                                platformName = 'window'
                                platformFormatID = 'common.window'
                              } else if (platform.toLowerCase().includes('mac')) {
                                platformName = 'mac_os_x'
                                platformFormatID = 'common.mac-os-x'
                              } else if (platform.toLowerCase().includes('ios')) {
                                platformName = 'ios'
                                platformFormatID = 'common.ios'
                              } else if (platform.toLowerCase().includes('android_apk')) {
                                platformName = 'android'
                                platformFormatID = 'common.android_apk'
                              } else if (platform.toLowerCase().includes('android_download')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('android_app')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('huawei')) {
                                platformName = 'huawei'
                                platformFormatID = 'common.huawei'
                              }

                              return (
                                <>
                                  <div className="col-lg-4">
                                    <div className={`card card--withShadow paymentCard`}>
                                      <img
                                        className="platform"
                                        src={images[platformName]}
                                        alt={platformName}
                                      />
                                      <div className="col-lg-12 mt-4">
                                        <span className="name">
                                          <FormattedMessage id={platformFormatID} />
                                        </span>
                                      </div>
                                      <div />
                                      <div className="col-lg-12">
                                        <a
                                          href={item[platform]}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <Button type="primary" className="uploadBtn">
                                            <FormattedMessage id="common.download" />
                                          </Button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          })}
                        </div>
                      </TabPane>
                    )
                  })}
                </Tabs>
              </>
            )}
            {/* <div className={`row ant_modal`}>
              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.window} alt="window" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.window" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.desktop_exe ? brandSettings.desktop_exe : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.mac_os_x} alt="mac-os-x" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.mac-os-x" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={brandSettings && brandSettings.mac_os ? brandSettings.mac_os : ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.ios} alt="ios" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.ios" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.ios_download_link
                          ? brandSettings.ios_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.android} alt="android" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.android_apk" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_apk ? brandSettings.android_apk : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.google_play_store} alt="google play" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.google-play" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_download_link
                          ? brandSettings.android_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.huawei} alt="huawei" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.huawei" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.huawei_download_link
                          ? brandSettings.huawei_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </Modal>
        </div>

        <div className="mr-3 mr-lg-4">
          <LanguageSelector1 isLoggedIn={true} />
        </div>
        <ProfileMenu1 />

        <div className="mr-3 mr-lg-4">
          {!isReferrerLinkAllFalse && (
            <div className="qrHolder">
              <i className={`qr icmn-qrcode`} onClick={this.showQRModal} />
            </div>
          )}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.member_qr' })}
            visible={QRvisible}
            onOk={this.QRhandleOk}
            onCancel={this.qrCancel}
            footer={[
              <Button key="submit" type="primary" onClick={this.QRhandleOk}>
                OK
              </Button>,
            ]}
            className={`text-left ${
              isReferrerLinkMoreThan2 ? 'ant_modal_ref_qr' : 'ant_modal_ref_qr_sm'
            }`}
          >
            <div className={`row`}>
              <div className="col-12 text-center">
                <h5>
                  <FormattedMessage id="topBar.modal.member_qr.desc" />
                </h5>
              </div>
              <div className="col-12 d-flex flex-column flex-lg-row justify-content-around">
                {brandSettings.home_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.HOMEPAGE" />
                    </h3>
                    <a
                      href={`${ApiConfig.websiteURL}?affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${ApiConfig.websiteURL}?affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode value={`${ApiConfig.websiteURL}?affiliate=${user['code']}`} />
                    </div>
                  </div>
                )}
                {brandSettings.live_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.LIVE" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
                {brandSettings.demo_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.DEMO" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default TopBar1
