import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { TopBar2 } from 'shared-crm/components/LayoutComponents/TopBar'
import Menu from 'shared-crm/components/LayoutComponents/Menu'
import { Footer2 } from 'shared-crm/components/LayoutComponents/Footer'
import Breadcrumbs from 'shared-crm/components/LayoutComponents/Breadcrumbs'
import Settings from 'shared-crm/components/LayoutComponents/Settings'

const mapStateToProps = ({ settings, user }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isLightTheme: settings.isLightTheme,
  brandSettings_layout: user.brandSettings_layout,
})

@withRouter
@connect(mapStateToProps)
@connect(({ settings }) => ({ settings }))
class MainLayout_2 extends React.PureComponent {
  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      isLightTheme,
      brandSettings_layout,
      settings: { isMobileView },
    } = this.props

    const pathName = this.props.location.pathname

    let isPathCommunity

    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    let hideBreadcrumbPathNameList = ['/dashboard/summary', '/markets']
    let hideFooterPathNameList = ['/markets']

    return (
      <Layout
        id="main_layout"
        className={`${classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
          lightTheme: isLightTheme,
          darkTheme: !isLightTheme,
        })}
        main_layout_${brandSettings_layout}`}
      >
        <Layout.Header style={{ position: 'fixed', width: '100%', top: 0, zIndex: 99 }}>
          <TopBar2 />
        </Layout.Header>

        <Menu onMouseHover={onmouseover} />

        <Layout
          className={`${
            pathName === '/dashboard/summary' ? 'layoutImageBackground' : 'layoutColorBackground'
          } ${hideBreadcrumbPathNameList.includes(pathName) && 'pb-0'}`}
        >
          <BackTop
            visible={pathName !== '/markets'}
            style={{
              bottom: isMobileView === true && isPathCommunity === true && '8rem',
              right: isMobileView === true && isPathCommunity === true && '2rem',
            }}
          />
          <Settings />

          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div
              className={`utils__content h-100 ${
                hideBreadcrumbPathNameList.includes(pathName) ? 'p-0' : ''
              } ${pathName === '/markets' && 'maxWidth_unset'}`}
            >
              {!hideBreadcrumbPathNameList.includes(pathName) &&
                !pathName.includes('/community') && <Breadcrumbs />}
              {children}
            </div>
          </Layout.Content>
          {/* {!hideFooterPathNameList.includes(pathName) && ( */}

          {/* )} */}
        </Layout>
        <Layout.Footer style={{ position: 'fixed', width: '100%', bottom: 0, zIndex: 99 }}>
          <Footer2 />
        </Layout.Footer>
      </Layout>
    )
  }
}

export default MainLayout_2
