import BaseApi from './baseAPI'
import Axios from 'axios'
import store from 'store'

// Must be function instead of Object as Generators don't work with complex structure...I guess.

const ApiUser = () => {
  const _api = BaseApi.api
  const _communityAPI = BaseApi.communityAPI
  const _communityENV = BaseApi._communityENV
  const _cmsAPI = BaseApi.cmsAPI
  const _newCmsAPI = BaseApi.newCmsAPI
  const _nextradesAPI = BaseApi.nextradesAPI
  const _marketNewsAPI = BaseApi.marketNewsAPI
  const _cTradaerNextradesAPI = BaseApi.cTradaerNextradesAPI

  const getParams = (paramsData) => {
    let params = ''
    Object.keys(paramsData).map((key, index) => {
      let param = ''
      if (index !== 0) {
        param += `&`
      }
      param += `${key}=${encodeURIComponent(paramsData[key])}`
      return (params += param)
    })
    return params
  }

  const brandSettings = () => {
    return _api.post('user-api/data/brand-settings')
  }

  const brandSettingsTNCPrivacy = () => {
    return _api.post('user-api/data/brand-settings-tnc-privacy')
  }

  const moduleSettings = () => {
    return _api.post('trader/module/setting')
  }

  const sidebarModuleSettings = () => {
    return _api.get(`trader/module`)
  }

  const sidebarModuleUserSettings = (user_id) => {
    return _api.get(`trader/module/info?user_id=` + user_id)
  }

  const sidebarModulePostUserSettings = (data) => {
    return _api.post(`trader/module/update-web`, data)
  }

  const individualSignUp = (queryParams) => {
    return _api.post('api/sign-up?' + queryParams)
  }

  const corporateSignUp = (queryParams) => {
    return _api.post('api/sign-up-corporate?' + queryParams)
  }

  const demoSignUp = (queryParams) => {
    return _api.post('api/sign-up-demo?' + queryParams)
  }

  const userLogin = (data) => {
    return _api.post('api/login', data)
  }

  const changePassword = (data) => {
    return _api.post('user-api/data/change-password', data)
  }

  const loginHistory = (data) => {
    return _api.post(`user-api/data/login-history?${getParams(data)}`)
  }

  const getMe = (data) => {
    return _api.post(`user-api/data/account-details?${getParams(data)}`)
  }

  const walletOperation = (data) => {
    return _api.post(`user-api/data/wallet-operations?${getParams(data)}`)
  }

  const walletOperationsDetails = (data) => {
    return _api.post(`user-api/data/wallet-operations-details?${getParams(data)}`)
  }

  const walletOperationsDetailsData = (data) => {
    return _api.post(`user-api/data/wallet-operations-details-data?${getParams(data)}`)
  }

  const bankDetails = (data) => {
    return _api.post(`user-api/data/bank-details?${getParams(data)}`)
  }

  const updateBankDetail = (data) => {
    return _api.post('user-api/data/update-bank', data)
  }

  const foreverpayBank = (data) => {
    return _api.post(`user-api/data/foreverpay-bank?${getParams(data)}`)
  }

  const foreverpayBankState = (data) => {
    return _api.post(`user-api/data/foreverpay-bank-state?${getParams(data)}`)
  }

  const foreverpayBankCity = (data) => {
    return _api.post(`user-api/data/foreverpay-bank-city?${getParams(data)}`)
  }

  const updateForeverpayBank = (data) => {
    return _api.post('user-api/data/update-foreverpay-bank', data)
  }

  const paytrustBank = (data) => {
    return _api.post(`user-api/data/paytrust-bank?${getParams(data)}`)
  }

  const updatePaytrustBank = (data) => {
    return _api.post('user-api/data/update-paytrust-bank', data)
  }

  const updateFasapay = (data) => {
    return _api.post('user-api/data/update-fasapay', data)
  }

  const helppayBank = (data) => {
    return _api.post(`user-api/data/helppay-bank?${getParams(data)}`)
  }

  const updateHelppayBank = (data) => {
    return _api.post('user-api/data/update-helppay-bank', data)
  }

  const eeziepayBank = (data) => {
    return _api.post(`user-api/data/eeziepay-bank?${getParams(data)}`)
  }

  const updateEeziepayBank = (data) => {
    return _api.post('user-api/data/update-eeziepay-bank', data)
  }

  const eeziepayINRBank = (data) => {
    return _api.post(`trader/payment/eeziepay-inr-bank?${getParams(data)}`)
  }

  const updateEeziepayINRBank = (data) => {
    return _api.post('trader/payment/update-eeziepay-inr-bank', data)
  }

  const xPayBank = (data) => {
    return _api.post(`user-api/data/xpay-bank?${getParams(data)}`)
  }

  const updateXPayBank = (data) => {
    return _api.post('user-api/data/update-xpay-bank', data)
  }

  const anpayBank = (data) => {
    return _api.post(`user-api/data/anpay-bank?${getParams(data)}`)
  }

  const updateAnpayBank = (data) => {
    return _api.post('user-api/data/update-anpay-bank', data)
  }

  const paybnbBank = (data) => {
    return _api.post(`user-api/data/paybnb-bank?${getParams(data)}`)
  }

  const updatePaybnbBank = (data) => {
    return _api.post('user-api/data/update-paybnb-bank', data)
  }

  const fivepayBank = (data) => {
    return _api.post(`user-api/data/fivepay-bank?${getParams(data)}`)
  }

  const updateFivepayBank = (data) => {
    return _api.post('user-api/data/update-fivepay-bank', data)
  }

  const directPayBank = () => {
    return _api.post(`trader/payment/direct-pay-bank`)
  }

  const papayaPayBank = (data) => {
    return _api.post(`trader/payment/papaya-pay-bank?${getParams(data)}`)
  }

  const updatePapayaPayBank = (data) => {
    return _api.post('trader/payment/update-papaya-pay-bank', data)
  }

  const updateResidence = (data) => {
    return _api.post('user-api/data/update-residence-info', data)
  }

  const updateUserInfo = (data) => {
    return _api.post('user-api/data/update-user-info', data)
  }

  const updateProfile = (data) => {
    return _api.post('user-api/data/update-profile', data)
  }

  const updateLanguage = (data) => {
    return _api.post('user-api/data/update-language', data)
  }

  const upgradeCorporate = (data) => {
    return _api.post('user-api/data/upgrade-corporate', data)
  }

  const corporateInfo = (data) => {
    return _api.post('user-api/data/corporate-info', data)
  }

  const addWithdrawal = (data) => {
    return _api.post('user-api/data/withdrawal', data)
  }

  // KYC API
  const getKYC = (data) => {
    return _api.post(`user-api/data/kyc?${getParams(data)}`)
  }

  const uploadKYC = (data) => {
    return _api.post('user-api/data/upload-kyc', data)
  }

  const historyKYC = (data) => {
    return _api.post(`user-api/data/kyc-history?${getParams(data)}`)
  }

  const selfTrading = (data) => {
    return _api.post(`user-api/data/self-trading-mt4?${getParams(data)}`)
  }

  const selfTradingDemo = (data) => {
    return _api.post(`user-api/data/demo-mt4?${getParams(data)}`)
  }

  const selfTradingFull = (data) => {
    return _api.post('user-api/data/self-trading-mt4-full', data)
  }

  const selfTradingDemoFull = (data) => {
    return _api.post('user-api/data/demo-mt4-full', data)
  }

  const tradingProduct = (data) => {
    return _api.post(`user-api/data/self-trading-product?${getParams(data)}`)
  }

  const getAffiliateTree = (data) => {
    return _api.post(`user-api/data/affiliate-tree?${getParams(data)}`)
  }

  const affiliateClientListing = (data) => {
    return _api.post(`user-api/data/client-listing?${getParams(data)}`)
  }

  const affiliateCommissionHistory = (data) => {
    return _api.post(`user-api/data/client-commission-history?${getParams(data)}`)
  }

  const affiliateClientTradingTxs = (data) => {
    return _api.post(`user-api/data/client-trading-transactions?${getParams(data)}`)
  }

  const affiliateClientDepWitTxs = (data) => {
    return _api.post(`user-api/data/client-deposit-withdrawal-transactions?${getParams(data)}`)
  }

  const networkMt4 = (data) => {
    return _api.post('user-api/data/network-mt4', data)
  }

  const depositMt4 = (data) => {
    return _api.post('user-api/data/deposit-mt4', data)
  }

  const openMt4 = (data) => {
    return _api.post('user-api/data/open-mt4', data)
  }

  const mt4LiveInfo = (data) => {
    return _api.post(`user-api/data/mt4-live-info?${getParams(data)}`)
  }

  const mt4MarginInfo = (data) => {
    return _api.post(`user-api/data/mt4-margin-info?${getParams(data)}`)
  }

  const mt4BalanceCredit = (data) => {
    return _api.post(`user-api/data/mt4-balance-credit?${getParams(data)}`)
  }

  const withdrawalMt4 = (data) => {
    return _api.post('user-api/data/withdraw-mt4', data)
  }

  const changeMt4Pass = (data) => {
    return _api.post('user-api/data/change-mt4-password', data)
  }

  const changeMt4InvestorPass = (data) => {
    return _api.post('user-api/data/change-mt4-investor-password', data)
  }

  const submitTicket = (data) => {
    return _api.post('user-api/data/submit-ticket', data)
  }

  const getTickets = (data) => {
    return _api.post(`user-api/data/ticket?${getParams(data)}`)
  }

  const ticketDetail = (data) => {
    return _api.post(`user-api/data/ticket-detail?${getParams(data)}`)
  }

  const submitTicketDetail = (data) => {
    return _api.post('user-api/data/submit-ticket-detail', data)
  }

  const resetPassword = (data) => {
    return _api.post('api/reset-password', data)
  }

  const uploadProfilePic = (data) => {
    return _api.post('user-api/data/upload-profile-pic', data)
  }

  const depositHistory = (data) => {
    return _api.post(`user-api/data/deposit-history?${getParams(data)}`)
  }

  const forgotPassword = (data) => {
    return _api.post('api/forgot-password', data)
  }

  const bankDeposit = (data) => {
    return _api.post('user-api/data/deposit', data)
  }

  const nganDeposit = (data) => {
    return _api.post('deposit-nganluong', data)
  }

  const companyCryptoWalletDeposit = (data) => {
    return _api.post('user-api/data/deposit-company-crypto-wallet', data)
  }

  const withdrawalHistory = (data) => {
    return _api.post(`user-api/data/withdrawal-history?${getParams(data)}`)
  }

  const balanceGraph = (data) => {
    return _api.post(`user-api/data/daily-balance?${getParams(data)}`)
  }

  const upcomingEvents = () => {
    // const prefix = process.env.NODE_ENV === 'production' ? "" : `https://cors-anywhere.herokuapp.com/`
    return _nextradesAPI.post(`api/upcoming-events?limit=10`)
  }

  const newsRedirect = (data) => {
    return _api.get(`user-api/data/login-voyance-link?user_id=${data.user_id}`)
  }

  const cancelWithdrawal = (data) => {
    return _api.post(`user-api/data/cancel-withdrawal`, data)
  }

  const currencyRate = (data) => {
    return _api.post(`api/currency-rate`, data)
  }

  const pspCurrencyRate = (data) => {
    return _api.post(`user-api/data/psp-currency-rate`, data)
  }

  const pspRateFee = (data) => {
    return _api.post(`trader/payment/psp-rate-fee`, data)
  }

  const cryptoRate = (data) => {
    return _api.post(`user-api/data/crypto-rate?${getParams(data)}`)
  }

  const goLive = (data) => {
    return _api.post('user-api/data/go-live', data)
  }

  const confirmAPI = (data) => {
    return _api.post(`user-api/data/confirm-api`, data)
  }

  const cancelAPI = (data) => {
    return _api.post(`user-api/data/cancel-api`, data)
  }

  // Copy Trading
  const masterTraders = (data) => {
    return _api.post(`user-api/data/master-traders?${getParams(data)}`)
  }

  const masterTrader = (data) => {
    return _api.post(`user-api/data/master-trader?${getParams(data)}`)
  }

  const updateTrader = (data) => {
    return _api.post(`user-api/data/update-trader`, data)
  }

  const updateTraderPhoto = (data) => {
    return _api.post('user-api/data/update-trader-photo', data)
  }

  const masterFollowers = (data) => {
    // const prefix = process.env.NODE_ENV === 'production' ? "" : `https://cors-anywhere.herokuapp.com/`
    return _nextradesAPI.post(`voyance-api/mt4-followers/${data}`)
  }

  const masterTrades = (data) => {
    return _api.post(`user-api/data/master-trades?${getParams(data)}`)
  }

  const followingList = (data) => {
    return _api.post(`user-api/data/following?${getParams(data)}`)
  }

  const followerTrades = (data) => {
    return _api.post(`user-api/data/follower-trades-by-user?${getParams(data)}`)
  }

  const followerCloseTrade = (data) => {
    return _api.post(`user-api/data/follower-close-trade`, data)
  }

  const watchlist = (data) => {
    return _api.post(`user-api/data/watchlist?${getParams(data)}`)
  }

  const updateWatchlist = (data) => {
    return _api.post(`user-api/data/update-watchlist`, data)
  }

  const pauseCopy = (data) => {
    return _api.post(`user-api/data/pause-copy`, data)
  }

  const resumeCopy = (data) => {
    return _api.post(`user-api/data/resume-copy`, data)
  }

  const stopCopy = (data) => {
    return _api.post(`user-api/data/stop-copy`, data)
  }

  const startCopy = (data) => {
    return _api.post(`user-api/data/start-copy`, data)
  }

  const masterSymbols = (data) => {
    return _api.post(`user-api/data/master-symbols?${getParams(data)}`)
  }

  const updateCopySettings = (data) => {
    return _api.post(`user-api/data/update-copy-settings`, data)
  }

  const getCopySettings = (data) => {
    return _api.post(`user-api/data/copy-settings?${getParams(data)}`)
  }

  const mt4Stats = (data) => {
    // const prefix = process.env.NODE_ENV === 'production' ? "" : `https://cors-anywhere.herokuapp.com/`
    return _nextradesAPI.post(`voyance-api/mt4-stats/${data}`)
  }

  const mt4ProfitsChart = (data) => {
    // const prefix = process.env.NODE_ENV === 'production' ? "" : `https://cors-anywhere.herokuapp.com/`
    return _nextradesAPI.post(`voyance-api/mt4-profits/${data}`)
  }

  const mt4EquityBalanceChart = (data) => {
    return _api.post(`voyance-api/mt4-equity-balance/${data.mt4}?timeframe=${data.timeframe}`)
  }

  const mt4MonthlyPL = (data) => {
    // const prefix = process.env.NODE_ENV === 'production' ? "" : `https://cors-anywhere.herokuapp.com/`
    return _nextradesAPI.post(`voyance-api/mt4-monthly-pl/${data}`)
  }

  const mt4SkilledSymbolsChart = (data) => {
    return _api.post(`voyance-api/mt4-skilled-symbols-chart/${data}`)
  }

  const mt4MostTradedChart = (data) => {
    return _api.post(`voyance-api/mt4-most-traded-chart/${data}`)
  }

  const mt4HoldingTimeChart = (data) => {
    return _api.post(`voyance-api/mt4-holding-time-chart/${data}`)
  }

  const paymentMethod = (data) => {
    return _api.post(`user-api/data/payment-method`, data)
  }

  const pendingWithdrawalsByMt4 = (data) => {
    return _api.post(`user-api/data/pending-withdrawals-by-mt4?${getParams(data)}`)
  }

  // MAM/PAMM
  const moneyManagerMt4 = (data) => {
    return _api.post(`user-api/data/money-manager-mt4?${getParams(data)}`)
  }

  const moneyManagers = (data) => {
    return _api.post(`user-api/data/money-managers?${getParams(data)}`)
  }

  const moneyManager = (data) => {
    return _api.post(`user-api/data/money-manager?${getParams(data)}`)
  }

  const updateMoneyManager = (data) => {
    return _api.post(`user-api/data/update-money-manager`, data)
  }

  const updateMoneyManagerSetting = (data) => {
    return _api.post(`user-api/data/update-money-manager-setting`, data)
  }

  const updateMoneyManagerPhoto = (data) => {
    return _api.post(`user-api/data/update-money-manager-photo`, data)
  }

  const investMoneyManager = (data) => {
    return _api.post(`user-api/data/invest-money-manager`, data)
  }

  const withdrawMoneyManager = (data) => {
    return _api.post(`user-api/data/withdraw-money-manager`, data)
  }

  const investing = (data) => {
    return _api.post(`user-api/data/investing?${getParams(data)}`)
  }

  const investorDeposits = (data) => {
    return _api.post(`user-api/data/investor-deposits?${getParams(data)}`)
  }

  const investorDepositAction = (data) => {
    return _api.post(`user-api/data/investor-deposit-action`, data)
  }

  const investorWithdrawals = (data) => {
    return _api.post(`user-api/data/investor-withdrawals?${getParams(data)}`)
  }

  const investorWithdrawalAction = (data) => {
    return _api.post(`user-api/data/investor-withdrawal-action`, data)
  }

  const investorSL = (data) => {
    return _api.post(`trader/investor/sl?${getParams(data)}`)
  }

  const investorDetach = (data) => {
    return _api.post(`trader/investor/detach?${getParams(data)}`)
  }

  const moneyManagerInvestors = (data) => {
    return _api.post(`user-api/data/money-manager-investors?${getParams(data)}`)
  }

  const moneyManagerTrades = (data) => {
    return _api.post(`user-api/data/money-manager-trades?${getParams(data)}`)
  }

  // Liquidity Pool
  const liqPoolTNC = () => {
    return _api.post('user-api/liquidity-pool/tnc')
  }

  const liqPoolWalletData = (data) => {
    return _api.post(`user-api/liquidity-pool/wallet-data?${getParams(data)}`)
  }

  const liqPoolWalletHistory = (data) => {
    return _api.post(`user-api/liquidity-pool/wallet-history?${getParams(data)}`)
  }

  const liqPoolCompanyWalletAddress = (data) => {
    return _api.post(`user-api/liquidity-pool/company-wallet-address?${getParams(data)}`)
  }

  const liqPoolDeposit = (data) => {
    return _api.post(`user-api/liquidity-pool/deposit`, data)
  }

  const liqPoolWithdraw = (data) => {
    return _api.post(`user-api/liquidity-pool/withdraw`, data)
  }

  const liqPoolStake = (data) => {
    return _api.post(`user-api/liquidity-pool/stake`, data)
  }

  const liqPoolStakeData = (data) => {
    return _api.post(`user-api/liquidity-pool/stake-data?${getParams(data)}`)
  }

  const liqPoolStakeHistory = (data) => {
    return _api.post(`user-api/liquidity-pool/stake-history?${getParams(data)}`)
  }

  const liqPoolStakeWithdraw = (data) => {
    return _api.post(`user-api/liquidity-pool/stake-withdraw`, data)
  }

  // Task Centre
  const taskCentreTaskListing = (data) => {
    return _api.post(`user-api/data/task-listing?${getParams(data)}`)
  }

  const taskCentreClaimTask = (data) => {
    return _api.post(`user-api/data/claim-task`, data)
  }

  const taskCentreCompleteTask = (data) => {
    return _api.post(`user-api/data/complete-task`, data)
  }

  const taskCentreForfeitTask = (data) => {
    return _api.post(`user-api/data/forfeit-task`, data)
  }

  // Reward Centre
  const rewardCentreRewardListing = (data) => {
    return _api.post(`user-api/data/reward-listing?${getParams(data)}`)
  }

  const rewardCentreRedeemVoucher = (data) => {
    return _api.post(`user-api/data/redeem-voucher`, data)
  }

  const rewardCentreRedeemVoucherCode = (data) => {
    return _api.post(`user-api/data/redeem-voucher-code`, data)
  }

  const rewardCentreChoosePrize = (data) => {
    return _api.post(`user-api/data/update-loyalty-program-user-reward`, data)
  }

  //Promo Materials
  const promoMaterial = (data) => {
    return _api.get(`trader/promo-material?${getParams(data)}`)
  }

  const promoMaterialCounter = (data) => {
    return _api.post(`trader/promo-material/promo-material-view?${getParams(data)}`)
  }

  // HWGC
  const hwgcStatus = (data) => {
    return _api.post(`trader/payment/hwgc-status?${getParams(data)}`)
  }

  const hwgcSignUp = (data) => {
    return _api.post(`trader/payment/hwgc-sign-up`, data)
  }

  const hwgcRate = (data) => {
    return _api.post(`trader/payment/hwgc-rate`)
  }

  const hwgcBankWire = (data) => {
    return _api.post(`trader/payment/hwgc-bank-wire`, data)
  }

  const hwgcBankAccount = (data) => {
    return _api.post(`trader/payment/hwgc-bank-account?${getParams(data)}`)
  }

  const hwgcUpdateBank = (data) => {
    return _api.post('trader/payment/hwgc-update-bank', data)
  }

  const hwgcCard = (data) => {
    return _api.post(`trader/payment/hwgc-card?${getParams(data)}`)
  }

  const hwgcApplyCard = (data) => {
    return _api.post('trader/payment/hwgc-apply-card', data)
  }

  const hwgcBindCard = (data) => {
    return _api.post('trader/payment/hwgc-bind-card', data)
  }

  const hwgcUpdateEmail = (data) => {
    return _api.post('trader/payment/hwgc-update-email', data)
  }

  const hwgcUpdateMobile = (data) => {
    return _api.post('trader/payment/hwgc-update-mobile', data)
  }

  // Instruments
  const spreadGroupSymbolPrice = (spread_group) => {
    return _api.post(`symbol-api/spread-group-symbols-price/${spread_group}`)
  }

  const spreadGroupSymbolPricecTrader = (data) => {
    return _cTradaerNextradesAPI.get(`spreadGroupSymbolPrice?${getParams(data)}`)
  }

  const symbolViews = (data) => {
    return _api.post(`user-api/data/views`, data)
  }

  const symbolUpdateViews = (data) => {
    return _api.post('user-api/data/update-views', data)
  }

  const symbolDetails = (data) => {
    let params = ''
    if (Object.keys(data).length > 0) {
      params = getParams(data)
    }
    return _api.post(`trader/trade/symbol-details?${params}`)
  }

  const ohlc = (data) => {
    const { base_symbol, interval, start_date, end_date } = data
    return _api.post(`symbol-api/ohlc/${base_symbol}/${interval}/${start_date}/${end_date}`)
  }

  const moduleMarketsData = (data) => {
    return _api.post(`trader/module/markets`)
  }

  const tradeSymbol = (data) => {
    return _api.post(`trader/trade/symbol?${getParams(data)}`)
  }

  const favouriteSymbol = (data) => {
    return _api.post(`trader/trade/favourite-symbol?${getParams(data)}`)
  }

  const addFavouriteSymbol = (data) => {
    return _api.post('trader/trade/favourite-symbol/add', data)
  }

  const removeFavouriteSymbol = (data) => {
    return _api.post('trader/trade/favourite-symbol/remove', data)
  }

  const marketsOpenTrades = (data) => {
    return _api.post(`trader/trade/open-trades?${getParams(data)}`)
  }

  const marketsTrades = (data) => {
    return _api.post(`trader/trade/trades?${getParams(data)}`)
  }

  const marketsTradeOpen = (data) => {
    return _api.post(`trader/trade/open`, data)
  }

  const marketsTradeClose = (data) => {
    return _api.post(`trader/trade/close`, data)
  }

  const marketsTradeCloseSymbol = (data) => {
    return _api.post(`trader/trade/close-symbol`, data)
  }

  const marketsTradeCloseBy = (data) => {
    return _api.post(`trader/trade/close-by`, data)
  }

  const marketsTradeModify = (data) => {
    return _api.post(`trader/trade/modify`, data)
  }

  const marketsTradeDeletePending = (data) => {
    return _api.post(`trader/trade/delete-pending`, data)
  }

  const marketsJournal = (data) => {
    return _api.post(`trader/trade/journal?${getParams(data)}`)
  }

  // Trading Central Modules
  const symbolCategory = () => {
    return _api.post(`api/symbol-category`)
  }

  const tradeIdeas = (data) => {
    return _nextradesAPI.post(`tc/trade-ideas?${getParams(data)}`)
  }

  const smartScore = (data) => {
    return _nextradesAPI.post(`tc/smart-score?${getParams(data)}`)
  }

  const aiTechScreener = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener?${getParams(data)}`)
  }

  const aiTechScreenerEventTypes = (data) => {
    return _nextradesAPI.post(`tc/event-types?${getParams(data)}`)
  }

  const aiTechScreenerEvents = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener/events?${getParams(data)}`)
  }

  const aiTechScreenerEventCounts = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener/event_counts?${getParams(data)}`)
  }

  const aiTechScreenerTechnicalSummaries = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener/technical_summaries?${getParams(data)}`)
  }

  const aiTechScreenerSupportResistance = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener/support_resistance?${getParams(data)}`)
  }

  const aiTechScreenerStopPrice = (data) => {
    return _nextradesAPI.post(`tc/ai-technical-screener/stop_price?${getParams(data)}`)
  }

  const economicCalendar = (data) => {
    return _nextradesAPI.post(`tc-e/economic-calendar?${getParams(data)}`)
  }

  const economicCalendarEventChart = (data) => {
    return _nextradesAPI.post(`tc-e/economic-calendar-event-chart?${getParams(data)}`)
  }

  const economicCalendarCurrencySymbol = (data) => {
    return _nextradesAPI.post(`tc-e/economic-calendar-currency-symbol?${getParams(data)}`)
  }

  const economicCalendarVolatility = (data) => {
    return _nextradesAPI.post(`tc-e/volatility?${getParams(data)}`)
  }

  const economicCalendarImpact = (data) => {
    return _nextradesAPI.post(`tc-e/impact?${getParams(data)}`)
  }

  const rssNews = (data) => {
    return _marketNewsAPI.get(`api/v1/marketNews/news?${getParams(data)}`)
  }

  const liveBroadcast = (data) => {
    return _communityAPI.post(`api-v2.php?type=get-voyance-web-video`, getCommunityParams(data))
  }

  // CMS API
  const cmsInstrumentsCategories = (data) => {
    return _cmsAPI.get(`api/v2/categories/Instruments`)
  }

  const cmsInstruments = (data) => {
    return _cmsAPI.get(`api/v2/data/Instruments?${getParams(data)}`)
  }

  const cmsEducationCategoriesOld = (data) => {
    return _cmsAPI.get(`api/v2/categories/Education`)
  }

  // New CMS
  const cmsEducationCategories = (params) => {
    return _newCmsAPI.get(
      `section/getSectionCategory?name=education&${params.lang ? `lang=${params.lang}` : ''}`,
    )
  }

  const getPromotion = (params) => {
    return _cmsAPI.get(
      `api/v2/data/Promotion?${
        params.lang ? `lang=${params.lang}` : ''
      }&page=1&row=&category_title=${encodeURIComponent('Tradersroom Web')}`,
    )
  }

  // New CMS
  const getPromotionNew = (params) => {
    return _newCmsAPI.get(
      `section/getSectionPosting?name=promotion&${params.lang ? `lang=${params.lang}` : ''}`,
    )
  }

  // Community
  const getCommunityParams = (params) => {
    let formData = new FormData()
    formData.set('server_key', '37904c4f8a43d26f8cb42727a62f2cca')
    formData.set('brand', store.get('communityBrandSettingsCompanyNameShort'))
    formData.set('include_only_brand_users', store.get('communityIncludeOnlyBrandUsersEnabled'))
    Object.keys(params).map((key) => formData.set(key, params[key]))
    return formData
  }

  const appendCommunityFormdata = (formData) => {
    formData.append('server_key', '37904c4f8a43d26f8cb42727a62f2cca')
    formData.append('brand', store.get('communityBrandSettingsCompanyNameShort'))
    formData.append('include_only_brand_users', store.get('communityIncludeOnlyBrandUsersEnabled'))
    return formData
  }

  const communityAuth = (data) => {
    // return _communityAPI.post(`api-v2.php?type=auth`, getCommunityParams(data))
    return _api.post(
      `/trader/community/access-token?user_id=${data.user_id}&type=${_communityENV}`,
      getCommunityParams(data),
    )
  }

  const communityGetUserData = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-user-data-v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityPosts = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetUserPosts = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetRecommended = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=fetch-recommended&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityCreateAlbum = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=albums&access_token=${data.access_token}`,
      data.params,
    )
  }

  const communityAddtoAlbum = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=albums&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  // const postNewCommunityPost = (data) => {
  //   return _communityAPI.post(
  //     `​api-v2.php?type=new_post&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  const postCommunityPost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=new_post_v3&access_token=${data.access_token}`,
      appendCommunityFormdata(data.params),
    )
  }

  const postComment = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=comments&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const postReply = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=comments&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const fetchComments = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=comments&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }
  const fetchCommentsReply = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=comments&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityCommentLike = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=comments&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetPostData = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-post-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const postLike = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetFollowersList = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-user-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetFollowingList = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-user-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetGroupData = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-group-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityEditPost = (data) => {
    return _communityAPI.post(
      `/api-v2.php?type=post-actions_v2&access_token=${data.access_token}`,
      appendCommunityFormdata(data.params),
      // getCommunityParams(data.params),
    )
  }

  const communityDeletePost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityDisablePostComments = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communitySavePost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=save-post&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityReportPost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityPhotoPostFilter = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityVideoPostFilter = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityFollowUser = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=follow-user&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetAdditionalPosts = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetAdditionalVideoPosts = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetAdditionalNewsFeed = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetRSSNews = (data) => {
    return _communityAPI.post(`api-v2.php?type=get-voyance-news`, getCommunityParams(data.params))
  }

  const communitySharePost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=share-post-on-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityHidePost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetTrendingHashTag = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-general-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetEventsList = (data) => {
    return _communityAPI.post(
      // `api-v2.php?type=get-events&access_token=${data.access_token}`,
      `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  // const communityGetEventGoingList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  // const communityGetMyGoingEventsList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  const communityGetMyInterestedEventsList = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetMySavedEventsList = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  // const communityGetPastEventsList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  // const communityGetEventInterestedList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  // const communityGetEventInvitedList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  // const communityGetMyEventsList = (data) => {
  //   return _communityAPI.post(
  //     `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
  //     getCommunityParams(data.params),
  //   )
  // }

  const communityGetMyEventsCustomList = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityJoinEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=go-to-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityCreateEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=create-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityPinPost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=post-actions_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetEventData = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-event-by-id&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetEventPosts = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=posts_v2&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityInviteToEvent = (data) => {
    return _communityAPI.post(
      `/api-v2.php?type=register_event_invite_v2&access_token=${data.access_token}`,
      // getCommunityParams(data.params),
      appendCommunityFormdata(data.params),
    )
  }

  const communitySearchAll = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=search_voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communitySearchUsers = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=search_type_voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communitySearchType = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=search_type_voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityUpdateEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=events&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGoingToEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=go-to-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityInterestedInEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=interest-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communitySearchEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-events-voyance&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetMostFollowersUsers = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get-general-data&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityDeleteEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=events&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityEventAction = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=event-action&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communitySaveEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=save-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityRemoveFilesfromPost = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=delete_album_media_v2&access_token=${data.access_token}`,
      appendCommunityFormdata(data.params),
    )
  }

  const communityReportEvent = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=report-event&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }
  const communityUpdatePostFeeling = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=update_post_feeling&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetUserPhotos = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get_images_list&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetUserVideos = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get_video_list&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetUserSounds = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get_sound_list&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityGetUserDocuments = (data) => {
    return _communityAPI.post(
      `api-v2.php?type=get_document_list&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityReportComment = (data) => {
    return _communityAPI.post(
      `/api-v2.php?type=report_comment&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const communityReportReply = (data) => {
    return _communityAPI.post(
      `/api-v2.php?type=report_comment_reply&access_token=${data.access_token}`,
      getCommunityParams(data.params),
    )
  }

  const traderModuleCommunity = () => {
    return _api.post(`trader/module/community`)
  }

  const loyaltyProgramListing = (data) => {
    return _api.get(`user-api/data/loyalty-program-listing?${getParams(data)}`)
  }

  const loyaltyProgramCompleteTask = (data) => {
    return _api.post(`user-api/data/complete-loyalty-program-task`, data)
  }

  return {
    brandSettings,
    moduleSettings,
    sidebarModuleSettings,
    brandSettingsTNCPrivacy,
    sidebarModuleUserSettings,
    sidebarModulePostUserSettings,
    individualSignUp,
    corporateSignUp,
    demoSignUp,
    userLogin,
    changePassword,
    loginHistory,
    getMe,
    walletOperation,
    walletOperationsDetails,
    walletOperationsDetailsData,
    bankDetails,
    updateBankDetail,
    foreverpayBank,
    foreverpayBankState,
    foreverpayBankCity,
    updateForeverpayBank,
    paytrustBank,
    updatePaytrustBank,
    updateFasapay,
    helppayBank,
    updateHelppayBank,
    eeziepayBank,
    updateEeziepayBank,
    eeziepayINRBank,
    updateEeziepayINRBank,
    xPayBank,
    updateXPayBank,
    anpayBank,
    updateAnpayBank,
    paybnbBank,
    updatePaybnbBank,
    fivepayBank,
    updateFivepayBank,
    directPayBank,
    papayaPayBank,
    updatePapayaPayBank,
    updateResidence,
    updateUserInfo,
    updateProfile,
    updateLanguage,
    upgradeCorporate,
    corporateInfo,
    addWithdrawal,
    getKYC,
    uploadKYC,
    historyKYC,
    selfTrading,
    selfTradingDemo,
    selfTradingFull,
    selfTradingDemoFull,
    getAffiliateTree,
    affiliateClientListing,
    affiliateCommissionHistory,
    affiliateClientTradingTxs,
    affiliateClientDepWitTxs,
    networkMt4,
    depositMt4,
    tradingProduct,
    changeMt4Pass,
    changeMt4InvestorPass,
    submitTicket,
    getTickets,
    openMt4,
    mt4LiveInfo,
    mt4MarginInfo,
    mt4BalanceCredit,
    withdrawalMt4,
    ticketDetail,
    submitTicketDetail,
    resetPassword,
    uploadProfilePic,
    depositHistory,
    forgotPassword,
    bankDeposit,
    nganDeposit,
    companyCryptoWalletDeposit,
    withdrawalHistory,
    balanceGraph,
    upcomingEvents,
    newsRedirect,
    cancelWithdrawal,
    currencyRate,
    pspCurrencyRate,
    pspRateFee,
    cryptoRate,
    goLive,
    confirmAPI,
    cancelAPI,
    masterTraders,
    masterTrader,
    updateTrader,
    updateTraderPhoto,
    masterFollowers,
    masterTrades,
    followingList,
    followerTrades,
    followerCloseTrade,
    watchlist,
    updateWatchlist,
    pauseCopy,
    resumeCopy,
    stopCopy,
    startCopy,
    masterSymbols,
    updateCopySettings,
    getCopySettings,
    mt4Stats,
    mt4ProfitsChart,
    mt4EquityBalanceChart,
    mt4MonthlyPL,
    mt4SkilledSymbolsChart,
    mt4MostTradedChart,
    mt4HoldingTimeChart,
    paymentMethod,
    pendingWithdrawalsByMt4,
    moneyManagerMt4,
    moneyManagers,
    moneyManager,
    updateMoneyManager,
    updateMoneyManagerSetting,
    updateMoneyManagerPhoto,
    investMoneyManager,
    withdrawMoneyManager,
    investing,
    investorDeposits,
    investorDepositAction,
    investorWithdrawals,
    investorWithdrawalAction,
    investorSL,
    investorDetach,
    moneyManagerInvestors,
    moneyManagerTrades,
    liqPoolTNC,
    liqPoolWalletData,
    liqPoolWalletHistory,
    liqPoolCompanyWalletAddress,
    liqPoolDeposit,
    liqPoolWithdraw,
    liqPoolStake,
    liqPoolStakeData,
    liqPoolStakeHistory,
    liqPoolStakeWithdraw,
    taskCentreTaskListing,
    taskCentreClaimTask,
    taskCentreCompleteTask,
    taskCentreForfeitTask,
    rewardCentreRewardListing,
    rewardCentreRedeemVoucher,
    rewardCentreRedeemVoucherCode,
    rewardCentreChoosePrize,
    promoMaterial,
    promoMaterialCounter,
    hwgcStatus,
    hwgcSignUp,
    hwgcRate,
    hwgcBankWire,
    hwgcBankAccount,
    hwgcUpdateBank,
    hwgcCard,
    hwgcApplyCard,
    hwgcBindCard,
    hwgcUpdateEmail,
    hwgcUpdateMobile,
    spreadGroupSymbolPrice,
    spreadGroupSymbolPricecTrader,
    symbolViews,
    symbolUpdateViews,
    symbolDetails,
    symbolCategory,
    ohlc,
    moduleMarketsData,
    tradeSymbol,
    favouriteSymbol,
    addFavouriteSymbol,
    removeFavouriteSymbol,
    marketsOpenTrades,
    marketsTrades,
    marketsTradeOpen,
    marketsTradeClose,
    marketsTradeCloseSymbol,
    marketsTradeCloseBy,
    marketsTradeModify,
    marketsTradeDeletePending,
    marketsJournal,
    tradeIdeas,
    smartScore,
    aiTechScreener,
    aiTechScreenerEventTypes,
    aiTechScreenerEvents,
    aiTechScreenerEventCounts,
    aiTechScreenerTechnicalSummaries,
    aiTechScreenerSupportResistance,
    aiTechScreenerStopPrice,
    economicCalendar,
    economicCalendarEventChart,
    economicCalendarCurrencySymbol,
    economicCalendarVolatility,
    economicCalendarImpact,
    rssNews,
    liveBroadcast,
    cmsInstrumentsCategories,
    cmsInstruments,
    cmsEducationCategoriesOld,
    cmsEducationCategories,
    getPromotion,
    getPromotionNew,
    communityAuth,
    communityPosts,
    postCommunityPost,
    postComment,
    fetchComments,
    communityGetPostData,
    postReply,
    fetchCommentsReply,
    postLike,
    communityGetUserData,
    communityCreateAlbum,
    communityAddtoAlbum,
    communityGetRecommended,
    communityGetUserPosts,
    communityGetFollowersList,
    communityGetFollowingList,
    communityGetGroupData,
    communityEditPost,
    communityDeletePost,
    communityDisablePostComments,
    communitySavePost,
    communityReportPost,
    communityPhotoPostFilter,
    communityVideoPostFilter,
    communityFollowUser,
    communityGetAdditionalPosts,
    communityGetAdditionalNewsFeed,
    communityGetAdditionalVideoPosts,
    communityGetRSSNews,
    communitySharePost,
    communityHidePost,
    communityGetTrendingHashTag,
    communityCommentLike,
    communityGetEventsList,
    // communityGetEventGoingList,
    // communityGetEventInvitedList,
    // communityGetEventInterestedList,
    communityGetMyEventsCustomList,
    // communityGetPastEventsList,
    communityJoinEvent,
    communityCreateEvent,
    communityPinPost,
    communityGetEventData,
    communityGetEventPosts,
    communityInviteToEvent,
    // communityGetMyGoingEventsList,
    communityGetMyInterestedEventsList,
    communitySearchAll,
    // communityGetMyEventsList,
    communitySearchUsers,
    communityUpdateEvent,
    communityGoingToEvent,
    communityInterestedInEvent,
    communitySearchEvent,
    communityGetMostFollowersUsers,
    communitySearchType,
    communityDeleteEvent,
    traderModuleCommunity,
    communityEventAction,
    communitySaveEvent,
    communityRemoveFilesfromPost,
    communityReportEvent,
    communityUpdatePostFeeling,
    communityGetMySavedEventsList,
    communityGetUserPhotos,
    communityGetUserVideos,
    communityGetUserSounds,
    communityGetUserDocuments,
    communityReportComment,
    communityReportReply,
    loyaltyProgramListing,
    loyaltyProgramCompleteTask,
  }
}

export default ApiUser()
