// export const origin =
//   process.env.NODE_ENV === 'development'
//     ? 'https://member.equinox-markets.com'
//     : window.location.origin
export const origin = 'https://member.axecap.co'  // DEBUG

export default {
  baseURL: 'https://member.axecap.co/api/',
  siteURL: `${origin.replace('stg.', 'member.')}/`,
  websiteURL: `${origin.replace('member.', 'www.').replace('stg.', 'www.')}/`,
  cmsAPI: `${origin.replace('member.', 'cmsapi.')}/api/`,
  nextradesURL: 'https://nextrades.ai/',
  communityURL: 'https://nextrades.ai/community/',
  communityENV: 'production',
  headers: {
    'X-Parse-Application-Id': 'AT_POPApp',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  timeOut: 30000,
}
