export default {
  default_avatar: require('shared-crm/assets/images/avatar/default.png'),
  mt4: require('shared-crm/assets/images/mt4.png'),
  loading_chart_gif: require('shared-crm/assets/images/loading-chart.gif'),

  // Icons
  verified: require('shared-crm/assets/images/icons/verified.svg'),
  unverified: require('shared-crm/assets/images/icons/unverified.svg'),
  tick: require('shared-crm/assets/images/icons/tick.png'),
  exclaimation: require('shared-crm/assets/images/icons/exclaimation.png'),
  wallet: require('shared-crm/assets/images/icons/wallet-icon.png'),

  // PSP-logo
  bankwire: require('shared-crm/assets/images/psp-logo/bankwire.png'),
  _5pay: require('shared-crm/assets/images/psp-logo/5pay.png'),
  _9pay: require('shared-crm/assets/images/psp-logo/9pay.png'),
  btc: require('shared-crm/assets/images/psp-logo/btc.png'),
  eth: require('shared-crm/assets/images/psp-logo/eth.png'),
  usdt: require('shared-crm/assets/images/psp-logo/usdt.png'),
  hwgc: require('shared-crm/assets/images/psp-logo/hwgc.png'),
  coinpayment: require('shared-crm/assets/images/psp-logo/coinpayment.png'),
  eeziepay: require('shared-crm/assets/images/psp-logo/eeziepay.png'),
  enets: require('shared-crm/assets/images/psp-logo/enets.png'),
  fasapay: require('shared-crm/assets/images/psp-logo/fasapay.png'),
  foreverpay: require('shared-crm/assets/images/psp-logo/foreverpay.png'),
  fpx: require('shared-crm/assets/images/psp-logo/fpx.png'),
  help2pay: require('shared-crm/assets/images/psp-logo/help2pay.png'),
  lyhour: require('shared-crm/assets/images/psp-logo/lyhour.png'),
  m3pay: require('shared-crm/assets/images/psp-logo/m3pay.png'),
  nganluong: require('shared-crm/assets/images/psp-logo/nganluong.png'),
  paybnb: require('shared-crm/assets/images/psp-logo/paybnb.png'),
  paynow: require('shared-crm/assets/images/psp-logo/paynow.jpg'),
  paytrust: require('shared-crm/assets/images/psp-logo/paytrust.png'),
  visa_master_jcb: require('shared-crm/assets/images/psp-logo/visa-master-jcb.png'),
  xpay: require('shared-crm/assets/images/psp-logo/xpay.png'),
  epay: require('shared-crm/assets/images/psp-logo/epay.png'),
  directpay: require('shared-crm/assets/images/psp-logo/directpay.png'),

  // Country Flag
  cambodia: require('shared-crm/assets/images/country-flag/cambodia.svg'),
  china: require('shared-crm/assets/images/country-flag/china.svg'),
  indonesia: require('shared-crm/assets/images/country-flag/indonesia.svg'),
  international: require('shared-crm/assets/images/country-flag/international.svg'),
  malaysia: require('shared-crm/assets/images/country-flag/malaysia.svg'),
  philippines: require('shared-crm/assets/images/country-flag/philippines.svg'),
  singapore: require('shared-crm/assets/images/country-flag/singapore.svg'),
  thailand: require('shared-crm/assets/images/country-flag/thailand.svg'),
  uk: require('shared-crm/assets/images/country-flag/uk.svg'),
  vietnam: require('shared-crm/assets/images/country-flag/vietnam.svg'),
  india: require('shared-crm/assets/images/country-flag/india.svg'),

  // Platform
  android: require('shared-crm/assets/images/platform/android.png'),
  google_play_store: require('shared-crm/assets/images/platform/google-play-store.png'),
  huawei: require('shared-crm/assets/images/platform/huawei.png'),
  ios: require('shared-crm/assets/images/platform/ios.png'),
  mac_os_x: require('shared-crm/assets/images/platform/mac-os-x.png'),
  window: require('shared-crm/assets/images/platform/window.png'),

  // Liquidity Pool
  liqpool_mountain: require('shared-crm/assets/images/liqPool/liqpool-mountain.png'),

  // Flag for Market Hours
  london: require('shared-crm/assets/images/market-flag/london.png'),
  newyork: require('shared-crm/assets/images/market-flag/newyork.png'),
  sydney: require('shared-crm/assets/images/market-flag/sydney.png'),
  tokyo: require('shared-crm/assets/images/market-flag/tokyo.png'),

  // Instruments symbol
  symbol_alibaba: require('shared-crm/assets/images/instrument-symbol/alibaba.png'),
  symbol_amazon: require('shared-crm/assets/images/instrument-symbol/amazon.png'),
  symbol_apple: require('shared-crm/assets/images/instrument-symbol/apple.png'),
  symbol_google: require('shared-crm/assets/images/instrument-symbol/google.png'),
  symbol_intel: require('shared-crm/assets/images/instrument-symbol/intel.png'),
  symbol_tesla: require('shared-crm/assets/images/instrument-symbol/tesla.png'),

  // Newsletter
  newsletter_1: require('shared-crm/assets/images/newsletter/newsletter-1.jpg'),
  newsletter_2: require('shared-crm/assets/images/newsletter/newsletter-2.jpg'),
  newsletter_3: require('shared-crm/assets/images/newsletter/newsletter-3.jpg'),
  newsletter_4: require('shared-crm/assets/images/newsletter/newsletter-4.jpg'),

  // CopyTrading/MAMPAMM Guard
  guard_cl: 'resources/images/guard-cl.png',
  guard_bw: 'resources/images/guard-bw.png',

  // Theme Setting\
  light_mode: 'resources/images/theme-setting/light-mode.png',
  dark_mode: 'resources/images/theme-setting/dark-mode.png',
  device_setting: 'resources/images/theme-setting/device-setting.png',

  //sidebar navigation
  nav_a_i_technical_screener: '/resources/images/nav-icon/nav-ai-technical-screener.png',
  nav_a_i_technical_screener_active:
    '/resources/images/nav-icon/nav-ai-technical-screener-active.png',

  nav_analysis: '/resources/images/nav-icon/nav-analysis.png',
  nav_analysis_active: '/resources/images/nav-icon/nav-analysis-active.png',

  nav_analysis_view: '/resources/images/nav-icon/nav-analysis-view.png',
  nav_analysis_view_active: '/resources/images/nav-icon/nav-analysis-view-active.png',

  nav_charts: '/resources/images/nav-icon/nav-charts.png',
  nav_charts_active: '/resources/images/nav-icon/nav-charts-active.png',

  nav_client_deposits_withdrawals: '/resources/images/nav-icon/nav-client-deposits-withdrawals.png',
  nav_client_deposits_withdrawals_active:
    '/resources/images/nav-icon/nav-client-deposits-withdrawals-active.png',

  nav_client_listing: '/resources/images/nav-icon/nav-client-listing.png',
  nav_client_listing_active: '/resources/images/nav-icon/nav-client-listing-active.png',

  nav_clients_trades: '/resources/images/nav-icon/nav-client-trades.png',
  nav_clients_trades_active: '/resources/images/nav-icon/nav-client-trades-active.png',

  nav_commission_history: '/resources/images/nav-icon/nav-commission-history.png',
  nav_commission_history_active: '/resources/images/nav-icon/nav-commission-history-active.png',

  nav_community: '/resources/images/nav-icon/nav-community.png',
  nav_community_active: '/resources/images/nav-icon/nav-community-active.png',

  nav_news_feed: '/resources/images/nav-icon/nav-market-news.png',
  nav_news_feed_active: '/resources/images/nav-icon/nav-market-news-active.png',

  nav_events: '/resources/images/nav-icon/nav-community.png',
  nav_events_active: '/resources/images/nav-icon/nav-community-active.png',

  nav_copy_trade: '/resources/images/nav-icon/nav-copy-trade.png',
  nav_copy_trade_active: '/resources/images/nav-icon/nav-copy-trade-active.png',

  nav_deposit: '/resources/images/nav-icon/nav-deposit.png',
  nav_deposit_active: '/resources/images/nav-icon/nav-deposit-active.png',

  nav_economic_calendar: '/resources/images/nav-icon/nav-economic-calendar.png',
  nav_economic_calendar_active: '/resources/images/nav-icon/nav-economic-calendar-active.png',

  nav_education: '/resources/images/nav-icon/nav-education.png',
  nav_education_active: '/resources/images/nav-icon/nav-education-active.png',

  nav_faq: '/resources/images/nav-icon/nav-faq.png',
  nav_faq_active: '/resources/images/nav-icon/nav-faq-active.png',

  nav_home: '/resources/images/nav-icon/nav-home.png',
  nav_home_active: '/resources/images/nav-icon/nav-home-active.png',

  nav_liquidity_pool: '/resources/images/nav-icon/nav-liquidity-pool.png',
  nav_liquidity_pool_active: '/resources/images/nav-icon/nav-liquidity-pool-active.png',

  nav_mam_pamm: '/resources/images/nav-icon/nav-mampamm.png',
  nav_mam_pamm_active: '/resources/images/nav-icon/nav-mampamm-active.png',

  nav_market_news: '/resources/images/nav-icon/nav-market-news.png',
  nav_market_news_active: '/resources/images/nav-icon/nav-market-news-active.png',

  nav_markets: '/resources/images/nav-icon/nav-markets.png',
  nav_markets_active: 'resources/images/nav-icon/nav-markets-active.png',

  nav_newsletter: '/resources/images/nav-icon/nav-newsletter.png',
  nav_newsletter_active: 'resources/images/nav-icon/nav-newsletter-active.png',

  nav_panoramic_view: '/resources/images/nav-icon/nav-panoramic-view.png',
  nav_panoramic_view_active: '/resources/images/nav-icon/nav-panoramic-view-active.png',

  nav_promotions: '/resources/images/nav-icon/nav-promotion.png',
  nav_promotions_active: '/resources/images/nav-icon/nav-promotion-active.png',

  nav_quotes: '/resources/images/nav-icon/nav-quotes.png',
  nav_quotes_active: '/resources/images/nav-icon/nav-quotes-active.png',

  nav_referral: '/resources/images/nav-icon/nav-referral.png',
  nav_referral_active: '/resources/images/nav-icon/nav-referral-active.png',

  nav_reports: '/resources/images/nav-icon/nav-reports.png',
  nav_reports_active: '/resources/images/nav-icon/nav-reports-active.png',

  nav_reward_center: '/resources/images/nav-icon/nav-reward-center.png',
  nav_reward_center_active: '/resources/images/nav-icon/nav-reward-center-active.png',

  nav_self_trade: '/resources/images/nav-icon/nav-self-trade.png',
  nav_self_trade_active: '/resources/images/nav-icon/nav-self-trade-active.png',

  nav_smart_score: '/resources/images/nav-icon/nav-smart-score.png',
  nav_smart_score_active: '/resources/images/nav-icon/nav-smart-score-active.png',

  nav_support: '/resources/images/nav-icon/nav-support.png',
  nav_support_active: '/resources/images/nav-icon/nav-support-active.png',

  nav_task_center: '/resources/images/nav-icon/nav-task-center.png',
  nav_task_center_active: '/resources/images/nav-icon/nav-task-center-active.png',

  nav_technical_insights: '/resources/images/nav-icon/nav-technical-insights.png',
  nav_technical_insights_active: '/resources/images/nav-icon/nav-technical-insights-active.png',

  nav_trade_ideas: '/resources/images/nav-icon/nav-trade-ideas.png',
  nav_trade_ideas_active: '/resources/images/nav-icon/nav-trade-ideas-active.png',

  nav_trade: '/resources/images/nav-icon/nav-trades.png',
  nav_trade_active: '/resources/images/nav-icon/nav-trades-active.png',

  nav_verification: '/resources/images/nav-icon/nav-verification.png',
  nav_verification_active: '/resources/images/nav-icon/nav-verification-active.png',

  nav_verification_2: '/resources/images/nav-icon/nav-verification-2.png',
  nav_verification_2_active: '/resources/images/nav-icon/nav-verification-2-active.png',

  nav_wallet: '/resources/images/nav-icon/nav-wallet.png',
  nav_wallet_active: '/resources/images/nav-icon/nav-wallet-active.png',

  nav_wallet_operations: '/resources/images/nav-icon/nav-wallet-operations.png',
  nav_wallet_operations_active: '/resources/images/nav-icon/nav-wallet-operations-active.png',

  nav_withdrawal: '/resources/images/nav-icon/nav-withdrawal.png',
  nav_withdrawal_active: '/resources/images/nav-icon/nav-withdrawal-active.png',

  nav_all: '/resources/images/nav-icon/nav-all.png',
  nav_all_active: '/resources/images/nav-icon/nav-all-active.png',

  nav_profile: '/resources/images/nav-icon/nav-profile.png',
  nav_profile_active: '/resources/images/nav-icon/nav-profile-active.png',

  nav_live_broadcast: '/resources/images/nav-icon/nav-live-broadcast.png',
  nav_live_broadcast_active: '/resources/images/nav-icon/nav-live-broadcast-active.png',
  nav_promo_materials: '/resources/images/nav-icon/nav-promo-materials.png',
  nav_promo_materials_active: '/resources/images/nav-icon/nav-promo-materials-active.png',

  // Twemoji
  angry: require('shared-crm/assets/images/community/communityFeelingsImages/angry.svg'),
  happy: require('shared-crm/assets/images/community/communityFeelingsImages/happy.svg'),
  sad: require('shared-crm/assets/images/community/communityFeelingsImages/sad.svg'),
  loved: require('shared-crm/assets/images/community/communityFeelingsImages/loved.svg'),
  so_sad: require('shared-crm/assets/images/community/communityFeelingsImages/so_sad.svg'),
  confused: require('shared-crm/assets/images/community/communityFeelingsImages/confused.svg'),
  hot: require('shared-crm/assets/images/community/communityFeelingsImages/hot.svg'),
  broken: require('shared-crm/assets/images/community/communityFeelingsImages/broken.svg'),
  expresionless: require('shared-crm/assets/images/community/communityFeelingsImages/expresionless.svg'),
  cool: require('shared-crm/assets/images/community/communityFeelingsImages/cool.svg'),
  funny: require('shared-crm/assets/images/community/communityFeelingsImages/funny.svg'),
  tired: require('shared-crm/assets/images/community/communityFeelingsImages/tired.svg'),
  lovely: require('shared-crm/assets/images/community/communityFeelingsImages/lovely.svg'),
  blessed: require('shared-crm/assets/images/community/communityFeelingsImages/blessed.svg'),
  shocked: require('shared-crm/assets/images/community/communityFeelingsImages/shocked.svg'),
  sleepy: require('shared-crm/assets/images/community/communityFeelingsImages/sleepy.svg'),
  pretty: require('shared-crm/assets/images/community/communityFeelingsImages/pretty.svg'),
  bored: require('shared-crm/assets/images/community/communityFeelingsImages/bored.svg'),

  //Community Images
  sound_wave: require('shared-crm/assets/images/community/sound-wave.png'),
  nav_live_broadcast:'/resources/images/nav-icon/nav-live-broadcast.png',
  nav_live_broadcast_active:'/resources/images/nav-icon/nav-live-broadcast-active.png',
  community_404:require('shared-crm/assets/images/community/404-not-found-page.png'),
  community_mobile_home: '/resources/images/home.svg',
  community_mobile_event: '/resources/images/event.svg',
}
