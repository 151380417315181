import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduce } from 'lodash'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'
import EnterpriseModal from './enterpriseModal'

const mapStateToProps = ({ menu, user }) => ({
  isMenuTop: menu.isMenuTop,
  menuTopData: menu.menuTopData,
  menuLeftData: menu.menuLeftData,
  user: user,
  userInfo: user.user,
})

@withRouter
@connect(mapStateToProps)
class Breadcrumbs extends React.Component {
  state = {
    breadcrumb: [],
  }

  componentDidMount() {
    const { dispatch, user } = this.props
    const user_id = user['id']

    this.setBreadcrumbs(this.props)

    dispatch({
      type: 'user/GET_KYC',
      payload: {
        user_id: user_id,
      },
    })
  }

  componentWillReceiveProps(newProps) {
    this.setBreadcrumbs(newProps)
  }

  setBreadcrumbs = (props) => {
    const { isMenuTop, menuTopData, menuLeftData } = this.props
    this.setState({
      breadcrumb: this.getBreadcrumb(props, isMenuTop ? menuTopData : menuLeftData),
    })
  }

  getPath(data, url, parents = []) {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = this.getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter((e) => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  getBreadcrumb = (props, items) => {
    const [activeMenuItem, ...path] = this.getPath(items, props.location.pathname)

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={`${styles.arrow} text-muted`} />
              <span className="text-muted">
                <FormattedMessage id={item.title} />
              </span>
              <span className={`${styles.arrow} activeBreadcrumb`} />
              <span className="font-weight-bold activeBreadcrumb">
                <FormattedMessage id={activeMenuItem.title} />
              </span>
            </span>
          )
        }
        return (
          <span key={item.key}>
            <span className={`${styles.arrow} text-muted `} />
            <span className="text-muted activeBreadcrumb">
              <FormattedMessage id={item.title} />
            </span>
          </span>
        )
      })
    }
    return (
      <span className="activeBreadcrumb">
        <span className={styles.arrow} />
        <strong >{!!activeMenuItem && <FormattedMessage id={activeMenuItem.title} />}</strong>
      </span>
    )
  }

  render() {
    const { breadcrumb } = this.state
    const { userInfo } = this.props

    const pathName = this.props.location.pathname

    return (
      <div
        className={`breadcrumb d-flex flex-column flex-lg-row justify-content-between align-items-center`}
      >
        <div className={styles.path}>
          <Link to="/dashboard/summary" className="text-muted">
            <FormattedMessage id={'common.home'} />
          </Link>
          {breadcrumb}
        </div>
        {(pathName === '/dashboard/Settings' || pathName === '/dashboard/Verification') &&
          userInfo['type'] === 'individual' && <EnterpriseModal />}
      </div>
    )
  }
}

export default Breadcrumbs
