import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { currentAccount, logout } from 'services/user' // Don't remove this line
import { push } from 'react-router-redux'
import actions from './actions'
import * as selectors from './selectors'
import ApiUser from '../../api/userAPI'
import ApiConfig from 'api/config'
import * as _ from 'lodash'
import { STORAGE } from '../../config'
import { extractPriceData } from '../../services/public_functions'

export function* BRAND_SETTINGS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      brandSettingsLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.brandSettings)
    if (response.status === 200) {
      localStorage.setItem(
        'communityBrandSettingsCompanyNameShort',
        response.data.company_name_short,
      )

      yield put({
        type: 'user/SET_STATE',
        payload: {
          brandSettings: response.data,
          brandSettings_loginLayout: response.data.login_layout,
          brandSettings_layout: response.data.layout,
          // brandSettings_layout: 4, //DEBUG
          brandSettingsLoading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MODULE_SETTINGS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      sideBarModuleLoadings: true,
    },
  })

  try {
    const response = yield call(ApiUser.moduleSettings)
    if (response.data.status === 1) {
      localStorage.setItem(
        'communityIncludeOnlyBrandUsersEnabled',
        response.data.data.Community['Include only brand users'].enabled,
      )

      yield put({
        type: 'user/SET_STATE',
        payload: {
          sideBarModuleLoadings: false,
          moduleSettings: response.data.data,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* SIDEBAR_MODULE_SETTINGS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      sideBarModuleLoadings: true,
    },
  })
  try {
    const response = yield call(ApiUser.sidebarModuleSettings)
    if (response.data.status === 1) {
      let res = response.data.data
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // sideBarModulesSettings: response.data.data,
          sideBarModulesSettings: res,
          sideBarModuleLoadings: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* SIDEBAR_MODULE_USER_SETTINGS({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      sideBarModuleUserLoadings: true,
    },
  })
  try {
    const response = yield call(ApiUser.sidebarModuleUserSettings, user_id)
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          sideBarModulesUserSettings: response.data.data,
          sideBarModuleUserLoadings: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* SIDEBAR_MODULE_POST_USER_SETTINGS({ payload }) {
  const { formData } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const user_id = formData.get('user_id')
  const response = yield call(ApiUser.sidebarModulePostUserSettings, formData)
  if (response.data.status === 1) {
    const getresponse = yield call(ApiUser.sidebarModuleUserSettings, user_id)
    if (getresponse.data.status === 1) {
      notification.success({
        message: 'Menu setting is updated.',
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        sideBarModulesUserSettings: getresponse.data.data,
        loading: false,
      },
    })
  }
}

export function* BRAND_SETTINGS_TNC_PRIVACY() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      brandSettingsLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.brandSettingsTNCPrivacy)
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          brandSettingsTNCPrivacy: response.data,
          brandSettingsLoading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* LOGIN({ payload }) {
  const { email, password, fcm_token, lg } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = {
    username: email,
    password: password,
    fcm_token: fcm_token,
    lg: lg,
  }
  const response = yield call(ApiUser.userLogin, data)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      const user_id = response.data['user_id']

      /**
       * Save User Id for Auto Login purpose
       */
      localStorage.setItem(STORAGE.USER_ID, user_id)

      const reqData = {
        user_id: user_id,
      }
      yield call(BRAND_SETTINGS)
      yield call(MODULE_SETTINGS)
      yield call(GET_ME, { payload: reqData, firstLogin: true })
      yield call(CORPORATE_INFO, { payload: reqData })
      yield call(GET_SELF_TRADING, { payload: reqData })
      yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
      yield call(MONEY_MANAGER_MT4, { payload: reqData })
      yield call(COMMUNITY_AUTH, {
        payload: {
          user_id: localStorage.getItem(STORAGE.USER_ID),
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ME({ payload, updateAvatar = false, firstLogin }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.getMe, payload)
  if (response && response.data && !response.data.logout) {
    const avatar = yield select(selectors.avatar)
    const baseURL = ApiConfig.baseURL
    let profilePic = response.data['profile_pic']
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: user_id,
        authorized: true,
        user: response.data,
        loading: false,
        ...((!avatar || updateAvatar) && profilePic
          ? {
              avatar: `${baseURL}${
                response.data['profile_pic']
              }/?user_id=${user_id}&fake=${Math.random()}`,
            }
          : undefined),
      },
    })
    // yield call(BRAND_SETTINGS)
    // yield call(CORPORATE_INFO, { payload })
    // yield call(GET_SELF_TRADING, { payload })
    // yield call(GET_SELF_TRADING_DEMO, { payload })
    // yield call(MONEY_MANAGER_MT4, { payload })
    if (
      firstLogin &&
      response.data.type !== 'demo' &&
      response.data.verification_status === 'pending'
    ) {
      yield put(push('/dashboard/Verification'))
      notification.error({
        message: "You're not Verified yet.",
      })
      yield call(SYMBOL_CATEGORY)
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LOAD_ME({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      init: true,
    },
  })
  yield call(GET_ME, { payload })
  yield put({
    type: 'user/SET_STATE',
    payload: {
      init: false,
    },
  })
  yield call(BRAND_SETTINGS)
  yield call(MODULE_SETTINGS)
  yield call(COMMUNITY_AUTH, {
    payload: {
      user_id: localStorage.getItem(STORAGE.USER_ID),
    },
  })
  yield call(SIDEBAR_MODULE_USER_SETTINGS, { payload })
  yield call(SYMBOL_CATEGORY)
  yield call(FAVOURITE_SYMBOL, { payload })
  yield call(CMS_INSTRUMENTS_CATEGORIES)
  yield call(GET_SELF_TRADING, { payload })
  yield call(GET_SELF_TRADING_DEMO, { payload })
  yield call(MODULE_MARKETS_DATA)
}

// individual sign up
export function* SIGNUP_IN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  let query = ''
  _.forIn(payload, (value, key) => {
    query += key + '=' + encodeURIComponent(value) + '&'
  })
  query = query.substring(0, query.length - 1)

  const response = yield call(ApiUser.individualSignUp, query)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      // success
      yield put(push('/login'))
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      // failure
      notification.error({
        message: response.data['msg'][0],
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// corporate sign up
export function* SIGNUP_CO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  let query = ''
  _.forIn(payload, (value, key) => {
    query += key + '=' + encodeURIComponent(value) + '&'
  })
  query = query.substring(0, query.length - 1)

  const response = yield call(ApiUser.corporateSignUp, query)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      // success
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      // failure
      notification.error({
        message: response.data['msg'][0],
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// demo sign up
export function* SIGNUP_DE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  let query = ''
  _.forIn(payload, (value, key) => {
    query += key + '=' + encodeURIComponent(value) + '&'
  })
  query = query.substring(0, query.length - 1)

  const response = yield call(ApiUser.demoSignUp, query)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      // success
      yield put(push('/login'))
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      // failure
      notification.error({
        message: response.data['msg'][0],
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// export function* LOAD_CURRENT_ACCOUNT() {
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const response = yield call(currentAccount)
//   if (response) {
//     const { uid: id, email, photoURL: avatar } = response
//     yield put({
//       type: 'user/SET_STATE',
//       payload: {
//         id,
//         name: 'Administrator',
//         email,
//         avatar,
//         role: 'admin',
//         authorized: true,
//       },
//     })
//   }
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: false,
//     },
//   })
// }

export function* LOGOUT() {
  // yield call(logout)
  localStorage.removeItem(STORAGE.USER_ID)
  localStorage.removeItem(STORAGE.SYMBOL_PRICE)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: null,
      authorized: false,
      loading: false,
    },
  })
  yield put(push('/login'))
}

export function* CHANGE_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.changePassword, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      // success
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      // failure
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGIN_HISTORY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.loginHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loginHistory: response.data['history'],
          loginHistoryInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* WALLET_OPERATIONS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.walletOperation, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      /**
       * format operations response to show on table.
       */
      let operations = response.data['operations']
      operations = operations
        ? operations.map((i, index) => ({
            ...i,
            no: index,
            date: i['statement_date'],
            // date: i['statement_date'].split(' ')[0],
          }))
        : null

      yield put({
        type: 'user/SET_STATE',
        payload: {
          wallet: operations,
          walletInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* WALLET_OPERATIONS_DETAILS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      walletDetailsLoading: true,
    },
  })

  const response = yield call(ApiUser.walletOperationsDetails, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      /**
       * format operations response to show on table.
       */
      let list = response.data['list']
      list = list
        ? list.map((i, index) => ({
            ...i,
            no: index,
            clickable: response.data['clickable'],
          }))
        : null

      yield put({
        type: 'user/SET_STATE',
        payload: {
          walletOperationsDetails: { ...response.data, list },
          walletOperationsDetailsInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          walletDetailsLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* WALLET_OPERATIONS_DETAILS_DATA({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      walletDetailsLoading: true,
    },
  })

  const response = yield call(ApiUser.walletOperationsDetailsData, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      /**
       * format operations response to show on table.
       */
      let list = response.data['list']
      list = list
        ? list.map((i, index) => ({
            ...i,
            no: index,
          }))
        : null

      yield put({
        type: 'user/SET_STATE',
        payload: {
          walletOperationsDetailsData: { ...response.data, list },
          walletOperationsDetailsDataInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          walletDetailsLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_RESIDENCE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      por_loading: true,
    },
  })

  const response = yield call(ApiUser.updateResidence, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      por_loading: false,
    },
  })
}

export function* UPDATE_USER_INFO({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      poi_loading: true,
    },
  })

  const response = yield call(ApiUser.updateUserInfo, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      notification.success({
        message: response.data['msg'],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      poi_loading: false,
    },
  })
}

export function* UPDATE_NEWSLETTER({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      newsletterSubscribeLoading: true,
    },
  })

  try {
    const response = yield call(ApiUser.updateProfile, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        const reqData = {
          user_id: user_id,
        }
        yield call(GET_ME, { payload: reqData })
        if (payload.theme === undefined) {
          notification.success({
            message: response.data['msg'],
          })
        }
      } else if (response.data['status'] === 0) {
        if (payload.theme === undefined) {
          notification.error({
            message: response.data['msg'],
          })
        }
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (error) {
    // console.log('API - UPDATE_NEWSLETTER', error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        newsletterSubscribeLoading: false,
      },
    })
  }
}

export function* UPDATE_PROFILE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateProfile, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      if (payload.theme === undefined) {
        notification.success({
          message: response.data['msg'],
        })
      }
    } else if (response.data['status'] === 0) {
      if (payload.theme === undefined) {
        notification.error({
          message: response.data['msg'],
        })
      }
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_LANGUAGE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateLanguage, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      notification.success({
        message: response.data['msg'],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPGRADE_CORPORATE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.upgradeCorporate, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      notification.success({
        message: response.data['msg'],
      })
      yield call(GET_ME, { payload: reqData })
      yield call(CORPORATE_INFO, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CORPORATE_INFO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.corporateInfo, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          corporateInfo: response.data['data'],
          loading: false,
        },
      })
    }
  } else if (response.data['status'] === 0) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        corporateInfo: {},
        loading: false,
      },
    })
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_BANK_DETAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.bankDetails, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          banks: response.data['banks'],
          lyhour: response.data['lyhour'],
          nganluong_email: response.data['nganluong_email'],
          foreverpay: response.data['foreverpay'],
          paytrust: response.data['paytrust'],
          fasapay: response.data['fasapay'],
          helppay: response.data['helppay'],
          eeziepay: response.data['eeziepay'],
          eeziepayINR: response.data['EeziePay(INR)'],
          xpay: response.data['xpay'],
          _9pay: response.data['9Pay'],
          _5pay: response.data['5Pay'],
          paybnb: response.data['PayBnB'],
          papayapay: response.data['PapayaPay'],
          hwgc: response.data['HWGC'],
          hwgcCard: response.data['HWGC Card'] !== null ? response.data['HWGC Card'] : false,
          hwgcEmail: response.data['HWGC Email'],
          hwgcMobile: response.data['HWGC Mobile'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_BANK_DETAIL({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateBankDetail, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* FOREVERPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.foreverpayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          foreverpay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* FOREVERPAY_BANK_STATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.foreverpayBankState, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          foreverpay_bank_state_list: response.data['bank_state'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* FOREVERPAY_BANK_CITY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.foreverpayBankCity, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          foreverpay_bank_city_list: response.data['bank_city'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_FOREVERPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateForeverpayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PAYTRUST_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.paytrustBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          paytrust_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_PAYTRUST_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updatePaytrustBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_FASAPAY({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateFasapay, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HELPPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.helppayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          helppay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_HELPPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateHelppayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* EEZIEPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.eeziepayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          eeziepay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_EEZIEPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateEeziepayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* EEZIEPAY_INR_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.eeziepayINRBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          eeziepay_inr_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_EEZIEPAY_INR_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateEeziepayINRBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* XPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.xPayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          xpay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_XPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateXPayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* ANPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.anpayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          anpay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_ANPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateAnpayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PAYBNB_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.paybnbBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          paybnb_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_PAYBNB_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updatePaybnbBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* FIVEPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.fivepayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          fivepay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_FIVEPAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updateFivepayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* DIRECTPAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.directPayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          directpay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PAPAYA_PAY_BANK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.papayaPayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          papayapay_bank: response.data['bank'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_PAPAYA_PAY_BANK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.updatePapayaPayBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_BANK_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* ADD_WITHDRAWAL({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.addWithdrawal, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 10000,
      }
      yield call(WITHDRAWAL_HISTORY, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  } else {
    console.log('ERROR')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_KYC({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.getKYC, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          kyc: response.data['files'],
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPLOAD_KYC({ payload }) {
  const { formData } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const user_id = formData.get('user_id')
  const response = yield call(ApiUser.uploadKYC, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0]['content'],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_KYC, { payload: reqData })
      yield call(HISTORY_KYC, { payload: reqData })
    } else {
      notification.error({
        message: response.data['msg'][0]['content'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* HISTORY_KYC({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.historyKYC, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          kycHistory: response.data['history'],
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SELF_TRADING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      selfTradingLoading: true,
    },
  })
  const response = yield call(ApiUser.selfTrading, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      let list = response.data['mt4'].map((item) => {
        return { ...item, type: 'live' }
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mt4: list,
          selfTradingLoading: false,
          mt4Called: true,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_SELF_TRADING_DEMO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      selfTradingLoading: true,
    },
  })
  const response = yield call(ApiUser.selfTradingDemo, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      let list = response.data['mt4'].map((item) => {
        return { ...item, type: 'demo' }
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mt4Demo: list,
          selfTradingLoading: false,
          mt4DemoCalled: true,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_SELF_TRADING_FULL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.selfTradingFull, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mt4Full: response.data['mt4'].map((item) => {
            return {
              ...item,
              spread_group: encodeURIComponent(item.spread_group),
            }
          }),
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_SELF_TRADING_DEMO_FULL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.selfTradingDemoFull, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mt4DemoFull: response.data['mt4'].map((item) => {
            return {
              ...item,
              spread_group: encodeURIComponent(item.spread_group),
            }
          }),
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_AFFILIATE_TREE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.getAffiliateTree, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          affiliateTree: response.data['tree'],
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* AFFILIATE_CLIENT_LISTING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.affiliateClientListing, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          affiliateClientListing: response.data['tree'],
          affiliateClientListingInfo: {
            total_client: response.data['total_client'],
            total_account: response.data['total_account'],
            total_volume: response.data['total_volume'],
            total_profit: response.data['total_profit'],
            accountTypeArry: response.data['accountTypeArry'],
            total_record: response.data['total_record'],
            usernameNameArry: response.data['usernameNameArry'],
            emailArry: response.data['emailArry'],
            accountNoArry: response.data['accountNoArry'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* AFFILIATE_COMMISSION_HISTORY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.affiliateCommissionHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          affiliateCommHistory: response.data['list'],
          affiliateCommHistoryInfo: {
            total_commission_earned: response.data['total_commission_earned'],
            total_commission_earned_paid: response.data['total_commission_earned_paid'],
            total_commission_earned_pending: response.data['total_commission_earned_pending'],
            volume: response.data['volume'],
            nett_deposit: response.data['nett_deposit'],
            commissionType: response.data['commissionType'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* AFFILIATE_CLIENT_TRADING_TXS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.affiliateClientTradingTxs, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          affiliateClientTradingTxs: response.data['list'],
          affiliateClientTradingTxsInfo: {
            total_account: response.data['total_account'],
            total_transaction: response.data['total_transaction'],
            total_volume: response.data['total_volume'],
            total_profit: response.data['total_profit'],
            total_commission_earned: response.data['total_commission_earned'],
            total_commission_paid: response.data['total_commission_paid'],
            total_commission_pending: response.data['total_commission_pending'],
            total_record: response.data['total_record'],
            usernameList: response.data['usernameList'],
            orderNoList: response.data['orderNoList'],
            accountList: response.data['accountList'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.affiliateClientDepWitTxs, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          affiliateClientDepWitTxs: response.data['list'],
          affiliateClientDepWitTxsInfo: {
            total_client: response.data['total_client'],
            total_transaction: response.data['total_transaction'],
            total_nett_deposit: response.data['total_nett_deposit'],
            total_pending_transaction: response.data['total_pending_transaction'],
            total_deposit: response.data['total_deposit'],
            total_withdrawal: response.data['total_withdrawal'],
            pspArry: response.data['pspArry'],
            total_record: response.data['total_record'],
            usernameArry: response.data['usernameArry'],
            transactionArry: response.data['transactionArry'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* NETWORK_MT4({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      networkMt4Loading: true,
    },
  })
  const response = yield call(ApiUser.networkMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          networkMt4: response.data['mt4'],
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      networkMt4Loading: false,
    },
  })
}

export function* DEPOSIT_MT4({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.depositMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_SELF_TRADING, { payload: reqData })
      yield call(GET_ME, { payload: reqData })
      yield call(FOLLOWING_LIST, { payload: { user_id, page: 1, rows: 100 } })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRADING_PRODUCT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.tradingProduct, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          tradingProducts: response.data['product'].map((item) => {
            return {
              ...item,
              spread_group: encodeURIComponent(item.spread_group),
            }
          }),
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_MT4_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.changeMt4Pass, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_MT4_INVESTOR_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.changeMt4InvestorPass, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SUBMIT_TICKET({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.submitTicket, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_TICKETS, { payload: reqData })
      const new_ticket = yield select(selectors.latestTicket)
      // console.log('~~~new_ticket', new_ticket)
      if (new_ticket) yield put(push(`/support/${new_ticket.id}`))
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_TICKETS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.getTickets, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      if (payload['ticket_id']) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            ticket: response.data['ticket'],
          },
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            tickets: response.data['ticket'],
          },
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* OPEN_MT4({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.openMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_SELF_TRADING, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* MT4_LIVE_INFO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.mt4LiveInfo, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liveMt4: response.data,
          loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liveMt4: null,
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MT4_MARGIN_INFO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      liveMt4Loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4MarginInfo, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            liveMt4Margin: response.data,
            liveMt4Loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            liveMt4Margin: null,
            liveMt4Loading: false,
          },
        })
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (error) {
    console.log('ERR MT4_MARGIN_INFO => ', error)
  }
}

export function* MT4_BALANCE_CREDIT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      liveMt4Loading: true,
    },
  })
  const response = yield call(ApiUser.mt4BalanceCredit, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liveMt4BalanceCredit: response.data,
          liveMt4Loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liveMt4BalanceCredit: null,
          liveMt4Loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* WITHDRAWAL_MT4({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.withdrawalMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_SELF_TRADING, { payload: reqData })
      yield call(FOLLOWING_LIST, { payload: { user_id, page: 1, rows: 100 } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* TICKET_DETAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.ticketDetail, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ticketDetail: response.data['detail'],
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SUBMIT_TICKET_DETAIL({ payload }) {
  const { formData } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const user_id = formData.get('user_id')
  const ticket_id = formData.get('ticket_id')
  const response = yield call(ApiUser.submitTicketDetail, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        ticket_id: ticket_id,
      }
      yield call(TICKET_DETAIL, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.resetPassword, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPLOAD_PROFILE_PIC({ payload }) {
  const { formData } = payload
  const user = yield select(selectors.user)
  if (user['profile_pic']) {
    user['profile_pic'] = `${user['profile_pic']}&uploading=true`
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      user,
    },
  })
  const response = yield call(ApiUser.uploadProfilePic, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: formData.get('user_id'),
      }
      yield call(GET_ME, { payload: reqData, updateAvatar: true })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DEPOSIT_COMPANY_CRYPTO_WALLET({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.companyCryptoWalletDeposit, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_DEPOSIT_HISTORY, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  } else if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_DEPOSIT_HISTORY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.depositHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          depositHistory: response.data['history'],
          depositHistoryInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.forgotPassword, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* BANK_DEPOSIT({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.bankDeposit, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 10000,
      }
      yield call(GET_DEPOSIT_HISTORY, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  } else {
    console.log('ERROR')
  }
}

export function* NGAN_DEPOSIT({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.nganDeposit, payload)
  if (response && response.data && !response.data.logout) {
    const reqData = {
      user_id: user_id,
    }
    yield call(GET_DEPOSIT_HISTORY, { payload: reqData })
    // if (response.data['status'] === 1) {
    //   notification.success({
    //     message: response.data['msg'][0],
    //   })
    //   const reqData = {
    //     user_id: user_id,
    //     page: 1,
    //     rows: 10,
    //   }
    //   yield call(GET_DEPOSIT_HISTORY, { payload: reqData })
    // } else if (response.data['status'] === 0) {
    //   notification.error({
    //     message: response.data['msg'][0],
    //   })
    //   yield put({
    //     type: 'user/SET_STATE',
    //     payload: {
    //       loading: false,
    //     },
    //   })
    // }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* WITHDRAWAL_HISTORY({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.withdrawalHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          withdrawalHistory: response.data['history'],
          withdrawalHistoryInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* BALANCE_GRAPH({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      balanceGraphloading: true,
    },
  })
  const response = yield call(ApiUser.balanceGraph, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          balance: response.data['balance'],
          balanceGraphloading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          balanceGraphloading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MARKET_NEWS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.upcomingEvents, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketNews: response.data['data'],
            loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* NEWS_REDIRECT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.newsRedirect, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const newsRedirectLink = response.data['link']
      yield put({
        type: 'user/SET_STATE',
        payload: {
          newsRedirectLink,
          loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* CANCEL_WITHDRAWAL({ payload }) {
  const response = yield call(ApiUser.cancelWithdrawal, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'],
      })
      yield call(WITHDRAWAL_HISTORY, { payload })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CURRENCY_RATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.currencyRate, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          currencyRate: response.data['rate'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PSP_CURRENCY_RATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.pspCurrencyRate, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pspCurrencyRate: response.data['rate'],
          pspCurrencyFee: response.data['fee'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PSP_RATE_FEE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.pspRateFee, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pspRateFee_rate: response.data['rate'],
          pspRateFee_fee: response.data['fee'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* CRYPTO_RATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.cryptoRate, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          cryptoRate: response.data['rate'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

// go live
export function* GO_LIVE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.goLive, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      yield put(push('/dashboard/Verification'))
      notification.success({
        message: response.data['msg'],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// Confirm API
export function* CONFIRM_API({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.confirmAPI, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      notification.success({
        message: response.data['msg'],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// Cancel API
export function* CANCEL_API({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.cancelAPI, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      const reqData = {
        user_id: user_id,
      }
      yield call(GET_ME, { payload: reqData })
      notification.success({
        message: response.data['msg'],
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// Copy trading
export function* MASTER_TRADERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      masterTraderLoading: true,
    },
  })
  const response = yield call(ApiUser.masterTraders, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterTraders: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          masterTraderLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MASTER_TRADER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      masterTraderLoading: true,
    },
  })
  const response = yield call(ApiUser.masterTrader, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterTrader: response.data.master,
          masterTraderRankScore: response.data.rankScore,
          masterTraderTotalRankScore: response.data.total_rank_score,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterTraderLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_TRADER({ payload }) {
  const { user_id, mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateTrader, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        master_mt4: mt4,
      }
      yield call(MASTER_TRADER, { payload: reqData })
      yield call(GET_SELF_TRADING, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_TRADER_SELF_TRADING({ payload }) {
  const { user_id, mt4, is_private } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateTrader, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        master_mt4: mt4,
      }
      // if (is_private == '0') {
      //   yield put(push(`/copyTrading/mentorAccount?mt4=${mt4}&update=true`))
      // } else {
      // yield call(MASTER_TRADER, { payload: reqData })
      yield call(GET_SELF_TRADING, { payload: { user_id } })
      // }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_TRADER_PHOTO({ payload }) {
  const { formData, mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.updateTraderPhoto, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: formData.get('user_id'),
        master_mt4: mt4,
      }
      yield call(MASTER_TRADER, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* MASTER_FOLLOWERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.masterFollowers, payload)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            masterFollowers: response.data.followers,
            loading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MASTER_OPEN_TRADES({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctTradesloading: true,
    },
  })
  const response = yield call(ApiUser.masterTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterOpenTrades: response.data['list'],
          masterOpenTradesInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ctTradesloading: false,
      },
    })
  }
}

export function* MASTER_CLOSE_TRADES({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctTradesloading: true,
    },
  })
  const response = yield call(ApiUser.masterTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterCloseTrades: response.data['list'],
          masterCloseTradesInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ctTradesloading: false,
      },
    })
  }
}

export function* FOLLOWING_LIST({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.followingList, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          followingList: response.data['list'],
        },
      })
      yield call(GET_ME, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* FOLLOWER_TRADES_OPEN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctTradesloading: true,
    },
  })
  const response = yield call(ApiUser.followerTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          followerTradesOpen: response.data['list'],
          followerTradesOpenInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ctTradesloading: false,
      },
    })
  }
}

export function* FOLLOWER_TRADES_CLOSE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctTradesloading: true,
    },
  })
  const response = yield call(ApiUser.followerTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          followerTradesClose: response.data['list'],
          followerTradesCloseInfo: {
            total_record: response.data['total_record'],
          },
        },
      })
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ctTradesloading: false,
      },
    })
  }
}

export function* FOLLOWER_CLOSE_TRADE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.followerCloseTrade, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(FOLLOWER_TRADES_OPEN, { payload: { user_id, type: 'open' } })
      yield call(FOLLOWER_TRADES_CLOSE, { payload: { user_id, type: 'close' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* WATCHLIST({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.watchlist, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          watchlist: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_WATCHLIST({ payload }) {
  const { user_id, master_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctWatchActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateWatchlist, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })

      yield call(MASTER_TRADER, { payload: { user_id, master_mt4 } })
      yield call(WATCHLIST, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctWatchActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctWatchActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PAUSE_COPY({ payload }) {
  const { user_id, copying_id, master_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.pauseCopy, { user_id, copying_id })
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 100,
      }
      yield call(FOLLOWING_LIST, { payload: reqData })
      yield call(MASTER_TRADER, { payload: { user_id, master_mt4 } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* RESUME_COPY({ payload }) {
  const { user_id, copying_id, master_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.resumeCopy, { user_id, copying_id })
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 100,
      }
      yield call(FOLLOWING_LIST, { payload: reqData })
      yield call(MASTER_TRADER, { payload: { user_id, master_mt4 } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* STOP_COPY({ payload }) {
  const { user_id, copying_id, master_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.stopCopy, { user_id, copying_id })
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 100,
      }
      yield call(FOLLOWING_LIST, { payload: reqData })
      yield call(MASTER_TRADER, { payload: { user_id, master_mt4 } })
      yield call(FOLLOWER_TRADES_OPEN, { payload: { user_id, type: 'open' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* START_COPY({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')
  const master_mt4 = formData.get('master_mt4')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctCopyActionLoading: true,
    },
  })
  const response = yield call(ApiUser.startCopy, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 100,
      }
      yield call(FOLLOWING_LIST, { payload: reqData })
      yield call(MASTER_TRADER, { payload: { user_id, master_mt4 } })
      yield call(FOLLOWER_TRADES_OPEN, { payload: { user_id, type: 'open' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctCopyActionLoading: false,
        },
      })
      // yield put(push('/copyTrading/myportfolio'))
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctCopyActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MASTER_SYMBOLS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.masterSymbols, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          masterSymbols: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_COPY_SETTINGS({ payload }) {
  // const { user_id } = payload
  const { formData } = payload
  const user_id = formData.get('user_id')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ctActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateCopySettings, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        page: 1,
        rows: 10,
      }
      yield call(FOLLOWING_LIST, { payload: reqData })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ctActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* GET_COPY_SETTINGS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.getCopySettings, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          copyTradingSettings: response.data['settings'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MT4_STATS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4Stats, payload)
    // console.log('mt4Stats response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4Stats: response.data,
            loading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_PROFITS_CHART({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4ProfitsChart, payload)
    if (response && response.data) {
      // console.log('mt4ProfitsChart response ~~~')
      // console.log(response)
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4ProfitsChart: response.data.profitData,
            chartLoading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_EQUITY_BALANCE_CHART_7D({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4EquityBalanceChart, payload)
    // console.log('mt4EquityBalanceChart 7D response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4EquityBalanceChart_7D: response.data.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_EQUITY_BALANCE_CHART_1M({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4EquityBalanceChart, payload)
    // console.log('mt4EquityBalanceChart 1M response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4EquityBalanceChart_1M: response.data.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_EQUITY_BALANCE_CHART_6M({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4EquityBalanceChart, payload)
    // console.log('mt4EquityBalanceChart response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4EquityBalanceChart_6M: response.data.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_EQUITY_BALANCE_CHART_1Y({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4EquityBalanceChart, payload)
    // console.log('mt4EquityBalanceChart 1Y response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4EquityBalanceChart_1Y: response.data.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_MONTHLY_PL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4MonthlyPL, payload)
    // console.log('mt4MonthlyPL response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4MonthlyPL: response.data.profit,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_SKILLED_SYMBOLS_CHART({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4SkilledSymbolsChart, payload)
    // console.log('mt4SkilledSymbolsChart response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4SkilledSymbolsChart: response.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_MOST_TRADED_CHART({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4MostTradedChart, payload)
    // console.log('mt4MostTradedChart response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4MostTradedChart: response.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MT4_HOLDING_TIME_CHART({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      chartLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.mt4HoldingTimeChart, payload)
    // console.log('mt4HoldingTimeChart response ~~~')
    // console.log(response)
    if (response && response.data) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            mt4HoldingTimeChart: response.data,
            chartLoading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            chartLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* PAYMENT_METHOD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.paymentMethod, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          paymentMethod: response.data['list'],
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* PENDING_WITHDRAWALS_BY_MT4({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      pendingWithdrawalsloading: true,
    },
  })

  const response = yield call(ApiUser.pendingWithdrawalsByMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pendingWithdrawalsAmount:
            response.data['withdrawalAmount'] === null ? '0.00' : response.data['withdrawalAmount'],
          trading_voucher_amount:
            response.data['trading_voucher_amount'] === null
              ? ''
              : response.data['trading_voucher_amount'],
          withdrawable_balance: response.data['withdrawable_balance'],
          pendingWithdrawalsloading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pendingWithdrawalsloading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

// MAM/PAMM
export function* MONEY_MANAGER_MT4({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      selfTradingLoading: true,
    },
  })
  const response = yield call(ApiUser.moneyManagerMt4, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerMt4: response.data['mt4'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          selfTradingLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MONEY_MANAGERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      moneyManagerLoading: true,
    },
  })
  const response = yield call(ApiUser.moneyManagers, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagers: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          moneyManagerLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MONEY_MANAGER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      moneyManagerLoading: true,
    },
  })
  const response = yield call(ApiUser.moneyManager, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      let result = response.data.money_manager
      const baseURL = ApiConfig.baseURL
      result.profile_pic = result.profile_pic
        ? `${baseURL}${result.profile_pic}?fake=${Math.random()}`
        : ''

      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManager: result,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_MONEY_MANAGER({ payload }) {
  const { user_id, mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateMoneyManager, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0].content,
      })
      const reqData = {
        user_id: user_id,
        mt4: mt4,
      }
      yield call(MONEY_MANAGER, { payload: reqData })
      // yield call(MONEY_MANAGER_MT4, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0].content,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_MONEY_MANAGER_SETTING({ payload }) {
  const { user_id, mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.updateMoneyManagerSetting, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0].content,
      })
      const reqData = {
        user_id: user_id,
        mt4: mt4,
      }
      yield call(MONEY_MANAGER, { payload: reqData })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* UPDATE_MONEY_MANAGER_PHOTO({ payload }) {
  const { formData, mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.updateMoneyManagerPhoto, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0].content,
      })
      const reqData = {
        user_id: formData.get('user_id'),
        mt4: mt4,
      }
      yield call(MONEY_MANAGER, { payload: reqData })
    } else if (response.data['status'] === 0) {
      notification.error({
        message: response.data['msg'][0],
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* INVEST_MONEY_MANAGER({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')
  const mt4 = formData.get('money_manager_mt4')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.investMoneyManager, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      const reqData = {
        user_id: user_id,
        mt4: mt4,
      }
      yield call(MONEY_MANAGER, { payload: reqData })
      yield call(MONEY_MANAGERS, { payload: { user_id } })
      yield call(MONEY_MANAGER_MT4, { payload: { user_id } })
      yield call(INVESTING, { payload: { user_id } })
      yield call(GET_ME, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* WITHDRAW_MONEY_MANAGER({ payload }) {
  // const { formData } = payload
  // const user_id = formData.get('user_id')
  // const mt4 = formData.get('money_manager_mt4')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.withdrawMoneyManager, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      // const reqData = {
      //   user_id: user_id,
      //   mt4: mt4,
      // }
      // yield call(MONEY_MANAGER, { payload: reqData })
      // yield call(MONEY_MANAGERS, { payload: { user_id } })
      // yield call(MONEY_MANAGER_MT4, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.investing, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerInvestingList: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_DEPOSITS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.investorDeposits, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerInvestorDeposits: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_DEPOSIT_ACTION({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.investorDepositAction, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(INVESTOR_DEPOSITS, { payload: { user_id } })
      yield call(INVESTING, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_WITHDRAWALS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.investorWithdrawals, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerInvestorWithdrawals: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_WITHDRAWAL_ACTION({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  const response = yield call(ApiUser.investorWithdrawalAction, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(INVESTOR_WITHDRAWALS, { payload: { user_id } })
      yield call(INVESTING, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_SL({ payload }) {
  const { user_id, account, sl, page, money_manager_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  let params = { user_id, account, sl }
  const response = yield call(ApiUser.investorSL, params)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(INVESTING, { payload: { user_id } })
      if (page === 'managerAccount') {
        let reqData = { user_id, mt4: money_manager_mt4 }
        yield call(MONEY_MANAGER, { payload: reqData })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* INVESTOR_DETACH({ payload }) {
  const { user_id, account, page, money_manager_mt4 } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammActionLoading: true,
    },
  })
  let params = { user_id, account }
  const response = yield call(ApiUser.investorDetach, params)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(INVESTING, { payload: { user_id } })
      if (page === 'managerAccount') {
        let reqData = { user_id, mt4: money_manager_mt4 }
        yield call(MONEY_MANAGER, { payload: reqData })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MONEY_MANAGER_INVESTORS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.moneyManagerInvestors, payload)
    if (response && response.data && !response.data.logout) {
      if (response['status'] === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            moneyManagerInvestors: response.data['list'],
            loading: false,
          },
        })
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* MONEY_MANAGER_TRADES_OPEN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammTradesloading: true,
    },
  })
  const response = yield call(ApiUser.moneyManagerTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerTradesOpen: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammTradesloading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MONEY_MANAGER_TRADES_CLOSE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      mampammTradesloading: true,
    },
  })
  const response = yield call(ApiUser.moneyManagerTrades, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moneyManagerTradesClose: response.data['list'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          mampammTradesloading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

// Liquidity Pool
export function* LIQUIDITY_POOL_TNC() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.liqPoolTNC)
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolTNC: response.data,
          loading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* LIQUIDITY_POOL_WALLET_DATA({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolWalletData, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolWalletData: response.data['wallet'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_WALLET_HISTORY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolWalletHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      let data = { total: response.data['total'], list: response.data['list'] }

      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolWalletHistory: data,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_COMPANY_WALLET_ADDRESS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolCompanyWalletAddress, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolCompanyWalletAddress: response.data['address'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_DEPOSIT({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolDeposit, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item.content,
        }),
      )
      yield call(GET_ME, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_HISTORY, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0].content,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_WITHDRAW({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolWithdraw, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item.content,
        }),
      )
      yield call(GET_ME, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_HISTORY, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0].content,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_STAKE({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolStake, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'][0],
      })
      yield call(LIQUIDITY_POOL_WALLET_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_HISTORY, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_STAKE_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_STAKE_HISTORY, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_STAKE_DATA({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolStakeData, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolStakeData: response.data['stake'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_STAKE_HISTORY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolStakeHistory, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      let data = { total: response.data['total'], list: response.data['list'] }

      yield put({
        type: 'user/SET_STATE',
        payload: {
          liqPoolStakeHistory: data,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* LIQUIDITY_POOL_STAKE_WITHDRAW({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.liqPoolStakeWithdraw, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item.content,
        }),
      )
      yield call(LIQUIDITY_POOL_STAKE_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_STAKE_HISTORY, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_DATA, { payload: { user_id } })
      yield call(LIQUIDITY_POOL_WALLET_HISTORY, { payload: { user_id } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0].content,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

// Task & Reward Centre
export function* TASK_CENTRE_TASK_LISTING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      taskCentreListingLoading: true,
    },
  })
  const response = yield call(ApiUser.taskCentreTaskListing, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreTaskListing: response.data['list'],
          taskCentreListingLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* TASK_CENTRE_CLAIM_TASK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      taskCentreActionLoading: true,
    },
  })
  const response = yield call(ApiUser.taskCentreClaimTask, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'],
      })
      yield call(TASK_CENTRE_TASK_LISTING, { payload: { user_id, platform: 'web' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreActionLoading: false,
        },
      })
      if (response.data['claim_code']) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            taskCentreSucceefulModal: true,
            taskCentreCompletedTaskReward: response.data['reward'],
          },
        })
      }
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* TASK_CENTRE_COMPLETE_TASK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      taskCentreActionLoading: true,
    },
  })
  const response = yield call(ApiUser.taskCentreCompleteTask, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      // notification.success({
      //   message: response.data['msg'],
      // })
      yield call(TASK_CENTRE_TASK_LISTING, { payload: { user_id, platform: 'web' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreActionLoading: false,
          taskCentreSucceefulModal: true,
          taskCentreCompletedTaskReward: response.data['reward'],
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* TASK_CENTRE_FORFEIT_TASK({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      taskCentreActionLoading: true,
    },
  })
  const response = yield call(ApiUser.taskCentreForfeitTask, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'],
      })
      yield call(TASK_CENTRE_TASK_LISTING, { payload: { user_id, platform: 'web' } })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          taskCentreActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}


export function* TASK_CENTRE_CLOSE_SUCCESSFUL_MODAL() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      taskCentreCompletedTaskReward: [],
      taskCentreSucceefulModal: false,
    },
  })
}

export function* REWARD_CENTRE_REWARD_LISTING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreListingLoading: true,
    },
  })
  const response = yield call(ApiUser.rewardCentreRewardListing, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreRewardListing: response.data['list'],
          rewardCentreLoyaltyProgramListing: response.data['loyalty_program_list'],
          rewardCentreLuckyDrawListing: response.data['lucky_draw_list'],
          rewardCentreListingLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* REWARD_CENTRE_REDEEM_VOUCHER({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreActionLoading: true,
    },
  })

  const response = yield call(ApiUser.rewardCentreRedeemVoucher, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      // notification.success({
      //   message: response.data['msg'],
      // })
      yield call(REWARD_CENTRE_REWARD_LISTING, { payload: { user_id, platform: 'web' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreActionLoading: false,
          rewardCentreSucceefulModal: true,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'][0],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* REWARD_CENTRE_REDEEM_VOUCHER_CODE({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreActionLoading: true,
    },
  })

  const response = yield call(ApiUser.rewardCentreRedeemVoucherCode, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      notification.success({
        message: response.data['msg'],
      })
      yield call(REWARD_CENTRE_REWARD_LISTING, { payload: { user_id, platform: 'web' } })
    } else {
      notification.error({
        message: response.data['msg'],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreActionLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* REWARD_CENTRE_CHOOSE_PRIZE({ payload }) {
  const { user_id, reward_type } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreLoyaltyLoading: true,
    },
  })

  const response = yield call(ApiUser.rewardCentreChoosePrize, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      // notification.success({
      //   message: response.data['msg'],
      // })
      yield call(REWARD_CENTRE_REWARD_LISTING, { payload: { user_id, platform: 'web' } })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreLoyaltyLoading: false,
          rewardCentreLuckyDrawSuccessfulModal: reward_type === 'Lucky Draw Ticket' ? true : false,
          rewardCentreCashSuccessfulModal: reward_type === 'Lucky Draw Ticket' ? false : true,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rewardCentreLoyaltyLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'],
    })
    yield call(LOGOUT)
  }
}

export function* REWARD_CENTRE_CLOSE_LUCKY_DRAW_SUCCESS_MODAL() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreLuckyDrawSuccessfulModal: false,
    },
  })
}

export function* REWARD_CENTRE_CLOSE_CASH_SUCCESS_MODAL() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreCashSuccessfulModal: false,
    },
  })
}

export function* REWARD_CENTRE_CLOSE_SUCCESSFUL_MODAL() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rewardCentreSucceefulModal: false,
    },
  })
}

// HWGC
export function* HWGC_STATUS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.hwgcStatus, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          hwgcStatus: response.data['hwgc'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_SIGN_UP({ payload }) {
  const { formData } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const user_id = formData.get('user_id')
  const response = yield call(ApiUser.hwgcSignUp, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item,
        }),
      )
      yield call(HWGC_STATUS, { payload: { user_id } })
    } else {
      response.data['msg'].map((item) =>
        notification.error({
          message: item,
        }),
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_RATE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.hwgcRate, payload)
  if (response && response.data && !response.data.logout) {
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          hwgcRate: response.data,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_BANK_WIRE({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.hwgcBankWire, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item,
        }),
      )
      yield call(HWGC_STATUS, { payload: { user_id } })
      yield call(GET_DEPOSIT_HISTORY, { payload: { user_id } })
    } else {
      response.data['msg'].map((item) =>
        notification.error({
          message: item,
        }),
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_BANK_ACCOUNT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.hwgcBankAccount, payload)
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          hwgc_bank: response.data,
          loading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* HWGC_UPDATE_BANK({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.hwgcUpdateBank, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item,
        }),
      )
      yield call(HWGC_STATUS, { payload: { user_id } })
      yield call(GET_BANK_DETAIL, { payload: { user_id } })
    } else {
      response.data['msg'].map((item) =>
        notification.error({
          message: item,
        }),
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_CARD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.hwgcCard, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          hwgcApplyCardStatus: response.data['card'],
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_APPLY_CARD({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.hwgcApplyCard, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item,
        }),
      )
      yield call(HWGC_CARD, { payload: { user_id } })
    } else {
      response.data['msg'].map((item) =>
        notification.error({
          message: item,
        }),
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_BIND_CARD({ payload }) {
  const { formData } = payload
  const user_id = formData.get('user_id')

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.hwgcBindCard, formData)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      response.data['msg'].map((item) =>
        notification.success({
          message: item,
        }),
      )
      yield call(GET_BANK_DETAIL, { payload: { user_id } })
    } else {
      response.data['msg'].map((item) =>
        notification.error({
          message: item,
        }),
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* HWGC_UPDATE_EMAIL({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(ApiUser.hwgcUpdateEmail, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        response.data['msg'].map((item) =>
          notification.success({
            message: item,
          }),
        )
        yield call(GET_BANK_DETAIL, { payload: { user_id } })
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (err) {
    console.log('err HWGC_UPDATE_EMAIL', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* HWGC_UPDATE_MOBILE({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(ApiUser.hwgcUpdateMobile, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        response.data['msg'].map((item) =>
          notification.success({
            message: item,
          }),
        )
        yield call(GET_BANK_DETAIL, { payload: { user_id } })
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (err) {
    console.log('err HWGC_UPDATE_EMAIL', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// Instruments
export function* SPREAD_GROUP_SYMBOL_PRICE({ payload }) {
  try {
    const { spreadGroup } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        spreadGroupSymbolPriceLoading: true,
      },
    })
    const response = yield call(ApiUser.spreadGroupSymbolPrice, spreadGroup)
    if (response && response.data) {
      if (response.status === 200) {
        let result = response.data
        if (result && result.length) {
          let priceDataList = result.map((res) =>
            extractPriceData({ priceData: res, platform: 'MT5' }),
          )
          priceDataList.sort((a, b) => a.symbol.localeCompare(b.symbol))
          localStorage.setItem(STORAGE.SYMBOL_PRICE, JSON.stringify(priceDataList))
          yield put({
            type: 'user/SET_STATE',
            payload: {
              spreadGroupSymbolPrice: priceDataList,
              spreadGroupSymbolPriceLoading: false,
            },
          })
        }
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            spreadGroupSymbolPriceLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* SPREAD_GROUP_SYMBOL_PRICE_CTRADER({ payload }) {
  try {
    const { spreadGroup, serverName, symbol } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        spreadGroupSymbolPriceLoading: true,
      },
    })
    const data = {
      spreadGroup,
      serverName,
    }
    if (symbol) {
      data.symbol = symbol
    }
    const response = yield call(ApiUser.spreadGroupSymbolPricecTrader, data)
    if (response && response.data) {
      if (response.status === 200) {
        let result = response.data.payload
        if (result && result.length) {
          let priceDataList = result.map((res) =>
            extractPriceData({ priceData: res, platform: 'cTrader' }),
          )
          priceDataList.sort((a, b) => a.symbol.localeCompare(b.symbol))
          localStorage.setItem(STORAGE.SYMBOL_PRICE, JSON.stringify(priceDataList))
          yield put({
            type: 'user/SET_STATE',
            payload: {
              spreadGroupSymbolPrice: priceDataList,
              spreadGroupSymbolPriceLoading: false,
            },
          })
        }
      } else if (response['status'] !== 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            spreadGroupSymbolPriceLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* SYMBOL_VIEWS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      symbol_views_loading: true,
    },
  })
  const response = yield call(ApiUser.symbolViews, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          symbol_views: response.data.data,
          symbol_views_loading: false,
        },
      })
    } else {
      // notification.error({
      //   message: response.data['msg'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          symbol_views_loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* SYMBOL_UPDATE_VIEWS({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.symbolUpdateViews, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield call(SYMBOL_VIEWS, { payload: { user_id } })
    } else {
      notification.error({
        message: response.data['msg'],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* SYMBOL_DETAILS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      symbol_details_loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.symbolDetails, payload)
    if (response && response.data && !response.data.logout) {
      if (response.status === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            symbol_details: response.data,
            symbol_details_loading: false,
          },
        })
      }
    } else if (response.data.logout) {
      notification.error({
        key: 'logout',
        message: response.data['msg'][0],
      })
      yield call(LOGOUT)
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        symbol_details_loading: false,
      },
    })
  }
}

export function* OHLC({ payload }) {
  const { base_symbol } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.ohlc, payload)
  if (response && response.data && !response.data.logout) {
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          [`ohlc_markets_${base_symbol}`]: response.data,
        },
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* MODULE_MARKETS_DATA() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      moduleMarketsDataLoading: true,
    },
  })

  try {
    const response = yield call(ApiUser.moduleMarketsData)
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          moduleMarketsData: response.data,
        },
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        moduleMarketsDataLoading: false,
      },
    })
  }
}

export function* TRADE_SYMBOL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      tradeSymbolLoading: true,
    },
  })

  try {
    const response = yield call(ApiUser.tradeSymbol, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data.status) {
        let list = []
        response.data.data.map((cat) => {
          if (cat.base) {
            cat.base.map((base, index) => {
              list.push({
                base: base,
                symbol: cat.symbol[index],
                tc_symbol: cat.tc_symbol[index],
                description: cat.description[index],
              })
            })
          }
        })
        const listUniqueByKey = [...new Map(list.map((item) => [item['symbol'], item])).values()]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            tradeSymbol: response.data.data,
            tradeSymbol_amended: listUniqueByKey,
          },
        })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        tradeSymbolLoading: false,
      },
    })
  }
}

export function* FAVOURITE_SYMBOL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(ApiUser.favouriteSymbol, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            favouriteSymbol: response.data['favourite_symbol'],
          },
        })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD_FAVOURITE_SYMBOL({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(ApiUser.addFavouriteSymbol, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data.status === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        const reqData = {
          user_id: user_id,
        }
        yield call(FAVOURITE_SYMBOL, { payload: reqData })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        notification.error({
          message: response.data['msg'][0],
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REMOVE_FAVOURITE_SYMBOL({ payload }) {
  const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(ApiUser.removeFavouriteSymbol, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        const reqData = {
          user_id: user_id,
        }
        yield call(FAVOURITE_SYMBOL, { payload: reqData })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        notification.error({
          message: response.data['msg'][0],
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* MARKETS_OPEN_TRADES({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.marketsOpenTrades, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOpenTrades: response.data['open_trades'],
            marketsOpenTradeTempData: {},
          },
        })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* MARKETS_TRADES({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.marketsTrades, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        let symbolList =
          Object.values(response.data['symbol']).length > 0
            ? Object.values(response.data['symbol'])
            : []
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsTrades: response.data['trades'],
            marketsTradesTotal: response.data['total'],
            marketsTradesSymbolList: symbolList,
            marketsCloseTradeTempData: {},
          },
        })
      } else if (response.data.logout) {
        notification.error({
          key: 'logout',
          message: response.data['msg'][0],
        })
        yield call(LOGOUT)
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_OPEN({ payload }) {
  const { user_id, account, symbol, type, volume, comment } = payload
  const reqData = {
    user_id: user_id,
  }
  let orderTypeList = {
    BUY: 'Buy',
    SELL: 'Sell',
    BUY_LIMIT: 'BuyLimit',
    SELL_LIMIT: 'SellLimit',
    BUY_STOP: 'BuyStop',
    SELL_STOP: 'SellStop',
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
      marketsOpenTradeTempData: {
        Order: '',
        OpenTime: '',
        Type: orderTypeList[type.replace(' ', '_')],
        Symbol: symbol,
        Volume: volume,
        OpenPrice: '',
        Sl: payload.sl ? payload.sl : '0.00',
        Tp: payload.tp ? payload.tp : '0.00',
        Commission: '',
        Swap: '',
        CurrentPrice: '',
        Profit: '',
        Comment: comment,
        Expiration: '',
      },
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeOpen, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        // notification.success({
        //   message: response.data.msg,
        // })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
            marketsTradeActionLoading: false,
          },
        })
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data.msg,
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOpenTradeTempData: {},
          },
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_CLOSE({ payload }) {
  const { user_id, account, order, volume } = payload
  const reqData = {
    user_id: user_id,
  }
  let marketsOpenTrades = yield select(selectors.marketsOpenTrades)
  let tradeData = marketsOpenTrades.find((v) => v.Order === order)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
      // marketsCloseTradeTempData: {
      //   Order: order,
      //   OpenTime: tradeData.OpenTime,
      //   Type: tradeData.Type,
      //   Symbol: tradeData.Symbol,
      //   Volume: volume,
      //   OpenPrice: tradeData.OpenPrice,
      //   Sl: tradeData.Sl,
      //   Tp: tradeData.Tp,
      //   CloseTime: '',
      //   ClosePrice: '',
      //   Commission: '',
      //   Swap: '',
      //   Profit: '',
      //   Comment: '',
      // },
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeClose, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        // notification.success({
        //   message: response.data['msg'][0],
        // })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
          },
        })
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsCloseTradeTempData: {},
          },
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_CLOSE_BY({ payload }) {
  const { user_id, account } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeCloseBy, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
          },
        })
        const reqData = {
          user_id: user_id,
        }
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data['msg'][0],
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_CLOSE_SYMBOL({ payload }) {
  const { user_id, account } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeCloseSymbol, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
          },
        })
        const reqData = {
          user_id: user_id,
        }
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data['msg'][0],
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_MODIFY({ payload }) {
  const { user_id, account } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeModify, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
          },
        })
        const reqData = {
          user_id: user_id,
        }
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data['msg'][0],
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_TRADE_DELETE_PENDING({ payload }) {
  const { user_id, account, order } = payload
  const reqData = {
    user_id: user_id,
  }
  let marketsOpenTrades = yield select(selectors.marketsOpenTrades)
  let tradeData = marketsOpenTrades.find((v) => v.Order === order)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      marketsTradeActionLoading: true,
      marketsOrderModalClose: false,
      // marketsCloseTradeTempData: {
      //   Order: order,
      //   OpenTime: tradeData.OpenTime,
      //   Type: tradeData.Type,
      //   Symbol: tradeData.Symbol,
      //   Volume: tradeData.Volume,
      //   OpenPrice: tradeData.OpenPrice,
      //   Sl: tradeData.Sl,
      //   Tp: tradeData.Tp,
      //   CloseTime: '',
      //   ClosePrice: '',
      //   Commission: '',
      //   Swap: '',
      //   Profit: '',
      //   Comment: '',
      // },
    },
  })

  try {
    const response = yield call(ApiUser.marketsTradeDeletePending, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        notification.success({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsOrderModalClose: true,
          },
        })
        yield call(GET_SELF_TRADING, { payload: reqData })
        yield call(GET_SELF_TRADING_DEMO, { payload: reqData })
        yield call(MARKETS_OPEN_TRADES, { payload: { ...reqData, account } })
      } else if (response.data['status'] === 0) {
        notification.error({
          message: response.data['msg'][0],
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsCloseTradeTempData: {},
          },
        })
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        marketsTradeActionLoading: false,
      },
    })
  }
}

export function* MARKETS_JOURNAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.marketsJournal, payload)
    if (response && response.data && !response.data.logout) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            marketsJournal: response.data['journal'],
            marketsJournalTotal: response.data['total'],
          },
        })
      } else {
        response.data['msg'].map((item) =>
          notification.error({
            message: item,
          }),
        )
      }
    }
  } catch (err) {
    console.log('err', err)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// Trading Central Modules
export function* SYMBOL_CATEGORY() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.symbolCategory)
  if (response && response.data) {
    if (response.status === 200) {
      let list = []
      if (response.data.data.length > 0) {
        response.data.data.map((cat) => {
          if (cat.base) {
            cat.base.map((base, index) => {
              list.push({
                base: base,
                symbol: cat.symbol[index],
                tc_symbol: cat.tc_symbol[index],
                description: cat.description[index],
              })
            })
          }
        })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          symbol_category: response.data.data,
          symbol_category_amended: list,
          loading: false,
        },
      })
      let categoryList = response.data.data.map((category) => category.category)

      yield call(CMS_INSTRUMENTS, { payload: { category_title: 'all' } })
      yield all(
        categoryList.map((cat_name) =>
          call(CMS_INSTRUMENTS, { payload: { category_title: cat_name } }),
        ),
      )
    } else if (response['status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* TRADE_IDEAS({ payload }) {
  const { type_product } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      [`tradeIdeas_${type_product}_loading`]: true,
    },
  })
  const response = yield call(ApiUser.tradeIdeas, payload)
  if (response && response.data) {
    if (response.status === 200) {
      let list
      list = response.data['article']
      // if (Array.isArray(response.data['article'])) {
      //   list = response.data['article']
      // } else {
      //   list = [response.data['article']]
      // }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          [`tradeIdeas_${type_product}`]: list,
          [`tradeIdeas_${type_product}_loading`]: false,
        },
      })
    } else if (response['status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          [`tradeIdeas_${type_product}_loading`]: false,
        },
      })
    }
  }
}

export function* SMART_SCORE({ payload }) {
  const { params, category } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      smartScore_loading: true,
    },
  })
  const response = yield call(ApiUser.smartScore, params)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      let smartScore_data = yield select(selectors.smartScore_data)
      let obj = { ...smartScore_data }
      let formattedCatName = category.toLowerCase().replace(/ /g, '_')
      obj[formattedCatName] = response.data['data']
      obj[`${formattedCatName}_total_count`] = response.data['total_count']
      yield put({
        type: 'user/SET_STATE',
        payload: {
          smartScore_data: obj,
          smartScore_loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          smartScore_loading: false,
        },
      })
    }
  }
}

export function* AI_TECH_SCREENER({ payload }) {
  try {
    const { params, category } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreener_loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreener, params)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        let aiTechScreener_data = yield select(selectors.aiTechScreener_data)
        let obj = { ...aiTechScreener_data }
        let formattedCatName = category.toLowerCase().replace(/ /g, '_')
        obj[formattedCatName] = response.data['data']
        obj[`${formattedCatName}_total_count`] = response.data['total_count']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreener_data: obj,
            aiTechScreener_loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreener_loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreener_loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_EVENT_TYPES({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreener_loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerEventTypes, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerEventTypes: response.data.data.eventTypes,
            aiTechScreener_loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreener_loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreener_loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_EVENTS({ payload }) {
  try {
    // const { params, category, smartScore_data } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreenerEvents_loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerEvents, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerEvents: response.data.data,
            aiTechScreenerEvents_loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerEvents_loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        aiTechScreenerEvents_loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_EVENT_COUNTS({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerEventCounts, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerEventCounts: response.data.data.event_counts,
            loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_TECHNICAL_SUMMARIES({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerTechnicalSummaries, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerTechnicalSummaries: response.data.data.technical_summaries,
            loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_SUPPORT_RESISTANCE({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerSupportResistance, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerSupportResistance: response.data.data.support_resistance,
            loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* AI_TECH_SCREENER_STOP_PRICE({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(ApiUser.aiTechScreenerStopPrice, payload)
    if (response && response.data) {
      if (response.data['status'] === 1) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            aiTechScreenerStopPrice: response.data.data.stop_price,
            loading: false,
          },
        })
      } else if (response.data['status'] === 0) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ECONOMIC_CALENDAR({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.economicCalendar, payload)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          economicCalendar: response.data.list,
          loading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* ECONOMIC_CALENDAR_EVENT_CHART({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.economicCalendarEventChart, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          economicCalendarEventChart: response.data['data'],
          loading: false,
        },
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* ECONOMIC_CALENDAR_CURRENCY_SYMBOL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.economicCalendarCurrencySymbol, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          economicCalendarCurrencySymbol: Object.keys(response.data['currencySymbolArry']),
          loading: false,
        },
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'][0],
    })
    yield call(LOGOUT)
  }
}

export function* ECONOMIC_CALENDAR_VOLATILITY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.economicCalendarVolatility, payload)
  if (response && response.data) {
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          economicCalendarVolatility: response.data,
          loading: false,
        },
      })
    } else if (response['status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* ECONOMIC_CALENDAR_IMPACT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.economicCalendarImpact, payload)
  if (response && response.data) {
    if (response.status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          economicCalendarImpact: response.data,
          loading: false,
        },
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

//Promo Materials Modules
export function* PROMO_MATERIALS_MODAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsModalLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsModal: response.data.data,
          promoMaterialsModalLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsModalLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_COUNTER({ payload }) {
  yield call(ApiUser.promoMaterialCounter, payload)
}

export function* PROMO_MATERIALS_DASHBOARD_BANNER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsDashboardBannerLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardBanner: response.data.data,
          promoMaterialsDashboardBannerLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardBannerLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_DASHBOARD_VIDEO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsDashboardVideoLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardVideo: response.data.data,
          promoMaterialsDashboardVideoLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardVideoLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_DASHBOARD_LANDING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsDashboardLandingLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardLanding: response.data.data,
          promoMaterialsDashboardLandingLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardLandingLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_DASHBOARD_GIF({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsDashboardGifLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardGif: response.data.data,
          promoMaterialsDashboardGifLoading: false,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsDashboardGifLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_FILTER_BANNER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsFilterBannerLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterBanner: response.data.data,
          promoMaterialsFilterBannerLoading: false,
          promoMaterialsTotalBanner: response.data.data.total_banner,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterBannerLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_FILTER_VIDEO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsFilterVideoLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterVideo: response.data.data,
          promoMaterialsFilterVideoLoading: false,
          promoMaterialsTotalVideo: response.data.data.total_video,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterVideoLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_FILTER_LANDING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsFilterLandingLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterLanding: response.data.data,
          promoMaterialsFilterLandingLoading: false,
          promoMaterialsTotalLanding: response.data.data.total_landing,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterLandingLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_FILTER_GIF({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsFilterGifLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterGif: response.data.data,
          promoMaterialsFilterGifLoading: false,
          promoMaterialsTotalGif: response.data.data.total_gif,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterGifLoading: false,
        },
      })
    }
  }
}

export function* PROMO_MATERIALS_FILTER_LOGO({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      promoMaterialsFilterLogoLoading: true,
    },
  })

  const response = yield call(ApiUser.promoMaterial, payload)
  if (response && response.data) {
    if (response.data.status === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterLogo: response.data.data,
          promoMaterialsFilterLogoLoading: false,
          promoMaterialsTotalLogo: response.data.data.total_logo,
        },
      })
    } else if (response.data['status'] === 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          promoMaterialsFilterLogoLoading: false,
        },
      })
    }
  }
}

// Market News Modules
export function* RSS_NEWS({ payload }) {
  const { mode } = payload
  let newsList
  if (mode === 'replace') {
    newsList = []
  } else {
    newsList = yield select(selectors.news)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rssNewsLoading: true,
      communityRssNewsLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.rssNews, payload)
    if (response && response.data) {
      if (response.status === 200) {
        if (mode === 'replace') {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              rssNews: response.data.data,
              rssNewsLoading: false,
              communityRssNewsLoading: false,
              offset: 0,
              rssNewsNextNews: response.data.data,
            },
          })
        } else if (mode === 'add') {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              rssNews: [...newsList, ...response.data.data],
              rssNewsLoading: false,
              communityRssNewsLoading: false,

              offset: newsList.length,
              rssNewsNextNews: response.data.data,
            },
          })
        } else {
          console.log('Invalid mode', mode)
        }
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            rssNewsLoading: false,
            communityRssNewsLoading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

// Live Broadcast Modules
export function* LIVE_BROADCAST({ payload }) {
  const { mode } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.liveBroadcast, payload)
    if (response && response.data) {
      if (response.data.api_status === 200) {
        let broadcastState
        switch (mode) {
          case 'current':
            broadcastState = 'liveBroadcast'
            break
          case 'next':
            broadcastState = 'liveBroadcastNext'
            break
          default:
            break
        }
        yield put({
          type: 'user/SET_STATE',
          payload: {
            [broadcastState]: response.data.web_video,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

// CMS APIs
export function* CMS_INSTRUMENTS_CATEGORIES() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.cmsInstrumentsCategories)
    if (response && response.data) {
      if (response.status === 200) {
        let popular_cat
        response.data.categories.map((cat) =>
          cat.sub_categories.filter((sub) => {
            if (sub.title.toLowerCase().includes('popular')) {
              popular_cat = sub
            }
          }),
        )
        yield put({
          type: 'user/SET_STATE',
          payload: {
            cms_instruments_categories: response.data.categories,
            // cms_instruments_categories_popular: popular_cat,
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CMS_INSTRUMENTS({ payload }) {
  const { category_title } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.cmsInstruments, category_title !== 'all' ? payload : {})
    if (response && response.data) {
      if (response.status === 200) {
        if (response.data.topics !== undefined) {
          let result = response.data.topics.filter((v) => v.title !== 'Category Description')
          yield put({
            type: 'user/SET_STATE',
            payload: {
              [`cms_instruments_${category_title.toLowerCase().replace(/ /g, '_')}`]: result,
              loading: false,
            },
          })
        } else {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              loading: false,
            },
          })
        }
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CMS_EDUCATION_CATEGORIES_OLD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.cmsEducationCategoriesOld, payload)
    if (response && response.data) {
      if (response.status === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            cms_education_categories: response.data.categories || [],
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CMS_EDUCATION_CATEGORIES({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.cmsEducationCategories, payload)
    if (response && response.data) {
      if (response.status === 200) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            cms_education_categories: response.data.data || [],
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_PROMOTION({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.getPromotion, payload)
    if (response && response.data) {
      if (response.status === 200) {
        let list = response.data.topics
        let amendedList = list.map((v) => ({ ...v, displayedImage: v.banner_image_file }))
        yield put({
          type: 'user/SET_STATE',
          payload: {
            promotion: amendedList,
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_PROMOTION_NEW({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(ApiUser.getPromotionNew, payload)
    if (response && response.data) {
      if (response.status === 200) {
        let list = response.data.data
        let amendedList = list.map((v) => ({
          ...v,
          displayedImage: v.featureImage || v.backgroundImage,
        }))
        yield put({
          type: 'user/SET_STATE',
          payload: {
            promotion: amendedList,
            loading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

// Community
export function* COMMUNITY_AUTH({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityAuth, payload)
  if (response && response.data) {
    if (response.data['status'] === 1) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityUserId: response.data['user_id'],
          communityAccessToken: response.data['access_token'],

          loading: false,
        },
      })
      const reqData = {
        params: { fetch: 'user_data', user_id: response.data['user_id'] },
        access_token: response.data['access_token'],
      }
      yield call(COMMUNITY_GET_USER_DATA, { payload: reqData })
      // console.log('getUSERDATAFROMSAGA')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

// yield put({
//   type: 'user/SET_STATE',
//   payload: {
//     loading: true,
//   },
// })
// const response = yield call(ApiUser.communityPosts, payload)
// if (response && response.data) {
//   if (response.data['api_status'] === 200) {
//     let x
//     if (mode === 'replace') {
//       x = response.data['data']

//       console.log('FIRST CALL')
//     } else if(mode === 'additional'){
//       x = [...communityPosts, ...response.data['data']]
//       console.log('SECOND CALL')
//       console.log(x)

//     }
//     yield put({
//       type: 'user/SET_STATE',
//       payload: {
//         communityPosts: x,
//         loading: false,
//       },
//     })

export function* COMMUNITY_GET_USER_DATA({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityGetUserDataLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetUserData, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (page === 'homepage') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserData: response.data['user_data'],
            communityGetUserDataLoading: false,
          },
        })
      } else if (page === 'post') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityOtherUserData: response.data['user_data'],
            communityGetUserDataLoading: false,
          },
        })
      } else if (page === 'profile') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityProfilePageUserData: response.data['user_data'],
            communityGetUserDataLoading: false,
          },
        })
      } else if (page === 'create_event') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserData: response.data['user_data'],
            communityGetUserDataLoading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserData: response.data['user_data'],
            communityGetUserDataLoading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetUserDataLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_GROUP_DATA({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetGroupData, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetGroupData: response.data.group_data,
          loading: false,
        },
      })
      // const reqData = {
      //   access_token: response.data['access_token'],
      // }
      // yield call(COMMUNITY_POSTS, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_POSTS({ payload }) {
  const { mode, communityPosts, length } = payload
  // const { user_id } = payload]

  yield put({
    type: 'user/SET_STATE',
    payload: {
      // loading: true,
      communityPostsLoading: true,
    },
  })
  const response = yield call(ApiUser.communityPosts, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      if (mode === 'replace') {
        //   if (post.event && post.Orginaltext === '') {
        //   fileType = 'event'
        // }

        x = response.data['data']
        // .filter((item) => item.enabled === 1 && item.sub_modules.length === 0)
        var filteredArray =
          x &&
          x.filter(function (itm) {
            console.log('SAGACONSOLELOG')
            console.log(itm)
            return
          })
        //console.log('FIRST CALL')
      } else if (mode === 'additional') {
        x = [...communityPosts, ...response.data['data']]
        var filteredArray2 =
          y &&
          y.filter((itm) => {
            return itm.event && itm.Orginaltext === ''
          })
        // x = [...communityPosts, ...filteredArray2]

        y = [response.data['data']]
        //console.log('SECOND CALL')
        //console.log(x)
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityPosts: x,
          checkEmptyUserPosts: y,
          communityPostsLoading: false,
          // loading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // loading: false,
          communityPostsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENT_POSTS({ payload }) {
  const { mode, communityEventPosts, length } = payload
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityEventPostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventPosts, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      let r
      if (mode === 'replace') {
        x = response.data['data']
        z = response.data['data_pin_post']
        r = response.data['data']
        //console.log('FIRST CALL')
      } else if (mode === 'additional') {
        // console.log('SECOND CALL')
        // console.log(response.data['data'])

        x = [...communityEventPosts, ...response.data['data']]
        y = [response.data['data']]
        z = response.data['data_pin_post']
        r = response.data['data']
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityEventPosts: x,
          communityEmptyEventPostsList: y,
          communityEventPinnedPosts: z,
          communityEventPostLoading: false,
          communityEventPostsShowMore: r,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityEventPostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_USER_POSTS({ payload }) {
  // const { user_id } = payload
  const {
    mode,
    communityUserPosts,
    version,
    communityVideoPosts,
    communityPhotoPosts,
    communityUserSavedPosts,
  } = payload
  if (mode === 'replace') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        [`communityGetUser${version}PostsLoading`]: true,
      },
    })
  } else if (mode === 'additional') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUserPostsAdditionalLoading: true,
      },
    })
  }
  // yield put({
  //   type: 'user/SET_STATE',
  //   payload: {
  //     [`communityGetUser${version}PostsLoading`]: true,
  //   },
  // })
  const response = yield call(ApiUser.communityGetUserPosts, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (version === 'photos') {
        if (mode === 'replace') {
          x = response.data['data']
        } else if (mode === 'additional') {
          x = [...communityPhotoPosts, ...response.data['data']]
          y = [response.data['data']]
        }
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityPhotoPosts: x,
            checkEmptyPhotoFilterUserPosts: y,
            communityGetUserphotosPostsLoading: false,
          },
        })
      } else if (version === 'videos') {
        if (mode === 'replace') {
          x = response.data['data']
        } else if (mode === 'additional') {
          x = [...communityVideoPosts, ...response.data['data']]
          y = [response.data['data']]
        }
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityVideoPosts: x,
            checkEmptyVideoFilterUserPosts: y,
            communityGetUservideosPostsLoading: false,
          },
        })
      } else if (version === 'savedPosts') {
        if (mode === 'replace') {
          x = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communityGetUserSavedPostsLoading: true,
            },
          })
        } else if (mode === 'additional') {
          x = [...communityUserSavedPosts, ...response.data['data']]
          y = [response.data['data']]
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communityGetUserSavedPostsAdditionalLoading: true,
            },
          })
        }
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserSavedPosts: x,
            checkEmptycommnityUserSavedPosts: y,
            communityGetUserSavedPostsLoading: false,
            communityGetUserSavedPostsAdditionalLoading: false,
          },
        })
      } else {
        if (mode === 'replace') {
          x = response.data['data']
          z = response.data['data_pin_post']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communityUserPosts: x,
              checkEmptyUserPosts: y,
              communityUserPinnedPosts: z,
              communityGetUserPostsLoading: false,
            },
          })
        } else if (mode === 'additional') {
          x = [...communityUserPosts, ...response.data['data']]
          y = [response.data['data']]
          z = response.data['data_pin_post']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communityUserPosts: x,
              checkEmptyUserPosts: y,
              communityUserPinnedPosts: z,
              communityGetUserPostsAdditionalLoading: false,
            },
          })
        }
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          [`communityGetUser${version}Loading`]: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_FOLLOWERS_LIST({ payload }) {
  const { type } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      userFollowersLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetFollowersList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (type === 'replace') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityInviteList: response.data['followers'],
            loading: false,
            userFollowersLoading: false,
          },
        })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityUserFollowers: response.data['followers'],
          loading: false,
          userFollowersLoading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          userFollowersLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_FOLLOWING_LIST({ payload }) {
  const { type } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      userFollowingLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetFollowingList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (type === 'replace') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityInviteList: response.data['following'],
            loading: false,
            userFollowingLoading: false,
          },
        })
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityUserFollowing: response.data['following'],
          loading: false,
          userFollowingLoading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          userFollowingLoading: false,
        },
      })
    }
  }
}

export function* COMMUNIITY_GET_RECOMMENDED({ payload }) {
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetRecommended, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityRecommendedPosts: response.data['data'],
          loading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* POST_COMMUNITY_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityNewPostLoading: true,
    },
  })

  const response = yield call(ApiUser.postCommunityPost, payload)

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post has been posted.',
      })
      if (page === 'singleEvent') {
        const reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else {
        const reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length + 1, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Post failed, please check file type.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityNewPostLoading: false,
        },
      })
    }
  }
}

export function* POST_COMMENT({ payload }) {
  const { access_token, params } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityPostCommentLoading: true,
    },
  })
  const response = yield call(ApiUser.postComment, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      //console.log('Post has been posted')
      notification.success({
        message: 'Comment has been posted!',
      })
      let reqData = {
        access_token,
        params: {
          type: 'fetch_comments',
          post_id: params.post_id,
        },
      }
      yield call(FETCH_COMMENTS, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityPostCommentLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SHARE_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    event_id,
    eventParams: { id, type, limit },
    is_current_user,
  } = payload
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communitySharePostLoading: true,
    },
  })
  const response = yield call(ApiUser.communitySharePost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post has been shared',
      })
      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityPosts: response.data['data'],
      //     communitySharePostLoading: false,
      //   },
      // })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
      if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        if (is_current_user === false) {
          // do nothing
        } else {
          let reqData = {
            mode,
            access_token,
            params: { type: 'get_user_posts', limit: length, id: user_id },
          }
          yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        }
      } else if (page === 'eventPage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'shareSingleEventPage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', id: id, limit: limit },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      }

      //console.log('post DELETED')
      //console.log('Post has been posted')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySharePostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_CREATE_ALBUM({ payload }) {
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communitySharePostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityCreateAlbum, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityPosts: response.data['data'],
      //     loading: false,
      //   },
      // })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
      //console.log('Post has been posted')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_ADD_TO_ALBUM({ payload }) {
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityAddtoAlbum, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityPosts: response.data['data'],
      //     loading: false,
      //   },
      // })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
      //console.log('Post has been posted')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* POST_REPLY({ payload }) {
  const {
    access_token,
    post_id,
    reply_id,
    reply_comment_id,
    params: { comment_id, type },
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityCommentReplyLoading: true,
    },
  })
  const response = yield call(ApiUser.postReply, payload)
  if (response && response.data) {
    // console.log(type)
    // console.log('postreply', response.data['api_status'])
    if (response.data['api_status'] === 200) {
      if (type === 'delete') {
        notification.success({
          message: 'Comment deleted.',
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'delete_reply') {
        notification.success({
          message: 'Comment deleted.',
        })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'edit_reply') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'create_reply') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'comment_like') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'register_comment_reply_like') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
      } else if (type === 'comment_hide') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
        let reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id: comment_id,
          },
        }
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      } else if (type === 'reply_hide') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            community_post_reply_type: type,
          },
        })
        let reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id: reply_comment_id,
          },
        }
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      }

      let reqData = {
        access_token,
        params: {
          type: 'fetch_comments',
          post_id,
        },
      }
      yield call(FETCH_COMMENTS, { payload: reqData })

      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityCommentReplyLoading: false,
          showReplies: true,
        },
      })

      //console.log('Reply has been posted')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityCommentReplyLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_LIKE_COMMENT({ payload }) {
  const { access_token, post_id, comment_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityCommentLikeLoading: true,
    },
  })
  const response = yield call(ApiUser.communityCommentLike, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // postComments: response.data['data'],
          communityCommentLikeLoading: false,
        },
      })

      const reqData = {
        access_token,
        params: {
          type: 'fetch_comments',
          post_id,
        },
      }
      const reqData2 = {
        access_token,
        params: {
          type: 'fetch_comments_reply',
          comment_id,
        },
      }
      yield call(FETCH_COMMENTS, { payload: reqData })
      yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })

      //console.log('comments fetched')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityCommentLikeLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_INVITE_TO_EVENT({ payload }) {
  const { formData, access_token, event_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityInviteToEventLoading: true,
    },
  })
  const response = yield call(ApiUser.communityInviteToEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Invitations sent',
      })
      let reqData = {
        access_token,
        params: {
          event_id: event_id,
        },
      }

      yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // postComments: response.data['data'],
          communityInviteToEventLoading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityInviteToEventLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_DELETE_EVENT({ payload }) {
  const { access_token, post_id, comment_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityDeleteEventLoading: true,
    },
  })
  const response = yield call(ApiUser.communityDeleteEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // postComments: response.data['data'],
          communityDeleteEventLoading: false,
        },
      })

      notification.success({
        message: 'Event has been deleted',
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityDeleteEventLoading: false,
        },
      })
    }
  }
}

export function* FETCH_COMMENTS({ payload }) {
  const { mode } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      fetchCommentsLoading: true,
    },
  })
  const response = yield call(ApiUser.fetchComments, payload)
  if (response && response.data) {
    // console.log('fetchcommentresp', response)
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          postComments: response.data['data'].reverse(),
          fetchCommentsLoading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
      //console.log('comments fetched')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          fetchCommentsLoading: false,
        },
      })
    }
  }
}

export function* FETCH_COMMENTS_REPLY({ payload }) {
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityFetchCommentsReplyLoading: true,
    },
  })
  const response = yield call(ApiUser.fetchCommentsReply, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          postCommentsReply: response.data['data'],
          communityFetchCommentsReplyLoading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
      //console.log('comments replies fetched')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityFetchCommentsReplyLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_POST_DATA({ payload }) {
  const { type } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityGetPostdataLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetPostData, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (type === 'sharedPost') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySharedPostData: response.data['post_data'],
            communityGetPostdataLoading: false,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            postData: response.data['post_data'],
            communityGetPostdataLoading: false,
          },
        })
      }

      //console.log('post data get!')
    } else if (response.data['api_status'] !== 200) {
      // yield put(push('/community/community404'))

      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetPostdataLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENT_DATA({ payload }) {
  const { type } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityGetEventDataLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventData, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (type === 'sharedPost') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventData: response.data['events'],
            communityGetEventDataLoading: false,
          },
        })
      } else
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventData: response.data['events'],
            communityGetEventDataLoading: false,
          },
        })

      ////console.log('event data get!')
    } else if (response.data['api_status'] !== 200) {
      // yield put(push('/community/community404'))
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetEventDataLoading: false,
        },
      })
    }
  }
}

export function* POST_LIKE({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
    type,
    query,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      postLikeloading: true,
    },
  })
  const response = yield call(ApiUser.postLike, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (page === 'profilePage') {
        if (type === 'savedPosts') {
          // let reqData = {
          //   mode,
          //   access_token,
          //   params: { type: 'get_user_saved_posts', limit: length, id: user_id },
          // }
          // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        } else {
          // let reqData2 = {
          //   mode,
          //   access_token,
          //   params: { type: 'get_user_posts', limit: length, id: user_id },
          // }
          // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData2 })
        }

        // let reqData3 = {
        //   mode,
        //   access_token,
        //   version: 'photos',
        //   params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        // }
        // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        // let reqData4 = {
        //   mode,
        //   access_token,
        //   version: 'videos',
        //   params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        // }
        // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      }

      if (page === 'searchPage') {
        // let reqData = {
        //   mode,
        //   access_token,
        //   params: { type: 'posts', limit: 10, search_key: query, offset: 0 },
        // }
        // yield call(COMMUNITY_SEARCH_TYPE, { payload: reqData })
        // let reqData2 = {
        //   mode,
        //   access_token,
        //   params: { limit: 3, search_key: query, mode: 'replace' },
        // }
        // yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      }

      if (page === 'eventPage') {
        const reqData6 = {
          mode,
          access_token,
          params: { type: 'get_event_posts', id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData6 })
      }

      if (page === 'homepage') {
        const reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      }

      if (page === 'postPage') {
        // const reqData3 = {
        //   mode,
        //   access_token,
        //   params: { fetch: 'post_data', post_id: post_id },
        // }
        // yield call(COMMUNITY_GET_POST_DATA, { payload: reqData3 })
      }

      if (page === 'fileViewer') {
        //Do nothing
      }

      // yield call(COMMUNITY_PHOTO_POST_FILTER, { payload: reqData4 })
      // yield call(COMMUNITY_VIDEO_POST_FILTER, { payload: reqData5 })

      ////console.log('post liked')
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          postLikeloading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_EDIT_POST({ payload }) {
  const {
    access_token,
    params: {},
    mode,
    version,
    length,
    page,
    post_id,
    query,
    user_id,
    event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityEditPostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityEditPost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post has been editted',
      })
      if (page === 'post') {
        let reqData = {
          access_token,
          params: {
            fetch: 'post_data',
            post_id,
          },
        }
        yield call(COMMUNITY_GET_POST_DATA, { payload: reqData })
      }
      if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      }
      if (page === 'profilePage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData2 })
        let reqData3 = {
          mode,
          access_token,
          version: 'photos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        let reqData4 = {
          mode,
          access_token,
          version: 'videos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      }
      if (page === 'singleEvent') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      }
      if (page === 'searchPage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'posts', limit: 35, search_key: query, mode: 'replace' },
        }
        // yield call(COMMUNITY_SEARCH_TYPE, { payload: reqData })
        let reqData2 = {
          mode,
          access_token,
          params: { limit: 3, search_key: query, mode: 'replace' },
        }
        yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityEditPostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_DELETE_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id, search_key, communitySearchAllResults },
    mode,
    length,
    page,
    query,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityDeletePostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityDeletePost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post deleted',
      })
      if (page === 'profilePage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        let reqData3 = {
          mode,
          access_token,
          params: { user_id: user_id, offset: 0, limit: 10 },
        }
        yield call(COMMUNITY_GET_USER_PHOTOS, { payload: reqData3 })
        let reqData4 = {
          mode,
          access_token,
          params: { user_id: user_id, offset: 0, limit: 10 },
        }
        yield call(COMMUNITY_GET_USER_VIDEOS, { payload: reqData4 })
        let reqData5 = {
          mode,
          access_token,
          params: { user_id: user_id, offset: 0, limit: 10 },
        }
        yield call(COMMUNITY_GET_USER_SOUNDS, { payload: reqData5 })
        let reqData6 = {
          mode,
          access_token,
          params: { user_id: user_id, offset: 0, limit: 10 },
        }
        yield call(COMMUNITY_GET_USER_DOCUMENTS, { payload: reqData6 })
      } else if (page === 'homepage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData })
      } else if (page === 'singleEvent') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'searchPage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'posts', limit: 35, search_key: query, mode: 'replace' },
        }
        // yield call(COMMUNITY_SEARCH_TYPE, { payload: reqData })
        let reqData2 = {
          mode,
          access_token,
          params: { limit: 3, search_key: query, mode: 'replace' },
        }
        yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      } else {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityDeletePostLoading: false,
        },
      })
    }

    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     postData: response.data['post_data'],
    //     loading: false,
    //   },
    // })
  }
}

export function* COMMUNITY_DISABLE_POST_COMMENTS({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
    search_key,
    payload_event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityDisableCommentsLoading: true,
    },
  })
  const response = yield call(ApiUser.communityDisablePostComments, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (response.data['action'] === 'post comments disabled') {
        notification.success({
          message: 'Comments disabled',
        })
      } else {
        notification.success({
          message: 'Comments enabled',
        })
      }
      if (page === 'post') {
        let reqData = {
          access_token,
          params: {
            fetch: 'post_data',
            post_id,
          },
        }
        yield call(COMMUNITY_GET_POST_DATA, { payload: reqData })
      } else if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        let reqData3 = {
          mode,
          access_token,
          version: 'photos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        let reqData4 = {
          mode,
          access_token,
          version: 'videos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      } else if (page === 'singleEvent') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: payload_event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'searchPage') {
        let reqData = {
          mode,
          access_token,
          params: { search_key },
        }
        yield call(COMMUNITY_SEARCH_ALL, { payload: reqData })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityDisableCommentsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SAVE_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
    query,
    type,
  } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      communitySavePostLoading: true,
    },
  })
  const response = yield call(ApiUser.communitySavePost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (response.data['go_status'] === 'saved') {
        notification.success({
          message: 'Post saved',
        })
      } else {
        notification.success({
          message: 'Post unsaved',
        })
      }

      if (page === 'post') {
        let reqData = {
          access_token,
          params: {
            fetch: 'post_data',
            post_id,
          },
        }
        yield call(COMMUNITY_GET_POST_DATA, { payload: reqData })
      } else if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        if (type === 'savedPosts') {
          let reqData = {
            mode,
            access_token,
            version: 'savedPosts',
            params: { type: 'get_user_saved_posts', limit: '20', id: user_id },
          }
          yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        }
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        let reqData3 = {
          mode,
          access_token,
          version: 'photos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        let reqData4 = {
          mode,
          access_token,
          version: 'videos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      } else if (page === 'singleEvent') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'searchPage') {
        // let reqData2 = {
        //   mode,
        //   access_token,
        //   params: { limit: 3, search_key: query, mode: 'replace' },
        // }
        // let reqData = {
        //   mode,
        //   access_token,
        //   params: { type: 'posts', limit: 35, search_key: query, mode: 'replace' },
        // }
        // yield call(COMMUNITY_SEARCH_TYPE, { payload: reqData })
        // yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySavePostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_HIDE_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
    query,
  } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityHidePostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityHidePost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post has been hidden',
      })
      if (page === 'post') {
        // let reqData = {
        //   access_token,
        //   params: {
        //     mode,
        //     access_token,
        //     params: { type: 'get_news_feed', limit: length, id: user_id },
        //   },
        // }
        // yield call(COMMUNITY_POSTS, { payload: reqData })
      } else if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        // let reqData = {
        //   mode,
        //   access_token,
        //   params: { type: 'get_user_posts', limit: length, id: user_id },
        // }
        // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        // let reqData3 = {
        //   mode,
        //   access_token,
        //   version: 'photos',
        //   params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        // }
        // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        // let reqData4 = {
        //   mode,
        //   access_token,
        //   version: 'videos',
        //   params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        // }
        // yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      } else if (page === 'singleEvent') {
        // let reqData = {
        //   mode,
        //   access_token,
        //   params: { type: 'get_event_posts', limit: length, id: event_id },
        // }
        // yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'searchPage') {
        // let reqData2 = {
        //   mode,
        //   access_token,
        //   params: { limit: 3, search_key: query, mode: 'replace' },
        // }
        // yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityHidePostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_REPORT_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
    query,
  } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityReportPostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityReportPost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data['follow_status'],
      // })
      if (page === 'post') {
        let reqData = {
          access_token,
          params: {
            fetch: 'post_data',
            post_id,
          },
        }
        yield call(COMMUNITY_GET_POST_DATA, { payload: reqData })
      } else if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        let reqData3 = {
          mode,
          access_token,
          version: 'photos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        let reqData4 = {
          mode,
          access_token,
          version: 'videos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        }
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
      } else if (page === 'singleEvent') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData })
      } else if (page === 'searchPage') {
        // let reqData2 = {
        //   mode,
        //   access_token,
        //   params: { limit: 3, search_key: query, mode: 'replace' },
        // }
        // let reqData = {
        //   mode,
        //   access_token,
        //   params: { type: 'posts', limit: 35, search_key: query, mode: 'replace' },
        // }
        // yield call(COMMUNITY_SEARCH_TYPE, { payload: reqData })
        // yield call(COMMUNITY_SEARCH_ALL, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityReportPostLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_PIN_POST({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id },
    mode,
    length,
    page,
  } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      pinPostLoading: true,
    },
  })
  const response = yield call(ApiUser.communityPinPost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: response.data['action'],
      })

      if (page === 'post') {
        let reqData = {
          access_token,
          params: {
            fetch: 'post_data',
            post_id,
          },
        }
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        yield call(COMMUNITY_GET_POST_DATA, { payload: reqData })
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData2 })
      } else if (page === 'homepage') {
        let reqData2 = {
          mode,
          access_token,
          params: { type: 'get_news_feed', limit: length, id: user_id },
        }
        yield call(COMMUNITY_POSTS, { payload: reqData2 })
      } else if (page === 'profilePage') {
        let reqData = {
          mode,
          access_token,
          params: { type: 'get_user_posts', limit: length, id: user_id },
        }
        let reqData3 = {
          mode,
          access_token,
          version: 'photos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'photos' },
        }
        let reqData4 = {
          mode,
          access_token,
          version: 'videos',
          params: { type: 'get_user_posts', limit: length, id: user_id, filter_by: 'video' },
        }

        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData })
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData3 })
        yield call(COMMUNITY_GET_USER_POSTS, { payload: reqData4 })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            pinPostLoading: false,
          },
        })
      } else if (page === 'singleEvent') {
        let reqData5 = {
          mode,
          access_token,
          params: { type: 'get_event_posts', limit: length, id: event_id },
        }
        yield call(COMMUNITY_GET_EVENT_POSTS, { payload: reqData5 })
        yield put({
          type: 'user/SET_STATE',
          payload: {
            pinPostLoading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          pinPostLoading: false,
        },
      })
    }
  }
}

// export function* COMMUNITY_PHOTO_POST_FILTER({ payload }) {
//   const { mode, communityPhotoPosts, length } = payload

//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const response = yield call(ApiUser.communityPhotoPostFilter, payload)
//   if (response && response.data) {
//     if (response.data['api_status'] === 200) {
//       let x
//       let y
//       if (mode === 'replace') {
//         x = response.data['data']
//       } else if (mode === 'additional') {
//         x = [...communityPhotoPosts, ...response.data['data']]
//         y = [response.data['data']]
//       }
//       yield put({
//         type: 'user/SET_STATE',
//         payload: {
//           communityPhotoPosts: x,
//           checkEmptyPhotoFilterUserPosts: y,
//           loading: false,
//         },
//       })
//     } else if (response.data['api_status'] !== 200) {
//       yield put({
//         type: 'user/SET_STATE',
//         payload: {
//           loading: false,
//         },
//       })
//     }
//   }
// }

// export function* COMMUNITY_VIDEO_POST_FILTER({ payload }) {
//   const { mode, communityVideoPosts, length } = payload

//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const response = yield call(ApiUser.communityVideoPostFilter, payload)
//   if (response && response.data) {
//     if (response.data['api_status'] === 200) {
//       let x
//       let y
//       if (mode === 'replace') {
//         x = response.data['data']
//       } else if (mode === 'additional') {
//         x = [...communityVideoPosts, ...response.data['data']]
//         y = [response.data['data']]
//       }
//       yield put({
//         type: 'user/SET_STATE',
//         payload: {
//           communityVideoPosts: x,
//           checkEmptyVideoFilterUserPosts: y,
//           loading: false,
//         },
//       })
//     } else if (response.data['api_status'] !== 200) {
//       yield put({
//         type: 'user/SET_STATE',
//         payload: {
//           loading: false,
//         },
//       })
//     }
//   }
// }

export function* COMMUNITY_FOLLOW_USER({ payload }) {
  const {
    access_token,
    params: { user_id, post_id, event_id, recipient_id },
    mode,
    length,
    page,
    search_key,
    profileUserId,
    query,
    isOtherUser,
    activeTab,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityFollowUserLoading: true,
    },
  })
  const response = yield call(ApiUser.communityFollowUser, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: response.data['follow_status'],
        // message: 'You are now following this user.',
      })

      if (page === 'search') {
        let reqData5 = {
          access_token,
          // page: 'post',
          params: {
            access_token,
            limit: 3,
            search_key: query,
          },
          mode: 'replace',
        }
        let reqData4 = {
          access_token,
          // page: 'post',
          params: {
            type: 'users',
            search_key: query,
          },
        }
        // yield call(COMMUNITY_SEARCH_ALL, { payload: reqData5 })
        // yield call(COMMUNITY_SEARCH_USERS, { payload: reqData4 })
      }
      if (page !== 'profile') {
        let reqData2 = {
          access_token,
          // page: 'post',
          params: {
            fetch: 'followers',
            user_id: recipient_id,
          },
        }
        let reqData3 = {
          access_token,
          // page: 'post',
          params: {
            fetch: 'following',
            user_id: recipient_id,
          },
        }

        yield call(COMMUNITY_GET_FOLLOWERS_LIST, { payload: reqData2 })
        // yield call(COMMUNITY_GET_FOLLOWING_LIST, { payload: reqData3 })
      }
      if (mode === 'sideBar') {
        // let reqData2 = {
        //   fetch: 'most_follower_users',

        // }
        // yield call(COMMUNITY_GET_MOST_FOLLOWER_USERS, { payload: reqData2 })

        if (activeTab !== '6') {
          let reqData3 = {
            access_token,
            // page: 'post',
            params: {
              fetch: 'following',
              user_id: recipient_id,
            },
          }
          let reqData = {
            access_token,
            page: page,
            params: {
              fetch: 'user_data',
              user_id: recipient_id,
            },
          }
          yield call(COMMUNITY_GET_USER_DATA, { payload: reqData })
          // yield call(COMMUNITY_GET_FOLLOWERS_LIST, { payload: reqData2 })
          yield call(COMMUNITY_GET_FOLLOWING_LIST, { payload: reqData3 })
        }
        let reqData3 = {
          access_token,
          // page: 'post',
          params: {
            fetch: 'following',
            user_id: recipient_id,
          },
        }
        let reqData = {
          access_token,
          page: page,
          params: {
            fetch: 'user_data',
            user_id: recipient_id,
          },
        }
        yield call(COMMUNITY_GET_USER_DATA, { payload: reqData })
        // yield call(COMMUNITY_GET_FOLLOWERS_LIST, { payload: reqData2 })
        // yield call(COMMUNITY_GET_FOLLOWING_LIST, { payload: reqData3 })
      } else {
        let reqData = {
          access_token,
          page: page,
          params: {
            fetch: 'user_data',
            user_id: recipient_id,
          },
        }
        let reqData2 = {
          access_token,
          // page: 'post',
          params: {
            fetch: 'followers',
            user_id: recipient_id,
          },
        }
        let reqData3 = {
          access_token,
          // page: 'post',
          params: {
            fetch: 'following',
            user_id: recipient_id,
          },
        }
        yield call(COMMUNITY_GET_USER_DATA, { payload: reqData })
        yield call(COMMUNITY_GET_FOLLOWERS_LIST, { payload: reqData2 })
        yield call(COMMUNITY_GET_FOLLOWING_LIST, { payload: reqData3 })
      }
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityFollowUserLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_ADDITIONAL_POSTS({ payload }) {
  const { communityUserPosts } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetAdditionalPosts, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let y = [response.data['data']]
      let x = [...communityUserPosts, ...response.data['data']]
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityUserPosts: x,
          checkEmptyUserPosts: y,
          loading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_ADDITIONAL_NEWS_FEED({ payload }) {
  const { communityPosts } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetAdditionalNewsFeed, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data['follow_status'],
      // })
      let x = [...communityPosts, ...response.data['data']]
      let y = [response.data['data']]
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityPosts: x,
          checkEmptyUserPosts: y,
          loading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_RSS_NEWS({ payload }) {
  // const { communityPosts } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      rssNewsLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetRSSNews, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityRSSNews: response.data['news'],
          rssNewsLoading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          rssNewsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_TRENDING_HASHTAG({ payload }) {
  // const { communityPosts } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetTrendingHashTag, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityTrendingHashtags: response.data['trending_hashtag'],
          loading: false,
        },
      })
      // const reqData = {
      //   user_id: user_id,
      // }
      // yield call(GET_ME, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENTS_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityEventsListLoading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['events']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventsList: x,
            communityEventsListLoading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityEventsList, ...response.data['events']]
        y = [response.data['events']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventsList: x,
            communityEmptyEventsList: y,
            communityEventsListLoading: false,
          },
        })
      } else if (mode === 'invited') {
        z = response.data['invited']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInvitedList: z,
            communityEventsListLoading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityEventsListLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SEARCH_EVENTS_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityEventsListLoading: true,
    },
  })
  const response = yield call(ApiUser.communitySearchEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['events']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventsList: x,
            communityEventsListLoading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityEventsList, ...response.data['events']]
        y = [response.data['events']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventsList: x,
            communityEmptyEventsList: y,
            communityEventsListLoading: false,
          },
        })
      } else if (mode === 'invited') {
        z = response.data['invited']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInvitedList: z,
            communityEventsListLoading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityEventsListLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_MY_EVENTS_CUSTOM_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventGoingList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetMyEventsCustomList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let going
      let interested
      let invited
      let past
      if (mode === 'going') {
        going = response.data['going']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventGoingList: going,
            loading: false,
          },
        })
      } else if (mode === 'interested') {
        interested = [response.data['interested']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventInterestedList: interested,
            loading: false,
          },
        })
      } else if (mode === 'invited') {
        invited = response.data['invited']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventInvitedList: invited,
            loading: false,
          },
        })
      } else if (mode === 'past') {
        past = response.data['past']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventPastList: past,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENT_GOING_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventGoingList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['going']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventGoingList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityEventGoingList, ...response.data['going']]
        y = [response.data['going']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventGoingList: x,
            communityEmptyEventGoingList: y,
            loading: false,
          },
        })
      } else if (mode === 'invited') {
        z = response.data['invited']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInvitedList: z,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENT_INVITED_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventInvitedList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['invited']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInvitedList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityEventInvitedList, ...response.data['invited']]
        y = [response.data['invited']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInvitedList: x,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_EVENT_INTERESTED_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityEventInterestedList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetMyInterestedEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['interested']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInterestedList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityEventInterestedList, ...response.data['interested']]
        y = [response.data['interested']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityEventInterestedList: x,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_MY_EVENTS_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityMyEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['my_events']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventsList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityMyEventsList, ...response.data['my_events']]
        y = [response.data['my_events']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityMyEventsList: x,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_PAST_EVENTS_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communityPastEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['past']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityPastEventsList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityPastEventsList, ...response.data['past']]
        y = [response.data['past']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityPastEventsList: x,
            communityEmptyPastEventsList: y,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_SAVED_EVENTS_LIST({ payload }) {
  // const { user_id } = payload
  const { mode, communitySavedEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetMySavedEventsList, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let x
      let y
      let z
      if (mode === 'replace') {
        x = response.data['saved']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySavedEventsList: x,
            loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communitySavedEventsList, ...response.data['saved']]
        y = [response.data['saved']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySavedEventsList: x,
            communityEmptySavedEventsList: y,
            loading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}
export function* COMMUNITY_JOIN_EVENT({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    profileUserId,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      // loading: true,
      communityFollowLoading: true,
    },
  })
  const response = yield call(ApiUser.communityJoinEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: response.data['go_status'],
      })
      let reqData = {
        access_token,
        mode: 'replace',
        // page: 'post',
        params: {
          fetch: 'events',
        },
      }

      yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      // notification.error({
      //   message: response.data['follow_status'],
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityFollowLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_CREATE_EVENT({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityCreateEventLoading: true,
    },
  })

  const response = yield call(ApiUser.communityCreateEvent, payload)
  // const reqData2 = {
  //   mode,
  //   access_token,
  //   params: { type: 'get_news_feed', limit: length, id: user_id },
  // }
  // yield call(COMMUNITY_POSTS, { payload: reqData2 })

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Post has been posted.',
      })
      if (response.data['event_id'])
        yield put(push(`/community/event?id=${response.data['event_id']}`))
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Please fill in all details.',
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityCreateEventLoading: false,
      },
    })
  }
}

export function* COMMUNITY_UPDATE_EVENT({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.communityUpdateEvent, payload)

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Event has been updated.',
      })
      let reqData = {
        access_token,
        params: {
          event_id: event_id,
        },
      }

      yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Please fill in all details.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GOING_TO_EVENT({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityFollowLoading: true,
    },
  })

  const response = yield call(ApiUser.communityGoingToEvent, payload)

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (page === 'upcoming') {
        if (mode === 'going') {
          notification.success({
            message: response.data['go_status'],
          })
          let reqData = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'going',
            },
          }
          let reqData2 = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'interested',
            },
          }

          let reqData3 = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'my_events',
            },
          }

          let reqData4 = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'past',
            },
          }

          let reqData5 = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'invited',
            },
          }

          let reqData6 = {
            access_token,
            mode: 'replace',

            params: {
              fetch: 'events',
            },
          }

          // yield call(COMMUNITY_GET_EVENT_GOING_LIST, { payload: reqData })
          // yield call(COMMUNITY_GET_EVENT_INTERESTED_LIST, { payload: reqData2 })
          // yield call(COMMUNITY_GET_MY_EVENTS_LIST, { payload: reqData3 })
          // yield call(COMMUNITY_GET_PAST_EVENTS_LIST, { payload: reqData4 })
          // yield call(COMMUNITY_GET_EVENT_INVITED_LIST, { payload: reqData5 })
          // yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData6 })
        }
      } else {
        notification.success({
          message: 'You are now going to this event.',
        })
        let reqData = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
      }
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Please fill in all details.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityFollowLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_INTERESTED_IN_EVENT({ payload }) {
  const {
    access_token,
    params: { user_id, post_id },
    mode,
    length,
    page,
    event_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.communityInterestedInEvent, payload)

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (page === 'upcoming') {
        let reqData = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'going',
          },
        }
        let reqData2 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'interested',
          },
        }

        let reqData3 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'my_events',
          },
        }

        let reqData4 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'past',
          },
        }

        let reqData5 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'invited',
          },
        }

        // yield call(COMMUNITY_GET_EVENT_GOING_LIST, { payload: reqData })
        // yield call(COMMUNITY_GET_EVENT_INTERESTED_LIST, { payload: reqData2 })
        // yield call(COMMUNITY_GET_MY_EVENTS_LIST, { payload: reqData3 })
        // yield call(COMMUNITY_GET_PAST_EVENTS_LIST, { payload: reqData4 })
        // yield call(COMMUNITY_GET_EVENT_INVITED_LIST, { payload: reqData5 })
      } else {
        let reqData = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
      }
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Please fill in all details.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SEARCH_ALL({ payload }) {
  console.log('   ')
  // const { user_id } = payload
  const {
    // params: { mode },
    mode,
    communitySearchAllResults,
    suggestion,
  } = payload
  if (suggestion === 'suggestion') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communitySearchAllLoading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communitySearchAllLoading: mode === 'replace' ? true : false,
        communitySearchAllAdditionalLoading: mode === 'additional' ? true : false,
      },
    })
  }

  const response = yield call(ApiUser.communitySearchAll, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (suggestion === 'suggestion') {
        let data
        // let x = Object.keys(data)

        data = response.data
        delete data.api_status
        let x = []
        x.push(data)
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySearchAllResultsSuggestion: x,
            communitySearchAllLoading: false,
          },
        })
      }
      // params: { limit: 3, search_key: query, mode: 'replace' },

      if (mode === 'replace') {
        let data
        // let x = Object.keys(data)

        data = response.data
        delete data.api_status
        let x = []
        x.push(data)
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySearchAllResults: x,
            communityCheckEmptySearchAllResults: data,
            communitySearchAllLoading: false,
          },
        })
        // console.log('XXX')
        // console.log(x)
      }
      if (mode === 'additional') {
        let data
        data = response.data
        delete data.api_status
        let isAdditionalDataEmpty = Object.values(data).every(
          (val) => Array.isArray(val) && val.length === 0,
        )
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communitySearchAllResults: isAdditionalDataEmpty
              ? [...communitySearchAllResults]
              : [...communitySearchAllResults, data],
            communityCheckEmptySearchAllResults: data,
            communitySearchAllAdditionalLoading: false,
          },
        })

        // console.log('YYY')
        // console.log(data)
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySearchAllLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SEARCH_USERS({ payload }) {
  // const { user_id } = payload
  const { mode, communitySearchUsersResults, length } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      communitySearchLoading: true,
    },
  })
  const response = yield call(ApiUser.communitySearchUsers, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (mode === 'replace') {
      }
      let data

      data = response.data['data']
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySearchUsersResults: data,
          communitySearchLoading: false,
        },
      })
    } else if (mode === 'additional') {
      let data

      data = response.data['data']
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySearchUsersResults: [...communitySearchUsersResults, ...data],
          communitySearchLoading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySearchLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SEARCH_TYPE({ payload }) {
  // const { user_id } = payload
  const {
    params: { type },
    mode,
    suggestion,
    communitySearchUsersResults,
    communitySearchEventsResults,
    communitySearchPostsResults,
    // type,
    query,
  } = payload
  if (suggestion === 'suggestion') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communitySearchLoading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communitySearchLoading: mode === 'replace' ? true : false,
        communitySearchAdditionalLoading: mode === 'additional' ? true : false,
      },
    })
  }
  // params: { type: 'posts', limit: 10, search_key: query, mode: 'replace', offset: 0 },

  const response = yield call(ApiUser.communitySearchType, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (type === 'events') {
        if (mode === 'replace') {
          let data
          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchEventsResults: data,
              communityCheckSearchEventsEmpty: data,
              communitySearchLoading: false,
            },
          })
        } else if (mode === 'additional') {
          let data

          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchEventsResults: [...communitySearchEventsResults, ...data],
              communityCheckSearchEventsEmpty: data,
              communitySearchAdditionalLoading: false,
            },
          })
        }
        // mode,
        // access_token,
        // params: { type: 'posts', limit: 10, search_key: query, offset: 0 },
      } else if (type === 'posts') {
        if (mode === 'replace') {
          let data
          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchPostsResults: data,
              communityCheckSearchPostsEmpty: data,
              communitySearchLoading: false,
            },
          })
        } else if (mode === 'additional') {
          let data

          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchPostsResults: [...communitySearchPostsResults, ...data],
              communityCheckSearchPostsEmpty: data,
              communitySearchAdditionalLoading: false,
            },
          })
        }
      } else if (type === 'users') {
        if (suggestion === 'suggestion') {
          let data
          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchUsersResultsSuggestion: data,
              communityCheckSearchUsersEmpty: data,
              communitySearchLoading: false,
            },
          })
        }
        if (mode === 'replace') {
          let data
          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchUsersResults: data,
              communityCheckSearchUsersEmpty: data,
              communitySearchLoading: false,
            },
          })
        }
        if (mode === 'additional') {
          let data

          data = response.data['data']
          yield put({
            type: 'user/SET_STATE',
            payload: {
              communitySearchUsersResults: [...communitySearchUsersResults, ...data],
              communityCheckSearchUsersEmpty: data,
              communitySearchAdditionalLoading: false,
            },
          })
        }
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communitySearchLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_MOST_FOLLOWER_USERS({ payload }) {
  // const { user_id } = payload
  const { mode, communityPastEventsList, length } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityGetMostFollowersUsers, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      let data

      data = response.data['most_follower_users']
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityMostFollowersUsers: data,
          loading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_TERMS_AND_CONDITIONS() {
  // const { user_id } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.traderModuleCommunity)
  if (response && response.data) {
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityTermsandConditions: response.data,
          loading: false,
        },
      })
    } else if (response.status !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_EVENT_ACTIONS({ payload }) {
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(ApiUser.communityEventAction, payload)

  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (response.data['api_status'] === 200) {
        notification.success({
          message: response.data['go_status'],
        })
        let reqData = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'going',
          },
        }
        let reqData2 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'interested',
          },
        }

        let reqData3 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'my_events',
          },
        }

        let reqData4 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'past',
          },
        }

        let reqData5 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'invited',
          },
        }

        let reqData6 = {
          access_token,
          mode: 'replace',

          params: {
            fetch: 'events',
          },
        }
        let reqData7 = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData7 })
        // yield call(COMMUNITY_GET_EVENT_GOING_LIST, { payload: reqData })
        // yield call(COMMUNITY_GET_EVENT_INTERESTED_LIST, { payload: reqData2 })
        // yield call(COMMUNITY_GET_MY_EVENTS_LIST, { payload: reqData3 })
        // yield call(COMMUNITY_GET_PAST_EVENTS_LIST, { payload: reqData4 })
        // yield call(COMMUNITY_GET_EVENT_INVITED_LIST, { payload: reqData5 })
        yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData6 })
      } else {
        notification.success({
          message: response.data['go_status'],
        })
        let reqData = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
      }
    } else if (response.data['api_status'] !== 200) {
      notification.error({
        message: 'Please fill in all details.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_SAVE_EVENT({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communitySaveEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: response.data.go_status,
      })
      if (page === 'suggestedEvents') {
        let reqData = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'events',
          },
        }

        yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
      } else if (page === 'upcomingEvents') {
        let reqData = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'events',
          },
        }
        let reqData2 = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'going',
          },
        }
        let reqData3 = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'invited',
          },
        }

        let reqData4 = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'my_events',
          },
        }

        let reqData5 = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'past',
          },
        }

        let reqData6 = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'saved',
          },
        }

        yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
        yield call(COMMUNITY_GET_EVENT_GOING_LIST, { payload: reqData2 })
        yield call(COMMUNITY_GET_EVENT_INVITED_LIST, { payload: reqData3 })
        yield call(COMMUNITY_GET_MY_EVENTS_LIST, { payload: reqData4 })
        yield call(COMMUNITY_GET_PAST_EVENTS_LIST, { payload: reqData5 })
        yield call(COMMUNITY_GET_SAVED_EVENTS_LIST, { payload: reqData6 })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            // communityMostFollowersUsers: data,
            loading: false,
          },
        })
        let reqData = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_REPORT_EVENT({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityReportEvent, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: response.data.go_status,
      })
      if (page === 'suggestedEvents') {
        let reqData = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'events',
          },
        }

        yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            // communityMostFollowersUsers: data,
            loading: false,
          },
        })
        let reqData = {
          access_token,
          params: {
            event_id: event_id,
          },
        }

        let reqData2 = {
          access_token,
          params: {
            fetch: 'events',
            limit: 10,
            mode: 'replace',
          },
        }

        yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
        yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_REMOVE_FILES_FROM_POST({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityRemoveFilesfromPost, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      notification.success({
        message: 'Files removed',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // communityMostFollowersUsers: data,
          loading: false,
        },
      })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_UPDATE_POST_FEELING({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(ApiUser.communityUpdatePostFeeling, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data.go_status,
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          // communityMostFollowersUsers: data,
          loading: false,
        },
      })
      // let reqData = {
      //   access_token,
      //   params: {
      //     event_id: event_id,
      //   },
      // }

      // yield call(COMMUNITY_GET_EVENT_DATA, { payload: reqData })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_USER_PHOTOS({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
    communityUserPhotos,
  } = payload
  if (mode === 'replace') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUserphotosPostsLoading: true,
      },
    })
  } else if (mode === 'additional') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUserphotosPostsAdditionalLoading: true,
      },
    })
  }

  const response = yield call(ApiUser.communityGetUserPhotos, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data.go_status,
      // })
      let x
      let y
      if (mode === 'replace') {
        x = response.data['data']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserPhotos: x,
            checkEmptyCommunityUserPhotos: y,
            communityGetUserphotosPostsLoading: false,
            // loading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityUserPhotos, ...response.data['data']]
        y = [response.data['data']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserPhotos: x,
            checkEmptyCommunityUserPhotos: y,
            communityGetUserphotosPostsAdditionalLoading: false,
            // loading: false,
          },
        })
      }

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityUserPhotos: response.data,
      //     loading: false,
      //   },
      // })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetUserphotosPostsLoading: false,
          communityGetUserphotosPostsAdditionalLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_USER_VIDEOS({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
    communityUserVideos,
  } = payload
  if (mode === 'replace') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUservideosPostsLoading: true,
      },
    })
  } else if (mode === 'additional') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUservideosAdditionalPostsLoading: true,
      },
    })
  }
  const response = yield call(ApiUser.communityGetUserVideos, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data.go_status,
      // })
      let x
      let y
      if (mode === 'replace') {
        x = response.data['data']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserVideos: x,
            checkEmptyCommunityUserVideos: y,
            communityGetUservideosPostsLoading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityUserVideos, ...response.data['data']]
        y = [response.data['data']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserVideos: x,
            checkEmptyCommunityUserVideos: y,
            communityGetUservideosAdditionalPostsLoading: false,
          },
        })
      }

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityUserPhotos: response.data,
      //     loading: false,
      //   },
      // })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetUservideosPostsLoading: false,
          communityGetUservideosAdditionalPostsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_USER_SOUNDS({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
    communityUserSounds,
  } = payload
  if (mode === 'replace') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUsersoundsPostsLoading: true,
      },
    })
  } else if (mode === 'additional') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUsersoundsAdditionalPostsLoading: true,
      },
    })
  }

  const response = yield call(ApiUser.communityGetUserSounds, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data.go_status,
      // })
      let x
      let y
      if (mode === 'replace') {
        x = response.data['data']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserSounds: x,
            checkEmptyCommunityUserSounds: y,
            communityGetUsersoundsPostsLoading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityUserSounds, ...response.data['data']]
        y = [response.data['data']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserSounds: x,
            checkEmptyCommunityUserSounds: y,
            communityGetUsersoundsAdditionalPostsLoading: false,
          },
        })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetUsersoundsPostsLoading: false,
          communityGetUsersoundsAdditionalPostsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_GET_USER_DOCUMENTS({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { event_id, post_id },
    mode,
    length,
    page,
    communityUserDocuments,
  } = payload
  if (mode === 'replace') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUserdocumentsPostsLoading: true,
      },
    })
  } else if (mode === 'additional') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        communityGetUserdocumentsAdditionalPostsLoading: true,
      },
    })
  }

  const response = yield call(ApiUser.communityGetUserDocuments, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      // notification.success({
      //   message: response.data.go_status,
      // })
      let x
      let y
      if (mode === 'replace') {
        x = response.data['data']
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserDocuments: x,
            checkEmptyCommunityUserDocuments: y,
            communityGetUserdocumentsPostsLoading: false,
          },
        })
      } else if (mode === 'additional') {
        x = [...communityUserDocuments, ...response.data['data']]
        y = [response.data['data']]
        yield put({
          type: 'user/SET_STATE',
          payload: {
            communityUserDocuments: x,
            checkEmptyCommunityUserDocuments: y,
            communityGetUserdocumentsAdditionalPostsLoading: false,
          },
        })
      }

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     communityUserPhotos: response.data,
      //     loading: false,
      //   },
      // })
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityGetUserdocumentsPostsLoading: false,
          communityGetUserdocumentsAdditionalPostsLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_REPORT_COMMENT({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { comment_id },
    mode,
    length,
    page,
    post_id,
    reply_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityReportCommentLoading: true,
    },
  })
  const response = yield call(ApiUser.communityReportComment, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (response.data['code'] === 'report') {
        notification.success({
          message: 'Comment has been reported.',
        })
      } else {
        notification.success({
          message: 'Report cancelled.',
        })
      }

      if (page === 'suggestedEvents') {
        let reqData = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'events',
          },
        }

        // yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
      } else if (mode === 'reply') {
        const reqData = {
          access_token,
          params: {
            type: 'fetch_comments',
            post_id: post_id,
          },
        }
        const reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id: reply_id,
          },
        }
        yield call(FETCH_COMMENTS, { payload: reqData })
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            // communityMostFollowersUsers: data,
            communityReportCommentLoading: false,
          },
        })
        const reqData = {
          access_token,
          params: {
            type: 'fetch_comments',
            post_id: post_id,
          },
        }
        const reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id,
          },
        }
        yield call(FETCH_COMMENTS, { payload: reqData })
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityReportCommentLoading: false,
        },
      })
    }
  }
}

export function* COMMUNITY_REPORT_REPLY({ payload }) {
  // const { user_id } = payload
  const {
    access_token,
    params: { comment_id },
    mode,
    length,
    page,
    post_id,
    reply_id,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      communityReportCommentLoading: true,
    },
  })
  const response = yield call(ApiUser.communityReportReply, payload)
  if (response && response.data) {
    if (response.data['api_status'] === 200) {
      if (response.data['code'] === 'report') {
        notification.success({
          message: 'Reply has been reported.',
        })
      } else {
        notification.success({
          message: 'Report cancelled.',
        })
      }

      if (page === 'suggestedEvents') {
        let reqData = {
          access_token,
          mode: 'replace',
          params: {
            fetch: 'events',
          },
        }

        // yield call(COMMUNITY_GET_EVENTS_LIST, { payload: reqData })
      } else if (mode === 'reply') {
        const reqData = {
          access_token,
          params: {
            type: 'fetch_comments',
            post_id: post_id,
          },
        }
        const reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id: reply_id,
          },
        }
        yield call(FETCH_COMMENTS, { payload: reqData })
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            // communityMostFollowersUsers: data,
            communityReportCommentLoading: false,
          },
        })
        const reqData = {
          access_token,
          params: {
            type: 'fetch_comments',
            post_id: post_id,
          },
        }
        const reqData2 = {
          access_token,
          params: {
            type: 'fetch_comments_reply',
            comment_id,
          },
        }
        yield call(FETCH_COMMENTS, { payload: reqData })
        yield call(FETCH_COMMENTS_REPLY, { payload: reqData2 })
      }
    } else if (response.data['api_status'] !== 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          communityReportCommentLoading: false,
        },
      })
    }
  }
}

export function* LOYALTY_PROGRAM_LISTING({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loyaltyProgramLoading: true,
    },
  })
  try {
    const response = yield call(ApiUser.loyaltyProgramListing, payload)
    if (response.status === 200) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loyaltyProgramLoading: false,
          loyaltyProgramList: response.data,
          loyaltyProgramLoaded: true,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* LOYALTY_PROGRAM_COMPLETE_TASK({ payload }) {
  const { user_id } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loyaltyProgramClaimRewardLoading: true,
    },
  })

  const response = yield call(ApiUser.loyaltyProgramCompleteTask, payload)
  if (response && response.data && !response.data.logout) {
    if (response.data['status'] === 1) {
      yield call(LOYALTY_PROGRAM_LISTING, { payload: payload })
      notification.success({
        message: 'Reward Claimed.',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          data: response,
          loyaltyProgramClaimRewardLoading: false,
        },
      })
    } else {
      notification.error({
        message: response.data['msg'],
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loyaltyProgramClaimRewardLoading: false,
        },
      })
    }
  } else if (response.data.logout) {
    notification.error({
      key: 'logout',
      message: response.data['msg'],
    })
    yield call(LOGOUT)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BRAND_SETTINGS, BRAND_SETTINGS),
    takeEvery(actions.BRAND_SETTINGS_TNC_PRIVACY, BRAND_SETTINGS_TNC_PRIVACY),
    takeEvery(actions.MODULE_SETTINGS, MODULE_SETTINGS),
    takeEvery(actions.SIDEBAR_MODULE_SETTINGS, SIDEBAR_MODULE_SETTINGS),
    takeEvery(actions.SIDEBAR_MODULE_USER_SETTINGS, SIDEBAR_MODULE_USER_SETTINGS),
    takeEvery(actions.SIDEBAR_MODULE_POST_USER_SETTINGS, SIDEBAR_MODULE_POST_USER_SETTINGS),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_ME, LOAD_ME),
    takeEvery(actions.GET_ME, GET_ME),
    // takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SIGNUP_IN, SIGNUP_IN),
    takeEvery(actions.SIGNUP_CO, SIGNUP_CO),
    takeEvery(actions.SIGNUP_DE, SIGNUP_DE),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.LOGIN_HISTORY, LOGIN_HISTORY),
    takeEvery(actions.WALLET_OPERATIONS, WALLET_OPERATIONS),
    takeEvery(actions.WALLET_OPERATIONS_DETAILS, WALLET_OPERATIONS_DETAILS),
    takeEvery(actions.WALLET_OPERATIONS_DETAILS_DATA, WALLET_OPERATIONS_DETAILS_DATA),
    takeEvery(actions.UPDATE_RESIDENCE, UPDATE_RESIDENCE),
    takeEvery(actions.UPDATE_USER_INFO, UPDATE_USER_INFO),
    takeEvery(actions.UPDATE_NEWSLETTER, UPDATE_NEWSLETTER),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    takeEvery(actions.UPDATE_LANGUAGE, UPDATE_LANGUAGE),
    takeEvery(actions.UPGRADE_CORPORATE, UPGRADE_CORPORATE),
    takeEvery(actions.CORPORATE_INFO, CORPORATE_INFO),
    takeEvery(actions.GET_BANK_DETAIL, GET_BANK_DETAIL),
    takeEvery(actions.UPDATE_BANK_DETAIL, UPDATE_BANK_DETAIL),
    takeEvery(actions.FOREVERPAY_BANK, FOREVERPAY_BANK),
    takeEvery(actions.FOREVERPAY_BANK_STATE, FOREVERPAY_BANK_STATE),
    takeEvery(actions.FOREVERPAY_BANK_CITY, FOREVERPAY_BANK_CITY),
    takeEvery(actions.UPDATE_FOREVERPAY_BANK, UPDATE_FOREVERPAY_BANK),
    takeEvery(actions.PAYTRUST_BANK, PAYTRUST_BANK),
    takeEvery(actions.UPDATE_PAYTRUST_BANK, UPDATE_PAYTRUST_BANK),
    takeEvery(actions.UPDATE_FASAPAY, UPDATE_FASAPAY),
    takeEvery(actions.HELPPAY_BANK, HELPPAY_BANK),
    takeEvery(actions.UPDATE_HELPPAY_BANK, UPDATE_HELPPAY_BANK),
    takeEvery(actions.EEZIEPAY_BANK, EEZIEPAY_BANK),
    takeEvery(actions.UPDATE_EEZIEPAY_BANK, UPDATE_EEZIEPAY_BANK),
    takeEvery(actions.EEZIEPAY_INR_BANK, EEZIEPAY_INR_BANK),
    takeEvery(actions.UPDATE_EEZIEPAY_INR_BANK, UPDATE_EEZIEPAY_INR_BANK),
    takeEvery(actions.XPAY_BANK, XPAY_BANK),
    takeEvery(actions.UPDATE_XPAY_BANK, UPDATE_XPAY_BANK),
    takeEvery(actions.ANPAY_BANK, ANPAY_BANK),
    takeEvery(actions.UPDATE_ANPAY_BANK, UPDATE_ANPAY_BANK),
    takeEvery(actions.PAYBNB_BANK, PAYBNB_BANK),
    takeEvery(actions.UPDATE_PAYBNB_BANK, UPDATE_PAYBNB_BANK),
    takeEvery(actions.FIVEPAY_BANK, FIVEPAY_BANK),
    takeEvery(actions.UPDATE_FIVEPAY_BANK, UPDATE_FIVEPAY_BANK),
    takeEvery(actions.DIRECTPAY_BANK, DIRECTPAY_BANK),
    takeEvery(actions.PAPAYA_PAY_BANK, PAPAYA_PAY_BANK),
    takeEvery(actions.UPDATE_PAPAYA_PAY_BANK, UPDATE_PAPAYA_PAY_BANK),
    takeEvery(actions.ADD_WITHDRAWAL, ADD_WITHDRAWAL),
    takeEvery(actions.GET_KYC, GET_KYC),
    takeEvery(actions.UPLOAD_KYC, UPLOAD_KYC),
    takeEvery(actions.HISTORY_KYC, HISTORY_KYC),
    takeEvery(actions.GET_SELF_TRADING, GET_SELF_TRADING),
    takeEvery(actions.GET_SELF_TRADING_DEMO, GET_SELF_TRADING_DEMO),
    takeEvery(actions.GET_SELF_TRADING_FULL, GET_SELF_TRADING_FULL),
    takeEvery(actions.GET_SELF_TRADING_DEMO_FULL, GET_SELF_TRADING_DEMO_FULL),
    takeEvery(actions.GET_AFFILIATE_TREE, GET_AFFILIATE_TREE),
    takeEvery(actions.AFFILIATE_CLIENT_LISTING, AFFILIATE_CLIENT_LISTING),
    takeEvery(actions.AFFILIATE_COMMISSION_HISTORY, AFFILIATE_COMMISSION_HISTORY),
    takeEvery(actions.AFFILIATE_CLIENT_TRADING_TXS, AFFILIATE_CLIENT_TRADING_TXS),
    takeEvery(
      actions.AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS,
      AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS,
    ),
    takeEvery(actions.NETWORK_MT4, NETWORK_MT4),
    takeEvery(actions.DEPOSIT_MT4, DEPOSIT_MT4),
    takeEvery(actions.TRADING_PRODUCT, TRADING_PRODUCT),
    takeEvery(actions.CHANGE_MT4_PASSWORD, CHANGE_MT4_PASSWORD),
    takeEvery(actions.CHANGE_MT4_INVESTOR_PASSWORD, CHANGE_MT4_INVESTOR_PASSWORD),
    takeEvery(actions.SUBMIT_TICKET, SUBMIT_TICKET),
    takeEvery(actions.GET_TICKETS, GET_TICKETS),
    takeEvery(actions.OPEN_MT4, OPEN_MT4),
    takeEvery(actions.MT4_LIVE_INFO, MT4_LIVE_INFO),
    takeEvery(actions.MT4_MARGIN_INFO, MT4_MARGIN_INFO),
    takeEvery(actions.MT4_BALANCE_CREDIT, MT4_BALANCE_CREDIT),
    takeEvery(actions.WITHDRAWAL_MT4, WITHDRAWAL_MT4),
    takeEvery(actions.TICKET_DETAIL, TICKET_DETAIL),
    takeEvery(actions.SUBMIT_TICKET_DETAIL, SUBMIT_TICKET_DETAIL),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.UPLOAD_PROFILE_PIC, UPLOAD_PROFILE_PIC),
    takeEvery(actions.DEPOSIT_COMPANY_CRYPTO_WALLET, DEPOSIT_COMPANY_CRYPTO_WALLET),
    takeEvery(actions.GET_DEPOSIT_HISTORY, GET_DEPOSIT_HISTORY),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.BANK_DEPOSIT, BANK_DEPOSIT),
    takeEvery(actions.NGAN_DEPOSIT, NGAN_DEPOSIT),
    takeEvery(actions.WITHDRAWAL_HISTORY, WITHDRAWAL_HISTORY),
    takeEvery(actions.BALANCE_GRAPH, BALANCE_GRAPH),
    takeEvery(actions.MARKET_NEWS, MARKET_NEWS),
    takeEvery(actions.NEWS_REDIRECT, NEWS_REDIRECT),
    takeEvery(actions.CANCEL_WITHDRAWAL, CANCEL_WITHDRAWAL),
    takeEvery(actions.CURRENCY_RATE, CURRENCY_RATE),
    takeEvery(actions.PSP_CURRENCY_RATE, PSP_CURRENCY_RATE),
    takeEvery(actions.PSP_RATE_FEE, PSP_RATE_FEE),
    takeEvery(actions.CRYPTO_RATE, CRYPTO_RATE),
    takeEvery(actions.GO_LIVE, GO_LIVE),
    takeEvery(actions.CONFIRM_API, CONFIRM_API),
    takeEvery(actions.CANCEL_API, CANCEL_API),
    takeEvery(actions.MASTER_TRADERS, MASTER_TRADERS),
    takeEvery(actions.MASTER_TRADER, MASTER_TRADER),
    takeEvery(actions.UPDATE_TRADER, UPDATE_TRADER),
    takeEvery(actions.UPDATE_TRADER_SELF_TRADING, UPDATE_TRADER_SELF_TRADING),
    takeEvery(actions.UPDATE_TRADER_PHOTO, UPDATE_TRADER_PHOTO),
    takeEvery(actions.MASTER_FOLLOWERS, MASTER_FOLLOWERS),
    takeEvery(actions.MASTER_OPEN_TRADES, MASTER_OPEN_TRADES),
    takeEvery(actions.MASTER_CLOSE_TRADES, MASTER_CLOSE_TRADES),
    takeEvery(actions.FOLLOWING_LIST, FOLLOWING_LIST),
    takeEvery(actions.FOLLOWER_TRADES_OPEN, FOLLOWER_TRADES_OPEN),
    takeEvery(actions.FOLLOWER_TRADES_CLOSE, FOLLOWER_TRADES_CLOSE),
    takeEvery(actions.FOLLOWER_CLOSE_TRADE, FOLLOWER_CLOSE_TRADE),
    takeEvery(actions.WATCHLIST, WATCHLIST),
    takeEvery(actions.UPDATE_WATCHLIST, UPDATE_WATCHLIST),
    takeEvery(actions.PAUSE_COPY, PAUSE_COPY),
    takeEvery(actions.RESUME_COPY, RESUME_COPY),
    takeEvery(actions.STOP_COPY, STOP_COPY),
    takeEvery(actions.START_COPY, START_COPY),
    takeEvery(actions.MASTER_SYMBOLS, MASTER_SYMBOLS),
    takeEvery(actions.UPDATE_COPY_SETTINGS, UPDATE_COPY_SETTINGS),
    takeEvery(actions.GET_COPY_SETTINGS, GET_COPY_SETTINGS),
    takeEvery(actions.MT4_STATS, MT4_STATS),
    takeEvery(actions.MT4_PROFITS_CHART, MT4_PROFITS_CHART),
    takeEvery(actions.MT4_EQUITY_BALANCE_CHART_7D, MT4_EQUITY_BALANCE_CHART_7D),
    takeEvery(actions.MT4_EQUITY_BALANCE_CHART_1M, MT4_EQUITY_BALANCE_CHART_1M),
    takeEvery(actions.MT4_EQUITY_BALANCE_CHART_6M, MT4_EQUITY_BALANCE_CHART_6M),
    takeEvery(actions.MT4_EQUITY_BALANCE_CHART_1Y, MT4_EQUITY_BALANCE_CHART_1Y),
    takeEvery(actions.MT4_MONTHLY_PL, MT4_MONTHLY_PL),
    takeEvery(actions.MT4_SKILLED_SYMBOLS_CHART, MT4_SKILLED_SYMBOLS_CHART),
    takeEvery(actions.MT4_MOST_TRADED_CHART, MT4_MOST_TRADED_CHART),
    takeEvery(actions.MT4_HOLDING_TIME_CHART, MT4_HOLDING_TIME_CHART),
    takeEvery(actions.PAYMENT_METHOD, PAYMENT_METHOD),
    takeEvery(actions.PENDING_WITHDRAWALS_BY_MT4, PENDING_WITHDRAWALS_BY_MT4),
    takeEvery(actions.MONEY_MANAGER_MT4, MONEY_MANAGER_MT4),
    takeEvery(actions.MONEY_MANAGERS, MONEY_MANAGERS),
    takeEvery(actions.MONEY_MANAGER, MONEY_MANAGER),
    takeEvery(actions.UPDATE_MONEY_MANAGER, UPDATE_MONEY_MANAGER),
    takeEvery(actions.UPDATE_MONEY_MANAGER_SETTING, UPDATE_MONEY_MANAGER_SETTING),
    takeEvery(actions.UPDATE_MONEY_MANAGER_PHOTO, UPDATE_MONEY_MANAGER_PHOTO),
    takeEvery(actions.INVEST_MONEY_MANAGER, INVEST_MONEY_MANAGER),
    takeEvery(actions.WITHDRAW_MONEY_MANAGER, WITHDRAW_MONEY_MANAGER),
    takeEvery(actions.INVESTING, INVESTING),
    takeEvery(actions.INVESTOR_DEPOSITS, INVESTOR_DEPOSITS),
    takeEvery(actions.INVESTOR_DEPOSIT_ACTION, INVESTOR_DEPOSIT_ACTION),
    takeEvery(actions.INVESTOR_WITHDRAWALS, INVESTOR_WITHDRAWALS),
    takeEvery(actions.INVESTOR_WITHDRAWAL_ACTION, INVESTOR_WITHDRAWAL_ACTION),
    takeEvery(actions.INVESTOR_SL, INVESTOR_SL),
    takeEvery(actions.INVESTOR_DETACH, INVESTOR_DETACH),
    takeEvery(actions.MONEY_MANAGER_INVESTORS, MONEY_MANAGER_INVESTORS),
    takeEvery(actions.MONEY_MANAGER_TRADES_OPEN, MONEY_MANAGER_TRADES_OPEN),
    takeEvery(actions.MONEY_MANAGER_TRADES_CLOSE, MONEY_MANAGER_TRADES_CLOSE),
    takeEvery(actions.LIQUIDITY_POOL_TNC, LIQUIDITY_POOL_TNC),
    takeEvery(actions.LIQUIDITY_POOL_WALLET_DATA, LIQUIDITY_POOL_WALLET_DATA),
    takeEvery(actions.LIQUIDITY_POOL_WALLET_HISTORY, LIQUIDITY_POOL_WALLET_HISTORY),
    takeEvery(actions.LIQUIDITY_POOL_COMPANY_WALLET_ADDRESS, LIQUIDITY_POOL_COMPANY_WALLET_ADDRESS),
    takeEvery(actions.LIQUIDITY_POOL_DEPOSIT, LIQUIDITY_POOL_DEPOSIT),
    takeEvery(actions.LIQUIDITY_POOL_WITHDRAW, LIQUIDITY_POOL_WITHDRAW),
    takeEvery(actions.LIQUIDITY_POOL_STAKE, LIQUIDITY_POOL_STAKE),
    takeEvery(actions.LIQUIDITY_POOL_STAKE_DATA, LIQUIDITY_POOL_STAKE_DATA),
    takeEvery(actions.LIQUIDITY_POOL_STAKE_HISTORY, LIQUIDITY_POOL_STAKE_HISTORY),
    takeEvery(actions.LIQUIDITY_POOL_STAKE_WITHDRAW, LIQUIDITY_POOL_STAKE_WITHDRAW),
    takeEvery(actions.TASK_CENTRE_TASK_LISTING, TASK_CENTRE_TASK_LISTING),
    takeEvery(actions.TASK_CENTRE_CLAIM_TASK, TASK_CENTRE_CLAIM_TASK),
    takeEvery(actions.TASK_CENTRE_COMPLETE_TASK, TASK_CENTRE_COMPLETE_TASK),
    takeEvery(actions.TASK_CENTRE_FORFEIT_TASK, TASK_CENTRE_FORFEIT_TASK),
    takeEvery(actions.TASK_CENTRE_CLOSE_SUCCESSFUL_MODAL, TASK_CENTRE_CLOSE_SUCCESSFUL_MODAL),
    takeEvery(actions.REWARD_CENTRE_REWARD_LISTING, REWARD_CENTRE_REWARD_LISTING),
    takeEvery(actions.REWARD_CENTRE_REDEEM_VOUCHER, REWARD_CENTRE_REDEEM_VOUCHER),
    takeEvery(actions.REWARD_CENTRE_REDEEM_VOUCHER_CODE, REWARD_CENTRE_REDEEM_VOUCHER_CODE),
    takeEvery(actions.REWARD_CENTRE_CLOSE_SUCCESSFUL_MODAL, REWARD_CENTRE_CLOSE_SUCCESSFUL_MODAL),
    takeEvery(
      actions.REWARD_CENTRE_CLOSE_CASH_SUCCESS_MODAL,
      REWARD_CENTRE_CLOSE_CASH_SUCCESS_MODAL,
    ),
    takeEvery(
      actions.REWARD_CENTRE_CLOSE_LUCKY_DRAW_SUCCESS_MODAL,
      REWARD_CENTRE_CLOSE_LUCKY_DRAW_SUCCESS_MODAL,
    ),
    takeEvery(actions.REWARD_CENTRE_CHOOSE_PRIZE, REWARD_CENTRE_CHOOSE_PRIZE),
    takeEvery(actions.HWGC_STATUS, HWGC_STATUS),
    takeEvery(actions.HWGC_SIGN_UP, HWGC_SIGN_UP),
    takeEvery(actions.HWGC_RATE, HWGC_RATE),
    takeEvery(actions.HWGC_BANK_WIRE, HWGC_BANK_WIRE),
    takeEvery(actions.HWGC_BANK_ACCOUNT, HWGC_BANK_ACCOUNT),
    takeEvery(actions.HWGC_UPDATE_BANK, HWGC_UPDATE_BANK),
    takeEvery(actions.HWGC_CARD, HWGC_CARD),
    takeEvery(actions.HWGC_APPLY_CARD, HWGC_APPLY_CARD),
    takeEvery(actions.HWGC_BIND_CARD, HWGC_BIND_CARD),
    takeEvery(actions.HWGC_UPDATE_EMAIL, HWGC_UPDATE_EMAIL),
    takeEvery(actions.HWGC_UPDATE_MOBILE, HWGC_UPDATE_MOBILE),
    takeEvery(actions.SPREAD_GROUP_SYMBOL_PRICE, SPREAD_GROUP_SYMBOL_PRICE),
    takeEvery(actions.SPREAD_GROUP_SYMBOL_PRICE_CTRADER, SPREAD_GROUP_SYMBOL_PRICE_CTRADER),
    takeEvery(actions.SYMBOL_VIEWS, SYMBOL_VIEWS),
    takeEvery(actions.SYMBOL_UPDATE_VIEWS, SYMBOL_UPDATE_VIEWS),
    takeEvery(actions.SYMBOL_DETAILS, SYMBOL_DETAILS),
    takeEvery(actions.SYMBOL_CATEGORY, SYMBOL_CATEGORY),
    takeEvery(actions.OHLC, OHLC),
    takeEvery(actions.MODULE_MARKETS_DATA, MODULE_MARKETS_DATA),
    takeEvery(actions.TRADE_SYMBOL, TRADE_SYMBOL),
    takeEvery(actions.FAVOURITE_SYMBOL, FAVOURITE_SYMBOL),
    takeEvery(actions.ADD_FAVOURITE_SYMBOL, ADD_FAVOURITE_SYMBOL),
    takeEvery(actions.REMOVE_FAVOURITE_SYMBOL, REMOVE_FAVOURITE_SYMBOL),
    takeEvery(actions.MARKETS_OPEN_TRADES, MARKETS_OPEN_TRADES),
    takeEvery(actions.MARKETS_TRADES, MARKETS_TRADES),
    takeEvery(actions.MARKETS_TRADE_OPEN, MARKETS_TRADE_OPEN),
    takeEvery(actions.MARKETS_TRADE_CLOSE, MARKETS_TRADE_CLOSE),
    takeEvery(actions.MARKETS_TRADE_CLOSE_SYMBOL, MARKETS_TRADE_CLOSE_SYMBOL),
    takeEvery(actions.MARKETS_TRADE_CLOSE_BY, MARKETS_TRADE_CLOSE_BY),
    takeEvery(actions.MARKETS_TRADE_MODIFY, MARKETS_TRADE_MODIFY),
    takeEvery(actions.MARKETS_TRADE_DELETE_PENDING, MARKETS_TRADE_DELETE_PENDING),
    takeEvery(actions.MARKETS_JOURNAL, MARKETS_JOURNAL),
    takeEvery(actions.TRADE_IDEAS, TRADE_IDEAS),
    takeEvery(actions.SMART_SCORE, SMART_SCORE),
    takeEvery(actions.AI_TECH_SCREENER, AI_TECH_SCREENER),
    takeEvery(actions.AI_TECH_SCREENER_EVENT_TYPES, AI_TECH_SCREENER_EVENT_TYPES),
    takeEvery(actions.AI_TECH_SCREENER_EVENTS, AI_TECH_SCREENER_EVENTS),
    takeEvery(actions.AI_TECH_SCREENER_EVENT_COUNTS, AI_TECH_SCREENER_EVENT_COUNTS),
    takeEvery(actions.AI_TECH_SCREENER_TECHNICAL_SUMMARIES, AI_TECH_SCREENER_TECHNICAL_SUMMARIES),
    takeEvery(actions.AI_TECH_SCREENER_SUPPORT_RESISTANCE, AI_TECH_SCREENER_SUPPORT_RESISTANCE),
    takeEvery(actions.AI_TECH_SCREENER_STOP_PRICE, AI_TECH_SCREENER_STOP_PRICE),
    takeEvery(actions.ECONOMIC_CALENDAR, ECONOMIC_CALENDAR),
    takeEvery(actions.ECONOMIC_CALENDAR_EVENT_CHART, ECONOMIC_CALENDAR_EVENT_CHART),
    takeEvery(actions.ECONOMIC_CALENDAR_CURRENCY_SYMBOL, ECONOMIC_CALENDAR_CURRENCY_SYMBOL),
    takeEvery(actions.ECONOMIC_CALENDAR_VOLATILITY, ECONOMIC_CALENDAR_VOLATILITY),
    takeEvery(actions.ECONOMIC_CALENDAR_IMPACT, ECONOMIC_CALENDAR_IMPACT),
    takeEvery(actions.RSS_NEWS, RSS_NEWS),
    takeEvery(actions.PROMO_MATERIALS_MODAL, PROMO_MATERIALS_MODAL),
    takeEvery(actions.PROMO_MATERIALS_COUNTER, PROMO_MATERIALS_COUNTER),
    takeEvery(actions.PROMO_MATERIALS_DASHBOARD_BANNER, PROMO_MATERIALS_DASHBOARD_BANNER),
    takeEvery(actions.PROMO_MATERIALS_DASHBOARD_VIDEO, PROMO_MATERIALS_DASHBOARD_VIDEO),
    takeEvery(actions.PROMO_MATERIALS_DASHBOARD_LANDING, PROMO_MATERIALS_DASHBOARD_LANDING),
    takeEvery(actions.PROMO_MATERIALS_DASHBOARD_GIF, PROMO_MATERIALS_DASHBOARD_GIF),
    takeEvery(actions.PROMO_MATERIALS_FILTER_BANNER, PROMO_MATERIALS_FILTER_BANNER),
    takeEvery(actions.PROMO_MATERIALS_FILTER_VIDEO, PROMO_MATERIALS_FILTER_VIDEO),
    takeEvery(actions.PROMO_MATERIALS_FILTER_LANDING, PROMO_MATERIALS_FILTER_LANDING),
    takeEvery(actions.PROMO_MATERIALS_FILTER_GIF, PROMO_MATERIALS_FILTER_GIF),
    takeEvery(actions.PROMO_MATERIALS_FILTER_LOGO, PROMO_MATERIALS_FILTER_LOGO),
    takeEvery(actions.LIVE_BROADCAST, LIVE_BROADCAST),
    takeEvery(actions.CMS_INSTRUMENTS_CATEGORIES, CMS_INSTRUMENTS_CATEGORIES),
    takeEvery(actions.CMS_INSTRUMENTS, CMS_INSTRUMENTS),
    takeEvery(actions.CMS_EDUCATION_CATEGORIES_OLD, CMS_EDUCATION_CATEGORIES_OLD),
    takeEvery(actions.CMS_EDUCATION_CATEGORIES, CMS_EDUCATION_CATEGORIES),
    takeEvery(actions.GET_PROMOTION, GET_PROMOTION),
    takeEvery(actions.GET_PROMOTION_NEW, GET_PROMOTION_NEW),
    takeEvery(actions.COMMUNITY_AUTH, COMMUNITY_AUTH),
    takeEvery(actions.COMMUNITY_GET_USER_DATA, COMMUNITY_GET_USER_DATA),
    takeEvery(actions.COMMUNITY_POSTS, COMMUNITY_POSTS),
    takeEvery(actions.POST_COMMUNITY_POST, POST_COMMUNITY_POST),
    takeEvery(actions.POST_COMMENT, POST_COMMENT),
    takeEvery(actions.POST_REPLY, POST_REPLY),
    takeEvery(actions.FETCH_COMMENTS, FETCH_COMMENTS),
    takeEvery(actions.FETCH_COMMENTS_REPLY, FETCH_COMMENTS_REPLY),
    takeEvery(actions.COMMUNITY_GET_POST_DATA, COMMUNITY_GET_POST_DATA),
    takeEvery(actions.POST_LIKE, POST_LIKE),
    takeEvery(actions.COMMUNITY_CREATE_ALBUM, COMMUNITY_CREATE_ALBUM),
    takeEvery(actions.COMMUNIITY_GET_RECOMMENDED, COMMUNIITY_GET_RECOMMENDED),
    takeEvery(actions.COMMUNITY_GET_FOLLOWERS_LIST, COMMUNITY_GET_FOLLOWERS_LIST),
    takeEvery(actions.COMMUNITY_GET_FOLLOWING_LIST, COMMUNITY_GET_FOLLOWING_LIST),
    takeEvery(actions.COMMUNITY_GET_USER_POSTS, COMMUNITY_GET_USER_POSTS),
    takeEvery(actions.COMMUNITY_GET_GROUP_DATA, COMMUNITY_GET_GROUP_DATA),
    takeEvery(actions.COMMUNITY_EDIT_POST, COMMUNITY_EDIT_POST),
    takeEvery(actions.COMMUNITY_DELETE_POST, COMMUNITY_DELETE_POST),
    takeEvery(actions.COMMUNITY_DISABLE_POST_COMMENTS, COMMUNITY_DISABLE_POST_COMMENTS),
    takeEvery(actions.COMMUNITY_SAVE_POST, COMMUNITY_SAVE_POST),
    takeEvery(actions.COMMUNITY_REPORT_POST, COMMUNITY_REPORT_POST),
    // takeEvery(actions.COMMUNITY_PHOTO_POST_FILTER, COMMUNITY_PHOTO_POST_FILTER),
    // takeEvery(actions.COMMUNITY_VIDEO_POST_FILTER, COMMUNITY_VIDEO_POST_FILTER),
    takeEvery(actions.COMMUNITY_FOLLOW_USER, COMMUNITY_FOLLOW_USER),
    takeEvery(actions.COMMUNITY_GET_ADDITIONAL_POSTS, COMMUNITY_GET_ADDITIONAL_POSTS),
    takeEvery(actions.COMMUNITY_GET_RSS_NEWS, COMMUNITY_GET_RSS_NEWS),
    takeEvery(actions.COMMUNITY_SHARE_POST, COMMUNITY_SHARE_POST),
    takeEvery(actions.COMMUNITY_HIDE_POST, COMMUNITY_HIDE_POST),
    takeEvery(actions.COMMUNITY_GET_TRENDING_HASHTAG, COMMUNITY_GET_TRENDING_HASHTAG),
    takeEvery(actions.COMMUNITY_LIKE_COMMENT, COMMUNITY_LIKE_COMMENT),
    takeEvery(actions.COMMUNITY_GET_EVENTS_LIST, COMMUNITY_GET_EVENTS_LIST),
    takeEvery(actions.COMMUNITY_JOIN_EVENT, COMMUNITY_JOIN_EVENT),
    takeEvery(actions.COMMUNITY_GET_EVENT_GOING_LIST, COMMUNITY_GET_EVENT_GOING_LIST),
    takeEvery(actions.COMMUNITY_GET_EVENT_INVITED_LIST, COMMUNITY_GET_EVENT_INVITED_LIST),
    takeEvery(actions.COMMUNITY_GET_EVENT_INTERESTED_LIST, COMMUNITY_GET_EVENT_INTERESTED_LIST),
    takeEvery(actions.COMMUNITY_GET_MY_EVENTS_LIST, COMMUNITY_GET_MY_EVENTS_LIST),
    takeEvery(actions.COMMUNITY_GET_PAST_EVENTS_LIST, COMMUNITY_GET_PAST_EVENTS_LIST),
    takeEvery(actions.COMMUNITY_CREATE_EVENT, COMMUNITY_CREATE_EVENT),
    takeEvery(actions.COMMUNITY_GET_EVENT_DATA, COMMUNITY_GET_EVENT_DATA),
    takeEvery(actions.COMMUNITY_PIN_POST, COMMUNITY_PIN_POST),
    takeEvery(actions.COMMUNITY_GET_EVENT_POSTS, COMMUNITY_GET_EVENT_POSTS),
    takeEvery(actions.COMMUNITY_INVITE_TO_EVENT, COMMUNITY_INVITE_TO_EVENT),
    takeEvery(actions.COMMUNITY_GET_MY_EVENTS_CUSTOM_LIST, COMMUNITY_GET_MY_EVENTS_CUSTOM_LIST),
    takeEvery(actions.COMMUNITY_SEARCH_ALL, COMMUNITY_SEARCH_ALL),
    takeEvery(actions.COMMUNITY_SEARCH_USERS, COMMUNITY_SEARCH_USERS),
    takeEvery(actions.COMMUNITY_UPDATE_EVENT, COMMUNITY_UPDATE_EVENT),
    takeEvery(actions.COMMUNITY_GOING_TO_EVENT, COMMUNITY_GOING_TO_EVENT),
    takeEvery(actions.COMMUNITY_INTERESTED_IN_EVENT, COMMUNITY_INTERESTED_IN_EVENT),
    takeEvery(actions.COMMUNITY_SEARCH_EVENTS_LIST, COMMUNITY_SEARCH_EVENTS_LIST),
    takeEvery(actions.COMMUNITY_GET_MOST_FOLLOWER_USERS, COMMUNITY_GET_MOST_FOLLOWER_USERS),
    takeEvery(actions.COMMUNITY_SEARCH_TYPE, COMMUNITY_SEARCH_TYPE),
    takeEvery(actions.COMMUNITY_DELETE_EVENT, COMMUNITY_DELETE_EVENT),
    takeEvery(actions.COMMUNITY_GET_TERMS_AND_CONDITIONS, COMMUNITY_GET_TERMS_AND_CONDITIONS),
    takeEvery(actions.COMMUNITY_EVENT_ACTIONS, COMMUNITY_EVENT_ACTIONS),
    takeEvery(actions.COMMUNITY_SAVE_EVENT, COMMUNITY_SAVE_EVENT),
    takeEvery(actions.COMMUNITY_REMOVE_FILES_FROM_POST, COMMUNITY_REMOVE_FILES_FROM_POST),
    takeEvery(actions.COMMUNITY_REPORT_EVENT, COMMUNITY_REPORT_EVENT),
    takeEvery(actions.COMMUNITY_UPDATE_POST_FEELING, COMMUNITY_UPDATE_POST_FEELING),
    takeEvery(actions.COMMUNITY_GET_SAVED_EVENTS_LIST, COMMUNITY_GET_SAVED_EVENTS_LIST),
    takeEvery(actions.COMMUNITY_GET_USER_PHOTOS, COMMUNITY_GET_USER_PHOTOS),
    takeEvery(actions.COMMUNITY_GET_USER_VIDEOS, COMMUNITY_GET_USER_VIDEOS),
    takeEvery(actions.COMMUNITY_GET_USER_SOUNDS, COMMUNITY_GET_USER_SOUNDS),
    takeEvery(actions.COMMUNITY_GET_USER_DOCUMENTS, COMMUNITY_GET_USER_DOCUMENTS),
    takeEvery(actions.COMMUNITY_REPORT_COMMENT, COMMUNITY_REPORT_COMMENT),
    takeEvery(actions.COMMUNITY_REPORT_REPLY, COMMUNITY_REPORT_REPLY),
    takeEvery(actions.LOYALTY_PROGRAM_LISTING, LOYALTY_PROGRAM_LISTING),
    takeEvery(actions.LOYALTY_PROGRAM_COMPLETE_TASK, LOYALTY_PROGRAM_COMPLETE_TASK),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
