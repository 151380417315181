import antdData from 'antd/lib/locale-provider/vi_VN'
import localeData from 'react-intl/locale-data/vi'
import messages from './lang/vi-VN.json'

export default {
  locale: 'vi-VN',
  antdData,
  localeData,
  messages: messages["vi-VN"],
}
