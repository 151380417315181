import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import languageCode from '../../../shared-crm/data/languageCode'

@injectIntl
@withRouter
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
class RegisterLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
    affiliate: '',
  }

  componentDidMount() {
    const { dispatch } = this.props
    const url = this.props.location.search
    const params = queryString.parse(url)
    if (params && params['affiliate']) {
      this.setState({
        affiliate: params['affiliate'],
      })
    }
    dispatch({
      type: 'user/BRAND_SETTINGS',
    })

    if (params && params['language']) {
      let lang = params['language']
      if (lang.length > 2) {
        lang = `${lang.split('-')[0]}-${lang.split('-')[1].toUpperCase()}`
      }
      if (!Object.keys(languageCode).includes(lang)) {
        lang = 'en-US'
      }
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: lang,
        },
      })
    }

    if (params && params['lg']) {
      let lg = params['lg']
      let lgCodeCRM = Object.keys(languageCode).find((key) => languageCode[key] === lg)
      if (!lgCodeCRM) {
        lgCodeCRM = 'en-US'
      }
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: lgCodeCRM,
        },
      })
    }
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const {
      children,
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props
    const { affiliate } = this.state

    return (
      <Layout>
        <Layout.Content>
          <div
            id="register_layout"
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <div className="login-background-container">
              <div className="header">
                <nav className="navigation">
                  <ul className="navigationItems">
                    <li>
                      <Link
                        to={affiliate ? `/user/login/?affiliate=${affiliate}` : '/user/login'}
                        className={`navigationActive linkText`}
                      >
                        {formatMessage({ id: 'ur.login' })}
                      </Link>
                    </li>
                    <li>
                      <a
                        className="linkText"
                        href={
                          brandSettings && brandSettings.company_website_about_page
                            ? brandSettings.company_website_about_page
                            : ''
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage({ id: 'ur.about_us' })}
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkText"
                        href={
                          brandSettings && brandSettings.company_website_support_page
                            ? brandSettings.company_website_support_page
                            : ''
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage({ id: 'topBar.support' })}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div>{children}</div>
              {/* <div className="login-content">{children}</div> */}
              <div className="footer">
                <ul className="list-unstyled list-inline m-0">
                  <li className="list-inline-item">
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_term_of_use_link
                          ? brandSettings.company_term_of_use_link
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'common.terms_of_use' })}
                    </a>
                  </li>
                  <li className="active list-inline-item">
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_privacy_policy_link
                          ? brandSettings.company_privacy_policy_link
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'common.privacy_policy' })}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_return_refund_policy_link
                          ? brandSettings.company_return_refund_policy_link
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'common.refund_n_cancellation_policy' })}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_risk_disclosure_link
                          ? brandSettings.company_risk_disclosure_link
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'ur.rd' })}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_term_of_business_link
                          ? brandSettings.company_term_of_business_link
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'ur.tob' })}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default RegisterLayout
