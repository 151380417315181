// import antdData from 'antd/lib/locale-provider/ms_MY'
import localeData from 'react-intl/locale-data/id'
import messages from './lang/id-ID.json'

export default {
  locale: 'id-ID',
  antdData: null,
  localeData,
  messages: messages['id-ID'],
}
