import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout, Button, Modal, Input, Form, DatePicker, Switch, Icon } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _, { findLastKey } from 'lodash'
import { arrayMoveImmutable } from 'array-move'
import { FormattedMessage, injectIntl } from 'react-intl'
import modules from 'shared-crm/constants/modules'
import ApiConfig from 'api/config'
import external from 'shared-crm/constants/external'
import Avatar from 'shared-crm/components/CleanUIComponents/Avatar'
import styles from './style.module.scss'
import images from 'shared-crm/constants/images'
import { EditNavigation1 } from '../../EditNavigation'
import { SettingsModal1 } from '../../SettingsModal'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isTabletView: settings.isTabletView,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})

@Form.create()
@withRouter
@connect(mapStateToProps)
@injectIntl
class MenuLeft1 extends React.Component {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
    visible: false,
    brandSettings: {},
    isCopyTradingShown: false,
    isMAMPAMMShown: false,
    isLiqPoolShown: false,
    moreModalVisible: false,
    editNavigationModalVisible: false,
    settingsModalVisible: false,
    isMenuLeftShow: true,
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  componentDidMount() {
    const { location } = this.props
    const { isMenuLeftShow } = this.state
    if (location.pathname === '/markets' && isMenuLeftShow) {
      this.toggleMenuLeft()
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    if (!_.isEqual(newProps.user.brandSettings, this.state.brandSettings)) {
      this.setState({
        brandSettings: newProps.user.brandSettings,
        isCopyTradingShown:
          newProps.user.brandSettings.module && newProps.user.brandSettings.module['Copy Trading']
            ? true
            : false,
        isMAMPAMMShown:
          newProps.user.brandSettings.module && newProps.user.brandSettings.module['MAM/PAMM']
            ? true
            : false,
        isLiqPoolShown:
          newProps.user.brandSettings.module && newProps.user.brandSettings.module['Liquidity Pool']
            ? true
            : false,
      })
      this.setSelectedKeys(newProps)
    }
    if (newProps.location.pathname !== this.props.location.pathname) {
      this.setSelectedKeys(newProps)
      if (newProps.location.pathname === '/markets' && this.state.isMenuLeftShow) {
        this.toggleMenuLeft()
      }
    }
  }

  toggleMenuLeft = () => {
    const { dispatch, isMobileView, isMobileMenuOpen } = this.props
    const { isMenuLeftShow } = this.state
    const menuLeft = document.getElementById('menuLeft')
    if (isMobileView) {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileMenuOpen',
          value: !isMobileMenuOpen,
        },
      })
    } else {
      menuLeft.style.marginLeft = isMenuLeftShow ? '-256px' : '0px'
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: isMenuLeftShow,
        },
      })
    }
    this.setState({ isMenuLeftShow: !isMenuLeftShow })
  }

  redirectToVerification = () => {
    this.props.history.push('/dashboard/Verification')
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleGoLiveCancel = () => {
    this.setState({
      visible: false,
    })
  }

  handleGoLiveOk = (event) => {
    event.preventDefault()
    const { form, dispatch, user } = this.props
    form.validateFields((error, fieldsValue) => {
      if (!error) {
        const values = {
          ...fieldsValue,
          dob: fieldsValue['dob'].format('YYYY-MM-DD'),
          user_id: user['id'],
        }
        dispatch({
          type: 'user/GO_LIVE',
          payload: values,
        })
        this.setState({
          visible: false,
        })
      }
    })
    // form.resetFields()
  }

  setSelectedKeys = (props) => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = this.convertTextToLowercase(this.getModuleName(props.location.pathname))
    this.setState({
      selectedKeys: selectedItem ? [selectedItem] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = (openedKeys) => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = (e) => {
    const { dispatch, isSettingsOpen, isMobileMenuOpen } = this.props
    if (e.key === 'more') {
      this.showMoreModal()
      this.setState({
        selectedKeys: [e.key],
        // openKeys: e.keyPath,
      })
      return
    } else {
      store.set('app.menu.selectedKeys', [e.key])

      // custom action on settings menu item
      if (e.key === 'settings') {
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'isSettingsOpen',
            value: !isSettingsOpen,
          },
        })
        return
      }

      this.setState({
        selectedKeys: [e.key],
        // openKeys: e.keyPath,
      })
    }
  }

  changeTheme = (checked) => {
    const { dispatch } = this.props
    let isLightTheme, themeSelected
    if (checked) {
      isLightTheme = true
      themeSelected = 'light'
    } else {
      isLightTheme = false
      themeSelected = 'dark'
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: isLightTheme,
      },
    })
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: {
        user_id: this.props.user.id,
        theme: themeSelected,
      },
    })
  }

  showEditNavigationModal = () => {
    this.setState({
      moreModalVisible: false,
      editNavigationModalVisible: true,
    })
  }

  showMoreModal = () => {
    this.setState({
      moreModalVisible: true,
      editNavigationModalVisible: false,
    })
  }

  showSettingsModal = () => {
    this.setState({
      settingsModalVisible: true,
    })
  }

  handleSettingsOk = (event) => {
    event.preventDefault()
    this.setState({
      settingsModalVisible: false,
    })
  }

  handleSettingsCancel = () => {
    this.setState({
      settingsModalVisible: false,
    })
  }

  toggleModalVisible = (event) => {
    this.setState({
      selectedKeys: [],
      moreModalVisible: false,
      editNavigationModalVisible: false,
    })
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }

  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  generateMoreItem = (sideBarModulesUserSettings) => {
    const {
      isMobileView,
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props
    const MAX_MENU_ITEM = 5
    let otherModules = sideBarModulesUserSettings.filter(
      (item) => !item.web_default || item.web_default > MAX_MENU_ITEM,
    )

    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]

    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }

    return (
      <div id="moreModal" className="col">
        <div className="row content">
          <div className="col">
            {groupTypes.map((group, index) => {
              return (
                <div key={index}>
                  <div className="row moreHeaderItem">
                    <span>
                      {formatMessage({
                        id: 'module.group_type.' + this.convertTextToLowercase(group),
                      })}
                    </span>
                  </div>
                  {otherModules
                    .filter(
                      (item) =>
                        item.group_type === group &&
                        item.enabled === 1 &&
                        item.sub_modules.length === 0,
                    )
                    .map((module, index) => {
                      return external[this.convertTextToLowercase(module.name)] ? (
                        <a
                          href={
                            ApiConfig.websiteURL +
                            external[this.convertTextToLowercase(module.name)]
                          }
                          onClick={() => {
                            this.setState({ moreModalVisible: false })
                          }}
                          key={index}
                          className="row moreItem"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </a>
                      ) : (
                        <Link
                          onClick={() => {
                            this.setState({ moreModalVisible: false })
                            if (isMobileView) {
                              this.toggleMenuLeft()
                            }
                          }}
                          key={index}
                          className="row moreItem"
                          to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </Link>
                      )
                    })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="ant-dropdown-menu-item-divider divider"></div>
        <div className="row footer">
          <a
            onClick={() => {
              this.setState({ moreModalVisible: false, editNavigationModalVisible: true })
            }}
            className="editNavigationButton"
          >
            <span>
              {formatMessage({
                id: 'menuleft.editnavigation.edit_navigation',
              })}
            </span>
          </a>
        </div>
      </div>
    )
  }

  generateMenuItems = (sideBarModulesUserSettings) => {
    const {
      dispatch,
      isMobileMenuOpen,
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props

    const MAX_MENU_ITEM = 5
    let res = sideBarModulesUserSettings.filter(
      (item) => item.web_default && item.web_default <= MAX_MENU_ITEM,
    )

    let tempArray = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )
    if (tempArray.length !== 0 && tempArray !== undefined && tempArray !== null) {
      tempArray.unshift({ name: 'Home', sub_modules: [], enabled: true })
      tempArray.push({ name: 'More', sub_modules: [], enabled: true })
    }

    const generateItem = (item) => {
      const { name, enabled } = item
      if (name === 'More') {
        return (
          <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
            <a onClick={this.showMoreModal}>
              <span>
                {formatMessage({
                  id: 'module.' + this.convertTextToLowercase(name),
                })}
              </span>
            </a>
          </Menu.Item>
        )
      }

      return external[this.convertTextToLowercase(name)] !== undefined ? (
        <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
          <a
            href={ApiConfig.websiteURL + external[this.convertTextToLowercase(name)]}
            onClick={() => {
              dispatch({
                type: 'settings/CHANGE_SETTING',
                payload: {
                  setting: 'isMobileMenuOpen',
                  value: !isMobileMenuOpen,
                },
              })
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(name),
              })}
            </span>
          </a>
        </Menu.Item>
      ) : (
        <Menu.Item key={this.convertTextToLowercase(name)} disabled={!enabled}>
          <Link
            onClick={() => {
              dispatch({
                type: 'settings/CHANGE_SETTING',
                payload: {
                  setting: 'isMobileMenuOpen',
                  value: !isMobileMenuOpen,
                },
              })
            }}
            to={this.getPathName(name) ? this.getPathName(name) : '/'}
          >
            <span>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(name),
              })}
            </span>
          </Link>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        return generateItem(menuItem)
      })

    // return menuData.map((menuItem) => {
    return tempArray.map((menuItem) => {
      if (menuItem.sub_modules.length !== 0) {
        const subMenuTitle = (
          <span key={this.convertTextToLowercase(menuItem.name)}>
            <span className={styles.title}>
              {formatMessage({
                id: 'module.' + this.convertTextToLowercase(menuItem.name),
              })}
            </span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.name}>
            {generateSubmenu(menuItem.sub_modules)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const {
      selectedKeys,
      openedKeys,
      visible,
      moreModalVisible,
      editNavigationModalVisible,
      settingsModalVisible,
    } = this.state
    const {
      isMobileView,
      isTabletView,
      isLightTheme,
      user: { user, loading, brandSettings, sideBarModulesUserSettings },
      intl: { formatMessage },
      form,
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems(sideBarModulesUserSettings)
    const moreModal = this.generateMoreItem(sideBarModulesUserSettings)
    const pathName = window.location.href
    let isPathCommunity
    let tabletLandscape

    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    if (global.window.innerWidth >= 1024 && global.window.screen.width < 1366) {
      tabletLandscape = true
    } else tabletLandscape = false
    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
        id="menuLeft"
      >
        <Scrollbars className={isMobileView ? 'scrollbarMobile' : 'scrollbarDesktop'} autoHide>
          <Modal
            getContainer="#menuLeft"
            title={formatMessage({
              id: 'module.more',
            })}
            visible={moreModalVisible}
            onOk={this.toggleModalVisible}
            onCancel={this.toggleModalVisible}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal moreModal`}
          >
            {moreModal}
          </Modal>
          <Modal
            getContainer="#menuLeft"
            title={formatMessage({
              id: 'menuleft.editnavigation.edit_navigation',
            })}
            visible={editNavigationModalVisible}
            onOk={this.toggleModalVisible}
            onCancel={this.toggleModalVisible}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal edit_navigation_modal`}
          >
            <div className={`row ant_modal`}>
              <EditNavigation1 handleCancel={this.toggleModalVisible} />
            </div>
          </Modal>
          <Modal
            getContainer="#menuLeft"
            title={formatMessage({
              id: 'ct.form.settings',
            })}
            visible={settingsModalVisible}
            onOk={this.handleSettingsOk}
            onCancel={this.handleSettingsCancel}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal settings_modal`}
          >
            <div className={`row ant_modal`}>
              <SettingsModal1 handleCancel={this.toggleModalVisible} />
            </div>
          </Modal>
          <div className="avatarBg">
            <div className={styles.logo}>
              <div className={styles.logoContainer}>
                <img
                  src={
                    brandSettings && brandSettings.company_logo_inverse
                      ? brandSettings.company_logo_inverse
                      : ''
                  }
                  alt={
                    brandSettings && brandSettings.company_name_short
                      ? brandSettings.company_name_short
                      : ''
                  }
                />
              </div>
            </div>

            <div className="card-body text-center position-relative">
              {user.type !== 'demo' && (
                <div className="iconContainer">
                  {user.verification_status === 'verified' ? (
                    <img src={images.verified} className={'verifiedIcon'} alt="verified" />
                  ) : (
                    <img
                      src={images.unverified}
                      className={'unverifiedIcon'}
                      alt="verified"
                      onClick={this.redirectToVerification}
                    />
                  )}
                </div>
              )}
              <Avatar size="110" border="true" borderColor="white" uploadFlag />
              <br />
              <br />
              <h5 className="text-black">
                <strong className="white_font">{user ? user['username'] : 'username'}</strong>
              </h5>
              {
                // loading === true ? (
                //   <p className={`white_font mt-3`}> {'---'} </p>
                // ) :
                user['type'] !== 'demo' ? (
                  <h5 className={`white_font mt-3`}>
                    {user.currency + ' '}

                    {Number(user['ewallet_balance']).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h5>
                ) : (
                  <Button className={`golivebtn mt-3 mb-4`} onClick={this.showModal} shape="round">
                    <FormattedMessage id="nav.go-live" />
                  </Button>
                )
              }
              <br />
            </div>
          </div>

          <Modal
            title={formatMessage({ id: 'nav.go-live' })}
            visible={visible}
            onOk={this.handleGoLiveOk}
            // confirmLoading={confirmLoading}
            onCancel={this.handleGoLiveCancel}
            className={`ant_modal`}
          >
            <div className={`row ant_modal`}>
              <div className={`col-lg-12`}>
                <Form layout="vertical">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.ic' }).replace('*', '')}>
                              {form.getFieldDecorator('ic', {
                                initialValue: user['ic'] ? user['ic'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.ic_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.birthday' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('dob', {
                                rules: [
                                  {
                                    type: 'object',
                                    required: true,
                                    message: formatMessage({ id: 'ur.birthday_require' }),
                                  },
                                ],
                              })(<DatePicker getCalendarContainer={(trigger) => trigger.parentNode} />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-12 my-3">
                          <h4 className="text-uppercase">
                            <strong>
                              <FormattedMessage id="ur.residence_info" />
                            </strong>
                          </h4>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.address_1' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('address_1', {
                                initialValue: user['address_1'] ? user['address_1'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.address_1_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                              {form.getFieldDecorator('address_2', {
                                initialValue: user['address_2'] ? user['address_2'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.postcode' })}>
                              {form.getFieldDecorator('postcode', {
                                initialValue: user['postcode'] ? user['postcode'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6" />
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.state' })}>
                              {form.getFieldDecorator('state', {
                                initialValue: user['state'] ? user['state'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.city' }).replace('*', '')}>
                              {form.getFieldDecorator('city', {
                                initialValue: user['city'] ? user['city'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.city_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Modal>

          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>
          <div
            className="row mx-0"
            style={{ marginBottom: '20px', justifyContent: 'space-between', padding: '0 25px' }}
          >
            <div className="lightDarkSwitch">
              <Switch
                checkedChildren={
                  <i className="fa fa-sun-o" style={{ color: 'black' }} aria-hidden="true"></i>
                }
                unCheckedChildren={<i className="fa fa-moon-o" aria-hidden="true"></i>}
                checked={isLightTheme}
                disabled={loading}
                onChange={(checked) => this.changeTheme(checked)}
              />
            </div>
            <div className="align-self-center">
              <i
                onClick={() => this.showSettingsModal()}
                className="fa fa-cog menuLeftSettingIcon"
                aria-hidden="true"
              />
            </div>
          </div>

          {/* Footer */}
          <small className="sidebarFooter row mx-0 mt-5 mb-4 text_note">
            {/* <div className="col-lg-12">
              <FormattedMessage id="footer.note.part_1" />
              <Link to="/support">
                <FormattedMessage id="footer.note.part_2" />
              </Link>
              <FormattedMessage id="footer.note.part_3" />
              {brandSettings && brandSettings.support_email && (
                <a href={`mailto:${brandSettings.support_email}`}>{brandSettings.support_email}</a>
              )}
              <FormattedMessage id="footer.note.part_4" />
            </div> */}
            <div className="col-lg-12 mt-4">
              <div className="copyright d-flex">
                <img
                  src={
                    brandSettings && brandSettings.company_logo_small
                      ? brandSettings.company_logo_small
                      : ''
                  }
                  rel="noopener noreferrer"
                  alt={
                    brandSettings && brandSettings.company_name ? brandSettings.company_name : ''
                  }
                  className="mr-2"
                />
                <span>
                  © {brandSettings && brandSettings.copyright ? brandSettings.copyright : ''}{' '}
                  <a
                    href={
                      brandSettings && brandSettings.company_website
                        ? brandSettings.company_website
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {brandSettings && brandSettings.company_name ? brandSettings.company_name : ''}
                  </a>
                  <br />
                  <FormattedMessage id="common.copy-right" />
                </span>
              </div>
            </div>
          </small>
        </Scrollbars>

        {isPathCommunity ? (
          !tabletLandscape ? (
            !isMobileView && !isTabletView ? (
              <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
                {this.state.isMenuLeftShow ? <Icon type="left" /> : <Icon type="menu" />}
              </div>
            ) : (
              <></>
            )
          ) : null
        ) : !isMobileView && !isTabletView ? (
          <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
            {this.state.isMenuLeftShow ? <Icon type="left" /> : <Icon type="menu" />}
          </div>
        ) : (
          <></>
        )}
        {/* {!isMobileView && !isTabletView ? (
          <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
            {this.state.isMenuLeftShow ? <Icon type="left" /> : <Icon type="menu" />}
          </div>
        ) : (
          <></>
        )} */}
      </Sider>
    )
  }
}

export default MenuLeft1
