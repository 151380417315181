import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import styles from './style.module.scss'
import languageCode from 'shared-crm/data/languageCode'

@injectIntl
@withRouter
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
    affiliate: '',
  }

  componentDidMount() {
    const { dispatch } = this.props
    const url = this.props.location.search
    const params = queryString.parse(url)
    if (params && params['affiliate']) {
      this.setState({
        affiliate: params['affiliate'],
      })
    }
    dispatch({
      type: 'user/BRAND_SETTINGS',
    })

    if (params && params['language']) {
      let lang = params['language']
      if (lang.length > 2) {
        lang = `${lang.split('-')[0]}-${lang.split('-')[1].toUpperCase()}`
      }
      if (!Object.keys(languageCode).includes(lang)) {
        lang = 'en-US'
      }
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: lang,
        },
      })
    }

    if (params && params['lg']) {
      let lg = params['lg']
      let lgCodeCRM = Object.keys(languageCode).find((key) => languageCode[key] === lg)
      if (!lgCodeCRM) {
        lgCodeCRM = 'en-US'
      }
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: lgCodeCRM,
        },
      })
    }
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const {
      children,
      user: { brandSettings, brandSettingsLoading, brandSettings_layout },
      intl: { formatMessage },
    } = this.props
    const { backgroundEnabled, affiliate } = this.state

    console.log('PATHNAME', this.props.location.pathname)

    let flex_direction = 'column'

    if (brandSettings_layout === 5) {
      flex_direction =
        this.props.location.pathname === '/user/register' ||
        this.props.location.pathname === '/user/forgot' ||
        this.props.location.pathname === '/user/register-demo'
          ? 'column'
          : 'row'
    }

    return (
      <Layout>
        <Layout.Content>
          <div
            id="login_layout"
            className={`${
              backgroundEnabled ? `${styles.layout} ${styles.light}` : `${styles.layout}`
            } main_layout_${brandSettings_layout}`}
            style={{
              flexDirection: flex_direction,
            }}
          >
            <div className={styles.header}>
              <div className={styles.logo}>
                <Link to="/">
                  {!backgroundEnabled && !brandSettingsLoading && (
                    <img
                      src={
                        brandSettings && brandSettings.company_logo_inverse
                          ? brandSettings.company_logo_inverse
                          : ''
                      }
                      alt={`${
                        brandSettings && brandSettings.company_name_short
                          ? brandSettings.company_name_short
                          : ''
                      } Trader's room`}
                    />
                  )}
                  {backgroundEnabled && !brandSettingsLoading && (
                    <img
                      src={
                        brandSettings && brandSettings.company_logo_inverse
                          ? brandSettings.company_logo_inverse
                          : ''
                      }
                      alt={`${
                        brandSettings && brandSettings.company_name_short
                          ? brandSettings.company_name_short
                          : ''
                      } Trader's room`}
                    />
                  )}
                </Link>
              </div>
              <nav className={styles.navigation}>
                <ul className={styles.navigationItems}>
                  <li>
                    <Link
                      to={affiliate ? `/user/login/?affiliate=${affiliate}` : '/user/login'}
                      className={`${styles.navigationActive} linkText`}
                    >
                      {formatMessage({ id: 'ur.login' })}
                    </Link>
                  </li>
                  <li>
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_website_about_page
                          ? brandSettings.company_website_about_page
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'ur.about_us' })}
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkText"
                      href={
                        brandSettings && brandSettings.company_website_support_page
                          ? brandSettings.company_website_support_page
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: 'topBar.support' })}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`}>
              <ul className="list-unstyled list-inline mb-3">
                <li className="list-inline-item">
                  <a
                    className="linkText"
                    href={
                      brandSettings && brandSettings.company_term_of_use_link
                        ? brandSettings.company_term_of_use_link
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'common.terms_of_use' })}
                  </a>
                </li>
                <li className="active list-inline-item">
                  <a
                    className="linkText"
                    href={
                      brandSettings && brandSettings.company_privacy_policy_link
                        ? brandSettings.company_privacy_policy_link
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'common.privacy_policy' })}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="linkText"
                    href={
                      brandSettings && brandSettings.company_return_refund_policy_link
                        ? brandSettings.company_return_refund_policy_link
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'common.refund_n_cancellation_policy' })}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="linkText"
                    href={
                      brandSettings && brandSettings.company_risk_disclosure_link
                        ? brandSettings.company_risk_disclosure_link
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'ur.rd' })}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="linkText"
                    href={
                      brandSettings && brandSettings.company_term_of_business_link
                        ? brandSettings.company_term_of_business_link
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: 'ur.tob' })}
                  </a>
                </li>
              </ul>
              <p className="linkText">
                &copy; {brandSettings && brandSettings.copyright ? brandSettings.copyright : ''}{' '}
                {brandSettings && brandSettings.company_name ? brandSettings.company_name : ''} -{' '}
                {formatMessage({ id: 'common.all_rights_reserved' })}
              </p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
