import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Layout, Switch, Modal, Form, Icon } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import { injectIntl } from 'react-intl'
import Avatar from 'shared-crm/components/CleanUIComponents/Avatar'
import styles from './style.module.scss'
import images from 'shared-crm/constants/images'
import modules from 'shared-crm/constants/modules'
import external from 'shared-crm/constants/external'
import { Popover } from 'antd'
import { EditNavigation2 } from '../../EditNavigation'
import { SettingsModal2 } from '../../SettingsModal'

const { Sider } = Layout

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isTabletView: settings.isTabletView,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})

@Form.create()
@withRouter
@connect(mapStateToProps)
@injectIntl
class MenuLeft2 extends React.Component {
  state = {
    showEditNavigationModal: false,
    brandSettings: {},
    currentActive: null,
    popOverDrawerVisible: false,
    settingModalVisible: false,
    moduleSelected: null,
    isMenuLeftShow: true,
    morePopOverVisible: false,
    parentModulePopOverVisible: {
      moduleName: null,
      visible: false,
    },
  }

  componentDidMount() {
    const { isLoggedIn, dispatch, user } = this.props
    this.setState({ currentActive: this.getModuleName(this.props.location.pathname) })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.sideBarModulesUserSettings !== this.props.user.sideBarModulesUserSettings) {
      this.setState({ currentActive: this.getModuleName(this.props.location.pathname) + 'Current' })
      this.setMenuItemInactive(this.getModuleName(prevProps.location.pathname))
      this.setMenuItemActive(this.getModuleName(this.props.location.pathname))
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ currentActive: this.getModuleName(this.props.location.pathname) + 'Current' })
      this.setMenuItemInactive(this.getModuleName(prevProps.location.pathname))
      this.setMenuItemActive(this.getModuleName(this.props.location.pathname))
    }
  }

  handleClick = (e) => {
    const { dispatch, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])

    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
  }

  redirectToVerification = () => {
    this.props.history.push('/dashboard/Verification')
  }

  handleOk = (event) => {
    event.preventDefault()
    this.setState({
      showEditNavigationModal: false,
    })
  }

  showEditNavigationModal = () => {
    this.setState({
      morePopOverVisible: false,
      showEditNavigationModal: true,
    })
  }

  handleCancel = () => {
    this.setState({
      showEditNavigationModal: false,
    })
  }
  popOverDrawerVisibleToggle = (itemName, visible) => {
    if (itemName === 'all') {
      this.setState({ morePopOverVisible: visible })
    } else {
      this.setState({
        parentModulePopOverVisible: {
          moduleName: itemName,
          visible: visible,
        },
      })
    }
    if (visible) {
      this.setMenuItemActive(itemName)
    } else {
      this.setMenuItemInactive(itemName)
    }
  }

  removeItemActiveState = () => {
    this.setMenuItemInactive(this.state.currentActive)
  }

  handleOnMouseOver = (name, component) => {
    if (component === 'more') {
      this.setPopoverItemActive(name)
    } else if (component === 'drawer') {
      this.setDrawerItemActive(name)
    } else {
      if (this.state.currentActive !== name + 'Current') {
        this.setMenuItemActive(name)
      }
    }
  }
  handleOnMouseOut = (name, component) => {
    if (component === 'more') {
      this.setPopoverItemInactive(name)
    } else if (component === 'drawer') {
      this.setDrawerItemInactive(name)
    } else {
      if (this.state.currentActive !== name + 'Current') {
        this.setMenuItemInactive(name)
      }
    }
  }
  setDrawerItemActive = (name) => {
    var image = document.getElementById(name + 'DrawerImage')
    if (image) {
      image.src = images[`nav_${name}_active`]
    }

    var label = document.getElementById(name + 'DrawerLabel')
    if (label) {
      label.classList.add('subModuleTextActive')
      label.classList.remove('subModuleText')
    }
  }
  setDrawerItemInactive = (name) => {
    var image = document.getElementById(name + 'DrawerImage')
    if (image) {
      image.src = images[`nav_${name}`]
    }
    var label = document.getElementById(name + 'DrawerLabel')
    if (label) {
      label.classList.add('subModuleText')
      label.classList.remove('subModuleTextActive')
    }
  }

  setPopoverItemActive = (name) => {
    var image = document.getElementById(name + 'MoreImage')
    if (image) {
      image.src = images[`nav_${name}_active`]
    }
  }

  setPopoverItemInactive = (name) => {
    var image = document.getElementById(name + 'MoreImage')
    if (image) {
      image.src = images[`nav_${name}`]
    }
  }

  setMenuItemActive = (name) => {
    //Image
    var image = document.getElementById(name + 'Image')
    if (image) {
      image.src = images[`nav_${name}_active`]
    }

    //Label
    var label = document.getElementById(name + 'Label')
    if (label) {
      label.classList.add('menuLabelActive')
      label.classList.remove('menuLeftLabel')
    }
  }
  setMenuItemInactive = (name) => {
    //Image
    var image = document.getElementById(name + 'Image')
    if (image) {
      image.src = images[`nav_${name}`]
    }

    //Label
    var label = document.getElementById(name + 'Label')
    if (label) {
      label.classList.add('menuLeftLabel')
      label.classList.remove('menuLabelActive')
    }
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  generateMenuItems = (data) => {
    // Getting the sequence of the menu item from api
    const MAX_MENU_ITEM = 5
    let res = data.filter((item) => item.web_default && item.web_default <= MAX_MENU_ITEM)

    let tempArray = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )

    return tempArray
  }

  toggleMenuLeft = () => {
    const { dispatch } = this.props
    const menuLeft = document.getElementById('menuLeft')
    if (this.state.isMenuLeftShow) {
      menuLeft.style.marginLeft = '-80px'
      this.setState({ isMenuLeftShow: false })
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    } else {
      menuLeft.style.marginLeft = '0px'
      this.setState({ isMenuLeftShow: true })
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: false,
        },
      })
    }
  }

  showDrawer = (module) => {
    const drawer = document.getElementById('menuLeftPopOverDrawerMenu')
    drawer.style.left = '0'
    this.setState({ popOverDrawerVisible: true, moduleSelected: module })
  }

  closeDrawer = () => {
    const drawer = document.getElementById('menuLeftPopOverDrawerMenu')
    drawer.style.left = '433px'
    this.setState({ popOverDrawerVisible: false })
  }

  showSettingModal = () => {
    this.setState({ settingModalVisible: true })
  }

  handleSettingModalCancel = () => {
    this.setState({ settingModalVisible: false })
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }
  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  moreModulesPopOver = (sideBarModulesUserSettings, menuItem) => {
    const {
      intl: { formatMessage },
      isMobileView,
    } = this.props
    var otherModules = sideBarModulesUserSettings.filter((item) => !menuItem.includes(item))
    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]
    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }

    return (
      <div id="morePopOver" className="morePopoverContent">
        <div className="morePopoverModules">
          {groupTypes.map((group, index) => {
            return (
              <div className="modulesGroupPartition" key={index}>
                <p className="menuLabel">
                  {formatMessage({
                    id: 'module.group_type.' + this.convertTextToLowercase(group),
                  })}
                </p>
                <div className="row" style={{ textAlign: 'center' }}>
                  {otherModules
                    .filter((item) => item.group_type === group && item.enabled === 1)
                    .map((module) => {
                      return external[this.convertTextToLowercase(module.name)] ? (
                        <div className="col-sm-3">
                          <a
                            href={
                              this.props.user.brandSettings.company_website +
                              external[this.convertTextToLowercase(module.name)]
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div
                              onMouseOver={() =>
                                this.handleOnMouseOver(
                                  this.convertTextToLowercase(module.name),
                                  'more',
                                )
                              }
                              onMouseOut={() =>
                                this.handleOnMouseOut(
                                  this.convertTextToLowercase(module.name),
                                  'more',
                                )
                              }
                              className="morePopOverItem"
                            >
                              <img
                                id={`${this.convertTextToLowercase(module.name)}MoreImage`}
                                src={images[`nav_${this.convertTextToLowercase(module.name)}`]}
                                onMouseOver={(e) =>
                                  (e.currentTarget.src =
                                    images[
                                      `nav_${this.convertTextToLowercase(module.name)}_active`
                                    ])
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.src =
                                    images[`nav_${this.convertTextToLowercase(module.name)}`])
                                }
                                style={{ width: '30px', height: '30px' }}
                                alt=""
                              />
                              <p className="morePopoverLabel">
                                {formatMessage({
                                  id: 'module.' + this.convertTextToLowercase(module.name),
                                })}
                              </p>
                            </div>
                          </a>
                        </div>
                      ) : (
                        <div className="col-sm-3">
                          {module.sub_modules.length === 0 && (
                            <Link
                              onClick={() => {
                                this.setState({ morePopOverVisible: false })
                                if (isMobileView) {
                                  this.props.toggleOpen()
                                }
                              }}
                              to={
                                this.getPathName(module.name) ? this.getPathName(module.name) : '/'
                              }
                            >
                              <div
                                onMouseOver={() =>
                                  this.handleOnMouseOver(
                                    this.convertTextToLowercase(module.name),
                                    'more',
                                  )
                                }
                                onMouseOut={() =>
                                  this.handleOnMouseOut(
                                    this.convertTextToLowercase(module.name),
                                    'more',
                                  )
                                }
                                className="morePopOverItem"
                              >
                                <img
                                  id={`${this.convertTextToLowercase(module.name)}MoreImage`}
                                  src={images[`nav_${this.convertTextToLowercase(module.name)}`]}
                                  onMouseOver={(e) =>
                                    (e.currentTarget.src =
                                      images[
                                        `nav_${this.convertTextToLowercase(module.name)}_active`
                                      ])
                                  }
                                  onMouseOut={(e) =>
                                    (e.currentTarget.src =
                                      images[`nav_${this.convertTextToLowercase(module.name)}`])
                                  }
                                  style={{ width: '30px', height: '30px' }}
                                  alt=""
                                />
                                <p className="morePopoverLabel">
                                  {formatMessage({
                                    id: 'module.' + this.convertTextToLowercase(module.name),
                                  })}
                                </p>
                              </div>
                            </Link>
                          )}
                          {module.sub_modules.length > 0 && (
                            <a onClick={() => this.showDrawer(module)}>
                              <div
                                onMouseOver={() =>
                                  this.handleOnMouseOver(
                                    this.convertTextToLowercase(module.name),
                                    'more',
                                  )
                                }
                                onMouseOut={() =>
                                  this.handleOnMouseOut(
                                    this.convertTextToLowercase(module.name),
                                    'more',
                                  )
                                }
                                className="morePopOverItem"
                              >
                                <img
                                  id={`${this.convertTextToLowercase(module.name)}MoreImage`}
                                  src={images[`nav_${this.convertTextToLowercase(module.name)}`]}
                                  onMouseOver={(e) =>
                                    (e.currentTarget.src =
                                      images[
                                        `nav_${this.convertTextToLowercase(module.name)}_active`
                                      ])
                                  }
                                  onMouseOut={(e) =>
                                    (e.currentTarget.src =
                                      images[`nav_${this.convertTextToLowercase(module.name)}`])
                                  }
                                  style={{ width: '30px', height: '30px' }}
                                  alt=""
                                />
                                <p className="morePopoverLabel">
                                  {formatMessage({
                                    id: 'module.' + this.convertTextToLowercase(module.name),
                                  })}
                                </p>
                              </div>
                            </a>
                          )}
                        </div>
                      )
                    })}
                </div>
                {index < groupTypes.length - 1 ? (
                  <div className="ant-dropdown-menu-item-divider divider"></div>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </div>
        <div className="morePopoverFooter">
          <div className="ant-dropdown-menu-item-divider divider"></div>
          <a onClick={this.showEditNavigationModal}>
            <div className="editNavigationButton">
              <p className="editNavigationText">
                {formatMessage({
                  id: 'menuleft.editnavigation.edit_navigation',
                })}
              </p>
            </div>
          </a>
        </div>
        <div id="menuLeftPopOverDrawerMenu">
          <div className="popOverDrawerWrapper">
            <div className="row" style={{ alignItems: 'center', marginBottom: '13px' }}>
              <div className="col-3">
                <div onClick={this.closeDrawer} style={{ cursor: 'pointer', textAlign: 'left' }}>
                  <Icon type="left" />
                </div>
              </div>
              <div className="col-6">
                {this.state.moduleSelected && (
                  <div className="d-flex justify-content-center">
                    <p className="popOverModuleDrawerTitle">
                      {formatMessage({
                        id: 'module.' + this.convertTextToLowercase(this.state.moduleSelected.name),
                      })}
                    </p>
                  </div>
                )}
              </div>
              <div className="col-3"></div>
            </div>
            <div
              className="ant-dropdown-menu-item-divider divider"
              style={{ marginBottom: '13px' }}
            ></div>
            <div className="row">
              <div className="col subModuleList">
                {this.state.moduleSelected && this.state.moduleSelected.sub_modules.length > 0 ? (
                  this.state.moduleSelected.sub_modules.map((submodule) => {
                    return external[this.convertTextToLowercase(submodule.name)] !== undefined ? (
                      <a
                        href={
                          this.props.user.brandSettings.company_website +
                          external[this.convertTextToLowercase(submodule.name)]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="subModuleItem"
                          onMouseOver={() =>
                            this.handleOnMouseOver(
                              this.convertTextToLowercase(submodule.name),
                              'drawer',
                            )
                          }
                          onMouseOut={() =>
                            this.handleOnMouseOut(
                              this.convertTextToLowercase(submodule.name),
                              'drawer',
                            )
                          }
                        >
                          <img
                            className="subModuleIcon"
                            src={images[`nav_${this.convertTextToLowercase(submodule.name)}`]}
                            id={`${this.convertTextToLowercase(submodule.name) + 'DrawerImage'}`}
                            alt=""
                          />
                          <p
                            id={`${this.convertTextToLowercase(submodule.name) + 'DrawerLabel'}`}
                            className="subModuleText"
                          >
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(submodule.name),
                            })}
                          </p>
                        </div>
                      </a>
                    ) : (
                      <Link
                        to={
                          this.getPathName(submodule.name) ? this.getPathName(submodule.name) : '/'
                        }
                        onClick={() => {
                          this.setState({ morePopOverVisible: false })
                          if (isMobileView) {
                            this.props.toggleOpen()
                          }
                        }}
                      >
                        <div
                          className="subModuleItem"
                          onMouseOver={() =>
                            this.handleOnMouseOver(
                              this.convertTextToLowercase(submodule.name),
                              'drawer',
                            )
                          }
                          onMouseOut={() =>
                            this.handleOnMouseOut(
                              this.convertTextToLowercase(submodule.name),
                              'drawer',
                            )
                          }
                        >
                          <img
                            id={`${this.convertTextToLowercase(submodule.name) + 'DrawerImage'}`}
                            className="subModuleIcon"
                            src={images[`nav_${this.convertTextToLowercase(submodule.name)}`]}
                            alt=""
                          />
                          <p
                            id={`${this.convertTextToLowercase(submodule.name) + 'DrawerLabel'}`}
                            className="subModuleText"
                          >
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(submodule.name),
                            })}
                          </p>
                        </div>
                      </Link>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      showEditNavigationModal,
      settingModalVisible,
      isShowSidebar,
      morePopOverVisible,
      parentModulePopOverVisible,
    } = this.state
    const {
      isMobileView,
      isTabletView,
      isLightTheme,
      user: { sideBarModulesUserSettings, brandSettings },
      intl: { formatMessage },
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 80,
          collapsible: false,
          collapsed: true,
          onCollapse: this.onCollapse,
        }
      : {
          width: 80,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }
    const menuItemsList = this.generateMenuItems(sideBarModulesUserSettings)
    const pathName = window.location.href
    let isPathCommunity
    let tabletLandscape

    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    if (global.window.innerWidth >= 1024 && global.window.innerWidth < 1366) {
      tabletLandscape = true
    } else tabletLandscape = false
    return (
      <Sider {...menuSettings} id="menuLeft" className={styles.menu}>
        <Scrollbars className={isMobileView ? 'scrollbarMobile' : 'scrollbarDesktop'} autoHide>
          <Modal
            getContainer="#menuLeft"
            title={formatMessage({
              id: 'menuleft.editnavigation.edit_navigation',
            })}
            visible={showEditNavigationModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal edit_navigation_modal`}
          >
            <div className={`row ant_modal`}>
              <EditNavigation2 handleCancel={this.handleCancel} />
            </div>
          </Modal>

          <Modal
            getContainer="#menuLeft"
            title={formatMessage({
              id: 'ct.form.settings',
            })}
            visible={settingModalVisible}
            onOk={this.handleSettingModalCancel}
            onCancel={this.handleSettingModalCancel}
            destroyOnClose={true}
            footer={null}
            className={`ant_modal settings_modal`}
          >
            <div className={`row ant_modal`}>
              <SettingsModal2 handleCancel={this.handleCancel} />
            </div>
          </Modal>

          <div
            className={`${styles.navigation} navigation text-center`}
            style={{ marginBottom: '17px' }}
          >
            <div>
              <div>
                <div className="avatar_container">
                  <Avatar
                    className="avatar_picture"
                    size="100"
                    border="true"
                    borderColor="white"
                    uploadFlag
                  />
                </div>
              </div>

              <div>
                <Link to="/">
                  <div
                    onMouseOver={() => this.handleOnMouseOver('home', '')}
                    onMouseOut={() => this.handleOnMouseOut('home', '')}
                    className="menuLeftItem"
                  >
                    <img
                      id={`homeImage`}
                      src={images.nav_home}
                      onMouseOver={(e) => (e.currentTarget.src = images[`nav_home_active`])}
                      onMouseOut={(e) => (e.currentTarget.src = images[`nav_home`])}
                      className="menuLeftIcon"
                      alt=""
                    />
                    <p id={'homeLabel'} className="menuLeftLabel">
                      {formatMessage({
                        id: 'common.home',
                      })}
                    </p>
                  </div>
                </Link>
              </div>
              {menuItemsList.map((menuItem, index) => {
                if (menuItem.enabled) {
                  if (menuItem.sub_modules.length > 0) {
                    return (
                      <Popover
                        key={index}
                        getPopupContainer={() => document.getElementById('menuLeft')}
                        trigger="hover"
                        placement="right"
                        visible={
                          parentModulePopOverVisible.moduleName ===
                          this.convertTextToLowercase(menuItem.name)
                            ? parentModulePopOverVisible.visible
                            : false
                        }
                        onVisibleChange={(visible) =>
                          this.popOverDrawerVisibleToggle(
                            this.convertTextToLowercase(menuItem.name),
                            visible,
                          )
                        }
                        content={
                          <div id="parentModulePopOver" className="parentPopoverContent">
                            <div className="parentPopoverModules">
                              <div className="row" style={{ textAlign: 'center' }}>
                                {menuItem.sub_modules.map((submodule) => {
                                  return external[this.convertTextToLowercase(submodule.name)] ? (
                                    <div className="col-sm-3">
                                      <a
                                        href={
                                          this.props.user.brandSettings.company_website +
                                          external[this.convertTextToLowercase(submodule.name)]
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <div
                                          onMouseOver={() =>
                                            this.handleOnMouseOver(
                                              this.convertTextToLowercase(submodule.name),
                                              'Parent',
                                            )
                                          }
                                          onMouseOut={() =>
                                            this.handleOnMouseOut(
                                              this.convertTextToLowercase(submodule.name),
                                              'Parent',
                                            )
                                          }
                                          className="parentPopoverItem"
                                        >
                                          <div className="parentPopoverContainer">
                                            <img
                                              id={`${this.convertTextToLowercase(
                                                submodule.name,
                                              )}Image`}
                                              src={
                                                images[
                                                  `nav_${this.convertTextToLowercase(
                                                    submodule.name,
                                                  )}`
                                                ]
                                              }
                                              style={{ width: '20px', height: '20px' }}
                                              onMouseOver={(e) =>
                                                (e.currentTarget.src =
                                                  images[
                                                    `nav_${this.convertTextToLowercase(
                                                      submodule.name,
                                                    )}_active`
                                                  ])
                                              }
                                              onMouseOut={(e) =>
                                                (e.currentTarget.src =
                                                  images[
                                                    `nav_${this.convertTextToLowercase(
                                                      submodule.name,
                                                    )}`
                                                  ])
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <p className="parentPopoverLabel">
                                            {formatMessage({
                                              id:
                                                'module.' +
                                                this.convertTextToLowercase(submodule.name),
                                            })}
                                          </p>
                                        </div>
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="col-sm-3">
                                      <Link
                                        onClick={() =>
                                          this.setState({
                                            parentModulePopOverVisible: {
                                              moduleName: menuItem.name,
                                              visible: false,
                                            },
                                          })
                                        }
                                        to={
                                          this.getPathName(submodule.name)
                                            ? this.getPathName(submodule.name)
                                            : '/'
                                        }
                                      >
                                        <div
                                          onMouseOver={() =>
                                            this.handleOnMouseOver(
                                              this.convertTextToLowercase(submodule.name),
                                              'Parent',
                                            )
                                          }
                                          onMouseOut={() =>
                                            this.handleOnMouseOut(
                                              this.convertTextToLowercase(submodule.name),
                                              'Parent',
                                            )
                                          }
                                          className="parentPopoverItem"
                                        >
                                          <div className="parentPopoverContainer">
                                            <img
                                              id={`${this.convertTextToLowercase(
                                                submodule.name,
                                              )}Image`}
                                              src={
                                                images[
                                                  `nav_${this.convertTextToLowercase(
                                                    submodule.name,
                                                  )}`
                                                ]
                                              }
                                              style={{ width: '20px', height: '20px' }}
                                              onMouseOver={(e) =>
                                                (e.currentTarget.src =
                                                  images[
                                                    `nav_${this.convertTextToLowercase(
                                                      submodule.name,
                                                    )}_active`
                                                  ])
                                              }
                                              onMouseOut={(e) =>
                                                (e.currentTarget.src =
                                                  images[
                                                    `nav_${this.convertTextToLowercase(
                                                      submodule.name,
                                                    )}`
                                                  ])
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <p className="parentPopoverLabel">
                                            {formatMessage({
                                              id:
                                                'module.' +
                                                this.convertTextToLowercase(submodule.name),
                                            })}
                                          </p>
                                        </div>
                                      </Link>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className="menuLeftItem">
                          <img
                            id={this.convertTextToLowercase(menuItem.name) + `Image`}
                            src={images[`nav_${this.convertTextToLowercase(menuItem.name)}`]}
                            className="menuLeftIcon"
                            alt=""
                          />
                          <p
                            id={this.convertTextToLowercase(menuItem.name) + `Label`}
                            className="menuLeftLabel"
                          >
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(menuItem.name),
                            })}
                          </p>
                        </div>
                      </Popover>
                    )
                  } else {
                    return external[this.convertTextToLowercase(menuItem.name)] ? (
                      <a
                        href={
                          brandSettings.company_website +
                          external[this.convertTextToLowercase(menuItem.name)]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="menuLeftItem"
                          onMouseOver={() =>
                            this.handleOnMouseOver(
                              this.convertTextToLowercase(menuItem.name),
                              'Drawer',
                            )
                          }
                          onMouseOut={() =>
                            this.handleOnMouseOut(
                              this.convertTextToLowercase(menuItem.name),
                              'Drawer',
                            )
                          }
                        >
                          <img
                            id={this.convertTextToLowercase(menuItem.name) + `Image`}
                            src={images[`nav_${this.convertTextToLowercase(menuItem.name)}`]}
                            onMouseOver={(e) =>
                              (e.currentTarget.src =
                                images[`nav_${this.convertTextToLowercase(menuItem.name)}_active`])
                            }
                            className="menuLeftIcon"
                            alt=""
                          />
                          <p
                            id={this.convertTextToLowercase(menuItem.name) + `Label`}
                            className="menuLeftLabel"
                          >
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(menuItem.name),
                            })}
                          </p>
                        </div>
                      </a>
                    ) : (
                      <Link
                        to={this.getPathName(menuItem.name) ? this.getPathName(menuItem.name) : '/'}
                      >
                        <div
                          className="menuLeftItem"
                          onMouseOver={() =>
                            this.handleOnMouseOver(this.convertTextToLowercase(menuItem.name), '')
                          }
                          onMouseOut={() =>
                            this.handleOnMouseOut(this.convertTextToLowercase(menuItem.name), '')
                          }
                        >
                          <img
                            id={this.convertTextToLowercase(menuItem.name) + `Image`}
                            src={images[`nav_${this.convertTextToLowercase(menuItem.name)}`]}
                            onMouseOver={(e) =>
                              (e.currentTarget.src =
                                images[`nav_${this.convertTextToLowercase(menuItem.name)}_active`])
                            }
                            className="menuLeftIcon"
                            alt=""
                          />
                          <p
                            id={this.convertTextToLowercase(menuItem.name) + `Label`}
                            className="menuLeftLabel"
                          >
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(menuItem.name),
                            })}
                          </p>
                        </div>
                      </Link>
                    )
                  }
                }
              })}

              <div>
                <div>
                  <Popover
                    id="morePopoverElement"
                    getPopupContainer={() => document.getElementById('menuLeft')}
                    trigger="hover"
                    placement="rightBottom"
                    content={this.moreModulesPopOver(sideBarModulesUserSettings, menuItemsList)}
                    visible={morePopOverVisible}
                    onVisibleChange={(visible) => this.popOverDrawerVisibleToggle('all', visible)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <img id={`allImage`} src={images.nav_all} className="menuLeftIcon" alt="" />
                      <p id={'allLabel'} className="menuLeftLabel">
                        {formatMessage({
                          id: 'common.more',
                        })}
                      </p>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            {isMobileView && (
              <div className="settingIconContainer">
                <i
                  onClick={() => this.showSettingModal()}
                  className="fa fa-cog menuLeftSettingIcon"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </Scrollbars>
        {isPathCommunity ? (
          !tabletLandscape ? (
            !isMobileView && !isTabletView ? (
              <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
                {this.state.isMenuLeftShow ? <Icon type="left" /> : <Icon type="menu" />}
              </div>
            ) : (
              <></>
            )
          ) : null
        ) : !isMobileView && !isTabletView ? (
          <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
            {this.state.isMenuLeftShow ? <Icon type="left" /> : <Icon type="menu" />}
          </div>
        ) : (
          <></>
        )}
        {/* {!isMobileView && !isTabletView? (
          <div className="showHideSider" onClick={() => this.toggleMenuLeft()}>
            {this.state.isMenuLeftShow ? <Icon type="caret-left" /> : <Icon type="caret-right" />}
          </div>
        ) : (
          <></>
        )} */}
      </Sider>
    )
  }
}

export default MenuLeft2
