export default {
  profile: '/dashboard/Settings',
  deposit: '/deposit',
  withdrawal: '/withdrawal',
  wallet_operations: '/dashboard/walletManagement',
  self_trade: '/selfTrading',
  liquidity_pool: '/liquidityPool',
  economic_calendar: '/economicCalendar',
  market_news: '/market-news',
  trade_ideas: '/trade-ideas',
  referral: '/ib/affiliateTree',
  reward_center: '/reward-centre',
  task_center: '/task-centre',
  support: '/support',
  home: '/dashboard/summary',
  verification: '/dashboard/Verification',
  client_listing: '/ib/clientListing',
  commission_history: '/ib/commissionHistory',
  clients_trades: '/ib/clientTrades',
  client_deposits_withdrawals: '/ib/clientDepositsWithdrawals',
  smart_score: '/smart-score',
  live_broadcast: '/live-broadcast',
  quotes: '/markets',
  trade: '/markets',
  charts: '/markets',
  news_feed : '/community/homepage',
  events: '/community/suggested-events',
  promo_materials: '/promoMaterials',
  loyalty_program: '/loyalty-program',

  //parent module
  copy_trade: '/copyTrading/myportfolio', // parent module
  mam_pamm: '/mamPamm/investorsAcc', // parent module
  a_i_technical_screener: '/ai-technical-screener/summary',
  markets: '/markets', // parent module
  analysis: '', // parent module
  reports: '', // parent module
  community: '', //parent module


  //Not Available yet\

  //external link
  // education : '',
  // newsletter : '',
  // faq : '',
  // promotion : '',
}
