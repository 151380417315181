import actions from './actions'
import { getApiUrlBrandName } from '../../services/public_functions'
import brandConfig from '../../config/brand.config'

let brandName = getApiUrlBrandName({ domain: origin.replace('https://', '') }).brandName
let layout = brandConfig[brandName] && brandConfig[brandName].layout

const initialState = {
  brandSettings: {},
  brandSettings_layout: layout || 2,
  brandSettings_loginLayout: 'center',
  brandSettingsTNCPrivacy: {},
  brandSettingsLoading: false,
  newsletterSubscribeLoading: false,
  moduleSettings: {},
  sideBarModulesSettings: [],
  sideBarModulesLoading: false,
  sideBarModulesUserSettings: [],
  sideBarModulesUserLoading: false,
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: null,
  authorized: false,
  loading: false,
  balanceGraphloading: false,
  poi_loading: false,
  por_loading: false,
  init: true, // flag: init === true: check is pending, init!==true: check is finished
  loginHistory: [],
  loginHistoryInfo: {},
  user: null,
  corporateInfo: {},
  wallet: [],
  walletInfo: {},
  walletOperationsDetails: {},
  walletOperationsDetailsInfo: {},
  walletOperationsDetailsData: {},
  walletOperationsDetailsDataInfo: {},
  walletDetailsLoading: false,
  banks: [],
  lyhour: null,
  foreverpay: [],
  paytrust: [],
  fasapay: [],
  helppay: [],
  eeziepay: [],
  eeziepayINR: [],
  xpay: [],
  _9pay: [],
  _5pay: [],
  paybnb: {},
  papayapay: {},
  hwgc: {},
  hwgcCard: false,
  hwgcEmail: {},
  hwgcMobile: {},
  kyc: [],
  kycHistory: [],
  mt4: [],
  mt4Demo: [],
  mt4Called: false,
  mt4DemoCalled: false,
  mt4Full: [],
  mt4DemoFull: [],
  selfTradingLoading: false,
  affiliateTree: [],
  affiliateClientListing: [],
  affiliateClientListingInfo: {},
  affiliateCommHistory: [],
  affiliateCommHistoryInfo: {},
  affiliateClientTradingTxs: [],
  affiliateClientTradingTxsInfo: {},
  affiliateClientDepWitTxs: [],
  affiliateClientDepWitTxsInfo: {},
  networkMt4: [],
  networkMt4Loading: false,
  tradingProducts: [],
  tickets: [],
  ticket: null,
  ticketDetail: [],
  liveMt4: null,
  liveMt4Margin: null,
  liveMt4BalanceCredit: null,
  liveMt4Loading: false,
  depositHistory: [],
  depositHistoryInfo: {},
  withdrawalHistory: [],
  withdrawalHistoryInfo: {},
  nganluong_email: '',
  balance: [],
  marketNews: [],
  newsRedirectLink: null,
  foreverpay_bank: [],
  foreverpay_bank_state_list: [],
  foreverpay_bank_city_list: [],
  paytrust_bank: [],
  helppay_bank: {},
  eeziepay_bank: {},
  eeziepay_inr_bank: {},
  xpay_bank: {},
  anpay_bank: {},
  fivepay_bank: {},
  paybnb_bank: {},
  directpay_bank: {},
  papayapay_bank: {},
  currencyRate: [],
  pspCurrencyRate: {},
  pspCurrencyFee: {},
  pspRateFee_rate: {},
  pspRateFee_fee: {},
  cryptoRate: [],
  masterTraders: [],
  masterTrader: '',
  masterTraderRankScore: {},
  masterTraderTotalRankScore: 0,
  masterOpenTrades: [],
  masterOpenTradesInfo: {},
  masterCloseTrades: [],
  masterCloseTradesInfo: {},
  masterFollowers: [],
  masterSymbols: {},
  followingList: [],
  followerTradesOpen: [],
  followerTradesOpenInfo: {},
  followerTradesClose: [],
  followerTradesCloseInfo: {},
  watchlist: [],
  copyTradingSettings: '',
  mt4Stats: '',
  mt4ProfitsChart: '',
  mt4EquityBalanceChart_7D: '',
  mt4EquityBalanceChart_1M: '',
  mt4EquityBalanceChart_6M: '',
  mt4EquityBalanceChart_1Y: '',
  mt4MonthlyPL: '',
  mt4SkilledSymbolsChart: '',
  mt4MostTradedChart: '',
  mt4HoldingTimeChart: '',
  chartLoading: false,
  ctTradesloading: false,
  ctActionLoading: false,
  ctCopyActionLoading: false,
  ctWatchActionLoading: false,
  masterTraderLoading: false,
  paymentMethod: {},
  pendingWithdrawalsloading: false,
  pendingWithdrawalsAmount: '0.00',
  trading_voucher_amount: '',
  withdrawable_balance: 0,
  moneyManagerMt4: [],
  moneyManagers: [],
  moneyManager: {},
  moneyManagerInvestingList: [],
  moneyManagerLoading: false,
  mampammActionLoading: false,
  mampammTradesloading: false,
  moneyManagerInvestorDeposits: [],
  moneyManagerInvestorWithdrawals: [],
  moneyManagerInvestors: [],
  moneyManagerTradesOpen: [],
  moneyManagerTradesClose: [],
  liqPoolTNC: {},
  liqPoolWalletData: {},
  liqPoolWalletHistory: {},
  liqPoolWalletCompanyWalletAddress: {},
  liqPoolStakeData: {},
  liqPoolStakeHistory: {},
  taskCentreTaskListing: [],
  taskCentreListingLoading: false,
  taskCentreActionLoading: false,
  taskCentreSucceefulModal: false,
  taskCentreCompletedTaskReward: [],
  rewardCentreRewardListing: [],
  rewardCentreLoyaltyProgramListing: [],
  rewardCentreLuckyDrawListing: [],
  rewardCentreListingLoading: false,
  rewardCentreActionLoading: false,
  rewardCentreLoyaltyLoading: false,
  rewardCentreSucceefulModal: false,
  rewardCentreLuckyDrawSuccessfulModal: false,
  rewardCentreCashSuccessfulModal: false,
  hwgcStatus: false,
  hwgcRate: {},
  hwgc_bank: {},
  hwgcApplyCardStatus: false,
  tradeIdeas_forex: [],
  tradeIdeas_stock: [],
  tradeIdeas_index: [],
  tradeIdeas_commodities: [],
  tradeIdeas_forex_loading: false,
  tradeIdeas_stock_loading: false,
  tradeIdeas_index_loading: false,
  tradeIdeas_commodities_loading: false,
  smartScore_data: {},
  smartScore_loading: false,
  aiTechScreener_data: {},
  aiTechScreener_loading: false,
  aiTechScreenerEventTypes: {},
  aiTechScreenerEvents: {},
  aiTechScreenerEvents_loading: false,
  aiTechScreenerEventCounts: {},
  aiTechScreenerTechnicalSummaries: {},
  aiTechScreenerSupportResistance: {},
  aiTechScreenerStopPrice: {},
  economicCalendar: [],
  economicCalendarEventChart: [],
  economicCalendarCurrencySymbol: [],
  economicCalendarVolatility: {},
  economicCalendarImpact: {},
  rssNews: [],
  rssNewsNextNews: [],
  rssNewsLoading: false,
  promoMaterials: [],
  promoMaterialsDashboardBanner: [],
  promoMaterialsDashboardBannerLoading: false,
  promoMaterialsDashboardVideo: [],
  promoMaterialsDashboardVideoLoading: false,
  promoMaterialsDashboardLanding: [],
  promoMaterialsDashboardLandingLoading: false,
  promoMaterialsDashboardGif: [],
  promoMaterialsDashboardGifLoading: false,
  promoMaterialsFilterBanner: [],
  promoMaterialsFilterBannerLoading: false,
  promoMaterialsFilterVideo: [],
  promoMaterialsFilterVideoLoading: false,
  promoMaterialsFilterLanding: [],
  promoMaterialsFilterLandingLoading: false,
  promoMaterialsFilterGif: [],
  promoMaterialsFilterGifLoading: false,
  promoMaterialsFilterLogo: [],
  promoMaterialsFilterLogoLoading: false,
  promoMaterialsLoading: false,
  promoMaterialsTotalBanner: '',
  promoMaterialsTotalVideo: '',
  promoMaterialsTotalGif: '',
  promoMaterialsTotalLanding: '',
  promoMaterialsTotalLogo: '',
  promoMaterialsModal: [],
  promoMaterialsModalLoading: false,
  liveBroadcast: [],
  liveBroadcastNext: [],
  promotion: [],
  spreadGroupSymbolPrice: [],
  spreadGroupSymbolPriceLoading: false,
  symbol_views: {},
  symbol_views_loading: false,
  symbol_details: {},
  symbol_category: [],
  symbol_category_amended: [],
  ohlc: {},
  cms_instruments_categories: [],
  // cms_instruments: [],
  cms_education_categories: [],
  moduleMarketsData: {},
  tradeSymbol: [],
  tradeSymbolLoading: false,
  tradeSymbol_amended: [],
  favouriteSymbol: [],
  moduleMarketsDataLoading: true,
  marketsOpenTrades: [],
  marketsTrades: [],
  marketsTradesTotal: {},
  marketsTradesSymbolList: [],
  marketsTradeActionLoading: false,
  marketsOrderModalClose: false,
  marketsJournal: [],
  marketsJournalTotal: [],
  marketsOpenTradeTempData: {},
  marketsCloseTradeTempData: {},

  // Community
  communityUserId: {},
  communityUserData: {},
  communityOtherUserData: {},
  communityAccessToken: '',
  communityPosts: [],
  communityUserPosts: [],
  communityRecommendedPosts: [],
  communityCommentReplyLoading: false,
  postComments: [],
  postCommentsReply: [],
  postData: {},
  communityUserFollowers: [],
  communityUserFollowing: [],
  communityGroupData: [],
  communityPhotoPosts: [],
  communityVideoPosts: [],
  communityMusicPosts: [],
  communityFilesPosts: [],
  communityMapsPosts: [],
  communityTextPosts: [],
  communityRSSNews: [],
  communityTrendingHashtags: [],
  communityEventsList: [],
  communityEventGoingList: [],
  communityEventInvitedList: [],
  communityEventInterestedList: [],
  communitySavedEventsList: [],
  communityMyEventsList: [],
  communityPastEventsList: [],
  communityEventData: [],
  communityEventPosts: [],
  communityMyEventGoingList: [],
  communityMyEventInterestedList: [],
  communityMyEventInvitedList: [],
  communityMyEventPastList: [],
  communitySearchAllResults: [],
  communitySearchAllResultsSuggestion: [],
  communitySearchUsersResults: [],
  communitySearchUsersResultsSuggestion: [],
  communitySearchPostsResults: [],
  communitySearchEventsResults: [],
  communitySearchEventsResultsSuggestion: [],
  checkEmptyUserPosts: [],
  checkEmptyVideoFilterUserPosts: [],
  checkEmptyPhotoFilterUserPosts: [],
  communityCheckSearchEventsEmpty: [],
  communityCheckSearchPostsEmpty: [],
  communityCheckSearchUsersEmpty: [],
  communityEmptyEventGoingList: [],
  communityEmptyEventsList: [],
  communityEmptyEventInterestedList: [],
  communityEmptyMyEventsList: [],
  communityEmptyPastEventsList: [],
  communityEmptySavedEventsList: [],
  communityEmptyEventPostsList: [],
  communityCheckEmptySearchAllResults: [],
  communityMostFollowersUsers: [],
  communityTermsandConditions: [],
  communityFollowLoading: true,
  communitySearchLoading: true,
  communitySearchAllLoading: true,
  communityInviteList: [],
  communityEventsListLoading: true,
  communityGetUserDataLoading: true,
  communityGetUserPostsLoading: true,
  communityGetUserphotosPostsLoading: true,
  communityGetUservideosPostsLoading: true,
  userFollowersLoading: true,
  userFollowingLoading: true,
  communityRssNewsLoading: true,
  communityPostsLoading: true,
  communityGetPostdataLoading: true,
  communityGetEventDataLoading: true,
  pinPostLoading: false,
  communitySearchAllAdditionalLoading: false,
  communitySearchAdditionalLoading: false,
  communityUserPhotos: [],
  checkEmptyCommunityUserPhotos: [],
  communityUserVideos: [],
  communityUserSounds: [],
  communityUserDocuments: [],
  checkEmptyCommunityUserVideos: [],
  checkEmptyCommunityUserSounds: [],
  checkEmptyCommunityUserDocuments: [],
  fetchCommentsLoading: false,
  showReplies: false,
  communityUserPinnedPosts: [],
  communityEventPostLoading: false,
  communityEventPinnedPosts: [],
  communityEventPostsShowMore: [],
  communityGetUserphotosPostsAdditionalLoading: false,
  communityGetUsersoundsAdditionalPostsLoading: false,
  communityGetUservideosAdditionalPostsLoading: false,
  communityGetUserdocumentsAdditionalPostsLoading: false,
  communityHidePostLoading: false,
  postLikeloading: false,
  communityGetUserPostsAdditionalLoading: false,
  communitySharedPostData: {},
  communitySharePostLoading: false,
  communityCommentLikeLoading: false,
  communityInviteToEventLoading: false,
  communityDeleteEventLoading: false,
  communityFetchCommentsReplyLoading: false,
  communityEditPostLoading: false,
  communityDeletePostLoading: false,
  communityDisableCommentsLoading: false,
  communitySavePostLoading: false,
  communityReportPostLoading: false,
  communityFollowUserLoading: false,
  communityProfilePageUserData: {},
  communityPostCommentLoading: false,
  communityGetUserSavedPostsLoading: false,
  communityUserSavedPosts: [],
  checkEmptycommnityUserSavedPosts: [],
  communityGetUserSavedPostsAdditionalLoading: false,
  community_post_reply_type: '',
  communityNewPostLoading: false,
  communityCreateEventLoading: false,
  communityReportCommentLoading: false,

  //Loyalty Program
  loyaltyProgramLoading: false,
  loyaltyProgramLoaded: false,
  loyaltyProgramClaimRewardLoading: false,
  loyaltyProgramList: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
