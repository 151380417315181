// components/QRCode.tsx
import QRCodeReact from 'qrcode.react'
import React from 'react'
import { connect } from 'react-redux'

@connect(({ user }) => ({ user }))
class QRCode extends React.PureComponent {
  render() {
    const {
      value,
      bgColor,
      fgColor,
      user: { brandSettings_layout },
    } = this.props
    let bg
    let fg

    switch (brandSettings_layout) {
      case 1:
        bg = bgColor ? bgColor : '#FFFFFF'
        fg = fgColor ? fgColor : '#000000'
        break
      case 2:
        bg = bgColor ? bgColor : 'transparent'
        fg = fgColor ? fgColor : '#FFFFFF'
        break
      default:
        bg = '#FFFFFF'
        fg = '#000000'
        break
    }

    return (
      <div className="svgWrapper my-4">
        <QRCodeReact renderAs="svg" value={value} bgColor={bg} fgColor={fg} />
      </div>
    )
  }
}

export default QRCode
