// import antdData from 'antd/lib/locale-provider/vi_VN'
import localeData from 'react-intl/locale-data/km'
import messages from './lang/kh-KH.json'

export default {
  locale: 'km',
  antdData: null,
  localeData: localeData,
  messages: messages["kh-KH"],
}
