import React from 'react'
import { connect } from 'react-redux'
import { Icon, Switch, Button } from 'antd'
import images from 'shared-crm/constants/images'
import { FormattedMessage, injectIntl } from 'react-intl'
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: user.user['type'] !== 'demo' ? menu.menuLeftData : menu.menuLeftDataDemo,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  user: user,
})

@connect(mapStateToProps)
@injectIntl
class EditNavigation5 extends React.Component {
  state = {
    menuItemSequence: [],
    nonSelectedItem: [],
    checkList: [],
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ menuItemSequence }) => ({
      menuItemSequence: arrayMoveImmutable(menuItemSequence, oldIndex, newIndex),
    }))
  }

  generateMenuItems = (data) => {
    // Getting the sequence of the menu item from api
    const MAX_MENU_ITEM = 5
    let res = data.filter((item) => item.web_default && item.web_default <= MAX_MENU_ITEM)

    let tempArray = res.sort((a, b) =>
      a.web_default > b.web_default ? 1 : b.web_default > a.web_default ? -1 : 0,
    )

    this.setState({ menuItemSequence: tempArray })
    this.initCheckList(data)
  }

  removeItemFromArray = (fullList, itemToRemove) => {
    let tempArray = fullList
    const index = tempArray.indexOf(itemToRemove)

    if (index > -1) {
      tempArray.splice(index, 1)
    }
    return tempArray
  }

  removeMenuItemFromList = (listItem) => {
    this.setState({
      menuItemSequence: this.removeItemFromArray(this.state.menuItemSequence, listItem),
    })
    this.disabledCheckListWhenRemoved(listItem)
  }

  disabledCheckListWhenRemoved = (listItem) => {
    if (listItem.parent_id === 0) {
      this.state.checkList.map((module) => {
        if (module.module_id === listItem.module_id) {
          module.checked = false
        }
        return module
      })
    } else {
      this.state.checkList.map((module) => {
        if (module.module_id === listItem.parent_id) {
          let parent = module.sub_modules.map((submodule) => {
            if (submodule.module_id === listItem.module_id) {
              submodule.checked = false
            }
            return submodule
          })
          return parent
        }
        return module
      })
    }
  }

  componentDidMount() {
    const {
      user: { sideBarModulesUserSettings },
    } = this.props
    this.generateMenuItems(sideBarModulesUserSettings)
  }

  initCheckList(fullList) {
    let filterOutSubModules = [...fullList].filter((item) => item.parent_id === 0)
    let tempArray = filterOutSubModules.map((parentModule) => {
      if (parentModule.web_default) {
        parentModule.checked = true
      } else {
        parentModule.checked = false
        let tempSubModules = [...parentModule.sub_modules]
        let testArr = []
        if (tempSubModules.length) {
          tempSubModules.map((subModule) => {
            testArr.push({ ...subModule, checked: subModule.web_default ? true : false })
          })
          parentModule.sub_modules = testArr
        }
      }
      return parentModule
    })
    this.setState({ checkList: tempArray })
  }

  handleSwitchChanged = (value, subModule, parentModule) => {
    let menuItems = this.state.menuItemSequence
    let checkList = [...this.state.checkList]
    if (value) {
      if (subModule) {
        menuItems.push(subModule)
        checkList = checkList.map((module) => {
          if (module.module_id === parentModule.module_id) {
            module.sub_modules = module.sub_modules.map((submodule) => {
              if (submodule.module_id === subModule.module_id) {
                submodule.checked = true
              }
              return submodule
            })
          }
          return module
        })
        this.setState({ checkList: checkList, menuItemSequence: menuItems })
      } else {
        menuItems.push(parentModule)
        checkList = checkList.map((module) => {
          if (module.module_id === parentModule.module_id) {
            module.sub_modules = module.sub_modules.map((submodule) => {
              submodule.checked = false
              return submodule
            })
            module.checked = true
          }
          return module
        })
        this.setState({ checkList: checkList, menuItemSequence: menuItems })
      }
    } else {
      if (subModule) {
        this.removeMenuItemFromList(
          menuItems.find((item) => item.module_id === subModule.module_id),
        )
        checkList = checkList.map((module) => {
          if (module.module_id === parentModule.module_id) {
            module.sub_modules = module.sub_modules.map((submodule) => {
              if (submodule.module_id === subModule.module_id) {
                submodule.checked = false
              }
              return submodule
            })
          }
          return module
        })
        this.setState({ checkList: checkList, menuItemSequence: menuItems })
      } else {
        this.removeMenuItemFromList(
          menuItems.find((item) => item.module_id === parentModule.module_id),
        )
        checkList = checkList.map((module) => {
          if (module.module_id === parentModule.module_id) {
            module.sub_modules = module.sub_modules.map((submodule) => {
              submodule.checked = false
              return submodule
            })
            module.checked = false
          }
          return module
        })
        this.setState({ checkList: checkList, menuItemSequence: menuItems })
      }
    }
  }

  setToDefault = () => {
    const {
      user: { sideBarModulesSettings },
    } = this.props
    this.setState({ menuItemSequence: [], checkList: [] })
    this.generateMenuItems(sideBarModulesSettings)
  }

  handleSubmit = () => {
    let menuItems = [...this.state.menuItemSequence]
    let tempArray = menuItems.map((item) => {
      return item.module_id
    })
    const { dispatch } = this.props
    let formData = new FormData()
    formData.set('user_id', this.props.user['id'])
    formData.append(`web_default`, tempArray)
    dispatch({
      type: 'user/SIDEBAR_MODULE_POST_USER_SETTINGS',
      payload: {
        formData,
      },
    })
    this.props.handleCancel()
  }
  onCancel = () => {
    const {
      user: { sideBarModulesUserSettings },
    } = this.props

    this.generateMenuItems(sideBarModulesUserSettings)
    this.props.handleCancel()
  }

  handleOnMouseOver = (name, column) => {
    this.setMenuItemActive(name, column)
  }
  handleOnMouseOut = (name, column) => {
    this.setMenuItemInactive(name, column)
  }
  convertTextToLowercase = (text) => {
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }
  setMenuItemActive = (name, column) => {
    if (column === 'left') {
      var label = document.getElementById(name + 'LabelEdit')
      label.classList.add('editNavigationHoverItemText')
      label.classList.remove('editNavigationItemText')

      var navItem = document.getElementById(name + 'EditNav')
      navItem.classList.add('editNavigationLeftItemHover')
      navItem.classList.remove('editNavigationLeftItem')
    }
    if (column === 'right') {
      // var text = document.getElementById(name + "EditNavRightText")
      // text.c

      var rightItem = document.getElementById(name + 'EditNavRight')
      rightItem.classList.add('editNavigationRightItemModuleHover')
      rightItem.classList.remove('editNavigationRightItemModule')

      // var rightSubItem = document.getElementById(name + 'EditNavRightSub')
      // rightSubItem.classList.add('editNavigationRightItemSubmoduleHover')
      // rightSubItem.classList.remove('editNavigationRightItemSubmodule')
    }
  }
  setMenuItemInactive = (name, column) => {
    if (column === 'left') {
      var label = document.getElementById(name + 'LabelEdit')
      label.classList.add('editNavigationItemText')
      label.classList.remove('editNavigationHoverItemText')

      var navItem = document.getElementById(name + 'EditNav')
      navItem.classList.add('editNavigationLeftItem')
      navItem.classList.remove('editNavigationLeftItemHover')
    }
    if (column === 'right') {
      var rightItem = document.getElementById(name + 'EditNavRight')
      rightItem.classList.add('editNavigationRightItemModule')
      rightItem.classList.remove('editNavigationRightItemModuleHover')

      // var rightSubItem = document.getElementById(name + 'EditNavRightSub')
      // rightSubItem.classList.add('editNavigationRightItemSubmodule')
      // rightSubItem.classList.remove('editNavigationRightItemSubmoduleHover')
    }
  }
  render() {
    const {
        isLightTheme,
      intl: { formatMessage },
    } = this.props
    const { menuItemSequence } = this.state
    const MAX_MENU_ITEM_LENGTH = 5
    let spaceLeft = MAX_MENU_ITEM_LENGTH - menuItemSequence.length

    const DragHandle = SortableHandle(() => (
      <div className="editNavigationDragHandler fa-stack">
        <i className="fa fa-bars" style={{ cursor: 'row-resize' }} />
        <i className="fa fa-arrows-v" style={{ cursor: 'row-resize' }} />
      </div>
    ))

    const SortableItem = SortableElement(({ value, ...props }) => (
      <div
        {...props}
        id={value.name + `EditNav`}
        className={
          value.web_fixed
            ? `main_layout_5 editNavigationLeftItemDisabled`
            : `main_layout_5 ${isLightTheme? `lightTheme` : `darkTheme`} editNavigationLeftItem`
        }
        onMouseOver={() => {
          value.web_fixed === 0 && this.handleOnMouseOver(value.name, 'left')
        }}
        onMouseOut={() => {
          value.web_fixed === 0 && this.handleOnMouseOut(value.name, 'left')
        }}
      >
        <div className="col-8 col-lg-9">
          <span id={value.name + `LabelEdit`} className="editNavigationItemText">
            {formatMessage({
              id: 'module.' + this.convertTextToLowercase(value.name),
            })}
          </span>
        </div>
        <div className="col-4 col-lg-3 text-right d-flex align-items-center">
          {value.web_fixed ? (
            <></>
          ) : (
            <span className="editNavigationDrag">
              <a
                className="editNavigationRemoveItem"
                onClick={() => this.removeMenuItemFromList(value)}
              >
                <i className="fa fa-trash" />
              </a>
            </span>
          )}
          <DragHandle />
          {/* <div className="editNavigationDrag">
            <span>{value.web_fixed ? <></> : <DragHandle />}</span>
          </div> */}
        </div>
      </div>
    ))

    const SortContainer = SortableContainer(({ children }) => {
      return <div className="editNavigationSortableContainer">{children}</div>
    })

    if (menuItemSequence === undefined) {
      return <div>no data</div>
    }
    return (
      <div id="editNavigationModal" className="col">
        <div className="row">
          <div className="col-lg-6">
            <p className="editNavigationHeader">
              {formatMessage({
                id: 'menuleft.editnavigation.navigation_items',
              })}
            </p>
            {menuItemSequence.map((value, index) => {
              if (value.web_fixed) {
                return (
                  <div key={index} className="main_layout_5 editNavigationLeftItemDisabled">
                    <div className="col-8 col-lg-9">
                      <span
                        id={value.name + `LabelEdit`}
                        className="editNavigationSortableDisableItem"
                      >
                        {formatMessage({
                          id: 'module.' + this.convertTextToLowercase(value.name),
                        })}
                      </span>
                    </div>
                    <div className="col-lg-1">
                      <></>
                    </div>
                    <div className="col-lg-2 editNavigationDrag">
                      <></>
                    </div>
                  </div>
                )
              }
            })}
            <SortContainer onSortEnd={this.onSortEnd} useDragHandle>
              {menuItemSequence.map((value, index) => {
                return (
                  value.web_fixed === 0 && (
                    <SortableItem
                      onMouseOver={() => this.handleOnMouseOver(value.name, 'left')}
                      onMouseOut={() => this.handleOnMouseOut(value.name, 'left')}
                      key={`item-${value.name}`}
                      index={index}
                      value={value}
                    />
                  )
                )
              })}
              <div className="editNavigationPlaceholder">
                {formatMessage(
                  {
                    id: 'menuleft.editnavigation.more_to_add',
                  },
                  { spaces_left: spaceLeft },
                )}
              </div>
            </SortContainer>
          </div>
          <div className="col-lg-6">
            <p className="editNavigationHeader">
              {formatMessage({
                id: 'menuleft.editnavigation.items_for_selection',
              })}
            </p>
            <div className="editNavigationItemContainer">
              {this.state.checkList.map((item, index) => {
                let subItemChecked = item.sub_modules.filter((subItem) => subItem.checked === true)
                  .length
                  ? true
                  : false
                return (
                  <>
                    <div
                      key={index}
                      id={item.name + `EditNavRight`}
                      className="main_layout_5 editNavigationRightItemModule"
                      onMouseOver={() => this.handleOnMouseOver(item.name, 'right')}
                      onMouseOut={() => this.handleOnMouseOut(item.name, 'right')}
                    >
                      <div className="row mx-0">
                        <div id={item.name + `EditNavRightText`} className="col-9 col-lg-9">
                          <p className="editNavigationItemModuleText">
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(item.name),
                            })}
                          </p>
                        </div>
                        <div className="col-3 col-lg-3">
                          <div className="editNavigationItemSwitch">
                            <Switch
                              checked={item.checked}
                              disabled={
                                item.web_fixed ||
                                subItemChecked ||
                                menuItemSequence.length >= MAX_MENU_ITEM_LENGTH
                                  ? (item.checked === true) & !item.web_fixed
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(value) => this.handleSwitchChanged(value, null, item)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {item.sub_modules.length !== 0 &&
                      item.sub_modules.map((subModule, index) => {
                        return (
                          <div
                            key={index}
                            className="main_layout_5 editNavigationRightItemSubmodule"
                          >
                            <div className="row mx-0">
                              <div className="col-8 col-lg-8 offset-1">
                                <p className="editNavigationItemSubmoduleText">
                                  {formatMessage({
                                    id: 'module.' + this.convertTextToLowercase(subModule.name),
                                  })}
                                </p>
                              </div>
                              <div className="col-3 col-lg-3">
                                <div className="editNavigationItemSwitch">
                                  <Switch
                                    checked={subModule.checked}
                                    disabled={
                                      item.web_fixed ||
                                      item.checked === true ||
                                      menuItemSequence.length >= MAX_MENU_ITEM_LENGTH
                                        ? (subModule.checked === true) & !item.web_fixed
                                          ? false
                                          : true
                                        : false
                                    }
                                    onChange={(value) =>
                                      this.handleSwitchChanged(value, subModule, item)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row editNavigationFooter">
          <div className="col-lg-6">
            <a className="resetToDefaultButton" onClick={() => this.setToDefault()}>
              <Icon type="history" />
              <span>
                {formatMessage({
                  id: 'menuleft.editnavigation.reset_to_default',
                })}
              </span>
            </a>
          </div>

          <div className="buttonWrapper col-lg-6">
            <Button className="main_layout_5 ant-btn ant-btn-secondary" onClick={this.onCancel}>
              <FormattedMessage id="common.cancel" />
            </Button>

            <Button className="main_layout_5 ant-btn ant-btn-primary" onClick={this.handleSubmit}>
              <FormattedMessage id="ct.form.save" />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditNavigation5
