import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MenuLeft1, MenuLeft2, MenuLeft4 } from './MenuLeft'
import MenuTop from './MenuTop'

const mapStateToProps = ({ settings, user }) => ({
  isMenuTop: settings.isMenuTop,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isTabletView: settings.isTabletView,
  isMobileView: settings.isMobileView,
  isLightTheme: settings.isLightTheme,
  brandSettings_layout: user.brandSettings_layout,
  user_id: user.id,
  locale: settings.locale,
})

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {
  componentDidMount() {
    const { dispatch, user_id, locale } = this.props

    dispatch({
      type: 'user/SIDEBAR_MODULE_SETTINGS',
    })
    if (user_id) {
      dispatch({
        type: 'user/SIDEBAR_MODULE_USER_SETTINGS',
        payload: {
          user_id,
        },
      })
    }
  }
  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  render() {
    const {
      isMenuTop,
      isMobileMenuOpen,
      isMobileView,
      isTabletView,
      isLightTheme,
      brandSettings_layout,
    } = this.props
    const pathName = window.location.href

    let isPathCommunity
    let tabletLandscape

    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    if (global.window.innerWidth >= 1024 && global.window.screen.width < 1366) {
      tabletLandscape = true
    } else tabletLandscape = false

    const BootstrappedMenu = () => {
      if (isPathCommunity) {
        if (tabletLandscape) {
          return (
            <DrawerMenu
              getContainer={null}
              level={null}
              open={isMobileMenuOpen}
              onMaskClick={this.toggleOpen}
              onHandleClick={this.toggleOpen}
              className={isLightTheme ? 'drawer-light' : ''}
            >
              {brandSettings_layout === 1 && <MenuLeft1 />}
              {brandSettings_layout === 2 && <MenuLeft2 toggleOpen={this.toggleOpen} />}
              <div>hi</div>
            </DrawerMenu>
          )
        }
      }
      if (isMobileView || isTabletView) {
        return (
          // <></>
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            {brandSettings_layout === 1 && <MenuLeft1 />}
            {brandSettings_layout === 2 && <MenuLeft2 toggleOpen={this.toggleOpen} />}
            {brandSettings_layout === 4 && <MenuLeft4 isDrawer={true} />}
          </DrawerMenu>
        )
      }
      if (isMenuTop) {
        return <MenuTop />
      }
      if (brandSettings_layout === 1) {
        return <MenuLeft1 />
      }
      if (brandSettings_layout === 2) {
        return <MenuLeft2 />
      }
      if (brandSettings_layout === 4) {
        return <MenuLeft4 />
      }
      return <MenuLeft1 />
    }

    return BootstrappedMenu()
  }
}

export default AppMenu
