import { origin } from '../../api/config'
import brandConfig from '../config/brand.config'

export const getApiUrlBrandName = ({ domain }) => {
  let apiUrl = ''
  let brandName = ''
  switch (domain) {
    case 'equinoxmarkets.com':
    case 'www.equinoxmarkets.com':
    case 'member.equinoxmarkets.com':
    case 'equinox-markets.com':
    case 'www.equinox-markets.com':
    case 'member.equinox-markets.com':
      apiUrl = 'https://api.equinoxmarkets.app'
      brandName = 'equinox'
      break
    case 'antospnc.com':
    case 'member.antospnc.com':
    case 'antos.io':
    case 'member.antos.io':
      apiUrl = 'https://api.antospnc.app'
      brandName = 'antos'
      break
    case '360tradehub.com':
    case 'www.360tradehub.com':
    case 'member.360tradehub.com':
    case 'staging.360tradehub.com':
    case 'tradehub360.com':
    case 'www.tradehub360.com':
    case 'member.tradehub360.com':
      apiUrl = 'https://api.360tradehub.app'
      brandName = '360tradehub'
      break
    case 'blueoceanfin.com':
    case 'www.blueoceanfin.com':
    case 'member.blueoceanfin.com':
    case 'blueocex.com':
    case 'www.blueocex.com':
    case 'member.blueocex.com':
      apiUrl = 'https://api.blueoceanfin.app'
      brandName = 'blueocean'
      break
    case 'fundamarkets.com':
    case 'www.fundamarkets.com':
    case 'member.fundamarkets.com':
      apiUrl = 'https://api.fundamarkets.app'
      brandName = 'funda'
      break
    case 'minosfutures.com':
    case 'www.minosfutures.com':
    case 'member.minosfutures.com':
      apiUrl = 'https://api.minosmarkets.app'
      brandName = 'minos'
      break
    case 'cronoscap.com':
    case 'www.cronoscap.com':
    case 'member.cronoscap.com':
      apiUrl = 'https://api.cronoscap.app'
      brandName = 'cronos'
      break
    default:
      apiUrl = 'https://api.blueoceanfin.app'
      brandName = 'blueocean'
      break
  }
  return { apiUrl, brandName }
}

export const extractPriceData = ({ priceData, platform }) => {
  let symbol
  let symbolId
  let ask
  let bid
  let pip
  let spread
  switch (platform) {
    case 'MT5':
      symbol = priceData.Symbol
      symbolId = priceData.Symbol
      ask = priceData.Ask ? Number(priceData.Ask.replace(/,/g, '')) : 0
      bid = priceData.Bid ? Number(priceData.Bid.replace(/,/g, '')) : 0
      pip = priceData.Pip
      spread = priceData.Spread
      break
    case 'cTrader':
    case 'CTrader':
      symbol = priceData.symbol
      symbolId = priceData.symbolId
      ask = priceData.ask
      bid = priceData.bid
      pip = priceData.pip
      spread = priceData.spread
      break
    default:
      symbol = ''
      symbolId = ''
      ask = 0
      bid = 0
      pip = 0
      spread = 0
      break
  }
  return { symbol, symbolId, ask, bid, pip, spread }
}

export const getDefaultSpreadGroup = ({ tradingAcc, tradingProducts }) => {
  const defaultAcc = tradingAcc.find((v) => Number(v.is_default) === 1)
  const matchedProduct = defaultAcc
    ? tradingProducts.find((v) => v.spread_group === defaultAcc.spread_group)
    : tradingProducts.find((v) => Number(v.is_public) === 1)
  return matchedProduct ? matchedProduct.spread_group : ''
}

export const getSpreadGroupSymbolPrice = ({ dispatch, tradingProducts, spreadGroup }) => {
  if (spreadGroup && tradingProducts.length > 0) {
    let brandName = getApiUrlBrandName({ domain: origin.replace('https://', '') }).brandName
    let serverName = brandConfig[brandName].cTraderServer

    let platform = ''
    let accTypeData = tradingProducts.find((v) => v.spread_group === spreadGroup)
    if (accTypeData) {
      platform = accTypeData.platform
    }

    switch (platform) {
      case 'MT5':
        dispatch({
          type: 'user/SPREAD_GROUP_SYMBOL_PRICE',
          payload: {
            spreadGroup,
          },
        })
        break
      case 'cTrader':
      case 'CTrader':
        dispatch({
          type: 'user/SPREAD_GROUP_SYMBOL_PRICE_CTRADER',
          payload: {
            spreadGroup,
            serverName,
          },
        })
        break
      default:
        break
    }
  }
}
