import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon, Divider, Button, Modal, Form, DatePicker, Input } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import 'moment-timezone'

import SVG, { Props as SVGProps } from 'react-inlinesvg'
import { getSVGIconsSet, getSVGIcon } from '../../../assets/icons'

@Form.create()
@connect(({ user }) => ({ user }))
@connect(({ settings }) => ({ settings }))
@injectIntl
class Footer3 extends React.Component {
  state = {
    gmtTime: moment().utcOffset('+01:00').format('h:mm a'),
    goLiveModalVisible: false,
  }

  showGoLiveModal = () => {
    this.setState({
      goLiveModalVisible: true,
    })
  }

  goLiveCancel = () => {
    this.setState({
      goLiveModalVisible: false,
    })
  }

  goLivehandleOk = (event) => {
    event.preventDefault()
    const { form, dispatch, user } = this.props
    form.validateFields((error, fieldsValue) => {
      if (!error) {
        const values = {
          ...fieldsValue,
          dob: fieldsValue['dob'].format('YYYY-MM-DD'),
          user_id: user['id'],
        }
        // console.log(values)
        dispatch({
          type: 'user/GO_LIVE',
          payload: values,
        })
        this.setState({
          goLiveModalVisible: false,
        })
      }
    })
    // form.resetFields()
  }

  componentDidMount() {
    this.interval = setInterval(this.updateReminderTime.bind(this), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateReminderTime() {
    const {
      settings: { isMobileView },
      user: { brandSettings },
    } = this.props
    if (isMobileView) {
      this.setState({
        gmtTime: moment().utcOffset(brandSettings.timezone).format('h:mm a'),
      })
    } else {
      this.setState({
        gmtTime: moment().utcOffset(brandSettings.timezone).format('h:mm:ss a'),
      })
    }
  }

  render() {
    const {
      form,
      user: { user, brandSettings },
      intl: { formatMessage },
      settings: { isMobileView },
    } = this.props
    const { gmtTime, goLiveModalVisible } = this.state

    const iconSet = getSVGIconsSet(3)

    let gmtString
    if (brandSettings.timezone === undefined) {
      gmtString = '+1'
    } else {
      if (brandSettings.timezone === '+00:00') {
        gmtString = ''
      } else if (brandSettings.timezone.charAt(1) === '0') {
        gmtString = brandSettings.timezone.slice(0, 1) + brandSettings.timezone.slice(2, 3)
      } else {
        gmtString = brandSettings.timezone.substring(0, 3)
      }
    }

    return (
      <>
        <div id="footer">
          <div className="inner">
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className="footerLeft col-sm-3"></div>
              <div
                className={`footerRight col-sm-9 d-flex align-items-center ${
                  isMobileView ? 'justify-content-center' : 'justify-content-end'
                }`}
              >
                {user.type === 'demo' && (
                  <Button
                    className={`golivebtn mr-3 mr-lg-0`}
                    onClick={this.showGoLiveModal}
                    shape="round"
                    style={{ height: '95%' }}
                  >
                    <FormattedMessage id="nav.go-live" />
                  </Button>
                )}
                {user['type'] !== 'demo' && (
                  <>
                    <div className="dropdown d-flex mr-4 mr-lg-4">
                      <Link to="/deposit" style={{ display: 'flex' }}>
                        <SVG
                          height={isMobileView ? '32px' : '20px'}
                          width={isMobileView ? '32px' : '20px'}
                          className="mr-2"
                          src={`${getSVGIcon(iconSet, 'deposit')}`}
                        />
                        <span className="d-none d-xl-inline">
                          {formatMessage({ id: 'topBar.deposit' })}
                        </span>
                      </Link>
                    </div>
                    {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                    <div className="dropdown d-flex mr-4 mr-lg-4">
                      <Link to="/withdrawal" style={{ display: 'flex' }}>
                        <SVG
                          height={isMobileView ? '32px' : '20px'}
                          width={isMobileView ? '32px' : '20px'}
                          className="mr-2"
                          src={`${getSVGIcon(iconSet, 'withdrawal')}`}
                        />

                        <span className="d-none d-xl-inline">
                          {formatMessage({ id: 'topBar.withdrawal' })}
                        </span>
                      </Link>
                    </div>
                  </>
                )}
                {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                <div className="supportContainer d-flex mr-4">
                  <Link to="/support" style={{ display: 'flex' }}>
                    <SVG
                      height={isMobileView ? '32px' : '20px'}
                      width={isMobileView ? '32px' : '20px'}
                      className="mr-2"
                      src={`${getSVGIcon(iconSet, 'support')}`}
                    />
                    {isMobileView ? (
                      ''
                    ) : (
                      <span className="d-none d-xl-inline">
                        {formatMessage({ id: 'footer.online_support' })}
                      </span>
                    )}
                  </Link>
                </div>
                {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                <div className="timeContainer pr-2 d-flex">
                  <a>
                    <Icon type="clock-circle" />
                    <span>
                      GMT{gmtString} {gmtTime}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          getContainer="#topbar"
          title={formatMessage({ id: 'nav.go-live' })}
          visible={goLiveModalVisible}
          onOk={this.goLivehandleOk}
          // confirmLoading={confirmLoading}
          onCancel={this.goLiveCancel}
          className="ant_modal"
        >
          <div className={`row ant_modal`}>
            <div className="col-lg-12">
              <Form layout="vertical">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.ic' }).replace('*', '')}>
                            {form.getFieldDecorator('ic', {
                              initialValue: user['ic'] ? user['ic'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.ic_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.birthday' }).replace('*', '')}>
                            {form.getFieldDecorator('dob', {
                              rules: [
                                {
                                  type: 'object',
                                  required: true,
                                  message: formatMessage({ id: 'ur.birthday_require' }),
                                },
                              ],
                            })(<DatePicker getCalendarContainer={(trigger) => trigger.parentNode} />)}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-12 my-3">
                        <h4 className="text-uppercase">
                          <strong>
                            <FormattedMessage id="ur.residence_info" />
                          </strong>
                        </h4>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.address_1' }).replace('*', '')}>
                            {form.getFieldDecorator('address_1', {
                              initialValue: user['address_1'] ? user['address_1'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.address_1_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                            {form.getFieldDecorator('address_2', {
                              initialValue: user['address_2'] ? user['address_2'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.postcode' })}>
                            {form.getFieldDecorator('postcode', {
                              initialValue: user['postcode'] ? user['postcode'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6" />
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.state' })}>
                            {form.getFieldDecorator('state', {
                              initialValue: user['state'] ? user['state'] : '',
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item label={formatMessage({ id: 'ur.city' }).replace('*', '')}>
                            {form.getFieldDecorator('city', {
                              initialValue: user['city'] ? user['city'] : '',
                              rules: [
                                {
                                  required: true,
                                  message: formatMessage({ id: 'ur.city_require' }),
                                },
                              ],
                            })(<Input size="default" />)}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default Footer3
