import axios from 'axios'

import ApiConfig from 'api/config'
import CommonConfig from './config'

class BaseApi {
  _baseURL = ApiConfig.baseURL
  // process.env.NODE_ENV === 'production'
  //   ? ApiConfig.baseURL
  //   : `https://cors-anywhere.herokuapp.com/${ApiConfig.baseURL}`
  _communityURL = ApiConfig.communityURL
  _communityENV = ApiConfig.communityENV
  _newCmsURL = ApiConfig.cmsAPI
  _cmsURL = ApiConfig.websiteURL
  _nextradesURL = ApiConfig.nextradesURL
  _marketNewsURL = CommonConfig._marketNewsURL
  _cTradaerNextradesURL = CommonConfig._cTradaerNextradesURL

  _api = null
  _uploadAPI = null
  _communityAPI = null
  _newCmsAPI = null
  _cmsAPI = null
  _nextradesAPI = null
  _marketNewsAPI = null
  _cTradaerNextradesAPI = null

  constructor() {
    let baseURL = this._baseURL

    if (!this._uploadAPI) {
      this._uploadAPI = axios.create({
        baseURL,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }

    if (!this._cmsAPI) {
      baseURL = this._cmsURL.replace('/web/#/', '/')
      this._cmsAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    }

    if (!this._newCmsAPI) {
      baseURL = this._newCmsURL
      this._newCmsAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'e01a4b78-d5ca-404e-a63c-7e8c032eeb38'
        },
      })
    }

    if (!this._nextradesAPI) {
      baseURL = this._nextradesURL
      this._nextradesAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    }

    if (!this._marketNewsAPI) {
      baseURL = this._marketNewsURL
      this._marketNewsAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    }

    
    if (!this._cTradaerNextradesAPI) {
      baseURL = this._cTradaerNextradesURL
      this._cTradaerNextradesAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    }

    if (!this._api) {
      baseURL = this._baseURL
      this._api = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        timeout: 1000000,
      })

      this._api.interceptors.request.use(
        function (config) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              '%cREQUEST: ' + config.method.toUpperCase() + ' %c' + config.baseURL + config.url,
              'color: blue; font-weight: bold;',
              'color: black;font-weight: normal;',
              config.data,
              config.headers,
            )
          }
          return config
        },
        function (error) {
          console.log('%cREQUEST: ' + error, 'color: red; font-weight: bold;')
          return Promise.reject(error)
        },
      )

      this._api.interceptors.response.use(
        function (response) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              '%cRESPONSE: ' + response.status + ' %c' + response.config.url,
              'color: blue; font-weight: bold;',
              'color: black;font-weight: normal;',
              response.data,
            )
          }
          return response
        },
        function (error) {
          console.log('%cRESPONSE: ' + error, 'color: red; font-weight: bold;')
          return Promise.reject(error)
        },
      )
    }

    if (!this._communityAPI) {
      baseURL = this._communityURL
      this._communityAPI = axios.create({
        baseURL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        timeout: 1000000,
      })

      this._communityAPI.interceptors.request.use(
        function (config) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              '%cREQUEST: ' + config.method.toUpperCase() + ' %c' + config.baseURL + config.url,
              'color: blue; font-weight: bold;',
              'color: black;font-weight: normal;',
              config.data,
              config.headers,
            )
          }
          return config
        },
        function (error) {
          console.log('%cREQUEST: ' + error, 'color: red; font-weight: bold;')
          return Promise.reject(error)
        },
      )

      this._communityAPI.interceptors.response.use(
        function (response) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              '%cRESPONSE: ' + response.status + ' %c' + response.config.url,
              'color: blue; font-weight: bold;',
              'color: black;font-weight: normal;',
              response.data,
            )
          }
          return response
        },
        function (error) {
          console.log('%cRESPONSE: ' + error, 'color: red; font-weight: bold;')
          return Promise.reject(error)
        },
      )
    }
  }

  get baseUrl() {
    return this._baseURL
  }

  get api() {
    return this._api
  }

  get communityAPI() {
    return this._communityAPI
  }

  get cmsAPI() {
    return this._cmsAPI
  }

  get newCmsAPI() {
    return this._newCmsAPI
  }

  get nextradesAPI() {
    return this._nextradesAPI
  }

  get marketNewsAPI() {
    return this._marketNewsAPI
  }

  get cTradaerNextradesAPI() {
    return this._cTradaerNextradesAPI
  }

  get uploadAPI() {
    return this._uploadAPI
  }

  get authToken() {
    return this._api.defaults.headers.common['Authorization']
  }

  set authToken(token) {
    if (token === null) {
      delete this._api.defaults.headers.common['Authorization']
      return
    }
    this._api.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }
}

export default new BaseApi()
