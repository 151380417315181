import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Popover, Progress, Modal, Form, Select, Tag } from 'antd'
import moment from 'moment'
import images from 'shared-crm/constants/images'
import data from './data.json'

const FormItem = Form.Item
const { Option } = Select

@connect(({ user }) => ({ user }))
@injectIntl
class TaskCard extends React.Component {
  state = {}

  getDayLeft = (expiry_date) => {
    var a = moment(expiry_date)
    var b = moment()
    var diff = a.diff(b, 'days')
    if (diff < 0) {
      return 'Expired'
    } else if (diff < 7) {
      return `${diff} days left`
    } else if (diff === 7) {
      return '1 week left'
    } else if (diff < 30) {
      return `${Math.trunc(diff / 7)} weeks left`
    } else if (diff === 30) {
      return `1 month left`
    } else if (diff < 365) {
      return `${Math.trunc(diff / 30)} months left`
    } else if (diff === 365) {
      return '1 year left'
    } else if (diff > 365) {
      return `${Math.trunc(diff / 365)} years left`
    }
  }

  statusTag = (status) => {
    switch (status) {
      case 'expired':
        return <Tag className="tag-expired">Expired</Tag>

      case 'none':
        return <></>

      case 'active':
        return <Tag className="tag-progress">In Progress</Tag>

      case 'completed':
        return <Tag className="tag-completed">Completed</Tag>

      default:
        return <></>
    }
  }

  render() {
    const {
      task,
      user: { loading, taskCentreActionLoading, taskCentreTaskListing },
      intl: { formatMessage },
    } = this.props

    return (
      <>
        {taskCentreTaskListing.map((task, index) => {
          let status
          if (task.status === 'Expired') {
            status = 'expired'
          } else {
            switch (task.claim_status) {
              case '':
                status = 'none'
                break
              case 'active':
                status = 'active'
                break
              case 'completed':
                status = 'completed'
                break

              default:
                break
            }
          }
          return (
            <div className="task-item" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="task-header">
                <span className="task-title">{task.name}</span>
                {this.statusTag(status)}
              </div>
              <div className="task-footer">
                {task.condition.length > 0 &&
                  task.condition.map((condition, index) => {
                    let quantifier = ''
                    switch (task.criteria) {
                      case 'Deposit Amount':
                      case 'Trade Profit':
                      case 'Group Deposit Amount (Network)':
                      case 'Group Deposit Amount (Network Only)':
                      case 'Group Trade Profit (Network)':
                      case 'Group Trade Profit (Network Only)':
                        quantifier = 'USD'
                        break
                      case 'Trade Volume':
                      case 'Group Trade Volume (Network)':
                      case 'Group Trade Volume (Network Only)':
                        quantifier = 'lots'
                        break
                      case 'No. of Trades':
                      case 'Group No. of Trades (Network)':
                      case 'Group No. of Trades (Network Only)':
                        quantifier = formatMessage({ id: 'taskCentre.trades' })
                        break
                      case 'No. of Active Clients':
                      case 'No. of Active Clients (Network)':
                      case 'No. of Active Clients (Network Only)':
                      case 'No. of Dividend':
                        quantifier = ''
                        break
                      default:
                        break
                    }

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className="task-progress-text-wrapper">
                            <span className="task-progress-text">{`${condition.progress}/${condition.value} ${quantifier}`}</span>
                          </div>
                          <div>
                            <span>{this.getDayLeft('2022-12-1')}</span>
                          </div>
                        </div>
                        <div style={{ width: '100%' }}>
                          <Progress
                            percent={(condition.progress / condition.value) * 100}
                            size="small"
                            format={() => ''}
                          />
                        </div>
                      </>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

export default TaskCard
