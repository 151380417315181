// import antdData from 'antd/lib/locale-provider/zh_TW'
import localeData from 'react-intl/locale-data/zh'
import messages from './lang/zh-HK.json'

export default {
  locale: 'zh-HK',
  antdData: null,
  localeData,
  messages: messages["zh-HK"],
}
