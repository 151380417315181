import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon, Divider, Button, Modal, Form, DatePicker, Input } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import 'moment-timezone'

@Form.create()
@connect(({ user }) => ({ user }))
@connect(({ settings }) => ({ settings }))
@injectIntl
class Footer4 extends React.Component {
  state = {
    gmtTime: moment().utcOffset('+01:00').format('h:mm:ss a'),
    goLiveModalVisible: false,
  }

  showGoLiveModal = () => {
    this.setState({
      goLiveModalVisible: true,
    })
  }

  goLiveCancel = () => {
    this.setState({
      goLiveModalVisible: false,
    })
  }

  

  componentDidMount() {
    this.interval = setInterval(this.updateReminderTime.bind(this), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateReminderTime() {
    const {
      user: { brandSettings },
    } = this.props
    this.setState({
      gmtTime: moment().utcOffset(brandSettings.timezone).format('h:mm:ss a'),
    })
  }

  render() {
    const {
      form,
      user: { user, brandSettings },
      intl: { formatMessage },
      settings: { isMobileView },
    } = this.props
    const { gmtTime, goLiveModalVisible } = this.state

    let gmtString
    if (brandSettings.timezone === undefined) {
      gmtString = '+1'
    } else {
      if (brandSettings.timezone === '+00:00') {
        gmtString = ''
      } else if (brandSettings.timezone.charAt(1) === '0') {
        gmtString = brandSettings.timezone.slice(0, 1) + brandSettings.timezone.slice(2, 3)
      } else {
        gmtString = brandSettings.timezone.substring(0, 3)
      }
    }

    return (
      <>
        <div id="footer">
          <div className="inner">
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className="footerLeft col-sm-3"></div>
              <div
                className={`footerRight col-sm-9 d-flex align-items-center ${
                  isMobileView ? 'justify-content-center' : 'justify-content-end'
                }`}
              >
                {user['type'] !== 'demo' && (
                  <>
                    <div className="dropdown d-flex mr-4 mr-lg-4">
                      <Link to="/deposit">
                        <i className="icon icmn-coin-dollar mr-2" />
                        <span className="d-none d-xl-inline">
                          {formatMessage({ id: 'topBar.deposit' })}
                        </span>
                      </Link>
                    </div>
                    {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                    <div className="dropdown d-flex mr-4 mr-lg-4">
                      <Link to="/withdrawal">
                        <i className="icon icmn-credit-card mr-2" />
                        <span className="d-none d-xl-inline">
                          {formatMessage({ id: 'topBar.withdrawal' })}
                        </span>
                      </Link>
                    </div>
                  </>
                )}
                {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                <div className="supportContainer d-flex mr-4">
                  <Link to="/support">
                    <Icon type="question-circle" />
                    {isMobileView ? (
                      ''
                    ) : (
                      <span className="d-none d-xl-inline">
                        {formatMessage({ id: 'footer.online_support' })}
                      </span>
                    )}
                  </Link>
                </div>
                {!isMobileView && <Divider type="vertical" className="mx-0 mr-lg-4" />}
                <div className="timeContainer pr-2 d-flex">
                  <a>
                    <Icon type="clock-circle" />
                    <span>
                      GMT{gmtString} {gmtTime}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer4
