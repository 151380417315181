// import antdData from 'antd/lib/locale-provider/ms_MY'
import localeData from 'react-intl/locale-data/ms'
import messages from './lang/ms-MY.json'

export default {
  locale: 'ms-MY',
  antdData: null,
  localeData,
  messages: messages["ms-MY"],
}
