import React from 'react'
import { Menu, Dropdown, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ApiConfig from 'api/config'
import languageCode from '../../../../data/languageCode'

@injectIntl
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
class LanguageSelector4 extends React.Component {
  state = {
    modalVisible: false,
    selectedLanguage: 'en-US',
  }

  changeLang = (key) => {
    const { isLoggedIn, dispatch, user } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    if (isLoggedIn) {
      dispatch({
        type: 'user/UPDATE_LANGUAGE',
        payload: {
          user_id: user.id,
          lg: languageCode[key],
        },
      })
    }
    this.setState({ selectedLanguage: key })
  }
  componentDidMount() {
    const {
      settings: { locale },
    } = this.props

    this.setState({ selectedLanguage: locale })
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props
    if (nextProps.user.user !== this.props.user.user) {
      if (this.props.isLoggedIn && nextProps.user.user.lg) {
        let lgCodeCRM = ''
        let lg = nextProps.user.user.lg
        if (nextProps.user.user.lg === 'my') {
          lg = 'ms'
        }
        lgCodeCRM = Object.keys(languageCode).find((key) => languageCode[key] === lg)
        if (!lgCodeCRM) {
          lgCodeCRM = 'en-US'
        }
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'locale',
            value: lgCodeCRM,
          },
        })
      }
    }
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    })
  }

  render() {
    const {
      isLoggedIn,
      user: { brandSettings_layout },
      settings: { locale },
      intl: { formatMessage },
    } = this.props

    const { modalVisible, selectedLanguage } = this.state

    const nextradesURL = ApiConfig.nextradesURL

    const languages = [
      {
        key: 'en-US',
        label: 'English',
        imgSrc: `${nextradesURL}public/flags/4x3/us.svg`,
      },
      {
        key: 'zh-CN',
        label: '简体中文',
        imgSrc: `${nextradesURL}public/flags/4x3/cn.svg`,
      },
      {
        key: 'zh-HK',
        label: '繁體中文',
        imgSrc: `${nextradesURL}public/flags/4x3/hk.svg`,
      },
      {
        key: 'ja-JP',
        label: '日本語',
        imgSrc: `${nextradesURL}public/flags/4x3/jp.svg`,
      },
      {
        key: 'vi-VN',
        label: 'Tiếng Việt',
        imgSrc: `${nextradesURL}public/flags/4x3/vn.svg`,
      },
      {
        key: 'ms-MY',
        label: 'Malay',
        imgSrc: `${nextradesURL}public/flags/4x3/my.svg`,
      },
      {
        key: 'th-TH',
        label: 'ภาษาไทย',
        imgSrc: `${nextradesURL}public/flags/4x3/th.svg`,
      },
      {
        key: 'km',
        label: 'ខ្មែរ',
        imgSrc: `${nextradesURL}public/flags/4x3/kh.svg`,
      },
      {
        key: 'id-ID',
        label: 'Indonesia',
        imgSrc: `${nextradesURL}public/flags/4x3/id.svg`,
      },
    ]

    return (
      <>
        <img
          onClick={this.showModal}
          alt="flag"
          className="flagImg"
          src={`${nextradesURL}public/flags/4x3/${formatMessage({
            id: 'language_flag_name',
          })}.svg`}
        />

        <Modal
          getContainer="#menuLeft"
          title={formatMessage({
            id: 'menuleft.settingsmodal.language',
          })}
          visible={modalVisible}
          onOk={this.handleSettingsOk}
          onCancel={this.closeModal}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal language_modal`}
        >
          <div className={`language_modal_wrapper`}>
            <div className={`listContainer`}>
              {languages.map((language, index) => (
                <div
                  key={index}
                  onClick={() => {
                    this.changeLang(language.key)
                  }}
                  className={`d-flex w-100 itemContainer ${
                    selectedLanguage === language.key && 'selectedLanguage'
                  }`}
                >
                  <img alt="flag" className="flagImg" src={language.imgSrc} />
                  <span>{language.label}</span>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default LanguageSelector4
