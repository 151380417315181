import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'
import messages from './lang/en-US.json'

export default {
  locale: 'en-US',  
  antdData,
  localeData,
  messages: messages["en-US"],
}
