import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Badge } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import Avatar from '../../../CleanUIComponents/Avatar'

@withRouter
@connect(({ user }) => ({ user }))
class ProfileMenu1 extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user } = this.props
    const menu = (
      <Menu selectable={false} getPopupContainer={(trigger) => trigger.parentNode}>
        <Menu.Item className="hover">
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />,
            {user.user && user.user['username'] ? user.user['username'] : ''}
          </strong>
          <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.rank" />
            </strong>
            Introducing Broker
          </div>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            Trader
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="hover">
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.user ? user.user['email'] : ''}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {user.user ? user.user['mobile'] : ''}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="hover">
          <Link to="/dashboard/Settings">
            <i className={`menuIcon icmn-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </Link>
        </Menu.Item>
        <Menu.Item className="hover">
          <Link to="/task-centre">
            <img
              className="menuIconImg"
              src="resources/images/task-centre-bw.png"
              alt="Task centre"
            />
            <span className="imgMenuItemText">
              <FormattedMessage id="nav.task-centre" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item className="hover">
          <Link to="/reward-centre">
            <img
              className="menuIconImg"
              src="resources/images/reward-centre-bw.png"
              alt="Reward centre"
            />
            <span className="imgMenuItemText">
              <FormattedMessage id="nav.reward-centre" />
            </span>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="hover">
          <a href={void 0} onClick={this.logout}>
            <i className={`menuIcon icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={this.addCount}
        getPopupContainer={() => document.getElementById('topbar')}
      >
        <div className="d-flex align-items-center mr-3 mr-lg-4">
          <Avatar size={40} border="true" borderColor="white" />
          <i className="fa fa-caret-down text-muted ml-2"></i>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu1
