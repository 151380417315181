import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { notification } from 'antd'
import IdleTimer from 'react-idle-timer'
import ApiConfig from 'api/config'
import { BroadcastChannel } from 'broadcast-channel'

import Loader from 'shared-crm/components/LayoutComponents/Loader'
import IndexLayout from 'shared-crm/layouts'
import NotFoundPage from 'shared-crm/pages/404'
import { STORAGE, VERSION } from 'shared-crm/config'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const overwriteRoute = {
  // 1: [],
  // 2: [],
  1: [
    {
      path: '/dashboard/summary',
      component: loadable(() => import('shared-crm/pages/dashboard/summary')),
    },
    {
      path: '/dashboard/Settings',
      component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings')),
    },
  ],
  2: [
    {
      path: 'dashboard/summary',
      component: loadable(() => import('shared-crm/pages/dashboard/summary2')),
    },
    {
      path: '/dashboard/Settings',
      component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings')),
    },
  ],
  3: [
    {
      path: 'dashboard/summary',
      component: loadable(() => import('shared-crm/pages/dashboard/summary3')),
    },
    {
      path: '/dashboard/Settings',
      component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings')),
    },
  ],
  4: [
    {
      path: 'dashboard/summary',
      component: loadable(() => import('shared-crm/pages/dashboard/summary4')),
    },
    {
      path: '/dashboard/Settings',
      component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings')),
    },
  ],
  5: [
    {
      path: '/dashboard/summary',
      component: loadable(() => import('shared-crm/pages/dashboard/summary5')),
    },
    {
      path: '/dashboard/Settings',
      component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings5')),
    },
    {
      path: '/dashboard/Verification',
      component: loadable(() => import('shared-crm/pages/dashboard/Verification5')),
    },
    {
      path: '/ib/clientListing',
      component: loadable(() => import('shared-crm/pages/ib/reports5/clientListing')),
    },

    {
      path: '/ib/commissionHistory',
      component: loadable(() => import('shared-crm/pages/ib/reports5/commissionHistory')),
    },

    {
      path: '/ib/clientTrades',
      component: loadable(() => import('shared-crm/pages/ib/reports5/clientTradingTxs')),
    },

    {
      path: '/ib/clientDepositsWithdrawals',
      component: loadable(() => import('shared-crm/pages/ib/reports5/clientDepWitTxs')),
    },
    {
      path: '/task-centre',
      component: loadable(() => import('shared-crm/pages/taskCentre/taskCentre5')),
    },
  ],
}

const routes = [
  // System Pages
  {
    path: '/user/auto',
    component: loadable(() => import('pages/login/index')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/login/index')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('shared-crm/pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/reset',
    component: loadable(() => import('shared-crm/pages/user/resetpassword')),
    exact: true,
  },
  {
    path: '/user/register',
    component: loadable(() => import('shared-crm/pages/user/register')),
    exact: true,
  },
  {
    path: '/user/register-demo',
    component: loadable(() => import('shared-crm/pages/user/register-demo')),
    exact: true,
  },
  {
    path: '/deposit',
    component: loadable(() => import('shared-crm/pages/deposit')),
  },
  {
    path: '/withdrawal',
    component: loadable(() => import('shared-crm/pages/withdrawal')),
  },
  {
    path: '/support',
    component: loadable(() => import('shared-crm/pages/support')),
    exact: true,
  },
  {
    path: '/support/:ticketId',
    component: loadable(() => import('shared-crm/pages/supportDetail')),
    exact: true,
  },

  {
    path: '/dashboard/summary',
    component: loadable(() => import('shared-crm/pages/dashboard/summary')),
    component_2: loadable(() => import('shared-crm/pages/dashboard/summary2')),
    component_3: loadable(() => import('shared-crm/pages/dashboard/summary3')),
    component_4: loadable(() => import('shared-crm/pages/dashboard/summary4')),
    component_5: loadable(() => import('shared-crm/pages/dashboard/summary5')),
    layout_2: true,
    layout_3: true,
    layout_4: true,
    layout_5: true,
  },
  {
    path: '/dashboard/walletManagement',
    component: loadable(() => import('shared-crm/pages/dashboard/walletManagement')),
  },
  {
    path: '/dashboard/Settings',
    component: loadable(() => import('shared-crm/pages/dashboard/Settings/Settings')),
  },
  {
    path: '/dashboard/Verification',
    component: loadable(() => import('shared-crm/pages/dashboard/Verification')),
    component_4: loadable(() => import('shared-crm/pages/dashboard/Verification4')),
    layout_2: false,
    layout_3: false,
    layout_4: true,
  },

  {
    path: '/selfTrading',
    component: loadable(() => import('shared-crm/pages/selfTrading')),
  },

  {
    path: '/managedAcc',
    component: loadable(() => import('shared-crm/pages/managedAcc')),
  },

  {
    path: '/ib/affiliateTree',
    component: loadable(() => import('shared-crm/pages/ib/affiliateTree')),
  },

  {
    path: '/ib/clientListing',
    component: loadable(() => import('shared-crm/pages/ib/reports/clientListing')),
  },

  {
    path: '/ib/commissionHistory',
    component: loadable(() => import('shared-crm/pages/ib/reports/commissionHistory')),
  },

  {
    path: '/ib/clientTrades',
    component: loadable(() => import('shared-crm/pages/ib/reports/clientTradingTxs')),
  },

  {
    path: '/ib/clientDepositsWithdrawals',
    component: loadable(() => import('shared-crm/pages/ib/reports/clientDepWitTxs')),
  },

  {
    path: '/ib/calendar',
    component: loadable(() => import('shared-crm/pages/ib/calendar')),
  },

  {
    path: '/copyTrading/myPortfolio',
    component: loadable(() => import('shared-crm/pages/copyTrading/myPortfolio')),
  },

  {
    path: '/copyTrading/mentorAccount',
    component: loadable(() => import('shared-crm/pages/copyTrading/myPortfolio/mentorAccount')),
  },

  {
    path: '/copyTrading/strategies',
    component: loadable(() => import('shared-crm/pages/copyTrading/strategies')),
  },

  {
    path: '/mamPamm/moneyManagers',
    component: loadable(() => import('shared-crm/pages/mamPamm/moneyManagers')),
  },

  {
    path: '/mamPamm/investorsAcc',
    component: loadable(() => import('shared-crm/pages/mamPamm/investorsAcc')),
  },

  {
    path: '/mamPamm/moneyManagerAcc',
    component: loadable(() => import('shared-crm/pages/mamPamm/investorsAcc/moneyManagerAcc')),
  },

  {
    path: '/liquidityPool',
    component: loadable(() => import('shared-crm/pages/liquidityPool')),
  },

  {
    path: '/economicCalendar',
    component: loadable(() => import('shared-crm/pages/economicCalendar')),
  },

  {
    path: '/task-centre',
    component: loadable(() => import('shared-crm/pages/taskCentre')),
  },

  {
    path: '/reward-centre',
    component: loadable(() => import('shared-crm/pages/rewardCentre')),
  },

  {
    path: '/trade-ideas',
    component: loadable(() => import('shared-crm/pages/tradeIdeas')),
  },

  {
    path: '/market-news',
    component: loadable(() => import('shared-crm/pages/rssNews')),
  },

  {
    path: '/promoMaterials',
    component: loadable(() => import('shared-crm/pages/promoMaterials')),
  },

  {
    path: '/live-broadcast',
    component: loadable(() => import('shared-crm/pages/liveBroadcast')),
  },

  {
    path: '/smart-score',
    component: loadable(() => import('shared-crm/pages/smartScore')),
  },

  {
    path: '/ai-technical-screener/summary',
    component: loadable(() => import('shared-crm/pages/aiTechnicalScreener')),
  },

  {
    path: '/ai-technical-screener/instrument/:baseSymbol',
    component: loadable(() => import('shared-crm/pages/aiTechnicalScreener/instrument')),
  },

  {
    path: '/markets',
    component: loadable(() => import('shared-crm/pages/markets')),
  },

  {
    path: '/webtraderMT5',
    component: loadable(() => import('shared-crm/pages/markets/webtrader/mt5')),
  },

  {
    path: '/ai-technical-screener/event-screener',
    component: loadable(() => import('shared-crm/pages/aiTechnicalScreener/eventScreener')),
  },

  // Layout
  {
    path: '/layout/bootstrap',
    component: loadable(() => import('shared-crm/pages/layout/bootstrap')),
    exact: true,
  },
  {
    path: '/layout/card',
    component: loadable(() => import('shared-crm/pages/layout/card')),
    exact: true,
  },
  {
    path: '/layout/utilities',
    component: loadable(() => import('shared-crm/pages/layout/utilities')),
    exact: true,
  },
  {
    path: '/layout/typography',
    component: loadable(() => import('shared-crm/pages/layout/typography')),
    exact: true,
  },
  {
    path: '/layout/mail-templates',
    component: loadable(() => import('shared-crm/pages/layout/mail-templates')),
    exact: true,
  },

  // Icons
  {
    path: '/icons/fontawesome',
    component: loadable(() => import('shared-crm/pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linear',
    component: loadable(() => import('shared-crm/pages/icons/linear')),
    exact: true,
  },
  {
    path: '/icons/icomoon',
    component: loadable(() => import('shared-crm/pages/icons/icomoon')),
    exact: true,
  },

  // Charts
  {
    path: '/charts/chartist',
    component: loadable(() => import('shared-crm/pages/charts/chartist')),
    exact: true,
  },
  {
    path: '/charts/chart',
    component: loadable(() => import('shared-crm/pages/charts/chart')),
    exact: true,
  },
  {
    path: '/charts/peity',
    component: loadable(() => import('shared-crm/pages/charts/peity')),
    exact: true,
  },
  {
    path: '/charts/c3',
    component: loadable(() => import('shared-crm/pages/charts/c3')),
    exact: true,
  },

  //loyalty program
  {
    path: '/loyalty-program',
    component: loadable(() => import('shared-crm/pages/loyaltyProgram')),
    exact: true,
  },

  // AntDesign
  {
    path: '/antd',
    component: loadable(() => import('shared-crm/pages/antd')),
    exact: true,
  },

  // Community
  {
    path: '/community/homepage',
    component: loadable(() => import('shared-crm/pages/community/homepage')),
  },

  {
    path: '/community/post',
    component: loadable(() => import('shared-crm/pages/community/post')),
  },
  {
    path: '/community/profile',
    component: loadable(() => import('shared-crm/pages/community/profile')),
  },
  {
    path: '/community/suggested-events',
    component: loadable(() => import('shared-crm/pages/community/event')),
  },
  {
    path: '/community/create-event',
    component: loadable(() => import('shared-crm/pages/community/create-event')),
  },
  {
    path: '/community/upcoming-events',
    component: loadable(() => import('shared-crm/pages/community/upcoming-events')),
  },
  {
    path: '/community/event',
    component: loadable(() => import('shared-crm/pages/community/single-event')),
  },

  {
    path: '/community/search',
    component: loadable(() => import('shared-crm/pages/community/search')),
  },
  {
    path: '/community/community404',
    component: loadable(() => import('shared-crm/pages/community/community404')),
  },
  // {
  //   path: '/community/zoom_test',
  //   component: loadable(() => import('shared-crm/pages/community/zoom_test')),
  // },
]

@connect(({ router }) => ({ router }))
@connect(({ user }) => ({ user }))
@injectIntl
class Router extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTimedOut: false,
      timeoutMins: 5,
      broadcastChannel: false,
    }
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  handleOnAction(event) {
    // console.log('DEBUG - user did something', event)
    // console.log('DEBUG - time remaining', this.idleTimer.getRemainingTime() / 60 / 1000)
  }

  handleOnActive(event) {
    // console.log('DEBUG - user is active', event)
    this.setState({ isTimedOut: false })
  }

  handleOnIdle(event) {
    const {
      dispatch,
      history,
      intl: { formatMessage },
    } = this.props
    // console.log('DEBUG - user is idle', event)
    // console.log('DEBUG - last active', this.idleTimer.getLastActiveTime())

    let isMarketsPageOpen = JSON.parse(localStorage.getItem('isMarketsPageOpen')) || false

    let pathBeforeLogin = ['/user/login', '/user/register', '/user/register-demo', '/user/forgot']

    if (!pathBeforeLogin.includes(history.location.pathname) && !isMarketsPageOpen) {
      notification.warning({
        message: formatMessage({ id: 'common.session_expired' }),
        description: formatMessage({ id: 'common.session_expired.note' }),
        duration: 0,
      })
      dispatch({
        type: 'user/LOGOUT',
      })
    } else {
      this.idleTimer.reset()
    }
  }

  hideLoader = () => {
    document.getElementById('loading-screen').style.display = 'none'
  }

  componentDidMount() {
    const { router } = this.props
    window.addEventListener('beforeunload', this.onUnload)
    this.hideLoader()
    // For DEBUG
    // this.intervalID = setInterval(() => {
    //   console.log(this.idleTimer.getRemainingTime())
    // }, 1000)

    const tradersroom_version = localStorage.getItem(STORAGE.TRADERSROOM_VERSION)
    const new_version = VERSION.TRADERSROOM_VERSION
    if (tradersroom_version !== new_version) {
      window.location.reload(true)
      localStorage.setItem(STORAGE.TRADERSROOM_VERSION, new_version)
    }

    let broadcastChannel = new BroadcastChannel(`${ApiConfig.baseURL}`)
    this.setState({ broadcastChannel })

    if (router.location.pathname === '/markets') {
      localStorage.setItem('isMarketsPageOpen', JSON.stringify(true))
      broadcastChannel.postMessage('new_markets_page')
    }
    broadcastChannel.addEventListener('message', (e) => {
      this.handleBroadcastMsg(e)
    })
  }

  componentDidUpdate(prevProps) {
    const { broadcastChannel } = this.state
    if (prevProps.router !== this.props.router) {
      if (this.props.router.location.pathname === '/markets') {
        broadcastChannel.postMessage('new_markets_page')
        localStorage.setItem('isMarketsPageOpen', JSON.stringify(true))
      } else {
        localStorage.setItem('isMarketsPageOpen', JSON.stringify(false))
        broadcastChannel.postMessage('check_any_markets_page')
      }
    }
  }

  handleBroadcastMsg = (msg) => {
    const { router } = this.props
    const { broadcastChannel } = this.state

    if (msg === 'new_markets_page' || msg === 'markets_page_existed') {
      localStorage.setItem('isMarketsPageOpen', JSON.stringify(true))
    } else if (msg === 'markets_page_unload') {
      localStorage.setItem('isMarketsPageOpen', JSON.stringify(false))
      setTimeout(() => broadcastChannel.postMessage('check_any_markets_page'), 1000)
    } else if (msg === 'check_any_markets_page') {
      if (router.location.pathname === '/markets') {
        broadcastChannel.postMessage('markets_page_existed')
      }
    }
  }

  onUnload = () => {
    const { router } = this.props
    const { broadcastChannel } = this.state
    if (router.location.pathname === '/markets') {
      broadcastChannel.postMessage('markets_page_unload')
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
    // For DEBUG
    // clearInterval(this.intervalID)
  }

  render() {
    const {
      history,
      user: { brandSettings_layout },
    } = this.props
    const { timeoutMins } = this.state

    // To select and overwrite the routes for different layout
    const routesIndex = routes.map((e) => e.path)
    const targetLayout = overwriteRoute[brandSettings_layout]

    var targetRoutes = [...routes]

    if (targetLayout && targetLayout.length > 0) {
      targetLayout.forEach((item) => {
        let index = routesIndex.indexOf(item.path)
        if (index >= 0) {
          targetRoutes[index] = item
        } else {
          targetRoutes.push(item)
        }
      })
    }
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref
          }}
          timeout={1000 * 60 * timeoutMins}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
          crossTab={{
            emitOnAllTabs: true,
          }}
        />
        <ConnectedRouter history={history}>
          <IndexLayout>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/dashboard/summary" />} />

              {targetRoutes.map((route) => {
                let route_component = route.component

                switch (brandSettings_layout) {
                  case 1:
                    route_component = route.component
                    break
                  case 2:
                    route_component = route.layout_2 ? route.component_2 : route.component
                    break
                  case 3:
                    route_component = route.layout_3 ? route.component_3 : route.component
                    break
                  case 4:
                    route_component = route.layout_4 ? route.component_4 : route.component
                    break

                  case 5:
                    route_component = route.layout_5 ? route.component_5 : route.component
                    break

                  default:
                    route_component = route.component
                    break
                }
                return (
                  <Route
                    path={route.path}
                    component={route_component}
                    key={route.path}
                    exact={route.exact}
                  />
                )
              })}
              <Route component={NotFoundPage} />
            </Switch>
          </IndexLayout>
        </ConnectedRouter>
      </>
    )
  }
}

export default Router
