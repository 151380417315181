import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

@connect(({ user }) => ({ user }))
@connect(({ settings }) => ({ settings }))
class Footer1 extends React.Component {
  render() {
    const {
      user: { brandSettings, brandSettings_layout },
      settings: { isMobileView },
    } = this.props

    const pathName = window.location.href

    let isPathCommunity

    let communityBottomTabHeight =
      document.getElementById('communityBottomNav') &&
      document.getElementById('communityBottomNav').offsetHeight

    let styles
    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    if (isMobileView && isPathCommunity) {
      styles = {
        padding: '0',
      }
    }

    return (
      <div
        id="footer"
        // className={`${isMobileView && isPathCommunity && 'communityPaddingBottom'}`}
        style={{
          paddingBottom:
            isMobileView === true && isPathCommunity === true && brandSettings_layout === 1
              ? '7rem'
              : null
        }}
      >
        <div
          className="inner"
          // className={`${isMobileView && isPathCommunity ? 'communityInner' : 'inner'}`}
          style={{
            paddingBottom: isMobileView === true && isPathCommunity === true ? '0' : 'unset',
          }}
          // style={styles}

          // style={{padding: '0'}}
        >
          <div className="row"></div>
          <div className="bottom">
            <div className="row">
              <div className="col-lg-8">
                <FormattedMessage id="footer.note.part_1" />
                <Link to="/support">
                  <FormattedMessage id="footer.note.part_2" />
                </Link>
                <FormattedMessage id="footer.note.part_3" />
                {brandSettings && brandSettings.support_email && (
                  <a href={`mailto:${brandSettings.support_email}`}>
                    {brandSettings.support_email}
                  </a>
                )}
                <FormattedMessage id="footer.note.part_4" />
              </div>
              <div className="col-lg-4">
                <div className="copyright">
                  <img
                    src={
                      brandSettings && brandSettings.company_logo_small
                        ? brandSettings.company_logo_small
                        : ''
                    }
                    rel="noopener noreferrer"
                    alt={
                      brandSettings && brandSettings.company_name ? brandSettings.company_name : ''
                    }
                  />
                  <span>
                    © {brandSettings && brandSettings.copyright ? brandSettings.copyright : ''}{' '}
                    <a
                      href={
                        brandSettings && brandSettings.company_website
                          ? brandSettings.company_website
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brandSettings && brandSettings.company_name
                        ? brandSettings.company_name
                        : ''}
                    </a>
                    <br />
                    <FormattedMessage id="common.copy-right" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer1
