import * as _ from 'lodash'

export const user = ({ user }) => user.user

export const news = ({ user }) => user.rssNews

export const avatar = ({ user }) => user.avatar

export const latestTicket = ({ user: { tickets } }) =>
  _.chain(tickets).orderBy(['id'], ['desc']).first().value()

export const smartScore_data = ({ user }) => user.smartScore_data

export const aiTechScreener_data = ({ user }) => user.aiTechScreener_data

export const marketsOpenTrades = ({ user }) => user.marketsOpenTrades
