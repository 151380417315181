import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { TopBar1 } from 'shared-crm/components/LayoutComponents/TopBar'
import Menu from 'shared-crm/components/LayoutComponents/Menu'
import { Footer1 } from 'shared-crm/components/LayoutComponents/Footer'
import Breadcrumbs from 'shared-crm/components/LayoutComponents/Breadcrumbs'
import Settings from 'shared-crm/components/LayoutComponents/Settings'

const mapStateToProps = ({ settings, user }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isLightTheme: settings.isLightTheme,
  brandSettings_layout: user.brandSettings_layout,
})

@withRouter
@connect(mapStateToProps)
@connect(({ settings }) => ({ settings }))
class MainLayout_1 extends React.PureComponent {
  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      isLightTheme,
      brandSettings_layout,
      settings: { isMobileView },
    } = this.props

    let isPathCommunity

    const pathName = this.props.location.pathname
    if (pathName.includes('community')) {
      isPathCommunity = true
    } else isPathCommunity = false

    return (
      <Layout
        id="main_layout"
        className={`${classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
          lightTheme: isLightTheme,
          darkTheme: !isLightTheme,
        })} main_layout_${brandSettings_layout}`}
      >
        <BackTop
          visible={pathName !== '/markets'}
          style={{
            bottom: isMobileView === true && isPathCommunity === true && '8rem',
            right: isMobileView === true && isPathCommunity === true && '2rem',
          }}
        />
        <Menu />
        <Settings />
        <Layout>
          <Layout.Header>
            <TopBar1 />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className={`utils__content h-100 ${pathName === '/markets' && 'p-0 maxWidth_unset'}`}>
              {/* {pathName !== '/markets' && <Breadcrumbs />} */}
              {pathName !== '/markets' && !pathName.includes('/community') && <Breadcrumbs />}

              {children}
            </div>
          </Layout.Content>
          {/* {pathName !== '/markets' && (
            <Layout.Footer>
              <Footer1 />
            </Layout.Footer>
          )} */}
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout_1
