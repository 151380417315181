const brandConfig = {
  equinox: {
    layout: 2,
    cTraderServer: 'GTECH',
    instrumentTablePath: '/available-pairs',
    spreadGroup: {
      standard: 'EQ-Mercury',
      vip: 'EQ-Pluto',
    },
  },
  antos: {
    layout: 2,
    cTraderServer: '',
    instrumentTablePath: '/fx',
    spreadGroup: {
      standard: 'APM15G20',
      vip: 'APM15G15',
    },
  },
  '360tradehub': {
    layout: 3,
    cTraderServer: 'GTECH',
    instrumentTablePath: '/products-1',
    spreadGroup: {
      standard: '360-Std',
      vip: '360-Pro',
    },
  },
  blueocean: {
    layout: 4,
    cTraderServer: 'BO',
    instrumentTablePath: '/web/#/products',
    spreadGroup: {
      standard: 'real\\BO-MY-Std',
      vip: 'real\\BO-MY-Pro',
      // standard: "BO-MY-Std",
      // vip: "BO-MY-Pro",
    },
  },
  funda: {
    layout: 5,
    cTraderServer: 'BO',
    instrumentTablePath: '/web/#/markets',
    spreadGroup: {
      standard: 'FM-MY-Std',
      vip: 'FM-MY-Pro',
    },
  },
  minos: {
    layout: 1,
    cTraderServer: 'GTECH',
    instrumentTablePath: '/web/#/markets',
    spreadGroup: {
      standard: 'MM-FUTRAFZT',
      vip: 'MM-Platinum',
    },
  },
  cronos: {
    layout: 1,
    cTraderServer: 'GTECH',
    instrumentTablePath: '/web/#/market',
    spreadGroup: {
      standard: 'CR-MY-Std',
      vip: 'CR-MY-Pro',
    },
  },
}

export default brandConfig
