import antdData from 'antd/lib/locale-provider/ja_JP'
import localeData from 'react-intl/locale-data/ja'
import messages from './lang/ja-JP.json'

export default {
  locale: 'ja-JP',  
  antdData,
  localeData,
  messages: messages["ja-JP"],
}
