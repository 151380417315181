import React from 'react'
import style from './style.module.scss'
import { Icon, message, Upload } from 'antd'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import images from 'shared-crm/constants/images'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg'
  if (!isJPG) {
    message.error('You can only upload JPG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJPG && isLt2M
}

@connect(({ user }) => ({ user }))
@injectIntl
class Avatar extends React.Component {
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl: imageUrl,
          loading: false,
        }),
      )
    }

    const { dispatch, user, page, mt4 } = this.props
    let formData = new FormData()
    formData.set('user_id', user['id'])
    if (page == 'masterTrader') {
      formData.set('image', info.file.originFileObj)
      formData.set('mt4', mt4)
    } else if (page == 'moneyManager') {
      formData.set('image', info.file.originFileObj)
      formData.set('mt4', mt4)
    } else {
      formData.set('file', info.file.originFileObj)
    }
    if (page == 'masterTrader') {
      dispatch({
        type: 'user/UPDATE_TRADER_PHOTO',
        payload: {
          formData,
          mt4,
        },
      })
    } else if (page == 'moneyManager') {
      dispatch({
        type: 'user/UPDATE_MONEY_MANAGER_PHOTO',
        payload: {
          formData,
          mt4,
        },
      })
    } else {
      dispatch({
        type: 'user/UPLOAD_PROFILE_PIC',
        payload: {
          formData,
        },
      })
    }
  }

  state = {
    loading: false,
    imageUrl: '',
  }

  static defaultProps = {
    size: false,
    border: false,
    borderColor: '#d2d9e5',
    uploadFlag: false,
  }

  render() {
    let { size, borderColor, border, user, isMessage, owner, uploadFlag, page } = this.props
    let src =  images.default_avatar
    // if (user.brandSettings_layout === 3) {
    //    src = images.default_avatar_3
    // }

    if (page === 'masterTrader') {
      if (user.masterTrader && user.masterTrader.profile_pic) {
        src = user.masterTrader.profile_pic
      }
    } else if (page === 'moneyManager') {
      if (user.moneyManager && user.moneyManager.profile_pic) {
        src = user.moneyManager.profile_pic
      }
    } else {
      if (user.avatar) src = user.avatar
      if (owner) src = this.props.src
    }
    const uploadButton = (
      <div className={`uploadButton ${style.uploadButton}`}>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">


        </div>
      </div>
    )
    return (
      <div
        className={`avatar ${style.avatar} ${size ? style[`size${size}`] : ''} ${
          border ? style.border : ''
        }`}
        style={{
          borderColor,
        }}
      >
        {isMessage ? (
          <img src={src} alt="User" align="middle" />
        ) : uploadFlag ? (
          <Upload
            name="avatar"
            listType="picture-card"
            className="user-profile"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            showUploadList={false}
            accept=".jpg, .jpeg, .png"
            beforeUpload={false}
            onChange={this.handleChange}
          >
            <img src={src} alt="User" align="middle" className={style.uploadImgBackground} />
            {uploadButton}
          </Upload>
        ) : (
          <img src={src} alt="User" />
        )}
      </div>
    )
  }
}

export default Avatar
