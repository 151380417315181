import React from 'react'
import { LocaleProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux'
import english from 'shared-crm/locales/en-US'
import vietnam from 'shared-crm/locales/vi-VN'
import chinese_simplified from 'shared-crm/locales/zh-CN'
import chinese_traditional from 'shared-crm/locales/zh-HK'
import cambodia from 'shared-crm/locales/kh-KH'
import malay from 'shared-crm/locales/ms-MY'
import thai from 'shared-crm/locales/th-TH'
import japanese from 'shared-crm/locales/ja-JP'
import indonesia from 'shared-crm/locales/id-ID'

addLocaleData(english.localeData)
addLocaleData(vietnam.localeData)
addLocaleData(chinese_simplified.localeData)
addLocaleData(chinese_traditional.localeData)
addLocaleData(cambodia.localeData)
addLocaleData(malay.localeData)
addLocaleData(thai.localeData)
addLocaleData(japanese.localeData)
addLocaleData(indonesia.localeData)

const locales = {
  'en-US': english,
  'vi-VN': vietnam,
  'zh-CN': chinese_simplified,
  'zh-HK': chinese_traditional,
  km: cambodia,
  'ms-MY': malay,
  'th-TH': thai,
  'ja-JP': japanese,
  'id-ID': indonesia,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]
    return (
      <LocaleProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

export default Localization
