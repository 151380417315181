let origin = window.location.origin
// let origin = 'https://member.blueocenfin.com'  // DEBUG

export default {
  _marketNewsURL: 'https://market-news.nextrades.ai/',
  _cTradaerNextradesURL: 'https://ctrader.nextrades.ai/api/rest/',
  headers: {
    'X-Parse-Application-Id': 'AT_POPApp',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  timeOut: 30000,
}
