import React, { Component } from 'react'
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  notification,
  Empty,
  Icon,
  Tooltip,
  Upload,
  Radio,
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import style from './style.module.scss'
import * as _ from 'lodash'
import ApiConfig from 'api/config'

const { Option } = Select
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const baseURL = ApiConfig.baseURL

@Form.create()
@connect(({ user }) => ({ user }))
@injectIntl
class EnterpriseModal extends Component {
  state = {
    isEnterpriseModalShown: false,
    incorporation_years_list: [],
    incorp_year: '',
    incorp_month: '',
    incorp_date: '',
    incorpDate_error_msg: false,
    years_list: [],
    dob_year: '',
    dob_month: '',
    dob_date: '',
    dob_error_msg: false,
    confirmLoading: false,
    userAccData: {},

    fileList_coi: [],
    fileList_moa: [],
    fileList_cogs: [],
    fileList_rod: [],
    fileList_ros: [],
    fileList_poi: [],
    fileList_front: [],
    fileList_back: [],
    fileList_por: [],
    btnLoading: '',
    kyc: [],
    kyc_coi: {},
    kyc_moa: {},
    kyc_cogs: {},
    kyc_rod: {},
    kyc_ros: {},
    kyc_poi: {},
    kyc_front: {},
    kyc_back: {},
    kyc_por: {},

    userData: {},
    corporateInfoData: {},
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.user.kyc, prevState.kyc)) {
      let coi = nextProps.user.kyc.filter((item) => item.type === 'kyc_coi')
      let moa = nextProps.user.kyc.filter((item) => item.type === 'kyc_moa')
      let cogs = nextProps.user.kyc.filter((item) => item.type === 'kyc_cogs')
      let rod = nextProps.user.kyc.filter((item) => item.type === 'kyc_rod')
      let ros = nextProps.user.kyc.filter((item) => item.type === 'kyc_ros')
      let poi = nextProps.user.kyc.filter((item) => item.type === 'kyc_poi')
      let front = nextProps.user.kyc.filter((item) => item.type === 'kyc_front')
      let back = nextProps.user.kyc.filter((item) => item.type === 'kyc_back')
      let por = nextProps.user.kyc.filter((item) => item.type === 'kyc_por')
      return {
        kyc: nextProps.user.kyc,
        kyc_coi: coi.length > 0 ? coi[0] : '',
        kyc_moa: moa.length > 0 ? moa[0] : '',
        kyc_cogs: cogs.length > 0 ? cogs[0] : '',
        kyc_rod: rod.length > 0 ? rod[0] : '',
        kyc_ros: ros.length > 0 ? ros[0] : '',
        kyc_poi: poi.length > 0 ? poi[0] : '',
        kyc_front: front.length > 0 ? front[0] : '',
        kyc_back: back.length > 0 ? back[0] : '',
        kyc_por: por.length > 0 ? por[0] : '',
      }
    }
    if (!_.isEqual(nextProps.user.user, prevState.userData)) {
      let year = ''
      let month = ''
      let date = ''
      if (nextProps.user.user && nextProps.user.user.birthday !== null) {
        year = nextProps.user.user.birthday.split('-')[0]
        month = nextProps.user.user.birthday.split('-')[1]
        date = nextProps.user.user.birthday.split('-')[2]
      }
      return {
        userData: nextProps.user.user,
        dob_year: year,
        dob_month: month,
        dob_date: date,
      }
    }
    if (!_.isEqual(nextProps.user.corporateInfo, prevState.corporateInfoData)) {
      let corp_year = ''
      let corp_month = ''
      let corp_date = ''
      if (
        nextProps.user.corporateInfo &&
        nextProps.user.corporateInfo.incorporation_date !== null
      ) {
        corp_year = nextProps.user.corporateInfo.incorporation_date.split('-')[0]
        corp_month = nextProps.user.corporateInfo.incorporation_date.split('-')[1]
        corp_date = nextProps.user.corporateInfo.incorporation_date.split('-')[2]
      }
      return {
        corporateInfoData: nextProps.user.corporateInfo,
        incorp_year: corp_year,
        incorp_month: corp_month,
        incorp_date: corp_date,
      }
    }
    return null
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: 'user/CORPORATE_INFO',
      payload: {
        user_id: this.props.user.id,
      },
    })

    let currentYear = new Date().getFullYear()
    let maxYear = currentYear - 100
    let minYear = currentYear - 18

    let years_list = []
    for (let year = minYear; year >= maxYear; year--) {
      years_list.push(year)
    }

    let incorporation_years_list = []
    for (let year = currentYear; year >= maxYear; year--) {
      incorporation_years_list.push(year)
    }
    this.setState({
      years_list: years_list,
      incorporation_years_list: incorporation_years_list,
    })
  }

  showModal = () => {
    this.setState({
      isEnterpriseModalShown: true,
    })
  }

  handleCancel = () => {
    const { form } = this.props
    form.resetFields()
    this.setState({
      isEnterpriseModalShown: false,
    })
  }

  handleChange = (info, type) => {
    let fileList = [...info.fileList]

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1)

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url
      }
      return file
    })

    this.setState({ [`fileList_${type}`]: fileList, btnLoading: type })

    const { dispatch } = this.props
    let formData = new FormData()
    formData.set('user_id', this.props.user['id'])
    formData.append(`file[${type}]`, fileList[0].originFileObj)

    // console.log(`file[${type}]`)
    // console.log(fileList)

    dispatch({
      type: 'user/UPLOAD_KYC',
      payload: {
        formData,
      },
    })
  }

  handleOk = (formValues) => {
    const {
      form,
      dispatch,
      user,
      intl: { formatMessage },
      user: { loading },
    } = this.props
    const { incorp_year, incorp_month, incorp_date, dob_year, dob_month, dob_date } = this.state
    form.validateFields((error, fieldsValue) => {
      if (incorp_year && incorp_month && incorp_date && dob_year && dob_month && dob_date) {
        if (!error) {
          const values = {
            ...fieldsValue,
            user_id: user['id'],
            incorporation_date: `${incorp_year}-${incorp_month}-${incorp_date}`,
            rep_dob: `${dob_year}-${dob_month}-${dob_date}`,
          }

          dispatch({
            type: 'user/UPGRADE_CORPORATE',
            payload: values,
          })

          if (!loading) {
            this.setState({
              isEnterpriseModalShown: false,
            })
            form.resetFields()
          }
        }
      } else {
        if (!incorp_year || !incorp_month || !incorp_date) {
          this.setState({ incorpDate_error_msg: true })
          notification.error({
            message: formatMessage({ id: 'ur.incorporation_date_require' }),
          })
        }
        if (!dob_year || !dob_month || !dob_date) {
          this.setState({ dob_error_msg: true })
          notification.error({
            message: formatMessage({ id: 'ur.birthday_require' }),
          })
        }
      }
    })
  }

  render() {
    const {
      isEnterpriseModalShown,
      confirmLoading,
      incorporation_years_list,
      incorpDate_error_msg,
      incorp_year,
      incorp_month,
      incorp_date,
      years_list,
      dob_error_msg,
      dob_date,
      dob_month,
      dob_year,
      kyc_coi,
      kyc_moa,
      kyc_cogs,
      kyc_rod,
      kyc_ros,
      kyc_poi,
      kyc_front,
      kyc_back,
      kyc_por,
      btnLoading,
    } = this.state
    const {
      form,
      intl: { formatMessage },
      user: { loading, user, corporateInfo },
    } = this.props

    let isUserVerified = user['verification_status'] === 'verified'

    let isCorporateInfoAvailable = Object.keys(corporateInfo).length > 0

    return (
      <React.Fragment>
        <div>
          <Button
            type="primary"
            icon="swap"
            loading={loading}
            className={`w-auto mt-0`}
            onClick={this.showModal}
          >
            <FormattedMessage id="as.upgrade_to_enterprise_acc" />
          </Button>
        </div>
        <Modal
          getContainer="#main_layout"
          title={formatMessage({ id: 'as.upgrade_to_enterprise_acc' })}
          visible={isEnterpriseModalShown}
          loading={loading}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okText={formatMessage({ id: 'ur.submit' })}
          className={`ant_modal enterpriseModal`}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                this.handleOk(values)
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
        >
          <div className={`row ant_modal`}>
            <div className={`col-lg-12`}>
              <Form layout="vertical" hideRequiredMark onSubmit={this.corporateSignUp}>
                <h4 className="text-uppercase">
                  <strong>
                    <FormattedMessage id="ur.company_info" />
                  </strong>
                </h4>
                <br />
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.company_name' })}>
                      {form.getFieldDecorator('company_name', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['company_name'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.company_name_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.reg_number' })}>
                      {form.getFieldDecorator('reg_no', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['reg_no'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.reg_number_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.nature_business' })}>
                      {form.getFieldDecorator('business_nature', {
                        initialValue: isCorporateInfoAvailable
                          ? corporateInfo['business_nature']
                          : 'Administrative/Clerical',
                      })(
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={formatMessage({ id: 'ur.nature_business' })}
                          OptionFilterProp="children"
                        >
                          <Option value="Administrative/Clerical">Administrative/Clerical</Option>
                          <Option value="Advertising/Marketing/Public Relations">
                            Advertising/Marketing/Public Relations
                          </Option>
                          <Option value="Airlines">Airlines</Option>
                          <Option value="Arts/Entertainment/Media">Arts/Entertainment/Media</Option>
                          <Option value="Broker/Dealer">Broker/Dealer</Option>
                          <Option value="Construction/Real Estate">Construction/Real Estate</Option>
                          <Option value="Education/Training">Education/Training</Option>
                          <Option value="Engineering/Architecture">Engineering/Architecture</Option>
                          <Option value="Executive Management">Executive Management</Option>
                          <Option value="Financial Services/Banking">
                            Financial Services/Banking
                          </Option>
                          <Option value="FX Trading">FX Trading</Option>
                          <Option value="Government/Military">Government/Military</Option>
                          <Option value="Healthcare">Healthcare</Option>
                          <Option value="Hospitality/Tourism">Hospitality/Tourism</Option>
                          <Option value="Information Technology">Information Technology</Option>
                          <Option value="Insurance">Insurance</Option>
                          <Option value="Law Enforcement/ Emergency Response">
                            Law Enforcement/ Emergency Response
                          </Option>
                          <Option value="Legal/Compliance">Legal/Compliance</Option>
                          <Option value="Manufacturing/ Production/ Operations">
                            Manufacturing/ Production/ Operations
                          </Option>
                          <Option value="Utilities">Utilities</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.incorporation_date' })}>
                      <div className="row mx-auto">
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 col-sm-3 p-0"
                          placeholder="Date"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ incorp_date: value })}
                          value={incorp_date}
                        >
                          <Option value="01">01</Option>
                          <Option value="02">02</Option>
                          <Option value="03">03</Option>
                          <Option value="04">04</Option>
                          <Option value="05">05</Option>
                          <Option value="06">06</Option>
                          <Option value="07">07</Option>
                          <Option value="08">08</Option>
                          <Option value="09">09</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                          <Option value="13">13</Option>
                          <Option value="14">14</Option>
                          <Option value="15">15</Option>
                          <Option value="16">16</Option>
                          <Option value="17">17</Option>
                          <Option value="18">18</Option>
                          <Option value="19">19</Option>
                          <Option value="20">20</Option>
                          <Option value="21">21</Option>
                          <Option value="22">22</Option>
                          <Option value="23">23</Option>
                          <Option value="24">24</Option>
                          <Option value="25">25</Option>
                          <Option value="26">26</Option>
                          <Option value="27">27</Option>
                          <Option value="28">28</Option>
                          <Option value="29">29</Option>
                          <Option value="30">30</Option>
                          <Option value="31">31</Option>
                        </Select>
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 col-sm-3 p-0"
                          placeholder="Month"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ incorp_month: value })}
                          value={incorp_month}
                        >
                          <Option value="01">01</Option>
                          <Option value="02">02</Option>
                          <Option value="03">03</Option>
                          <Option value="04">04</Option>
                          <Option value="05">05</Option>
                          <Option value="06">06</Option>
                          <Option value="07">07</Option>
                          <Option value="08">08</Option>
                          <Option value="09">09</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                        </Select>
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 col-sm-3 p-0"
                          placeholder="Year"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ incorp_year: value })}
                          value={incorp_year}
                        >
                          {incorporation_years_list.map((year) => (
                            <Option value={year} key={year}>
                              {year}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      {incorpDate_error_msg && (
                        <div className="text-danger mt-1">
                          {formatMessage({ id: 'ur.incorporation_date_require' })}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.phone' })}>
                      {form.getFieldDecorator('mobile', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['mobile'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.phone_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <Form.Item label={formatMessage({ id: 'ur.address_1' })}>
                      {form.getFieldDecorator('address_1', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['address_1'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.address_1_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={formatMessage({ id: 'st.city' })}>
                      {form.getFieldDecorator('city', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['city'] : '',
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-8">
                    <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                      {form.getFieldDecorator('address_2', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['address_2'] : '',
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={`${formatMessage({ id: 'ur.postcode' })} *`}>
                      {form.getFieldDecorator('postcode', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['postcode'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'st.postcode_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={`${formatMessage({ id: 'ur.state' })} *`}>
                      {form.getFieldDecorator('state', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['state'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'st.state_require' }),
                          },
                        ],
                      })(<Input size="default" />)}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={formatMessage({ id: 'ur.country' })}>
                      {form.getFieldDecorator('country', {
                        initialValue: isCorporateInfoAvailable ? corporateInfo['country'] : 'AF',
                      })(
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={formatMessage({ id: 'ur.select_country' })}
                          OptionFilterProp="children"
                        >
                          <Option value="AF">Afghanistan</Option>
                          <Option value="AL">Albania</Option>
                          <Option value="DZ">Algeria</Option>
                          <Option value="AD">Andorra</Option>
                          <Option value="AO">Angola</Option>
                          <Option value="AI">Anguilla</Option>
                          <Option value="AQ">Antarctica</Option>
                          <Option value="AG">Antigua and Barbuda</Option>
                          <Option value="AR">Argentina</Option>
                          <Option value="AM">Armenia</Option>
                          <Option value="AW">Aruba</Option>
                          <Option value="AU">Australia</Option>
                          <Option value="AT">Austria</Option>
                          <Option value="AZ">Azerbaijan</Option>
                          <Option value="BS">Bahamas</Option>
                          <Option value="BH">Bahrain</Option>
                          <Option value="BD">Bangladesh</Option>
                          <Option value="BB">Barbados</Option>
                          <Option value="BY">Belarus</Option>
                          <Option value="BE">Belgium</Option>
                          <Option value="BZ">Belize</Option>
                          <Option value="BJ">Benin</Option>
                          <Option value="BM">Bermuda</Option>
                          <Option value="BT">Bhutan</Option>
                          <Option value="BO">Bolivia</Option>
                          <Option value="BA">Bosnia and Herzegovina</Option>
                          <Option value="BW">Botswana</Option>
                          <Option value="BR">Brazil</Option>
                          <Option value="IO">British Indian Ocean</Option>
                          <Option value="BN">Brunei</Option>
                          <Option value="BG">Bulgaria</Option>
                          <Option value="BF">Burkina Faso</Option>
                          <Option value="BI">Burundi</Option>
                          <Option value="KH">Cambodia</Option>
                          <Option value="CM">Cameroon</Option>
                          <Option value="CA">Canada</Option>
                          <Option value="CV">Cape Verde</Option>
                          <Option value="KY">Cayman Islands</Option>
                          <Option value="CF">Central African Republic</Option>
                          <Option value="TD">Chad</Option>
                          <Option value="CL">Chile</Option>
                          <Option value="CN">China</Option>
                          <Option value="CX">Christmas Island</Option>
                          <Option value="CC">Cocos (Keeling) Islands</Option>
                          <Option value="CO">Colombia</Option>
                          <Option value="KM">Comoros</Option>
                          <Option value="CD">Congo, Democratic Republic of the</Option>
                          <Option value="CG">Congo, Republic of the</Option>
                          <Option value="CK">Cook Islands</Option>
                          <Option value="CR">Costa Rica</Option>
                          <Option value="HR">Croatia</Option>
                          <Option value="CY">Cyprus</Option>
                          <Option value="CZ">Czech Republic</Option>
                          <Option value="DK">Denmark</Option>
                          <Option value="DJ">Djibouti</Option>
                          <Option value="DM">Dominica</Option>
                          <Option value="DO">Dominican Republic</Option>
                          <Option value="TL">East Timor</Option>
                          <Option value="EC">Ecuador</Option>
                          <Option value="EG">Egypt</Option>
                          <Option value="SV">El Salvador</Option>
                          <Option value="GQ">Equatorial Guinea</Option>
                          <Option value="ER">Eritrea</Option>
                          <Option value="EE">Estonia</Option>
                          <Option value="ET">Ethiopia</Option>
                          <Option value="FK">Falkland Islands (Malvinas)</Option>
                          <Option value="FO">Faroe Islands</Option>
                          <Option value="FJ">Fiji</Option>
                          <Option value="FI">Finland</Option>
                          <Option value="FR">France</Option>
                          <Option value="GF">French Guiana</Option>
                          <Option value="PF">French Polynesia</Option>
                          <Option value="GA">Gabon</Option>
                          <Option value="GM">Gambia</Option>
                          <Option value="GE">Georgia</Option>
                          <Option value="DE">Germany</Option>
                          <Option value="GH">Ghana</Option>
                          <Option value="GI">Gibraltar</Option>
                          <Option value="GR">Greece</Option>
                          <Option value="GL">Greenland</Option>
                          <Option value="GD">Grenada</Option>
                          <Option value="GP">Guadeloupe</Option>
                          <Option value="GT">Guatemala</Option>
                          <Option value="GN">Guinea</Option>
                          <Option value="GW">Guinea-Bissau</Option>
                          <Option value="GY">Guyana</Option>
                          <Option value="HT">Haiti</Option>
                          <Option value="HN">Honduras</Option>
                          <Option value="HK">Hong Kong</Option>
                          <Option value="HU">Hungary</Option>
                          <Option value="IS">Iceland</Option>
                          <Option value="IN">India</Option>
                          <Option value="ID">Indonesia</Option>
                          <Option value="IE">Ireland</Option>
                          <Option value="IL">Israel</Option>
                          <Option value="IT">Italy</Option>
                          <Option value="CI">Ivory Coast (Côte d`Ivoire)</Option>
                          <Option value="JM">Jamaica</Option>
                          <Option value="JP">Japan</Option>
                          <Option value="JO">Jordan</Option>
                          <Option value="KZ">Kazakhstan</Option>
                          <Option value="KE">Kenya</Option>
                          <Option value="KI">Kiribati</Option>
                          <Option value="KR">Korea, South</Option>
                          <Option value="KW">Kuwait</Option>
                          <Option value="KG">Kyrgyzstan</Option>
                          <Option value="LA">Laos</Option>
                          <Option value="LV">Latvia</Option>
                          <Option value="LB">Lebanon</Option>
                          <Option value="LS">Lesotho</Option>
                          <Option value="LI">Liechtenstein</Option>
                          <Option value="LT">Lithuania</Option>
                          <Option value="LU">Luxembourg</Option>
                          <Option value="MO">Macau</Option>
                          <Option value="MK">Macedonia, Republic of</Option>
                          <Option value="MG">Madagascar</Option>
                          <Option value="MW">Malawi</Option>
                          <Option value="MY">Malaysia</Option>
                          <Option value="MV">Maldives</Option>
                          <Option value="ML">Mali</Option>
                          <Option value="MT">Malta</Option>
                          <Option value="MH">Marshall Islands</Option>
                          <Option value="MQ">Martinique</Option>
                          <Option value="MR">Mauritania</Option>
                          <Option value="MU">Mauritius</Option>
                          <Option value="YT">Mayotte</Option>
                          <Option value="MX">Mexico</Option>
                          <Option value="FM">Micronesia</Option>
                          <Option value="MD">Moldova</Option>
                          <Option value="MC">Monaco</Option>
                          <Option value="MN">Mongolia</Option>
                          <Option value="ME">Montenegro</Option>
                          <Option value="MS">Montserrat</Option>
                          <Option value="MA">Morocco</Option>
                          <Option value="MZ">Mozambique</Option>
                          <Option value="MM">Myanmar</Option>
                          <Option value="NA">Namibia</Option>
                          <Option value="NR">Nauru</Option>
                          <Option value="NP">Nepal</Option>
                          <Option value="NL">Netherlands</Option>
                          <Option value="AN">Netherlands Antilles</Option>
                          <Option value="NC">New Caledonia</Option>
                          <Option value="NZ">New Zealand</Option>
                          <Option value="NI">Nicaragua</Option>
                          <Option value="NE">Niger</Option>
                          <Option value="NG">Nigeria</Option>
                          <Option value="NU">Niue</Option>
                          <Option value="NF">Norfolk Island</Option>
                          <Option value="NO">Norway</Option>
                          <Option value="OM">Oman</Option>
                          <Option value="PK">Pakistan</Option>
                          <Option value="PS">Palestinian Territory</Option>
                          <Option value="PA">Panama</Option>
                          <Option value="PG">Papua New Guinea</Option>
                          <Option value="PY">Paraguay</Option>
                          <Option value="PE">Peru</Option>
                          <Option value="PH">Philippines</Option>
                          <Option value="PN">Pitcairn Island</Option>
                          <Option value="PL">Poland</Option>
                          <Option value="PT">Portugal</Option>
                          <Option value="QA">Qatar</Option>
                          <Option value="RE">Réunion</Option>
                          <Option value="RO">Romania</Option>
                          <Option value="RU">Russia</Option>
                          <Option value="RW">Rwanda</Option>
                          <Option value="SH">Saint Helena</Option>
                          <Option value="KN">Saint Kitts and Nevis</Option>
                          <Option value="LC">Saint Lucia</Option>
                          <Option value="PM">Saint Pierre and Miquelon</Option>
                          <Option value="VC">Saint Vincent and the Grenadines</Option>
                          <Option value="WS">Samoa</Option>
                          <Option value="SM">San Marino</Option>
                          <Option value="ST">São Tome and Principe</Option>
                          <Option value="SA">Saudi Arabia</Option>
                          <Option value="SN">Senegal</Option>
                          <Option value="RS">Serbia</Option>
                          <Option value="CS">Serbia and Montenegro</Option>
                          <Option value="SC">Seychelles</Option>
                          <Option value="SL">Sierra Leon</Option>
                          <Option value="SG">Singapore</Option>
                          <Option value="SK">Slovakia</Option>
                          <Option value="SI">Slovenia</Option>
                          <Option value="SB">Solomon Islands</Option>
                          <Option value="SO">Somalia</Option>
                          <Option value="ZA">South Africa</Option>
                          <Option value="GS">South Georgia and the South Sandwich Islands</Option>
                          <Option value="ES">Spain</Option>
                          <Option value="LK">Sri Lanka</Option>
                          <Option value="SR">Suriname</Option>
                          <Option value="SJ">Svalbard and Jan Mayen</Option>
                          <Option value="SZ">Swaziland</Option>
                          <Option value="SE">Sweden</Option>
                          <Option value="CH">Switzerland</Option>
                          <Option value="TW">Taiwan</Option>
                          <Option value="TJ">Tajikistan</Option>
                          <Option value="TZ">Tanzania</Option>
                          <Option value="TH">Thailand</Option>
                          <Option value="TG">Togo</Option>
                          <Option value="TK">Tokelau</Option>
                          <Option value="TO">Tonga</Option>
                          <Option value="TT">Trinidad and Tobago</Option>
                          <Option value="TN">Tunisia</Option>
                          <Option value="TR">Turkey</Option>
                          <Option value="TM">Turkmenistan</Option>
                          <Option value="TC">Turks and Caicos Islands</Option>
                          <Option value="TV">Tuvalu</Option>
                          <Option value="UG">Uganda</Option>
                          <Option value="UA">Ukraine</Option>
                          <Option value="AE">United Arab Emirates</Option>
                          <Option value="GB">United Kingdom</Option>
                          <Option value="US">United States</Option>
                          <Option value="UM">United States Minor Outlying Islands</Option>
                          <Option value="UY">Uruguay</Option>
                          <Option value="UZ">Uzbekistan</Option>
                          <Option value="VU">Vanuatu</Option>
                          <Option value="VA">Vatican City</Option>
                          <Option value="VE">Venezuela</Option>
                          <Option value="VN">Vietnam</Option>
                          <Option value="VG">Virgin Islands, British</Option>
                          <Option value="WF">Wallis and Futuna</Option>
                          <Option value="EH">Western Sahara</Option>
                          <Option value="YE">Yemen</Option>
                          <Option value="ZM">Zambia</Option>
                          <Option value="ZW">Zimbabwe</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="my-4">
                  <div className="font-weight-bold">
                    <FormattedMessage id="av.note" />
                  </div>
                  <div>
                    <FormattedMessage id="av.note_1" />
                  </div>
                  <div>
                    <FormattedMessage id="av.note_2" />
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.coi" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_coi).length === 0 ? (
                          <Empty />
                        ) : kyc_coi.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_coi.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <img src={`${baseURL}${kyc_coi.img_path}`} alt="kyc coi" />
                        )}
                      </div>
                      {form.getFieldDecorator(
                        'coi',
                        {},
                      )(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'coi')}
                          fileList={this.state.fileList_coi}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_coi).length > 0 && kyc_coi.status}>
                            <Button>
                              {Object.keys(kyc_coi).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'coi' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_coi).length > 0 &&
                                kyc_coi.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'coi' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_coi).length > 0 &&
                                kyc_coi.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'coi' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_coi).length > 0 &&
                                kyc_coi.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'coi' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_coi).length > 0 &&
                                kyc_coi.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'coi'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.rod" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_rod).length === 0 ? (
                          <Empty />
                        ) : kyc_rod.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_rod.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <a href={`${baseURL}${kyc_rod.img_path}&download=1`}>
                            <img src={`${baseURL}${kyc_rod.img_path}`} alt="kyc rod" />
                          </a>
                        )}
                      </div>
                      {form.getFieldDecorator('rod')(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'rod')}
                          fileList={this.state.fileList_rod}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_rod).length > 0 && kyc_rod.status}>
                            <Button>
                              {Object.keys(kyc_rod).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'rod' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_rod).length > 0 &&
                                kyc_rod.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'rod' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_rod).length > 0 &&
                                kyc_rod.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'rod' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_rod).length > 0 &&
                                kyc_rod.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'rod' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_rod).length > 0 &&
                                kyc_rod.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'rod'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.ros" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_ros).length === 0 ? (
                          <Empty />
                        ) : kyc_ros.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_ros.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <a href={`${baseURL}${kyc_ros.img_path}&download=1`}>
                            <img src={`${baseURL}${kyc_ros.img_path}`} alt="kyc ros" />
                          </a>
                        )}
                      </div>
                      {form.getFieldDecorator('ros')(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'ros')}
                          fileList={this.state.fileList_ros}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_ros).length > 0 && kyc_ros.status}>
                            <Button>
                              {Object.keys(kyc_ros).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'ros' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_ros).length > 0 &&
                                kyc_ros.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'ros' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_ros).length > 0 &&
                                kyc_ros.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'ros' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_ros).length > 0 &&
                                kyc_ros.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'ros' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_ros).length > 0 &&
                                kyc_ros.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'ros'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2 mt-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.moa" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_moa).length === 0 ? (
                          <Empty />
                        ) : kyc_moa.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_moa.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <a href={`${baseURL}${kyc_moa.img_path}&download=1`}>
                            <img src={`${baseURL}${kyc_moa.img_path}`} alt="kyc moa" />
                          </a>
                        )}
                      </div>
                      {form.getFieldDecorator('moa')(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'moa')}
                          fileList={this.state.fileList_moa}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_moa).length > 0 && kyc_moa.status}>
                            <Button>
                              {Object.keys(kyc_moa).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'moa' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_moa).length > 0 &&
                                kyc_moa.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'moa' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_moa).length > 0 &&
                                kyc_moa.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'moa' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_moa).length > 0 &&
                                kyc_moa.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'moa' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_moa).length > 0 &&
                                kyc_moa.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'moa'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2 mt-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.cogs" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_cogs).length === 0 ? (
                          <Empty />
                        ) : kyc_cogs.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_cogs.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <a href={`${baseURL}${kyc_cogs.img_path}&download=1`}>
                            <img src={`${baseURL}${kyc_cogs.img_path}`} alt="kyc cogs" />
                          </a>
                        )}
                      </div>
                      {form.getFieldDecorator('cogs')(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'cogs')}
                          fileList={this.state.fileList_cogs}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_cogs).length > 0 && kyc_cogs.status}>
                            <Button>
                              {Object.keys(kyc_cogs).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'cogs' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_cogs).length > 0 &&
                                kyc_cogs.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'cogs' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_cogs).length > 0 &&
                                kyc_cogs.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'cogs' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_cogs).length > 0 &&
                                kyc_cogs.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'cogs' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_cogs).length > 0 &&
                                kyc_cogs.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'cogs'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2 mt-lg-4">
                    <div className="d-flex flex-column align-items-center">
                      <div className="font-weight-bold mb-2 text-center">
                        <FormattedMessage id="av.director_poi" />
                      </div>
                      <div className={style.imgFrame}>
                        {Object.keys(kyc_poi).length === 0 ? (
                          <Empty />
                        ) : kyc_poi.fileExt === 'pdf' ? (
                          <a href={`${baseURL}${kyc_poi.img_path}&download=1`}>
                            <Icon type="file-pdf" className={style.pdfIcon} />
                          </a>
                        ) : (
                          <a href={`${baseURL}${kyc_poi.img_path}&download=1`}>
                            <img src={`${baseURL}${kyc_poi.img_path}`} alt="kyc poi" />
                          </a>
                        )}
                      </div>
                      {form.getFieldDecorator('poi')(
                        <Upload
                          accept=".jpg, .jpeg, .png, .bmp, .pdf"
                          beforeUpload={() => false}
                          className={`backBtn`}
                          onChange={(e) => this.handleChange(e, 'poi')}
                          fileList={this.state.fileList_poi}
                          showUploadList={false}
                        >
                          <Tooltip title={Object.keys(kyc_poi).length > 0 && kyc_poi.status}>
                            <Button>
                              {Object.keys(kyc_poi).length === 0 && (
                                <Icon
                                  type={loading && btnLoading === 'poi' ? 'loading' : 'upload'}
                                />
                              )}
                              {Object.keys(kyc_poi).length > 0 &&
                                kyc_poi.status.includes('Active') && (
                                  <Icon
                                    type={loading && btnLoading === 'poi' ? 'loading' : 'check'}
                                    className="text-success"
                                  />
                                )}
                              {Object.keys(kyc_poi).length > 0 &&
                                kyc_poi.status.includes('Rejected') && (
                                  <Icon
                                    type={loading && btnLoading === 'poi' ? 'loading' : 'close'}
                                    className="text-danger"
                                  />
                                )}
                              {Object.keys(kyc_poi).length > 0 &&
                                kyc_poi.status.includes('Waiting') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'poi' ? 'loading' : 'clock-circle'
                                    }
                                    className="text-warning"
                                  />
                                )}
                              {Object.keys(kyc_poi).length > 0 &&
                                kyc_poi.status.includes('Inactive') && (
                                  <Icon
                                    type={
                                      loading && btnLoading === 'poi'
                                        ? 'loading'
                                        : 'exclamation-circle'
                                    }
                                    className="text-secondary"
                                  />
                                )}
                              <FormattedMessage id="common.upload" />
                            </Button>
                          </Tooltip>
                        </Upload>,
                      )}
                    </div>
                  </div>
                </div>
                <h4 className="text-uppercase">
                  <strong>
                    <FormattedMessage id="ur.representative_info" />
                  </strong>
                </h4>
                <br />
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.name' })}>
                      {form.getFieldDecorator('rep_first_name', {
                        initialValue: user['name'] ? user['name'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.name_require' }),
                          },
                        ],
                      })(
                        <Input
                          size="default"
                          // value={user['name'] ? user['name'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.position_company' })}>
                      {form.getFieldDecorator('rep_position_in_company', {
                        initialValue: isCorporateInfoAvailable
                          ? corporateInfo['rep_position_in_company']
                          : 'Shareholder',
                      })(
                        <Select
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={formatMessage({ id: 'ur.position_company' })}
                        >
                          <Option value="Shareholder">
                            {formatMessage({ id: 'ur.shareholder' })}
                          </Option>
                          <Option value="Director">{formatMessage({ id: 'ur.director' })}</Option>
                          <Option value="Secretary">{formatMessage({ id: 'ur.secretary' })}</Option>
                          <Option value="Beneficial Owner">
                            {formatMessage({ id: 'ur.beneficial_owner' })}
                          </Option>
                          <Option value="Other">{formatMessage({ id: 'ur.other' })}</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-6">
                    <Form.Item label={formatMessage({ id: 'ur.ic' })}>
                      {form.getFieldDecorator('rep_id_no', {
                        initialValue: user['ic'] ? user['ic'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.ic_require' }),
                          },
                        ],
                      })(
                        <Input
                          size="default"
                          // value={user['ic'] ? user['ic'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-2">
                    <Form.Item label={formatMessage({ id: 'ur.gender' })}>
                      {form.getFieldDecorator('rep_gender', {
                        initialValue: user['gender'] ? user['gender'] : 'male',
                      })(
                        <RadioGroup disabled={isUserVerified ? true : false}>
                          <RadioButton value="male">
                            <FormattedMessage id="ur.male" />
                          </RadioButton>
                          <RadioButton value="female">
                            <FormattedMessage id="ur.female" />
                          </RadioButton>
                        </RadioGroup>,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={formatMessage({ id: 'ur.birthday' })}>
                      <div className="row mx-auto">
                        <Select
                          disabled={isUserVerified ? true : false}
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 p-0"
                          placeholder="Date"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ dob_date: value })}
                          value={dob_date}
                        >
                          <Option value="01">01</Option>
                          <Option value="02">02</Option>
                          <Option value="03">03</Option>
                          <Option value="04">04</Option>
                          <Option value="05">05</Option>
                          <Option value="06">06</Option>
                          <Option value="07">07</Option>
                          <Option value="08">08</Option>
                          <Option value="09">09</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                          <Option value="13">13</Option>
                          <Option value="14">14</Option>
                          <Option value="15">15</Option>
                          <Option value="16">16</Option>
                          <Option value="17">17</Option>
                          <Option value="18">18</Option>
                          <Option value="19">19</Option>
                          <Option value="20">20</Option>
                          <Option value="21">21</Option>
                          <Option value="22">22</Option>
                          <Option value="23">23</Option>
                          <Option value="24">24</Option>
                          <Option value="25">25</Option>
                          <Option value="26">26</Option>
                          <Option value="27">27</Option>
                          <Option value="28">28</Option>
                          <Option value="29">29</Option>
                          <Option value="30">30</Option>
                          <Option value="31">31</Option>
                        </Select>
                        <Select
                          disabled={isUserVerified ? true : false}
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 p-0"
                          placeholder="Month"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ dob_month: value })}
                          value={dob_month}
                        >
                          <Option value="01">01</Option>
                          <Option value="02">02</Option>
                          <Option value="03">03</Option>
                          <Option value="04">04</Option>
                          <Option value="05">05</Option>
                          <Option value="06">06</Option>
                          <Option value="07">07</Option>
                          <Option value="08">08</Option>
                          <Option value="09">09</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                        </Select>
                        <Select
                          disabled={isUserVerified ? true : false}
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          className="col-4 p-0"
                          placeholder="Year"
                          OptionFilterProp="children"
                          onChange={(value) => this.setState({ dob_year: value })}
                          value={dob_year}
                        >
                          {years_list.map((year) => (
                            <Option value={year} key={year}>
                              {year}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      {dob_error_msg && (
                        <div className="text-danger mt-1">
                          {formatMessage({ id: 'ur.birthday_require' })}
                        </div>
                      )}
                    </Form.Item>
                  </div>

                  <div className="col-xl-8">
                    <Form.Item label={formatMessage({ id: 'ur.address_1' })}>
                      {form.getFieldDecorator('rep_address_1', {
                        initialValue: user['address_1'] ? user['address_1'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'ur.address_1_require' }),
                          },
                        ],
                      })(
                        <Input
                          size="default"
                          // value={user['address_1'] ? user['address_1'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={formatMessage({ id: 'st.city' })}>
                      {form.getFieldDecorator('rep_city', {
                        initialValue: user['city'] ? user['city'] : '',
                      })(
                        <Input
                          size="default"
                          // value={user['city'] ? user['city'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-8">
                    <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                      {form.getFieldDecorator('rep_address_2', {
                        initialValue: user['address_2'] ? user['address_2'] : '',
                      })(
                        <Input
                          size="default"
                          // value={user['address_2'] ? user['address_2'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={`${formatMessage({ id: 'ur.postcode' })} *`}>
                      {form.getFieldDecorator('rep_postcode', {
                        initialValue: user['postcode'] ? user['postcode'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'st.postcode_require' }),
                          },
                        ],
                      })(
                        <Input
                          size="default"
                          // value={user['postcode'] ? user['postcode'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-7">
                    <Form.Item label={`${formatMessage({ id: 'ur.state' })} *`}>
                      {form.getFieldDecorator('rep_state', {
                        initialValue: user['state'] ? user['state'] : '',
                        rules: [
                          {
                            required: true,
                            message: formatMessage({ id: 'st.state_require' }),
                          },
                        ],
                      })(
                        <Input
                          size="default"
                          // value={user['state'] ? user['state'] : ''}
                          disabled={isUserVerified ? true : false}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-xl-4">
                    <Form.Item label={formatMessage({ id: 'ur.country' })}>
                      {form.getFieldDecorator('rep_country', {
                        initialValue: user['country'] ? user['country'] : 'AF',
                      })(
                        <Select
                          disabled={isUserVerified ? true : false}
                          id="product-edit-colors"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          // value={user['country'] ? user['country'] : 'AF'}
                          showSearch
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={formatMessage({ id: 'ur.select_country' })}
                          OptionFilterProp="children"
                        >
                          <Option value="AF">Afghanistan</Option>
                          <Option value="AL">Albania</Option>
                          <Option value="DZ">Algeria</Option>
                          <Option value="AD">Andorra</Option>
                          <Option value="AO">Angola</Option>
                          <Option value="AI">Anguilla</Option>
                          <Option value="AQ">Antarctica</Option>
                          <Option value="AG">Antigua and Barbuda</Option>
                          <Option value="AR">Argentina</Option>
                          <Option value="AM">Armenia</Option>
                          <Option value="AW">Aruba</Option>
                          <Option value="AU">Australia</Option>
                          <Option value="AT">Austria</Option>
                          <Option value="AZ">Azerbaijan</Option>
                          <Option value="BS">Bahamas</Option>
                          <Option value="BH">Bahrain</Option>
                          <Option value="BD">Bangladesh</Option>
                          <Option value="BB">Barbados</Option>
                          <Option value="BY">Belarus</Option>
                          <Option value="BE">Belgium</Option>
                          <Option value="BZ">Belize</Option>
                          <Option value="BJ">Benin</Option>
                          <Option value="BM">Bermuda</Option>
                          <Option value="BT">Bhutan</Option>
                          <Option value="BO">Bolivia</Option>
                          <Option value="BA">Bosnia and Herzegovina</Option>
                          <Option value="BW">Botswana</Option>
                          <Option value="BR">Brazil</Option>
                          <Option value="IO">British Indian Ocean</Option>
                          <Option value="BN">Brunei</Option>
                          <Option value="BG">Bulgaria</Option>
                          <Option value="BF">Burkina Faso</Option>
                          <Option value="BI">Burundi</Option>
                          <Option value="KH">Cambodia</Option>
                          <Option value="CM">Cameroon</Option>
                          <Option value="CA">Canada</Option>
                          <Option value="CV">Cape Verde</Option>
                          <Option value="KY">Cayman Islands</Option>
                          <Option value="CF">Central African Republic</Option>
                          <Option value="TD">Chad</Option>
                          <Option value="CL">Chile</Option>
                          <Option value="CN">China</Option>
                          <Option value="CX">Christmas Island</Option>
                          <Option value="CC">Cocos (Keeling) Islands</Option>
                          <Option value="CO">Colombia</Option>
                          <Option value="KM">Comoros</Option>
                          <Option value="CD">Congo, Democratic Republic of the</Option>
                          <Option value="CG">Congo, Republic of the</Option>
                          <Option value="CK">Cook Islands</Option>
                          <Option value="CR">Costa Rica</Option>
                          <Option value="HR">Croatia</Option>
                          <Option value="CY">Cyprus</Option>
                          <Option value="CZ">Czech Republic</Option>
                          <Option value="DK">Denmark</Option>
                          <Option value="DJ">Djibouti</Option>
                          <Option value="DM">Dominica</Option>
                          <Option value="DO">Dominican Republic</Option>
                          <Option value="TL">East Timor</Option>
                          <Option value="EC">Ecuador</Option>
                          <Option value="EG">Egypt</Option>
                          <Option value="SV">El Salvador</Option>
                          <Option value="GQ">Equatorial Guinea</Option>
                          <Option value="ER">Eritrea</Option>
                          <Option value="EE">Estonia</Option>
                          <Option value="ET">Ethiopia</Option>
                          <Option value="FK">Falkland Islands (Malvinas)</Option>
                          <Option value="FO">Faroe Islands</Option>
                          <Option value="FJ">Fiji</Option>
                          <Option value="FI">Finland</Option>
                          <Option value="FR">France</Option>
                          <Option value="GF">French Guiana</Option>
                          <Option value="PF">French Polynesia</Option>
                          <Option value="GA">Gabon</Option>
                          <Option value="GM">Gambia</Option>
                          <Option value="GE">Georgia</Option>
                          <Option value="DE">Germany</Option>
                          <Option value="GH">Ghana</Option>
                          <Option value="GI">Gibraltar</Option>
                          <Option value="GR">Greece</Option>
                          <Option value="GL">Greenland</Option>
                          <Option value="GD">Grenada</Option>
                          <Option value="GP">Guadeloupe</Option>
                          <Option value="GT">Guatemala</Option>
                          <Option value="GN">Guinea</Option>
                          <Option value="GW">Guinea-Bissau</Option>
                          <Option value="GY">Guyana</Option>
                          <Option value="HT">Haiti</Option>
                          <Option value="HN">Honduras</Option>
                          <Option value="HK">Hong Kong</Option>
                          <Option value="HU">Hungary</Option>
                          <Option value="IS">Iceland</Option>
                          <Option value="IN">India</Option>
                          <Option value="ID">Indonesia</Option>
                          <Option value="IE">Ireland</Option>
                          <Option value="IL">Israel</Option>
                          <Option value="IT">Italy</Option>
                          <Option value="CI">Ivory Coast (Côte d`Ivoire)</Option>
                          <Option value="JM">Jamaica</Option>
                          <Option value="JP">Japan</Option>
                          <Option value="JO">Jordan</Option>
                          <Option value="KZ">Kazakhstan</Option>
                          <Option value="KE">Kenya</Option>
                          <Option value="KI">Kiribati</Option>
                          <Option value="KR">Korea, South</Option>
                          <Option value="KW">Kuwait</Option>
                          <Option value="KG">Kyrgyzstan</Option>
                          <Option value="LA">Laos</Option>
                          <Option value="LV">Latvia</Option>
                          <Option value="LB">Lebanon</Option>
                          <Option value="LS">Lesotho</Option>
                          <Option value="LI">Liechtenstein</Option>
                          <Option value="LT">Lithuania</Option>
                          <Option value="LU">Luxembourg</Option>
                          <Option value="MO">Macau</Option>
                          <Option value="MK">Macedonia, Republic of</Option>
                          <Option value="MG">Madagascar</Option>
                          <Option value="MW">Malawi</Option>
                          <Option value="MY">Malaysia</Option>
                          <Option value="MV">Maldives</Option>
                          <Option value="ML">Mali</Option>
                          <Option value="MT">Malta</Option>
                          <Option value="MH">Marshall Islands</Option>
                          <Option value="MQ">Martinique</Option>
                          <Option value="MR">Mauritania</Option>
                          <Option value="MU">Mauritius</Option>
                          <Option value="YT">Mayotte</Option>
                          <Option value="MX">Mexico</Option>
                          <Option value="FM">Micronesia</Option>
                          <Option value="MD">Moldova</Option>
                          <Option value="MC">Monaco</Option>
                          <Option value="MN">Mongolia</Option>
                          <Option value="ME">Montenegro</Option>
                          <Option value="MS">Montserrat</Option>
                          <Option value="MA">Morocco</Option>
                          <Option value="MZ">Mozambique</Option>
                          <Option value="MM">Myanmar</Option>
                          <Option value="NA">Namibia</Option>
                          <Option value="NR">Nauru</Option>
                          <Option value="NP">Nepal</Option>
                          <Option value="NL">Netherlands</Option>
                          <Option value="AN">Netherlands Antilles</Option>
                          <Option value="NC">New Caledonia</Option>
                          <Option value="NZ">New Zealand</Option>
                          <Option value="NI">Nicaragua</Option>
                          <Option value="NE">Niger</Option>
                          <Option value="NG">Nigeria</Option>
                          <Option value="NU">Niue</Option>
                          <Option value="NF">Norfolk Island</Option>
                          <Option value="NO">Norway</Option>
                          <Option value="OM">Oman</Option>
                          <Option value="PK">Pakistan</Option>
                          <Option value="PS">Palestinian Territory</Option>
                          <Option value="PA">Panama</Option>
                          <Option value="PG">Papua New Guinea</Option>
                          <Option value="PY">Paraguay</Option>
                          <Option value="PE">Peru</Option>
                          <Option value="PH">Philippines</Option>
                          <Option value="PN">Pitcairn Island</Option>
                          <Option value="PL">Poland</Option>
                          <Option value="PT">Portugal</Option>
                          <Option value="QA">Qatar</Option>
                          <Option value="RE">Réunion</Option>
                          <Option value="RO">Romania</Option>
                          <Option value="RU">Russia</Option>
                          <Option value="RW">Rwanda</Option>
                          <Option value="SH">Saint Helena</Option>
                          <Option value="KN">Saint Kitts and Nevis</Option>
                          <Option value="LC">Saint Lucia</Option>
                          <Option value="PM">Saint Pierre and Miquelon</Option>
                          <Option value="VC">Saint Vincent and the Grenadines</Option>
                          <Option value="WS">Samoa</Option>
                          <Option value="SM">San Marino</Option>
                          <Option value="ST">São Tome and Principe</Option>
                          <Option value="SA">Saudi Arabia</Option>
                          <Option value="SN">Senegal</Option>
                          <Option value="RS">Serbia</Option>
                          <Option value="CS">Serbia and Montenegro</Option>
                          <Option value="SC">Seychelles</Option>
                          <Option value="SL">Sierra Leon</Option>
                          <Option value="SG">Singapore</Option>
                          <Option value="SK">Slovakia</Option>
                          <Option value="SI">Slovenia</Option>
                          <Option value="SB">Solomon Islands</Option>
                          <Option value="SO">Somalia</Option>
                          <Option value="ZA">South Africa</Option>
                          <Option value="GS">South Georgia and the South Sandwich Islands</Option>
                          <Option value="ES">Spain</Option>
                          <Option value="LK">Sri Lanka</Option>
                          <Option value="SR">Suriname</Option>
                          <Option value="SJ">Svalbard and Jan Mayen</Option>
                          <Option value="SZ">Swaziland</Option>
                          <Option value="SE">Sweden</Option>
                          <Option value="CH">Switzerland</Option>
                          <Option value="TW">Taiwan</Option>
                          <Option value="TJ">Tajikistan</Option>
                          <Option value="TZ">Tanzania</Option>
                          <Option value="TH">Thailand</Option>
                          <Option value="TG">Togo</Option>
                          <Option value="TK">Tokelau</Option>
                          <Option value="TO">Tonga</Option>
                          <Option value="TT">Trinidad and Tobago</Option>
                          <Option value="TN">Tunisia</Option>
                          <Option value="TR">Turkey</Option>
                          <Option value="TM">Turkmenistan</Option>
                          <Option value="TC">Turks and Caicos Islands</Option>
                          <Option value="TV">Tuvalu</Option>
                          <Option value="UG">Uganda</Option>
                          <Option value="UA">Ukraine</Option>
                          <Option value="AE">United Arab Emirates</Option>
                          <Option value="GB">United Kingdom</Option>
                          <Option value="US">United States</Option>
                          <Option value="UM">United States Minor Outlying Islands</Option>
                          <Option value="UY">Uruguay</Option>
                          <Option value="UZ">Uzbekistan</Option>
                          <Option value="VU">Vanuatu</Option>
                          <Option value="VA">Vatican City</Option>
                          <Option value="VE">Venezuela</Option>
                          <Option value="VN">Vietnam</Option>
                          <Option value="VG">Virgin Islands, British</Option>
                          <Option value="WF">Wallis and Futuna</Option>
                          <Option value="EH">Western Sahara</Option>
                          <Option value="YE">Yemen</Option>
                          <Option value="ZM">Zambia</Option>
                          <Option value="ZW">Zimbabwe</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>
                </div>
                {user['verification_status'] !== 'verified' && (
                  <>
                    <div className="my-4">
                      <div className="font-weight-bold">
                        <FormattedMessage id="av.note" />
                      </div>
                      <div>
                        <FormattedMessage id="av.note_1" />
                      </div>
                      <div>
                        <FormattedMessage id="av.note_2" />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-4 mt-2 mt-lg-4">
                        <div className="d-flex flex-column align-items-center">
                          <div className="font-weight-bold mb-2 text-center">
                            <FormattedMessage id="av.rep_poi_front" />
                          </div>
                          <div className="mb-2 text-center">
                            <FormattedMessage id="av.rep_poi_front.desc" />
                          </div>
                          <div className={style.imgFrame}>
                            {Object.keys(kyc_front).length === 0 ? (
                              <Empty />
                            ) : kyc_front.fileExt === 'pdf' ? (
                              <a href={`${baseURL}${kyc_front.img_path}&download=1`}>
                                <Icon type="file-pdf" className={style.pdfIcon} />
                              </a>
                            ) : (
                              <a href={`${baseURL}${kyc_front.img_path}&download=1`}>
                                <img src={`${baseURL}${kyc_front.img_path}`} alt="kyc front" />
                              </a>
                            )}
                          </div>
                          {form.getFieldDecorator('front')(
                            <Upload
                              accept=".jpg, .jpeg, .png, .bmp, .pdf"
                              beforeUpload={() => false}
                              className={`backBtn`}
                              onChange={(e) => this.handleChange(e, 'front')}
                              fileList={this.state.fileList_front}
                              showUploadList={false}
                            >
                              <Tooltip
                                title={Object.keys(kyc_front).length > 0 && kyc_front.status}
                              >
                                <Button>
                                  {Object.keys(kyc_front).length === 0 && (
                                    <Icon
                                      type={
                                        loading && btnLoading === 'front' ? 'loading' : 'upload'
                                      }
                                    />
                                  )}
                                  {Object.keys(kyc_front).length > 0 &&
                                    kyc_front.status.includes('Active') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'front' ? 'loading' : 'check'
                                        }
                                        className="text-success"
                                      />
                                    )}
                                  {Object.keys(kyc_front).length > 0 &&
                                    kyc_front.status.includes('Rejected') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'front' ? 'loading' : 'close'
                                        }
                                        className="text-danger"
                                      />
                                    )}
                                  {Object.keys(kyc_front).length > 0 &&
                                    kyc_front.status.includes('Waiting') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'front'
                                            ? 'loading'
                                            : 'clock-circle'
                                        }
                                        className="text-warning"
                                      />
                                    )}
                                  {Object.keys(kyc_front).length > 0 &&
                                    kyc_front.status.includes('Inactive') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'front'
                                            ? 'loading'
                                            : 'exclamation-circle'
                                        }
                                        className="text-secondary"
                                      />
                                    )}
                                  <FormattedMessage id="av.upload_front" />
                                </Button>
                              </Tooltip>
                            </Upload>,
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 mt-2 mt-lg-4">
                        <div className="d-flex flex-column align-items-center">
                          <div className="font-weight-bold mb-2 text-center">
                            <FormattedMessage id="av.rep_poi_back" />
                          </div>
                          <div className="mb-2 text-center">
                            <FormattedMessage id="av.rep_poi_back.desc" />
                          </div>
                          <div className={style.imgFrame}>
                            {Object.keys(kyc_back).length === 0 ? (
                              <Empty />
                            ) : kyc_back.fileExt === 'pdf' ? (
                              <a href={`${baseURL}${kyc_back.img_path}&download=1`}>
                                <Icon type="file-pdf" className={style.pdfIcon} />
                              </a>
                            ) : (
                              <a href={`${baseURL}${kyc_back.img_path}&download=1`}>
                                <img src={`${baseURL}${kyc_back.img_path}`} alt="kyc back" />
                              </a>
                            )}
                          </div>
                          {form.getFieldDecorator('back')(
                            <Upload
                              accept=".jpg, .jpeg, .png, .bmp, .pdf"
                              beforeUpload={() => false}
                              className={`backBtn`}
                              onChange={(e) => this.handleChange(e, 'back')}
                              fileList={this.state.fileList_back}
                              showUploadList={false}
                            >
                              <Tooltip title={Object.keys(kyc_back).length > 0 && kyc_back.status}>
                                <Button>
                                  {Object.keys(kyc_back).length === 0 && (
                                    <Icon
                                      type={loading && btnLoading === 'back' ? 'loading' : 'upload'}
                                    />
                                  )}
                                  {Object.keys(kyc_back).length > 0 &&
                                    kyc_back.status.includes('Active') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'back' ? 'loading' : 'check'
                                        }
                                        className="text-success"
                                      />
                                    )}
                                  {Object.keys(kyc_back).length > 0 &&
                                    kyc_back.status.includes('Rejected') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'back' ? 'loading' : 'close'
                                        }
                                        className="text-danger"
                                      />
                                    )}
                                  {Object.keys(kyc_back).length > 0 &&
                                    kyc_back.status.includes('Waiting') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'back'
                                            ? 'loading'
                                            : 'clock-circle'
                                        }
                                        className="text-warning"
                                      />
                                    )}
                                  {Object.keys(kyc_back).length > 0 &&
                                    kyc_back.status.includes('Inactive') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'back'
                                            ? 'loading'
                                            : 'exclamation-circle'
                                        }
                                        className="text-secondary"
                                      />
                                    )}
                                  <FormattedMessage id="av.upload_back" />
                                </Button>
                              </Tooltip>
                            </Upload>,
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 mt-2 mt-lg-4">
                        <div className="d-flex flex-column align-items-center">
                          <div className="font-weight-bold mb-2 text-center">
                            <FormattedMessage id="av.rep_por" />
                          </div>
                          <div className="mb-2 text-center">
                            <FormattedMessage id="av.por.desc" />
                          </div>
                          <div className={style.imgFrame}>
                            {Object.keys(kyc_por).length === 0 ? (
                              <Empty />
                            ) : kyc_por.fileExt === 'pdf' ? (
                              <a href={`${baseURL}${kyc_por.img_path}&download=1`}>
                                <Icon type="file-pdf" className={style.pdfIcon} />
                              </a>
                            ) : (
                              <a href={`${baseURL}${kyc_por.img_path}&download=1`}>
                                <img src={`${baseURL}${kyc_por.img_path}`} alt="kyc por" />
                              </a>
                            )}
                          </div>
                          {form.getFieldDecorator('por')(
                            <Upload
                              accept=".jpg, .jpeg, .png, .bmp, .pdf"
                              beforeUpload={() => false}
                              className={`backBtn`}
                              onChange={(e) => this.handleChange(e, 'por')}
                              fileList={this.state.fileList_por}
                              showUploadList={false}
                            >
                              <Tooltip title={Object.keys(kyc_por).length > 0 && kyc_por.status}>
                                <Button>
                                  {Object.keys(kyc_por).length === 0 && (
                                    <Icon
                                      type={loading && btnLoading === 'por' ? 'loading' : 'upload'}
                                    />
                                  )}
                                  {Object.keys(kyc_por).length > 0 &&
                                    kyc_por.status.includes('Active') && (
                                      <Icon
                                        type={loading && btnLoading === 'por' ? 'loading' : 'check'}
                                        className="text-success"
                                      />
                                    )}
                                  {Object.keys(kyc_por).length > 0 &&
                                    kyc_por.status.includes('Rejected') && (
                                      <Icon
                                        type={loading && btnLoading === 'por' ? 'loading' : 'close'}
                                        className="text-danger"
                                      />
                                    )}
                                  {Object.keys(kyc_por).length > 0 &&
                                    kyc_por.status.includes('Waiting') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'por'
                                            ? 'loading'
                                            : 'clock-circle'
                                        }
                                        className="text-warning"
                                      />
                                    )}
                                  {Object.keys(kyc_por).length > 0 &&
                                    kyc_por.status.includes('Inactive') && (
                                      <Icon
                                        type={
                                          loading && btnLoading === 'por'
                                            ? 'loading'
                                            : 'exclamation-circle'
                                        }
                                        className="text-secondary"
                                      />
                                    )}
                                  <FormattedMessage id="common.upload" />
                                </Button>
                              </Tooltip>
                            </Upload>,
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default EnterpriseModal
