import React from 'react'
import { Redirect } from 'react-router-dom'
import { Modal, Button, Form, Input, DatePicker, Divider, Switch, Tabs } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ProfileMenu2 } from './ProfileMenu'
import { LanguageSelector1 } from './LanguageSelector'
import { Link } from 'react-router-dom'
import QRCode from '../../GfxComponents/QRCode'
import ApiConfig from 'api/config'
import { connect } from 'react-redux'
import data from './data.json'
import images from 'shared-crm/constants/images'

const { TabPane } = Tabs

@Form.create()
@connect(({ settings }) => ({ settings }))
@connect(({ user }) => ({ user }))
@injectIntl
class TopBar2 extends React.Component {
  state = {
    ModalText: 'Content of the modal',
    mt4ModalVisible: false,
    QRModalVisible: false,
    goLiveModalVisible: false,
    confirmLoading: false,
    platformDownloadList: [],
  }

  componentDidMount() {
    this.getPlatformDownload()
  }

  showMT4Modal = () => {
    this.setState({
      mt4ModalVisible: true,
    })
  }

  showQRModal = () => {
    this.setState({
      QRModalVisible: true,
    })
  }

  showGoLiveModal = () => {
    this.setState({
      goLiveModalVisible: true,
    })
  }

  QRhandleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
      QRModalVisible: false,
    })
  }

  mt4handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        mt4ModalVisible: false,
        confirmLoading: false,
      })
    }, 0)
  }

  goLivehandleOk = (event) => {
    event.preventDefault()
    const { form, dispatch, user } = this.props
    form.validateFields((error, fieldsValue) => {
      if (!error) {
        const values = {
          ...fieldsValue,
          dob: fieldsValue['dob'].format('YYYY-MM-DD'),
          user_id: user['id'],
        }
        // console.log(values)
        dispatch({
          type: 'user/GO_LIVE',
          payload: values,
        })
        this.setState({
          goLiveModalVisible: false,
        })
      }
    })
    // form.resetFields()
  }

  mt4handleCancel = () => {
    this.setState({
      mt4ModalVisible: false,
    })
  }

  qrCancel = () => {
    this.setState({
      QRModalVisible: false,
    })
  }

  goLiveCancel = () => {
    this.setState({
      goLiveModalVisible: false,
    })
  }

  changeTheme = (checked) => {
    const { dispatch } = this.props
    let isLightTheme, themeSelected
    if (checked) {
      isLightTheme = true
      themeSelected = 'light'
    } else {
      isLightTheme = false
      themeSelected = 'dark'
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isLightTheme',
        value: isLightTheme,
      },
    })
    dispatch({
      type: 'user/UPDATE_PROFILE',
      payload: {
        user_id: this.props.user.id,
        theme: themeSelected,
      },
    })
  }

  getPlatformDownload = () => {
    const {
      user: { brandSettings },
    } = this.props
    const platform = ['mt4_download', 'mt5_download', 'ctrader_download', 'app_download']
    let platformList = []
    platform.map((platform) => {
      let platformLink = {}
      if (Object.keys(brandSettings).length > 0) {
        brandSettings[platform] && Object.keys(brandSettings[platform]).map((item) => {
          if (brandSettings[platform][item] !== '') {
            platformLink[item] = brandSettings[platform][item]
          }
        })
        if (Object.keys(platformLink).length > 0) {
          switch (platform) {
            case 'mt4_download':
              platformLink.name = 'MT4 Download'
              break
            case 'mt5_download':
              platformLink.name = 'MT5 Download'
              break
            case 'ctrader_download':
              platformLink.name = 'cTrader Download'
              break
            case 'app_download':
              platformLink.name = 'App Download'
              break
          }
          platformList.push(platformLink)
        }
      }
    })
    if (platformList.length > 0) {
      this.setState({ platformDownloadList: platformList })
    }
  }

  render() {
    const {
      QRModalVisible,
      mt4ModalVisible,
      goLiveModalVisible,
      confirmLoading,
      platformDownloadList,
    } = this.state
    const {
      form,
      settings: { isLightTheme },
      user: { user, brandSettings, loading },
      intl: { formatMessage },
      settings: { locale, isMobileView },
    } = this.props

    let crmURL = ApiConfig.siteURL

    let crmLang = locale
    if (crmLang.length > 2) {
      crmLang = `${locale.split('-')[0]}-${locale.split('-')[1].toLowerCase()}`
    }

    let websiteURL = brandSettings.company_website ? brandSettings.company_website : ''
    let websiteLang = 'en'

    if (websiteURL[websiteURL.length - 1] === '/') {
      websiteURL = websiteURL.slice(0, -1)
    }

    // if (data.brandLanguage[websiteURL] && data.brandLanguage[websiteURL][locale]) {
    //   websiteLang = data.brandLanguage[websiteURL][locale]
    // }
    switch (locale) {
      case 'ms-MY':
        websiteLang = 'ms'
        break
      case 'th-TH':
        websiteLang = 'th'
        break
      case 'vi-VN':
        websiteLang = 'vn'
        break
      case 'zh-CN':
        websiteLang = 'cn'
        break
      case 'zh-HK':
        websiteLang = 'zh'
        break
      case 'ja-JP':
        websiteLang = 'jp'
        break
      default:
        websiteLang = 'en'
        break
    }

    // if (websiteURL.includes('antospnc')) {
    //   if (locale === 'zh-CN') {
    //     websiteURL = `${websiteURL}/zh/%e4%b8%bb%e9%a1%b5/`
    //   }
    //   if (locale === 'ms-MY') {
    //     websiteURL = `${websiteURL}/bm/halaman-utama/`
    //   }
    // } else {
    //   websiteURL = `${websiteURL}/lang/${websiteLang}`
    // }
    // websiteURL = `${websiteURL}/lang/${websiteLang}`
    websiteURL = `${websiteURL}/home`

    let isReferrerLinkMoreThan2 =
      [
        brandSettings.home_referral_link,
        brandSettings.live_referral_link,
        brandSettings.demo_referral_link,
      ].filter((x) => x === true).length >= 2

    let isReferrerLinkAllFalse =
      !brandSettings.home_referral_link &&
      !brandSettings.live_referral_link &&
      !brandSettings.demo_referral_link

    return (
      <div id="topbar" className="row">
        {/* <Button onClick={this.changeTheme}>Change Theme</Button> */}
        <div className="col-3 h-100 d-flex">
          <Link
            to="/dashboard/summary"
            onClick={() => {
              this.setState({ morePopOverVisible: false })
              if (isMobileView) {
                this.props.toggleOpen()
              }
            }}
            className="d-flex"
          >
            <img
              className="logo"
              src={
                brandSettings
                  ? isMobileView
                    ? brandSettings.company_logo_small
                    : brandSettings.company_logo_inverse
                  : ''
              }
              alt={
                brandSettings && brandSettings.company_name_short
                  ? brandSettings.company_name_short
                  : ''
              }
            />
          </Link>
        </div>

        <div className="col-9 h-100 d-flex align-items-center justify-content-end p-0">
          {user.type === 'demo' && (
            <Button className={`btn-success mr-4`} onClick={this.showGoLiveModal} shape="round">
              <FormattedMessage id="nav.go-live" />
            </Button>
          )}

          {isMobileView ? (
            <i className={`downloadbtnMobile icmn-download mr-4`} onClick={this.showMT4Modal} />
          ) : (
            <Button
              className="downloadbtn"
              onClick={this.showMT4Modal}
              shape="round"
              icon="download"
            >
              <FormattedMessage id="topBar.platform_download" />
            </Button>
          )}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.mt4-download' })}
            visible={mt4ModalVisible}
            onOk={this.mt4handleOk}
            confirmLoading={confirmLoading}
            onCancel={this.mt4handleCancel}
            footer={[
              <Button key="submit" type="primary" onClick={this.mt4handleOk}>
                OK
              </Button>,
            ]}
            className={`ant_modal mt4_download_modal`}
          >
            {platformDownloadList.length > 0 && (
              <>
                <Tabs defaultActiveKey="0">
                  {platformDownloadList.map((item, index) => {
                    return (
                      <TabPane tab={item.name} key={index}>
                        <div className={`row ant_modal`}>
                          {Object.keys(item).map((platform, index) => {
                            if (platform !== 'name') {
                              let platformName = ''
                              let platformFormatID = ''
                              if (platform.toLowerCase().includes('desktop')) {
                                platformName = 'window'
                                platformFormatID = 'common.window'
                              } else if (platform.toLowerCase().includes('mac')) {
                                platformName = 'mac_os_x'
                                platformFormatID = 'common.mac-os-x'
                              } else if (platform.toLowerCase().includes('ios')) {
                                platformName = 'ios'
                                platformFormatID = 'common.ios'
                              } else if (platform.toLowerCase().includes('android_apk')) {
                                platformName = 'android'
                                platformFormatID = 'common.android_apk'
                              } else if (platform.toLowerCase().includes('android_download')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('android_app')) {
                                platformName = 'google_play_store'
                                platformFormatID = 'common.google-play'
                              } else if (platform.toLowerCase().includes('huawei')) {
                                platformName = 'huawei'
                                platformFormatID = 'common.huawei'
                              }

                              return (
                                // <>
                                <div key={index} className="col-lg-4">
                                  <div className={`card card--withShadow paymentCard`}>
                                    <img
                                      className="platform"
                                      src={images[platformName]}
                                      alt={platformName}
                                    />
                                    <div className="col-lg-12 mt-4">
                                      <span className="name">
                                        <FormattedMessage id={platformFormatID} />
                                      </span>
                                    </div>
                                    <div />
                                    <div className="col-lg-12">
                                      <a
                                        href={item[platform]}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        <Button type="primary" className="uploadBtn">
                                          <FormattedMessage id="common.download" />
                                        </Button>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                // </>
                              )
                            }
                          })}
                        </div>
                      </TabPane>
                    )
                  })}
                </Tabs>
              </>
            )}
            {/* <div className={`row ant_modal`}>
              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.window} alt="window" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.window" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.desktop_exe ? brandSettings.desktop_exe : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.mac_os_x} alt="mac-os-x" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.mac-os-x" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={brandSettings && brandSettings.mac_os ? brandSettings.mac_os : ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.ios} alt="ios" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.ios" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.ios_download_link
                          ? brandSettings.ios_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.android} alt="android" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.android_apk" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_apk ? brandSettings.android_apk : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.google_play_store} alt="google play" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.google-play" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.android_download_link
                          ? brandSettings.android_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className={`card card--withShadow paymentCard`}>
                  <img className="platform" src={images.huawei} alt="huawei" />
                  <div className="col-lg-12 mt-4">
                    <span className="name">
                      <FormattedMessage id="common.huawei" />
                    </span>
                  </div>
                  <div />
                  <div className="col-lg-12">
                    <a
                      href={
                        brandSettings && brandSettings.huawei_download_link
                          ? brandSettings.huawei_download_link
                          : ''
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button type="primary" className="uploadBtn">
                        <FormattedMessage id="common.download" />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </Modal>

          <Divider type="vertical" className="mx-0 mx-lg-4" />

          {user.type !== 'demo' && (
            <Link to="/dashboard/walletManagement">
              <div className="mr-4 h-100 d-flex align-items-center">
                <img src={images.wallet} className="mr-2" alt="wallet" />
                {!isMobileView && (
                  <span className="mt-1 text-white">
                    ${user['ewallet_balance'] ? user['ewallet_balance'] : '0.00'}
                  </span>
                )}
              </div>
            </Link>
          )}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'nav.go-live' })}
            visible={goLiveModalVisible}
            onOk={this.goLivehandleOk}
            // confirmLoading={confirmLoading}
            onCancel={this.goLiveCancel}
            className="ant_modal"
          >
            <div className={`row ant_modal`}>
              <div className="col-lg-12">
                <Form layout="vertical">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.ic' }).replace('*', '')}>
                              {form.getFieldDecorator('ic', {
                                initialValue: user['ic'] ? user['ic'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.ic_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.birthday' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('dob', {
                                rules: [
                                  {
                                    type: 'object',
                                    required: true,
                                    message: formatMessage({ id: 'ur.birthday_require' }),
                                  },
                                ],
                              })(<DatePicker getCalendarContainer={(trigger) => trigger.parentNode} />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-lg-12 my-3">
                          <h4 className="text-uppercase">
                            <strong>
                              <FormattedMessage id="ur.residence_info" />
                            </strong>
                          </h4>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item
                              label={formatMessage({ id: 'ur.address_1' }).replace('*', '')}
                            >
                              {form.getFieldDecorator('address_1', {
                                initialValue: user['address_1'] ? user['address_1'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.address_1_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.address_2' })}>
                              {form.getFieldDecorator('address_2', {
                                initialValue: user['address_2'] ? user['address_2'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.postcode' })}>
                              {form.getFieldDecorator('postcode', {
                                initialValue: user['postcode'] ? user['postcode'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6" />
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.state' })}>
                              {form.getFieldDecorator('state', {
                                initialValue: user['state'] ? user['state'] : '',
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <Form.Item label={formatMessage({ id: 'ur.city' }).replace('*', '')}>
                              {form.getFieldDecorator('city', {
                                initialValue: user['city'] ? user['city'] : '',
                                rules: [
                                  {
                                    required: true,
                                    message: formatMessage({ id: 'ur.city_require' }),
                                  },
                                ],
                              })(<Input size="default" />)}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Modal>

          {!isReferrerLinkAllFalse && (
            <i className={`qr icmn-qrcode mr-4`} onClick={this.showQRModal} />
          )}

          <Modal
            getContainer="#topbar"
            title={formatMessage({ id: 'topBar.modal.member_qr' })}
            visible={QRModalVisible}
            onOk={this.QRhandleOk}
            onCancel={this.qrCancel}
            footer={[
              <Button key="submit" type="primary" onClick={this.QRhandleOk}>
                OK
              </Button>,
            ]}
            className={`text-left ${isReferrerLinkMoreThan2 ? 'ant_modal_ref_qr' : 'ant_modal_ref_qr_sm'
              }`}
          >
            <div className={`row`}>
              <div className="col-12 text-center">
                <h5>
                  <FormattedMessage id="topBar.modal.member_qr.desc" />
                </h5>
              </div>
              <div className="col-12 d-flex flex-column flex-lg-row justify-content-around">
                {brandSettings.home_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.HOMEPAGE" />
                    </h3>
                    <a
                      href={`${websiteURL}?affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${websiteURL}?affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode value={`${websiteURL}?affiliate=${user['code']}`} />
                    </div>
                  </div>
                )}
                {brandSettings.live_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.LIVE" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
                {brandSettings.demo_referral_link && (
                  <div className={`text-center mt-4 mt-lg-2 qrCategoryContainer`}>
                    <h3 className="font-weight-bold">
                      <FormattedMessage id="topBar.modal.member_qr.DEMO" />
                    </h3>
                    <a
                      href={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                    </a>
                    <div className="mt-3">
                      <QRCode
                        value={`${crmURL}#/user/register-demo?language=${crmLang}&affiliate=${user['code']}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>

          <ProfileMenu2 />

          <Divider type="vertical" className="mx-0 mx-lg-4" />

          <div>
            <LanguageSelector1 isLoggedIn={true} />
          </div>

          {!isMobileView && <Divider type="vertical" className="mx-0 mx-lg-4" />}

          {!isMobileView && (
            <div className="mr-lg-4 lightDarkSwitch">
              <Switch
                checkedChildren={
                  <i className="fa fa-sun-o" style={{ color: 'black' }} aria-hidden="true"></i>
                }
                unCheckedChildren={<i className="fa fa-moon-o" aria-hidden="true"></i>}
                checked={isLightTheme}
                disabled={loading}
                onChange={(checked) => this.changeTheme(checked)}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TopBar2
