// import antdData from 'antd/lib/locale-provider/zh_CN'
import localeData from 'react-intl/locale-data/zh'
import messages from './lang/zh-CN.json'

export default {
  locale: 'zh-CN',
  antdData: null,
  localeData,
  messages: messages["zh-CN"],
}
