export async function getLeftMenuData() {
  return [
    {
      title: 'nav.dashboard',
      key: 'summary',
      url: '/dashboard/summary',
    },
    {
      title: 'nav.myaccount',
      key: 'myaccount',
      children: [
        {
          title: 'nav.wallet-management',
          key: 'walletmanagement',
          url: '/dashboard/walletManagement',
        },
        {
          title: 'nav.account-settings',
          key: 'Settings',
          url: '/dashboard/Settings',
        },
        {
          title: 'nav.account-verification',
          key: 'Verification',
          url: '/dashboard/Verification',
        },
      ],
    },
    {
      title: 'nav.self-trading',
      key: 'selfTrading',
      url: '/selfTrading',
    },
    // {
    //   title: 'Managed Account',
    //   key: 'managedAcc',
    //   url: '/managedAcc',
    // },
    {
      title: 'nav.copy-trading',
      key: 'copytrading',
      children: [
        {
          title: 'nav.my-portfolio',
          key: 'myportfolio',
          url: '/copyTrading/myportfolio',
        },
        {
          title: 'nav.strategies',
          key: 'strategies',
          url: '/copyTrading/strategies',
        },
        {
          title: 'nav.mentor-account',
          key: 'mentorAccount',
          url: '/copyTrading/mentorAccount',
        },
      ],
    },

    {
      title: 'nav.mam-pamm',
      key: 'mampamm',
      children: [
        {
          title: 'nav.money-managers',
          key: 'moneymanagers',
          url: '/mamPamm/moneyManagers',
        },
        {
          title: 'nav.investors-account',
          key: 'investorsaccount',
          url: '/mamPamm/investorsAcc',
        },
        {
          title: 'nav.money-manager-acc',
          key: 'moneymanageracc',
          url: '/mamPamm/moneyManagerAcc',
        },
      ],
    },

    {
      title: 'nav.liquidity-pool',
      key: 'liquidity-pool',
      url: '/liquidityPool',
    },

    {
      title: 'nav.affiliate-program',
      key: 'affiliate-program',
      children: [
        {
          title: 'nav.affiliate-tree',
          key: 'affiliateTree',
          url: '/ib/affiliateTree',
        },
        {
          title: 'nav.client-listing',
          key: 'client-listing',
          url: '/ib/clientListing',
        },
        {
          title: 'nav.commission-history',
          key: 'commission-history',
          url: '/ib/commissionHistory',
        },
        {
          title: 'nav.client-trades',
          key: 'client-trades',
          url: '/ib/clientTrades',
        },
        {
          title: 'nav.client-deposits-withdrawals',
          key: 'client-deposits-withdrawals',
          url: '/ib/clientDepositsWithdrawals',
        },
      ],
    },
    {
      title: 'Loyalty Program',
      key: 'loyalty-program',
      url: '/loyalty-program',
    },
    // {
    //   title: 'nav.news-events',
    //   key: 'news-events',
    //   children: [
    //     {
    //       title: 'nav.calendar',
    //       key: 'calendar',
    //       url: '/ib/calendar',
    //     },
    //   ],
    // },
    {
      title: 'nav.analysis',
      key: 'analysis',
      children: [
        {
          title: 'nav.economic-calendar',
          key: 'economic-calendar',
          url: '/economicCalendar',
        },
        {
          title: 'nav.trade-ideas',
          key: 'trade-ideas',
          url: '/trade-ideas',
        },
        {
          title: 'nav.rss-news',
          key: 'rss-news',
          url: '/market-news',
        },
        {
          title: 'module.live_broadcast',
          key: 'live-broadcast',
          url: '/live-broadcast',
        },
        {
          title: 'nav.smart-score',
          key: 'smart-score',
          url: '/smart-score',
        },
        {
          title: 'module.a_i_technical_screener',
          key: 'ai-technical-screener',
          url: '/ai-technical-screener/summary',
        },
        {
          title: 'module.a_i_technical_screener',
          key: 'ai-technical-screener',
          url: '/ai-technical-screener/event-screener',
        },
      ],
    },

    {
      title: 'nav.community',
      key: 'community',
      children: [
        {
          title: 'nav.news-feed',
          key: 'Verification',
          url: '/community/homepage',
        },
        {
          title: 'nav.events',
          key: 'Settings',
          url: '/community/suggested-events',
        },
       
      ],
    },
    {
      title: 'nav.promo-materials',
      key: 'promo-materials',
      url: '/promoMaterials',
    },
    {
      title: 'nav.task-centre',
      key: 'task-centre',
      url: '/task-centre',
    },
    {
      title: 'nav.reward-centre',
      key: 'reward-centre',
      url: '/reward-centre',
    },
    {
      title: 'nav.deposit',
      key: 'deposit',
      url: '/deposit',
    },
    {
      title: 'nav.withdrawal',
      key: 'withdrawal',
      url: '/withdrawal',
    },
    {
      title: 'nav.support',
      key: 'support',
      url: '/support',
    },
    {
      title: 'nav.markets',
      key: 'markets',
      url: '/markets',
    },
  ]
}
export async function getLeftMenuDataDemo() {
  return [
    {
      title: 'nav.dashboard',
      key: 'summary',
      url: '/dashboard/summary',
    },
    {
      title: 'nav.myaccount',
      key: 'myaccount',
      children: [
        {
          title: 'nav.account-settings',
          key: 'Settings',
          url: '/dashboard/Settings',
        },
        {
          title: 'nav.account-verification',
          key: 'Verification',
          url: '/dashboard/Verification',
        },
      ],
    },
    {
      title: 'nav.self-trading',
      key: 'selfTrading',
      url: '/selfTrading',
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com/react/getting-started',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Pages',
      key: 'pages',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
        {
          title: 'Dashboard Beta',
          key: 'dashboardBeta',
          url: '/dashboard/beta',
          pro: true,
        },
        {
          title: 'Dashboard Crypto',
          key: 'dashboardCrypto',
          url: '/dashboard/crypto',
          pro: true,
        },
        {
          title: 'Dashboard Gamma',
          key: 'dashboardGamma',
          url: '/dashboard/gamma',
          pro: true,
        },
        {
          title: 'Dashboard Docs',
          key: 'dashboardDocs',
          url: '/dashboard/docs',
          pro: true,
        },
        {
          divider: true,
        },
        {
          title: 'Default Pages',
          key: 'defaultPages',
          children: [
            {
              key: 'loginAlpha',
              title: 'Login Alpha',
              url: '/pages/login-alpha',
              pro: true,
            },
            {
              key: 'loginBeta',
              title: 'Login Beta',
              url: '/pages/login-beta',
              pro: true,
            },
            {
              key: 'register',
              title: 'Register',
              url: '/pages/register',
              pro: true,
            },
            {
              key: 'lockscreen',
              title: 'Lockscreen',
              url: '/pages/lockscreen',
              pro: true,
            },
            {
              key: 'pricingTable',
              title: 'Pricing Tables',
              url: '/pages/pricing-table',
              pro: true,
            },
            {
              key: 'invoice',
              title: 'Invoice',
              url: '/pages/invoice',
              pro: true,
            },
          ],
        },
        {
          title: 'Ecommerce',
          key: 'ecommerce',
          children: [
            {
              title: 'Dashboard',
              key: 'dashboard',
              url: '/ecommerce/dashboard',
              pro: true,
            },
            {
              title: 'Products Catalog',
              key: 'productsCatalog',
              url: '/ecommerce/products-catalog',
              pro: true,
            },
            {
              title: 'Products Details',
              key: 'productsDetails',
              url: '/ecommerce/product-details',
              pro: true,
            },
            {
              title: 'Products Edit',
              key: 'productsEdit',
              url: '/ecommerce/product-edit',
              pro: true,
            },
            {
              title: 'Products List',
              key: 'productsList',
              url: '/ecommerce/products-list',
              pro: true,
            },
            {
              title: 'Orders',
              key: 'orders',
              url: '/ecommerce/orders',
              pro: true,
            },
            {
              title: 'Cart',
              key: 'cart',
              url: '/ecommerce/cart',
              pro: true,
            },
          ],
        },
        {
          title: 'Apps',
          key: 'apps',
          children: [
            {
              title: 'Messaging',
              key: 'messaging',
              url: '/apps/messaging',
              pro: true,
            },
            {
              title: 'Mail',
              key: 'mail',
              url: '/apps/mail',
              pro: true,
            },
            {
              title: 'Profile',
              key: 'profile',
              url: '/apps/profile',
              pro: true,
            },
            {
              title: 'Gallery',
              key: 'gallery',
              url: '/apps/gallery',
              pro: true,
            },
          ],
        },
        {
          title: 'Blog',
          key: 'blog',
          children: [
            {
              title: 'Feed',
              key: 'blogFeed',
              url: '/blog/feed',
              pro: true,
            },
            {
              title: 'Post',
              key: 'blogPost',
              url: '/blog/post',
              pro: true,
            },
            {
              title: 'Add Post',
              key: 'blogAddPost',
              url: '/blog/add-blog-post',
              pro: true,
            },
          ],
        },
        {
          title: 'YouTube',
          key: 'youtube',
          children: [
            {
              title: 'Feed',
              key: 'youtubeFeed',
              url: '/youtube/feed',
              pro: true,
            },
            {
              title: 'View',
              key: 'youtubeView',
              url: '/youtube/view',
              pro: true,
            },
          ],
        },
        {
          title: 'GitHub',
          key: 'github',
          children: [
            {
              title: 'Explore',
              key: 'githubExplore',
              url: '/github/explore',
              pro: true,
            },
            {
              title: 'Discuss',
              key: 'githubDiscuss',
              url: '/github/discuss',
              pro: true,
            },
          ],
        },
      ],
    },
    {
      title: 'AntDesign',
      key: 'antComponents',
      icon: 'icmn icmn-menu',
      url: '/antd',
    },
    {
      title: 'Components',
      key: 'pagesBlocks',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Charts',
          key: 'charts',
          children: [
            {
              title: 'Chartist',
              key: 'chartist',
              url: '/charts/chartist',
            },
            {
              title: 'Chart',
              key: 'chart',
              url: '/charts/chart',
              pro: true,
            },
            {
              title: 'Peity',
              key: 'peity',
              url: '/charts/peity',
              pro: true,
            },
            {
              title: 'C3',
              key: 'c3',
              url: '/charts/c3',
              pro: true,
            },
          ],
        },
        {
          title: 'Mail Templates',
          key: 'mailTemplates',
          url: '/layout/mail-templates',
          pro: true,
        },
        {
          title: 'Icons',
          key: 'icons',
          children: [
            {
              title: 'FontAwesome',
              key: 'fontAwesome',
              url: '/icons/fontawesome',
            },
            {
              title: 'Linear',
              key: 'linear',
              url: '/icons/linear',
            },
            {
              title: 'Icomoon',
              key: 'icoMoon',
              url: '/icons/icomoon',
            },
          ],
        },
        {
          title: 'Bootstrap Grid',
          key: 'bootstrap',
          url: '/layout/bootstrap',
        },
        {
          title: 'Bootstrap Card',
          key: 'card',
          url: '/layout/card',
        },
        {
          title: 'Typography',
          key: 'typography',
          url: '/layout/typography',
        },
        {
          title: 'Utilities',
          key: 'utilities',
          url: '/layout/utilities',
        },
        {
          title: 'Nested Items',
          key: 'nestedItem1',
          disabled: true,
          children: [
            {
              title: 'Nested Item 1-1',
              key: 'nestedItem1-1',
              children: [
                {
                  title: 'Nested Item 1-1-1',
                  key: 'nestedItem1-1-1',
                },
                {
                  title: 'Nested Items 1-1-2',
                  key: 'nestedItem1-1-2',
                  disabled: true,
                },
              ],
            },
            {
              title: 'Nested Items 1-2',
              key: 'nestedItem1-2',
            },
          ],
        },
        {
          title: 'Disabled Item',
          key: 'disabledItem',
          disabled: true,
        },
      ],
    },
  ]
}
