import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'shared-crm/components/LayoutComponents/Loader'
import PublicLayout from './Public'
import { LoginLayout_1, LoginLayout_2 } from '../layouts/Login'
import { MainLayout_1, MainLayout_2, MainLayout_3, MainLayout_4, MainLayout_5 } from './Main'
import { STORAGE } from '../config'
import LoginLayout from '../../pages/login/layout/index'
import RegisterLayout from '../../pages/register/layout/index'

const Layouts = {
  1: {
    public: PublicLayout,
    login: LoginLayout,
    main: MainLayout_1,
  },
  2: {
    public: PublicLayout,
    login: LoginLayout,
    main: MainLayout_2,
  },
  3: {
    public: PublicLayout,
    login: LoginLayout, //temporary
    main: MainLayout_3,
  },
  4: {
    public: PublicLayout,
    login: LoginLayout,
    main: MainLayout_4,
  },
  5: {
    public: PublicLayout,
    login: LoginLayout,
    register: RegisterLayout,
    main: MainLayout_5,
  },
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentWillMount() {
    /**
     * Functions: check the initial load of the page, whether verified or not.
     */
    let url = window.location.href
    let urlID
    if (url.includes('?') && url.split('?').length > 1 && url.split('?')[1].includes('user_id')) {
      urlID = url.split('?')[1].split('=')[1]
      localStorage.setItem(STORAGE.USER_ID, urlID)
    }

    const { dispatch, user } = this.props
    const user_id = localStorage.getItem(STORAGE.USER_ID)
    if (user.id === '' && user_id) {
      dispatch({
        type: 'user/LOAD_ME',
        payload: {
          user_id,
        },
      })
    } else {
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          init: false,
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { location, dispatch } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      // window.scrollTo(0, 0)
    }
    if (prevProps.user.user !== this.props.user.user) {
      if (this.props.user.user) {
        let value
        switch (this.props.user.user.theme) {
          case 'light':
            value = true
            break
          case 'dark':
            value = false
            break
          case 'device':
            value = window.matchMedia('(prefers-color-scheme: light)').matches ? true : false
            break
          default:
            value = true
            break
        }
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'isLightTheme',
            value: value,
          },
        })
      }
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/' || pathname === '/webtraderMT5') {
        return 'public'
      }
      if (
        user.brandSettings_layout == 5 &&
        (pathname === '/user/register' || pathname === '/user/register-demo')
      ) {
        return 'register'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[user.brandSettings_layout][getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.init
    const isLoginLayout = getLayout() === 'login'
    const isRegisterLayout = getLayout() === 'register'

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout && !isRegisterLayout) {
        return <Loader />
      }
      // show loader when brand settings is not ready yet
      if (!isLoginLayout & !isRegisterLayout && user.brandSettingsLoading) {
        return <Loader />
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isRegisterLayout && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      }
      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        return (
          <Redirect
            to={{
              pathname: '/dashboard/summary',
              state: { prevPath: 'login' },
            }}
          />
        )
      }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet
          titleTemplate={`${
            user.brandSettings && user.brandSettings.company_name_short
              ? user.brandSettings.company_name_short
              : ''
          } | %s`}
          title="Trader's Room"
        />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
