import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Divider, Modal, Menu, Form, Select } from 'antd'
import moment from 'moment'
import { external, modules } from '../../../../constants'
import { EditNavigation5 } from '../../../LayoutComponents/EditNavigation'
import config from 'api/config'
import { Link } from 'react-router-dom'

const FormItem = Form.Item
const { Option } = Select
const { SubMenu } = Menu

@connect(({ user }) => ({ user }))
@connect(({ settings }) => ({ settings }))
@injectIntl
class SidebarMenu extends React.Component {
  state = {
    userPreferenceModules: [],
    openKeys: [],
    lastOpenKeys: '',
    moreModalVisible: false,
    editNavigationModalVisible: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props
    if (prevProps.user.id !== this.props.user.id) {
      dispatch({
        type: 'user/SIDEBAR_MODULE_USER_SETTINGS',
        payload: {
          user_id: this.props.user.id,
        },
      })
    }
  }

  convertTextToLowercase = (text) => {
    if (text === undefined || text === null || text === '') {
      return text
    }
    return text
      .toLowerCase()
      .replace('-', '_')
      .replace(/ /g, '_')
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
  }

  getPathName = (moduleName) => {
    var lowerCaseModuleName = this.convertTextToLowercase(moduleName)
    return modules[lowerCaseModuleName]
  }

  getModuleName = (pathName) => {
    let moduleName = Object.keys(modules).find((key) => modules[key] === pathName)
    return moduleName
  }

  onOpenChange = (openKeys) => {
    // console.log('Open Key', openKeys[0])
    let selectedKeys = []
    if (openKeys.length > 1) {
      selectedKeys.push(openKeys[1])
    } else {
      selectedKeys.push(openKeys[0])
    }
    this.setState({ lastOpenKeys: openKeys, openKeys: selectedKeys })
    // console.log('Open Key', this.state.openKeys)
  }

  showMoreModal = () => {
    const { toggleSidebarMenu } = this.props
    toggleSidebarMenu()
    this.setState({
      moreModalVisible: true,
      editNavigationModalVisible: false,
    })
  }

  toggleModalVisible = () => {
    const { toggleSidebarMenu } = this.props
    // event.preventDefault()
    toggleSidebarMenu()
    this.setState({
      moreModalVisible: false,
      editNavigationModalVisible: false,
    })
  }

  generateMoreItem = (sideBarModulesUserSettings) => {
    const {
      user: { brandSettings },
      intl: { formatMessage },
    } = this.props
    const MAX_MENU_ITEM = 5
    let otherModules = sideBarModulesUserSettings.filter(
      (item) => !item.web_default || item.web_default > MAX_MENU_ITEM,
    )

    let groupTypes = [
      ...new Set(
        otherModules.map((mod) => {
          if (mod.enabled) {
            return mod.group_type
          }
        }),
      ),
    ]

    //move Others group to the last
    if (groupTypes.indexOf('Others')) {
      var last_index = groupTypes.length - 1
      if (last_index >= groupTypes.length) {
        var k = last_index - groupTypes.length + 1
        while (k--) {
          groupTypes.push(undefined)
        }
      }
      groupTypes.splice(last_index, 0, groupTypes.splice(groupTypes.indexOf('Others'), 1)[0])
      groupTypes = groupTypes.filter((groupType) => groupType !== undefined)
    }
    return (
      <div id="moreModal" className="col">
        <div className="row content">
          <div className="col">
            {groupTypes.map((group, index) => {
              return (
                <div key={index}>
                  <div className="row moreHeaderItem">
                    <span>
                      {formatMessage({
                        id: 'module.group_type.' + this.convertTextToLowercase(group),
                      })}
                    </span>
                  </div>
                  {otherModules
                    .filter(
                      (item) =>
                        item.group_type === group &&
                        item.enabled === 1 &&
                        item.sub_modules.length === 0,
                    )
                    .map((module, index) => {
                      return external[this.convertTextToLowercase(module.name)] ? (
                        <a
                          key={index}
                          href={`${config.websiteURL}${
                            external[this.convertTextToLowercase(module.name)]
                          }`}
                          onClick={() => this.setState({ moreModalVisible: false })}
                          className="row moreItem"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </a>
                      ) : (
                        <Link
                          key={index}
                          onClick={() => {
                            this.setState({ moreModalVisible: false })
                            // if (isMobileView) {
                            //   this.props.toggleOpen()
                            // }
                          }}
                          className="row moreItem"
                          to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </Link>
                      )
                    })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="ant-dropdown-menu-item-divider divider"></div>
        <div className="row footer">
          <a
            onClick={() => {
              this.setState({ moreModalVisible: false, editNavigationModalVisible: true })
            }}
            className="editNavigationButton"
          >
            <span>
              {formatMessage({
                id: 'menuleft.editnavigation.edit_navigation',
              })}
            </span>
          </a>
        </div>
      </div>
    )
  }

  render() {
    const {
      user: { brandSettings, sideBarModulesUserSettings, brandSettings_layout },
      intl: { formatMessage },
      settings: { isLightTheme },
      show,
      preferenceModule,
      toggleSidebarMenu,
    } = this.props

    const { moreModalVisible, editNavigationModalVisible } = this.state

    const moreModal = this.generateMoreItem(sideBarModulesUserSettings)

    return (
      <div id="sidebar-menu" className={show ? 'show' : 'hide'}>
        <div>
          <div className="logo-wrapper">
            <Link
              onClick={this.toggleModalVisible}
              to={'/dashboard/summary'}
            >
              <img
                className="logo pr-2"
                src={isLightTheme ? brandSettings.company_logo : brandSettings.company_logo_inverse}
                alt={
                  brandSettings && brandSettings.company_name_short
                    ? brandSettings.company_name_short
                    : ''
                }
              />
            </Link>
          </div>
          <div className="sidebar-menu-modules">
            <Menu
              theme={isLightTheme ? 'light' : 'dark'}
              // theme={isLightTheme ? 'light' : 'dark'}
              //   onClick={this.handleClick}
              openKeys={this.state.openKeys}
              onOpenChange={this.onOpenChange}
              // selectedKeys={selectedKeys}
              //   openKeys={openedKeys}
              //   onOpenChange={this.onOpenChange}
              mode="inline"
              //   className={styles.navigation}
            >
              {preferenceModule.map((module) => {
                if (module.sub_modules.length !== 0) {
                  return (
                    <SubMenu
                      title={formatMessage({
                        id: 'module.' + this.convertTextToLowercase(module.name),
                      })}
                      key={module.key}
                    >
                      {module.sub_modules.map((sub_module) => {
                        if (external[this.convertTextToLowercase(sub_module.name)] !== undefined) {
                          return (
                            <Menu.Item
                              key={this.convertTextToLowercase(sub_module.name)}
                              disabled={module.enabled === 0}
                            >
                              <a
                                href={`${config.websiteURL}${
                                  external[this.convertTextToLowercase(sub_module.name)]
                                }`}
                                onClick={this.toggleModalVisible}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span>
                                  {formatMessage({
                                    id: 'module.' + this.convertTextToLowercase(sub_module.name),
                                  })}
                                </span>
                              </a>
                            </Menu.Item>
                          )
                        } else {
                          return (
                            <Menu.Item
                              key={this.convertTextToLowercase(sub_module.name)}
                              disabled={module.enabled === 0}
                            >
                              <Link
                                onClick={this.toggleModalVisible}
                                to={
                                  this.getPathName(sub_module.name)
                                    ? this.getPathName(sub_module.name)
                                    : '/'
                                }
                              >
                                <span>
                                  {formatMessage({
                                    id: 'module.' + this.convertTextToLowercase(sub_module.name),
                                  })}
                                </span>
                              </Link>
                            </Menu.Item>
                          )
                        }
                      })}
                    </SubMenu>
                  )
                } else {
                  if (external[this.convertTextToLowercase(module.name)] !== undefined) {
                    return (
                      <Menu.Item
                        key={this.convertTextToLowercase(module.name)}
                        disabled={module.enabled === 0}
                      >
                        <a
                          href={`${config.websiteURL}${
                            external[this.convertTextToLowercase(module.name)]
                          }`}
                          onClick={this.toggleModalVisible}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {formatMessage({
                              id: 'module.' + this.convertTextToLowercase(module.name),
                            })}
                          </span>
                        </a>
                      </Menu.Item>
                    )
                  } else {
                    if (module.name === 'More') {
                      return (
                        <Menu.Item
                          key={this.convertTextToLowercase(module.name)}
                          disabled={module.enabled === 0}
                        >
                          <a
                            onClick={() => {
                              this.showMoreModal()
                            }}
                          >
                            <span>
                              {formatMessage({
                                id: 'module.' + this.convertTextToLowercase(module.name),
                              })}
                            </span>
                          </a>
                        </Menu.Item>
                      )
                    } else {
                      return (
                        <Menu.Item
                          key={this.convertTextToLowercase(module.name)}
                          disabled={module.enabled === 0}
                        >
                          <Link
                            onClick={this.toggleModalVisible}
                            to={this.getPathName(module.name) ? this.getPathName(module.name) : '/'}
                          >
                            <span>
                              {formatMessage({
                                id: 'module.' + this.convertTextToLowercase(module.name),
                              })}
                            </span>
                          </Link>
                        </Menu.Item>
                      )
                    }
                  }
                }
              })}
            </Menu>
          </div>
        </div>
        <Modal
          getContainer="#sidebar-menu"
          title={formatMessage({
            id: 'module.more',
          })}
          visible={moreModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal moreModal`}
        >
          {moreModal}
        </Modal>

        <Modal
          getContainer={brandSettings_layout === 5 ? '#topbarcontainer' : '#topbar'}
          title={formatMessage({
            id: 'menuleft.editnavigation.edit_navigation',
          })}
          visible={editNavigationModalVisible}
          onOk={this.toggleModalVisible}
          onCancel={this.toggleModalVisible}
          destroyOnClose={true}
          footer={null}
          className={`ant_modal edit_navigation_modal`}
        >
          <div className={`row ant_modal`}>
            <EditNavigation5 handleCancel={this.toggleModalVisible} />
          </div>
        </Modal>
      </div>
    )
  }
}

export default SidebarMenu
