import { COMMUNITY_GET_USER_PHOTOS } from "./sagas"

const actions = {
  SET_STATE: 'user/SET_STATE',
  BRAND_SETTINGS: 'user/BRAND_SETTINGS',
  BRAND_SETTINGS_TNC_PRIVACY: 'user/BRAND_SETTINGS_TNC_PRIVACY',
  MODULE_SETTINGS: 'user/MODULE_SETTINGS',
  SIDEBAR_MODULE_SETTINGS: 'user/SIDEBAR_MODULE_SETTINGS',
  SIDEBAR_MODULE_USER_SETTINGS: 'user/SIDEBAR_MODULE_USER_SETTINGS',
  SIDEBAR_MODULE_POST_USER_SETTINGS: 'user/SIDEBAR_MODULE_POST_USER_SETTINGS',
  LOGIN: 'user/LOGIN',
  GET_ME: 'user/GET_ME',
  LOAD_ME: 'user/LOAD_ME',
  SIGNUP_IN: 'individual/SIGN_UP',
  SIGNUP_CO: 'corporate/SIGN_UP',
  SIGNUP_DE: 'demo/SIGN_UP',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  LOGIN_HISTORY: 'user/LOGIN_HISTORY',
  WALLET_OPERATIONS: 'user/WALLET_OPERATIONS',
  WALLET_OPERATIONS_DETAILS: 'user/WALLET_OPERATIONS_DETAILS',
  WALLET_OPERATIONS_DETAILS_DATA: 'user/WALLET_OPERATIONS_DETAILS_DATA',
  UPDATE_RESIDENCE: 'user/UPDATE_RESIDENCE',
  UPDATE_USER_INFO: 'user/UPDATE_USER_INFO',
  UPDATE_NEWSLETTER : 'user/UPDATE_NEWSLETTER',
  UPDATE_PROFILE: 'user/UPDATE_PROFILE',
  UPDATE_LANGUAGE: 'user/UPDATE_LANGUAGE',
  UPGRADE_CORPORATE: 'user/UPGRADE_CORPORATE',
  CORPORATE_INFO: 'user/CORPORATE_INFO',
  GET_BANK_DETAIL: 'user/GET_BANK_DETAIL',
  UPDATE_BANK_DETAIL: 'user/UPDATE_BANK_DETAIL',
  FOREVERPAY_BANK: 'user/FOREVERPAY_BANK',
  FOREVERPAY_BANK_STATE: 'user/FOREVERPAY_BANK_STATE',
  FOREVERPAY_BANK_CITY: 'user/FOREVERPAY_BANK_CITY',
  UPDATE_FOREVERPAY_BANK: 'user/UPDATE_FOREVERPAY_BANK',
  PAYTRUST_BANK: 'user/PAYTRUST_BANK',
  UPDATE_PAYTRUST_BANK: 'user/UPDATE_PAYTRUST_BANK',
  UPDATE_FASAPAY: 'user/UPDATE_FASAPAY',
  HELPPAY_BANK: 'user/HELPPAY_BANK',
  UPDATE_HELPPAY_BANK: 'user/UPDATE_HELPPAY_BANK',
  EEZIEPAY_BANK: 'user/EEZIEPAY_BANK',
  UPDATE_EEZIEPAY_BANK: 'user/UPDATE_EEZIEPAY_BANK',
  EEZIEPAY_INR_BANK: 'user/EEZIEPAY_INR_BANK',
  UPDATE_EEZIEPAY_INR_BANK: 'user/UPDATE_EEZIEPAY_INR_BANK',
  XPAY_BANK: 'user/XPAY_BANK',
  UPDATE_XPAY_BANK: 'user/UPDATE_XPAY_BANK',
  ANPAY_BANK: 'user/ANPAY_BANK',
  UPDATE_ANPAY_BANK: 'user/UPDATE_ANPAY_BANK',
  PAYBNB_BANK: 'user/PAYBNB_BANK',
  UPDATE_PAYBNB_BANK: 'user/UPDATE_PAYBNB_BANK',
  FIVEPAY_BANK: 'user/FIVEPAY_BANK',
  UPDATE_FIVEPAY_BANK: 'user/UPDATE_FIVEPAY_BANK',
  DIRECTPAY_BANK: 'user/DIRECTPAY_BANK',
  PAPAYA_PAY_BANK: 'user/PAPAYA_PAY_BANK',
  UPDATE_PAPAYA_PAY_BANK: 'user/UPDATE_PAPAYA_PAY_BANK',
  ADD_WITHDRAWAL: 'user/ADD_WITHDRAWAL',
  GET_KYC: 'user/GET_KYC',
  UPLOAD_KYC: 'user/UPLOAD_KYC',
  HISTORY_KYC: 'user/HISTORY_KYC',
  GET_SELF_TRADING: 'user/GET_SELF_TRADING',
  GET_SELF_TRADING_DEMO: 'user/GET_SELF_TRADING_DEMO',
  GET_SELF_TRADING_FULL: 'user/GET_SELF_TRADING_FULL',
  GET_SELF_TRADING_DEMO_FULL: 'user/GET_SELF_TRADING_DEMO_FULL',
  GET_AFFILIATE_TREE: 'user/GET_AFFILIATE_TREE',
  AFFILIATE_CLIENT_LISTING_INITIAL: 'user/AFFILIATE_CLIENT_LISTING_INITIAL',
  AFFILIATE_CLIENT_LISTING: 'user/AFFILIATE_CLIENT_LISTING',
  AFFILIATE_COMMISSION_HISTORY: 'user/AFFILIATE_COMMISSION_HISTORY',
  AFFILIATE_CLIENT_TRADING_TXS_INITIAL: 'user/AFFILIATE_CLIENT_TRADING_TXS_INITIAL',
  AFFILIATE_CLIENT_TRADING_TXS: 'user/AFFILIATE_CLIENT_TRADING_TXS',
  AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS_INITIAL:
    'user/AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS_INITIAL',
  AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS: 'user/AFFILIATE_CLIENT_DEPOSIT_WITHDRAWAL_TXS',
  NETWORK_MT4: 'user/NETWORK_MT4',
  DEPOSIT_MT4: 'user/DEPOSIT_MT4',
  TRADING_PRODUCT: 'user/TRADING_PRODUCT',
  CHANGE_MT4_PASSWORD: 'user/CHANGE_MT4_PASSWORD',
  CHANGE_MT4_INVESTOR_PASSWORD: 'user/CHANGE_MT4_INVESTOR_PASSWORD',
  SUBMIT_TICKET: 'user/SUBMIT_TICKET',
  GET_TICKETS: 'user/GET_TICKETS',
  OPEN_MT4: 'user/OPEN_MT4',
  MT4_LIVE_INFO: 'user/MT4_LIVE_INFO',
  MT4_MARGIN_INFO: 'user/MT4_MARGIN_INFO',
  MT4_BALANCE_CREDIT: 'user/MT4_BALANCE_CREDIT',
  WITHDRAWAL_MT4: 'user/WITHDRAWAL_MT4',
  TICKET_DETAIL: 'user/TICKET_DETAIL',
  SUBMIT_TICKET_DETAIL: 'user/SUBMIT_TICKET_DETAIL',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  UPLOAD_PROFILE_PIC: 'user/UPLOAD_PROFILE_PIC',
  DEPOSIT_COMPANY_CRYPTO_WALLET: 'user/DEPOSIT_COMPANY_CRYPTO_WALLET',
  GET_DEPOSIT_HISTORY: 'user/GET_DEPOSIT_HISTORY',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  BANK_DEPOSIT: 'user/BANK_DEPOSIT',
  NGAN_DEPOSIT: 'user/NGAN_DEPOSIT',
  WITHDRAWAL_HISTORY: 'user/WITHDRAWAL_HISTORY',
  BALANCE_GRAPH: 'user/BALANCE_GRAPH',
  MARKET_NEWS: 'user/MARKET_NEWS',
  NEWS_REDIRECT: 'user/NEWS_REDIRECT',
  CANCEL_WITHDRAWAL: 'user/CANCEL_WITHDRAWAL',
  CURRENCY_RATE: 'user/CURRENCY_RATE',
  PSP_CURRENCY_RATE: 'user/PSP_CURRENCY_RATE',
  PSP_RATE_FEE: 'user/PSP_RATE_FEE',
  CRYPTO_RATE: 'user/CRYPTO_RATE',
  GO_LIVE: 'user/GO_LIVE',
  CONFIRM_API: 'user/CONFIRM_API',
  CANCEL_API: 'user/CANCEL_API',
  MASTER_TRADERS: 'user/MASTER_TRADERS',
  MASTER_TRADER: 'user/MASTER_TRADER',
  UPDATE_TRADER: 'user/UPDATE_TRADER',
  UPDATE_TRADER_SELF_TRADING: 'user/UPDATE_TRADER_SELF_TRADING',
  UPDATE_TRADER_PHOTO: 'user/UPDATE_TRADER_PHOTO',
  MASTER_FOLLOWERS: 'user/MASTER_FOLLOWERS',
  MASTER_OPEN_TRADES: 'user/MASTER_OPEN_TRADES',
  MASTER_CLOSE_TRADES: 'user/MASTER_CLOSE_TRADES',
  FOLLOWING_LIST: 'user/FOLLOWING_LIST',
  FOLLOWER_TRADES_OPEN: 'user/FOLLOWER_TRADES_OPEN',
  FOLLOWER_TRADES_CLOSE: 'user/FOLLOWER_TRADES_CLOSE',
  FOLLOWER_CLOSE_TRADE: 'user/FOLLOWER_CLOSE_TRADE',
  WATCHLIST: 'user/WATCHLIST',
  UPDATE_WATCHLIST: 'user/UPDATE_WATCHLIST',
  PAUSE_COPY: 'user/PAUSE_COPY',
  RESUME_COPY: 'user/RESUME_COPY',
  STOP_COPY: 'user/STOP_COPY',
  START_COPY: 'user/START_COPY',
  MASTER_SYMBOLS: 'user/MASTER_SYMBOLS',
  UPDATE_COPY_SETTINGS: 'user/UPDATE_COPY_SETTINGS',
  GET_COPY_SETTINGS: 'user/GET_COPY_SETTINGS',
  MT4_STATS: 'user/MT4_STATS',
  MT4_PROFITS_CHART: 'user/MT4_PROFITS_CHART',
  MT4_EQUITY_BALANCE_CHART_7D: 'user/MT4_EQUITY_BALANCE_CHART_7D',
  MT4_EQUITY_BALANCE_CHART_1M: 'user/MT4_EQUITY_BALANCE_CHART_1M',
  MT4_EQUITY_BALANCE_CHART_6M: 'user/MT4_EQUITY_BALANCE_CHART_6M',
  MT4_EQUITY_BALANCE_CHART_1Y: 'user/MT4_EQUITY_BALANCE_CHART_1Y',
  MT4_MONTHLY_PL: 'user/MT4_MONTHLY_PL',
  MT4_SKILLED_SYMBOLS_CHART: 'user/MT4_SKILLED_SYMBOLS_CHART',
  MT4_MOST_TRADED_CHART: 'user/MT4_MOST_TRADED_CHART',
  MT4_HOLDING_TIME_CHART: 'user/MT4_HOLDING_TIME_CHART',
  PAYMENT_METHOD: 'user/PAYMENT_METHOD',
  PENDING_WITHDRAWALS_BY_MT4: 'user/PENDING_WITHDRAWALS_BY_MT4',
  MONEY_MANAGER_MT4: 'user/MONEY_MANAGER_MT4',
  MONEY_MANAGERS: 'user/MONEY_MANAGERS',
  MONEY_MANAGER: 'user/MONEY_MANAGER',
  UPDATE_MONEY_MANAGER: 'user/UPDATE_MONEY_MANAGER',
  UPDATE_MONEY_MANAGER_SETTING: 'user/UPDATE_MONEY_MANAGER_SETTING',
  UPDATE_MONEY_MANAGER_PHOTO: 'user/UPDATE_MONEY_MANAGER_PHOTO',
  INVEST_MONEY_MANAGER: 'user/INVEST_MONEY_MANAGER',
  WITHDRAW_MONEY_MANAGER: 'user/WITHDRAW_MONEY_MANAGER',
  INVESTING: 'user/INVESTING',
  INVESTOR_DEPOSITS: 'user/INVESTOR_DEPOSITS',
  INVESTOR_DEPOSIT_ACTION: 'user/INVESTOR_DEPOSIT_ACTION',
  INVESTOR_WITHDRAWALS: 'user/INVESTOR_WITHDRAWALS',
  INVESTOR_WITHDRAWAL_ACTION: 'user/INVESTOR_WITHDRAWAL_ACTION',
  INVESTOR_SL: 'user/INVESTOR_SL',
  INVESTOR_DETACH: 'user/INVESTOR_DETACH',
  MONEY_MANAGER_INVESTORS: 'user/MONEY_MANAGER_INVESTORS',
  MONEY_MANAGER_TRADES_OPEN: 'user/MONEY_MANAGER_TRADES_OPEN',
  MONEY_MANAGER_TRADES_CLOSE: 'user/MONEY_MANAGER_TRADES_CLOSE',
  LIQUIDITY_POOL_TNC: 'user/LIQUIDITY_POOL_TNC',
  LIQUIDITY_POOL_WALLET_DATA: 'user/LIQUIDITY_POOL_WALLET_DATA',
  LIQUIDITY_POOL_WALLET_HISTORY: 'user/LIQUIDITY_POOL_WALLET_HISTORY',
  LIQUIDITY_POOL_COMPANY_WALLET_ADDRESS: 'user/LIQUIDITY_POOL_COMPANY_WALLET_ADDRESS',
  LIQUIDITY_POOL_DEPOSIT: 'user/LIQUIDITY_POOL_DEPOSIT',
  LIQUIDITY_POOL_WITHDRAW: 'user/LIQUIDITY_POOL_WITHDRAW',
  LIQUIDITY_POOL_STAKE: 'user/LIQUIDITY_POOL_STAKE',
  LIQUIDITY_POOL_STAKE_DATA: 'user/LIQUIDITY_POOL_STAKE_DATA',
  LIQUIDITY_POOL_STAKE_HISTORY: 'user/LIQUIDITY_POOL_STAKE_HISTORY',
  LIQUIDITY_POOL_STAKE_WITHDRAW: 'user/LIQUIDITY_POOL_STAKE_WITHDRAW',
  TASK_CENTRE_TASK_LISTING: 'user/TASK_CENTRE_TASK_LISTING',
  TASK_CENTRE_CLAIM_TASK: 'user/TASK_CENTRE_CLAIM_TASK',
  TASK_CENTRE_COMPLETE_TASK: 'user/TASK_CENTRE_COMPLETE_TASK',
  TASK_CENTRE_FORFEIT_TASK: 'user/TASK_CENTRE_FORFEIT_TASK',
  TASK_CENTRE_CLOSE_SUCCESSFUL_MODAL: 'user/TASK_CENTRE_CLOSE_SUCCESSFUL_MODAL',
  REWARD_CENTRE_REWARD_LISTING: 'user/REWARD_CENTRE_REWARD_LISTING',
  REWARD_CENTRE_REDEEM_VOUCHER: 'user/REWARD_CENTRE_REDEEM_VOUCHER',
  REWARD_CENTRE_REDEEM_VOUCHER_CODE: 'user/REWARD_CENTRE_REDEEM_VOUCHER_CODE',
  REWARD_CENTRE_CHOOSE_PRIZE: 'user/REWARD_CENTRE_CHOOSE_PRIZE',
  REWARD_CENTRE_CLOSE_SUCCESSFUL_MODAL: 'user/REWARD_CENTRE_CLOSE_SUCCESSFUL_MODAL',
  REWARD_CENTRE_CLOSE_CASH_SUCCESS_MODAL: 'user/REWARD_CENTRE_CLOSE_CASH_SUCCESS_MODAL',
  REWARD_CENTRE_CLOSE_LUCKY_DRAW_SUCCESS_MODAL: 'user/REWARD_CENTRE_CLOSE_LUCKY_DRAW_SUCCESS_MODAL',
  HWGC_STATUS: 'user/HWGC_STATUS',
  HWGC_SIGN_UP: 'user/HWGC_SIGN_UP',
  HWGC_RATE: 'user/HWGC_RATE',
  HWGC_BANK_WIRE: 'user/HWGC_BANK_WIRE',
  HWGC_BANK_ACCOUNT: 'user/HWGC_BANK_ACCOUNT',
  HWGC_UPDATE_BANK: 'user/HWGC_UPDATE_BANK',
  HWGC_CARD: 'user/HWGC_CARD',
  HWGC_APPLY_CARD: 'user/HWGC_APPLY_CARD',
  HWGC_BIND_CARD: 'user/HWGC_BIND_CARD',
  HWGC_UPDATE_EMAIL: 'user/HWGC_UPDATE_EMAIL',
  HWGC_UPDATE_MOBILE: 'user/HWGC_UPDATE_MOBILE',
  SPREAD_GROUP_SYMBOL_PRICE: 'user/SPREAD_GROUP_SYMBOL_PRICE',
  SPREAD_GROUP_SYMBOL_PRICE_CTRADER: 'user/SPREAD_GROUP_SYMBOL_PRICE_CTRADER',
  SYMBOL_VIEWS: 'user/SYMBOL_VIEWS',
  SYMBOL_UPDATE_VIEWS: 'user/SYMBOL_UPDATE_VIEWS',
  SYMBOL_DETAILS: 'user/SYMBOL_DETAILS',
  SYMBOL_CATEGORY: 'user/SYMBOL_CATEGORY',
  OHLC: 'user/OHLC',
  MODULE_MARKETS_DATA: 'user/MODULE_MARKETS_DATA',
  TRADE_SYMBOL: 'user/TRADE_SYMBOL',
  FAVOURITE_SYMBOL: 'user/FAVOURITE_SYMBOL',
  ADD_FAVOURITE_SYMBOL: 'user/ADD_FAVOURITE_SYMBOL',
  REMOVE_FAVOURITE_SYMBOL: 'user/REMOVE_FAVOURITE_SYMBOL',
  MARKETS_OPEN_TRADES: 'user/MARKETS_OPEN_TRADES',
  MARKETS_TRADES: 'user/MARKETS_TRADES',
  MARKETS_TRADE_OPEN: 'user/MARKETS_TRADE_OPEN',
  MARKETS_TRADE_CLOSE: 'user/MARKETS_TRADE_CLOSE',
  MARKETS_TRADE_CLOSE_SYMBOL: 'user/MARKETS_TRADE_CLOSE_SYMBOL',
  MARKETS_TRADE_CLOSE_BY: 'user/MARKETS_TRADE_CLOSE_BY',
  MARKETS_TRADE_MODIFY: 'user/MARKETS_TRADE_MODIFY',
  MARKETS_TRADE_DELETE_PENDING: 'user/MARKETS_TRADE_DELETE_PENDING',
  MARKETS_JOURNAL: 'user/MARKETS_JOURNAL',
  TRADE_IDEAS: 'user/TRADE_IDEAS',
  SMART_SCORE: 'user/SMART_SCORE',
  AI_TECH_SCREENER: 'user/AI_TECH_SCREENER',
  AI_TECH_SCREENER_EVENT_TYPES: 'user/AI_TECH_SCREENER_EVENT_TYPES',
  AI_TECH_SCREENER_EVENTS: 'user/AI_TECH_SCREENER_EVENTS',
  AI_TECH_SCREENER_EVENT_COUNTS: 'user/AI_TECH_SCREENER_EVENT_COUNTS',
  AI_TECH_SCREENER_TECHNICAL_SUMMARIES: 'user/AI_TECH_SCREENER_TECHNICAL_SUMMARIES',
  AI_TECH_SCREENER_SUPPORT_RESISTANCE: 'user/AI_TECH_SCREENER_SUPPORT_RESISTANCE',
  AI_TECH_SCREENER_STOP_PRICE: 'user/AI_TECH_SCREENER_STOP_PRICE',
  ECONOMIC_CALENDAR: 'user/ECONOMIC_CALENDAR',
  ECONOMIC_CALENDAR_EVENT_CHART: 'user/ECONOMIC_CALENDAR_EVENT_CHART',
  ECONOMIC_CALENDAR_CURRENCY_SYMBOL: 'user/ECONOMIC_CALENDAR_CURRENCY_SYMBOL',
  ECONOMIC_CALENDAR_VOLATILITY: 'user/ECONOMIC_CALENDAR_VOLATILITY',
  ECONOMIC_CALENDAR_IMPACT: 'user/ECONOMIC_CALENDAR_IMPACT',
  RSS_NEWS: 'user/RSS_NEWS',
  PROMO_MATERIALS_MODAL: 'user/PROMO_MATERIALS_MODAL',
  PROMO_MATERIALS_COUNTER: 'user/PROMO_MATERIALS_COUNTER',
  PROMO_MATERIALS_DASHBOARD_BANNER: 'user/PROMO_MATERIALS_DASHBOARD_BANNER',
  PROMO_MATERIALS_DASHBOARD_VIDEO: 'user/PROMO_MATERIALS_DASHBOARD_VIDEO',
  PROMO_MATERIALS_DASHBOARD_LANDING: 'user/PROMO_MATERIALS_DASHBOARD_LANDING',
  PROMO_MATERIALS_DASHBOARD_GIF: 'user/PROMO_MATERIALS_DASHBOARD_GIF',
  PROMO_MATERIALS_FILTER_BANNER: 'user/PROMO_MATERIALS_FILTER_BANNER',
  PROMO_MATERIALS_FILTER_VIDEO: 'user/PROMO_MATERIALS_FILTER_VIDEO',
  PROMO_MATERIALS_FILTER_LANDING: 'user/PROMO_MATERIALS_FILTER_LANDING',
  PROMO_MATERIALS_FILTER_GIF: 'user/PROMO_MATERIALS_FILTER_GIF',
  PROMO_MATERIALS_FILTER_LOGO: 'user/PROMO_MATERIALS_FILTER_LOGO',
  LIVE_BROADCAST: 'user/LIVE_BROADCAST',
  CMS_INSTRUMENTS_CATEGORIES: 'user/CMS_INSTRUMENTS_CATEGORIES',
  CMS_INSTRUMENTS: 'user/CMS_INSTRUMENTS',
  CMS_EDUCATION_CATEGORIES_OLD: 'user/CMS_EDUCATION_CATEGORIES_OLD',
  CMS_EDUCATION_CATEGORIES: 'user/CMS_EDUCATION_CATEGORIES',
  GET_PROMOTION: 'user/GET_PROMOTION',
  GET_PROMOTION_NEW: 'user/GET_PROMOTION_NEW',
  COMMUNITY_AUTH:  'user/COMMUNITY_AUTH',
  COMMUNITY_GET_USER_DATA: 'user/COMMUNITY_GET_USER_DATA',
  COMMUNITY_POSTS:  'user/COMMUNITY_POSTS',
  COMMUNITY_GET_USER_POSTS:  'user/COMMUNITY_GET_USER_POSTS',
  POST_COMMUNITY_POST: 'user/POST_COMMUNITY_POST',
  POST_COMMENT: 'user/POST_COMMENT',
  FETCH_COMMENTS: 'user/FETCH_COMMENTS',
  FETCH_COMMENTS_REPLY: 'user/FETCH_COMMENTS_REPLY',
  COMMUNITY_GET_POST_DATA: 'user/COMMUNITY_GET_POST_DATA',
  POST_REPLY: 'user/POST_REPLY',
  POST_LIKE: 'user/POST_LIKE',
  COMMUNITY_CREATE_ALBUM: 'user/COMMUNITY_CREATE_ALBUM',
  COMMUNIITY_ADD_TO_ALBUM: 'user/COMMUNIITY_ADD_TO_ALBUM',
  COMMUNIITY_GET_RECOMMENDED: 'user/COMMUNIITY_GET_RECOMMENDED',
  COMMUNITY_GET_FOLLOWERS_LIST: 'user/COMMUNITY_GET_FOLLOWERS_LIST',
  COMMUNITY_GET_FOLLOWING_LIST: 'user/COMMUNITY_GET_FOLLOWING_LIST',
  COMMUNITY_GET_GROUP_DATA: 'user/COMMUNITY_GET_GROUP_DATA',
  COMMUNITY_EDIT_POST: 'user/COMMUNITY_EDIT_POST',
  COMMUNITY_DELETE_POST: 'user/COMMUNITY_DELETE_POST',
  COMMUNITY_DISABLE_POST_COMMENTS: 'user/COMMUNITY_DISABLE_POST_COMMENTS',
  COMMUNITY_SAVE_POST: 'user/COMMUNITY_SAVE_POST',
  COMMUNITY_REPORT_POST: 'user/COMMUNITY_REPORT_POST',
  // COMMUNITY_PHOTO_POST_FILTER: 'user/COMMUNITY_PHOTO_POST_FILTER',
  // COMMUNITY_VIDEO_POST_FILTER: 'user/COMMUNITY_VIDEO_POST_FILTER',
  COMMUNITY_FOLLOW_USER: 'user/COMMUNITY_FOLLOW_USER',
  COMMUNITY_GET_ADDITIONAL_POSTS: 'user/COMMUNITY_GET_ADDITIONAL_POSTS',
  COMMUNITY_GET_ADDITIONAL_NEWS_FEED: 'user/COMMUNITY_GET_ADDITIONAL_NEWS_FEED',
  COMMUNITY_GET_RSS_NEWS: 'user/COMMUNITY_GET_RSS_NEWS',
  COMMUNITY_SHARE_POST: 'user/COMMUNITY_SHARE_POST',
  COMMUNITY_HIDE_POST: 'user/COMMUNITY_HIDE_POST',
  COMMUNITY_GET_TRENDING_HASHTAG: 'user/COMMUNITY_GET_TRENDING_HASHTAG',
  COMMUNITY_LIKE_COMMENT: 'user/COMMUNITY_LIKE_COMMENT',
  COMMUNITY_GET_EVENTS_LIST: 'user/COMMUNITY_GET_EVENTS_LIST',
  COMMUNITY_GET_EVENT_GOING_LIST: 'user/COMMUNITY_GET_EVENT_GOING_LIST',
  COMMUNITY_GET_EVENT_INVITED_LIST: 'user/COMMUNITY_GET_EVENT_INVITED_LIST',
  COMMUNITY_GET_MY_EVENTS_LIST: 'user/COMMUNITY_GET_MY_EVENTS_LIST',
  COMMUNITY_GET_PAST_EVENTS_LIST: 'user/COMMUNITY_GET_PAST_EVENTS_LIST',
  COMMUNITY_GET_EVENT_INTERESTED_LIST: 'user/COMMUNITY_GET_EVENT_INTERESTED_LIST',
  COMMUNITY_JOIN_EVENT: 'user/COMMUNITY_JOIN_EVENT',
  COMMUNITY_CREATE_EVENT: 'user/COMMUNITY_CREATE_EVENT',
  COMMUNITY_PIN_POST: 'user/COMMUNITY_PIN_POST',
  COMMUNITY_GET_EVENT_DATA: 'user/COMMUNITY_GET_EVENT_DATA',
  COMMUNITY_GET_EVENT_POSTS: 'user/COMMUNITY_GET_EVENT_POSTS',
  COMMUNITY_INVITE_TO_EVENT: 'user/COMMUNITY_INVITE_TO_EVENT',
  COMMUNITY_GET_MY_EVENTS_CUSTOM_LIST: 'user/COMMUNITY_GET_MY_EVENTS_CUSTOM_LIST',
  COMMUNITY_SEARCH_ALL: 'user/COMMUNITY_SEARCH_ALL',
  COMMUNITY_SEARCH_USERS: 'user/COMMUNITY_SEARCH_USERS',
  COMMUNITY_SEARCH_TYPE: 'user/COMMUNITY_SEARCH_TYPE',
  COMMUNITY_UPDATE_EVENT: 'user/COMMUNITY_UPDATE_EVENT',
  COMMUNITY_GOING_TO_EVENT: 'user/COMMUNITY_GOING_TO_EVENT',
  COMMUNITY_INTERESTED_IN_EVENT: 'user/COMMUNITY_INTERESTED_IN_EVENT',
  COMMUNITY_SEARCH_EVENTS_LIST: 'user/COMMUNITY_SEARCH_EVENTS_LIST',
  COMMUNITY_GET_MOST_FOLLOWER_USERS: 'user/COMMUNITY_GET_MOST_FOLLOWER_USERS',
  COMMUNITY_DELETE_EVENT: 'user/COMMUNITY_DELETE_EVENT',
  COMMUNITY_GET_TERMS_AND_CONDITIONS: 'user/COMMUNITY_GET_TERMS_AND_CONDITIONS',
  COMMUNITY_EVENT_ACTIONS: 'user/COMMUNITY_EVENT_ACTIONS',
  COMMUNITY_SAVE_EVENT: 'user/COMMUNITY_SAVE_EVENT',
  COMMUNITY_REMOVE_FILES_FROM_POST: 'user/COMMUNITY_REMOVE_FILES_FROM_POST',
  COMMUNITY_REPORT_EVENT: 'user/COMMUNITY_REPORT_EVENT',
  COMMUNITY_UPDATE_POST_FEELING: 'user/COMMUNITY_UPDATE_POST_FEELING',
  COMMUNITY_GET_SAVED_EVENTS_LIST: 'user/COMMUNITY_GET_SAVED_EVENTS_LIST',
  COMMUNITY_GET_USER_PHOTOS: 'user/COMMUNITY_GET_USER_PHOTOS',
  COMMUNITY_GET_USER_VIDEOS: 'user/COMMUNITY_GET_USER_VIDEOS',
  COMMUNITY_GET_USER_SOUNDS: 'user/COMMUNITY_GET_USER_SOUNDS',
  COMMUNITY_GET_USER_DOCUMENTS: 'user/COMMUNITY_GET_USER_DOCUMENTS',
  COMMUNITY_REPORT_COMMENT: 'user/COMMUNITY_REPORT_COMMENT',
  COMMUNITY_REPORT_REPLY: 'user/COMMUNITY_REPORT_REPLY',
  LOYALTY_PROGRAM_LISTING: 'user/LOYALTY_PROGRAM_LISTING',
  LOYALTY_PROGRAM_COMPLETE_TASK: 'user/LOYALTY_PROGRAM_COMPLETE_TASK',
}

export default actions
